import {
  Button,
  Col,
  Image,
  Row,
  Segmented,
  Skeleton,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import segmentedStyles from "../../../assets/styles/Custom/Segmented.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";

import { useNavigate } from "react-router-dom";
import { TyutRender } from "../Helpers";
import {
  bookmarkTyutByIdApi,
  getAlTyutsApi,
} from "../../../services/tyuts.services";
import ShareTyutModal from "./ShareTyut/ShareTyutModal";
import { createWO } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserProvider";
import noCourses from "../../../assets/images/tyuts/notyuts.svg";
import TyutCourseTagSearch from "../TyutsSearch";
import CustomCard from "../../Blocks/CustomCard";

const Tyuts = ({ view }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    isOpen: false,
    tyut_id: null,
    creator_id: null,
  });
  const [loading, setLoading] = useState(false);
  const { userDetails } = useContext(UserContext);

  const [shareCourse, setShareCourse] = useState({
    share: false,
    courseId: null,
    creator_id: null,
  });

  async function getTyuts() {
    try {
      setLoading(true);
      const res = await getAlTyutsApi();
      setData(res.data);
    } catch (errors) {
      message.error(errors.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function bookmark(id, data) {
    try {
      const res = await bookmarkTyutByIdApi(id, data);
      if (res) {
        getTyuts();
        message.success(res?.data?.message);
      }
    } catch (errors) {
      message.error(errors.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  // useEffect(() => {
  //   getAllDraftsTyutAPI()
  //     .then((res) => {
  //       setDraftsData(res?.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [refresh]);

  useEffect(() => {
    getTyuts();
  }, []);

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Segmented
          id="tyuts-view"
          className={segmentedStyles.basicOne}
          options={[
            { value: "tyut", label: "Tyuts" },
            { value: "course", label: "Courses" },
          ]}
          onChange={(e) => navigate(`/trainings?type=${e}`)}
          value={view}
        />
      </Col>
      <Col
        span={12}
        className="flex-center"
        style={{ justifyContent: "flex-end" }}
      >
        <div style={{ width: "50%" }}>
          <TyutCourseTagSearch />
        </div>
        {createWO.includes(userDetails?.activeOrg?.role) && (
          <Button
            id="create-tyut"
            onClick={() => navigate("/trainings/tyut")}
            className={btnStyles.mdBtn}
            type="primary"
          >
            + Add Tyut
          </Button>
        )}
      </Col>
      {loading ? (
        <>
          {[...Array(4)].map((_, index) => (
            <Col
              xl={{ span: 6 }}
              lg={{ span: 6 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
              key={index}
            >
              <CustomCard style={{ minHeight: "150px" }}>
                <Skeleton.Image active />
                <Skeleton active />
              </CustomCard>
            </Col>
          ))}
        </>
      ) : data?.length > 0 ? (
        <TyutRender
          data={data}
          loading={loading}
          setIsModalOpen={setIsModalOpen}
          bookmark={bookmark}
          setShareCourse={setShareCourse}
          refresh={() => setRefresh((prev) => !prev)}
        />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // alignItems: "center",
              width: "100%",
              minHeight: "100%",
            }}
          >
            <Image
              alt="No courses found"
              width={342}
              height={342}
              preview={false}
              src={noCourses}
            />
            <Typography
              className={typoStyles.h6}
              style={{ textAlign: "center" }}
            >
              No tyuts(videos) added
            </Typography>
          </div>
        </>
      )}
      {isModalOpen.isOpen && (
        <ShareTyutModal
          creator_id={isModalOpen?.creator_id}
          tyut_id={isModalOpen?.tyut_id}
          isModalOpen={isModalOpen?.isOpen}
          handleCancel={() => setIsModalOpen({ isOpen: false })}
          handleOk={() => setIsModalOpen({ isOpen: false })}
        />
      )}

      {shareCourse.share && (
        <ShareCourse
          creator_id={shareCourse?.creator_id}
          setShareCourse={setShareCourse}
          shareCourse={shareCourse}
        />
      )}
      {/* {tyuts?.map((eachTuyt) => {
        return (
          <Col span={6}>
            <Card
              cover={
                <Image
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  preview={false}
                  alt="example"
                  onClick={() => setCurrTyut(eachTuyt)}
                  src={
                    youtubeRegex?.test(eachTuyt.tyut)
                      ? `https://img.youtube.com/vi/${getYouTubeVideoId(
                          eachTuyt?.tyut
                        )}/hqdefault.jpg`
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYfrqAqjMms4WpdiEjr0zdel5cr1C-XEoYJrLLte-xlg&s"
                  }
                />
              }
            >
              <Typography className={typoStyles.h6}>
                {eachTuyt.title}
              </Typography>
            </Card>
          </Col>
        );
      })} */}
    </Row>
  );
};

export default Tyuts;
