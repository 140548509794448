import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Typography,
  Tag,
  Space,
  Card,
  List,
  Input,
  Popconfirm,
  message,
  Table,
  Skeleton,
  Result,
  Empty,
  Flex,
  Modal,
  Form,
} from "antd";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { UserContext } from "../../context/UserProvider";
import {
  createDepartmentApi,
  deleteDepartmentApi,
  getDepartments,
  updateDepartmentApi,
} from "../../services/department.services";
import CustomLayout from "../Layout";
import OrgSettingsHeader from "./OrgSettingsHeader";
const Departments = () => {
  const { userDetails } = useContext(UserContext);
  const [departments, setDepartments] = useState([]);
  const [deptLoading, setDeptLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // const [deptValue, setDeptValue] = useState("");
  const [editDept, setEditDept] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [form] = Form.useForm();

  const fetchDepartments = async () => {
    setDeptLoading(true);
    try {
      const res = await getDepartments();
      setDepartments(res?.data);
      // setDeptValue("");
    } catch (error) {
      setDepartments([]);
    } finally {
      setDeptLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [refresh]);

  useEffect(() => {
    if (editDept) {
      form.setFieldsValue({
        name: editDept?.name,
      });
    }
  }, [editDept]);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  const onFinish = async (values) => {
    let payload = { name: values?.name };
    try {
      const resp = editDept?.id
        ? await updateDepartmentApi(editDept?.id, payload)
        : await createDepartmentApi(payload);
      if (resp) {
        console.log(resp?.data);
        message.success(resp?.data?.message);
        setModalVisible(false);
        refreshPage();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
      setModalVisible(false);
    } finally {
      form.resetFields();
      // setEditDept(null);
    }
  };

  // const addDepartment = async () => {
  //   let payload = { name: deptValue };
  //   try {
  //     const resp = await createDepartmentApi(payload);
  //     message.success(resp?.data?.message);
  //     refreshPage();
  //   } catch (error) {
  //     message.error(error?.response?.data?.message);
  //   }
  // };

  // const updateDepartment = async (data) => {
  //   let payload = { name: data?.name };
  //   try {
  //     const resp = await updateDepartmentApi(data?.id, payload);
  //     message.success(resp?.data?.message);
  //     refreshPage();
  //   } catch (error) {
  //     message.error(error?.response?.data?.message);
  //   } finally {
  //     setEditDept({});
  //   }
  // };

  const deleteDepartment = async (id) => {
    try {
      const resp = await deleteDepartmentApi(id);
      message.success(resp?.data?.message);
      refreshPage();
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const tableColumns = [
    {
      title: <Typography className={"tableHeader"}>Department name</Typography>,
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: <Typography className={"tableHeader"}>Actions</Typography>,
      dataIndex: "action",
      key: "action",
      width: 140,
      render: (_, record) => {
        return (
          <Flex gap={12} align="center" justify="start">
            {userDetails?.activeOrg?.role == "admin" && (
              <Popconfirm
                icon={false}
                description="Are you sure to delete this department?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  deleteDepartment(record?._id);
                }}
                onCancel={(e) => e.stopPropagation()}
              >
                <DeleteOutlined />
              </Popconfirm>
            )}
            {userDetails?.activeOrg?.role == "admin" && (
              <EditOutlined
                onClick={() => {
                  setEditDept({
                    id: record?._id,
                    name: record?.name,
                    // edit: true,
                  });
                  setModalVisible(true);
                }}
              />
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <CustomLayout
      header={<OrgSettingsHeader count={departments?.length || 0} />}
    >
      <Row gutter={[24, 18]}>
        <Col span={24} style={{ textAlign: "right" }}>
          {userDetails?.activeOrg?.role == "admin" && (
            <Button
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => {
                setEditDept(null);
                setModalVisible(true);
              }}
            >
              + Add Department
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            className="tableContainer"
            scroll={{
              x: 1200,
              y: "60vh",
            }}
            locale={{
              emptyText: deptLoading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No departments found</Typography>}
                />
              ),
            }}
            pagination={false}
            columns={tableColumns}
            dataSource={departments || []}
            loading={
              deptLoading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
          />
        </Col>
      </Row>
      {modalVisible && (
        <Modal
          title={`${editDept?.id ? "Edit" : "Add"} department`}
          open={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            form.resetFields();
            setEditDept(null);
          }}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
          >
            <Form.Item
              name={"name"}
              label="Department name"
              style={{ marginBottom: "0px" }}
              rules={[
                {
                  whitespace: true,
                  message:
                    "Department name cannot be empty or contain only spaces",
                },
                { required: true, message: "Please enter department name" },
                {
                  max: 50,
                  message: "Department name cannot excced 50 characters",
                },
                {
                  min: 3,
                  message:
                    "Department name should contain atleast 3 characters",
                },
              ]}
            >
              <Input size="medium" placeholder="Enter department name" />
            </Form.Item>
            <Form.Item
              style={{ textAlign: "right", marginBottom: "0px", marginTop: 20 }}
            >
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {/* <Row gutter={12}>
        <Col span={16}>
          <Card
            styles={{
              header: { backgroundColor: "#fff" },
            }}
            // style={{ width: "40vw", margin: "auto" }}
          >
            <Row gutter={[12, 12]} justify="center" align="middle">
              <Col span={24}>
                <Typography style={{ textAlign: "left", marginBottom: "10px" }}>
                  Departments
                </Typography>
                {userDetails?.activeOrg?.role == "admin" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 20,
                      marginBottom: 10,
                    }}
                  >
                    <Input
                      style={{ width: "85%" }}
                      placeholder="Enter department name"
                      value={deptValue}
                      onChange={(e) => setDeptValue(e?.target?.value)}
                    />
                    {deptValue?.trim() !== "" && (
                      <Button
                        className={btnStyles.smBtn}
                        type="primary"
                        onClick={() => addDepartment()}
                      >
                        Add
                      </Button>
                    )}
                  </div>
                )}

                {departments?.length > 0 && (
                  <>
                    <List
                      bordered
                      loading={deptLoading}
                      dataSource={departments}
                      renderItem={(item) => (
                        <List.Item>
                          {editDept?.id === item?._id && editDept?.edit ? (
                            <Input
                              value={editDept?.name}
                              onChange={(e) =>
                                setEditDept({
                                  ...editDept,
                                  name: e.target.value,
                                })
                              }
                            />
                          ) : (
                            <Typography.Text>{item?.name}</Typography.Text>
                          )}
                          {editDept?.id === item?._id && editDept?.edit ? (
                            <div
                              style={{
                                gap: "10px",
                                display: "flex",
                              }}
                            >
                              <CheckOutlined
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                  if (item.name !== editDept.name) {
                                    updateDepartment({
                                      id: item._id,
                                      name: editDept.name,
                                    });
                                  } else {
                                    setEditDept({});
                                  }
                                }}
                              />
                              <CloseOutlined
                                onClick={() => {
                                  setEditDept({ ...editDept, edit: false });
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                gap: "10px",
                                display: "flex",
                              }}
                            >
                              {userDetails?.activeOrg?.role == "admin" && (
                                <EditOutlined
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setEditDept({
                                      id: item?._id,
                                      name: item?.name,
                                      edit: true,
                                    });
                                  }}
                                />
                              )}
                              {userDetails?.activeOrg?.role == "admin" && (
                                <Popconfirm
                                  icon={false}
                                  description="Are you sure to delete?"
                                  onConfirm={() => {
                                    deleteDepartment(item?._id);
                                  }}
                                  onCancel={() => {}}
                                  okText="OK"
                                  cancelText="Cancel"
                                  placement="bottom"
                                >
                                  <DeleteOutlined
                                    style={{ cursor: "pointer" }}
                                  />
                                </Popconfirm>
                              )}
                            </div>
                          )}
                        </List.Item>
                      )}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row> */}
    </CustomLayout>
  );
};
export default Departments;
