import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../helpers/utility";
import { NotificationsProvider } from "../context/NotificationProvider";

const PrivateRoute = () => {
  return isAuthenticated && isAuthenticated() ? (
    <NotificationsProvider>
      <Outlet />
    </NotificationsProvider>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
