import React, { useContext, useState } from "react";
import { Image, Typography, Upload, message, theme } from "antd";
// import {
//   deleteAttachmentsAPI,
//   getSignedUrlAPI,
// } from "@/src/services/LMS/upload.services";
import axios from "axios";
import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";
import { UserContext } from "../../../../context/UserProvider";
import {
  getAssetsPath,
  getEncodedImage,
  getandUploadtoS3Url,
  renameFile,
  uploadVideo,
} from "../../../../helpers/utility";
import "../../SOP.css";
import { deleteFileApi } from "../../../../services/assets.services";
import { UploadOutlined } from "@ant-design/icons";
import { ONPREMISE } from "../../../../constants/defaultKeys";
import { delteS3FileApi } from "../../../../services/upload.services";
//  TODO upon delete ask to upload video
const { useToken } = theme;

function UploadElem({
  mediaType,
  setSignedUrl,
  uploading,
  setUploading,
  data = [],
}) {
  const { token } = useToken();

  const [showFileUploader, setShowFileUploader] = useState(true);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  if (data[0].src && data[0]?.id) {
    data = [{ uid: "1", name: data[0]?.src?.split("/")[1], status: "done" }];
  } else {
    data = null;
  }
  const OneMB = 1024 * 1024;
  const videoFileTypes = ["video/mp4", "video/mpeg"];
  const documentFileTypes = ["application/pdf"];

  const customUploadRequest = async (options, test) => {
    const { onSuccess, onError, file } = options;

    try {
      setUploading({ loading: true, type: "upload" });
      const resp =
        storageType == ONPREMISE
          ? await uploadVideo(file)
          : await getandUploadtoS3Url({ file: file, type: file.type });

      await setSignedUrl({
        file: storageType == ONPREMISE ? resp?.path : renameFile(resp?.path),
        previewURL: getAssetsPath(resp?.path, storageType),
        // previewURL:
        //   storageType == ONPREMISE
        //     ? getAssetsPath(getEncodedImage(resp?.path), storageType)
        //     : resp?.path,
      });
      setShowFileUploader(false);
      onSuccess("File uploaded successfully", resp);
    } catch (err) {
      console.log(err);
      onError(err);
    } finally {
      setUploading({ loading: false, type: "upload" });
    }
  };

  const uploadFileType =
    mediaType == "video" ? videoFileTypes : documentFileTypes;

  const uploadProps = {
    name: "file",
    defaultFileList: data,
    beforeUpload(file) {
      const fileSize = file.size / OneMB;
      if (fileSize > 100) {
        message.error(`File too large ${fileSize?.toFixed(0)} mb, max 100mb`);
        return Upload.LIST_IGNORE;
      }
      if (!uploadFileType.includes(file.type)) {
        message.error("Cannot upload this file");
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info, fileList) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove(file) {
      setSignedUrl(undefined);
      setShowFileUploader(true);
      try {
        setUploading({ loading: true, type: "delete" });
        const api = storageType == ONPREMISE ? deleteFileApi : delteS3FileApi;
        const res = await deleteFileApi(file?.xhr?.path);
      } catch (err) {
        console.log(err);
      } finally {
        setUploading({ loading: false, type: "delete" });
      }
      return true;
    },
    customRequest: customUploadRequest,
  };
  const documentFileExtensions = [".pdf"];

  const videoFileExtensions = [".mp4"];

  const { Dragger } = Upload;

  return (
    <>
      <Dragger
        accept={
          mediaType == "video" ? videoFileExtensions : documentFileExtensions
        }
        className={showFileUploader ? "uploadElem" : "uploadElemDisplayNone"}
        id="upload-file"
        maxCount={1}
        {...uploadProps}
        listType="picture"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #d0d5dd",
        }}
      >
        <div
          style={{
            margin: "0 auto",
            padding: "5px",
            display: "flex",
            alignItems: "center",
            maxWidth: "max-content",
            borderRadius: "8px",
            border: "1px solid #EAECF0",
            background: "#FFF",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          }}
        >
          <UploadOutlined />
        </div>
        <p className="ant-upload-text">
          <span style={{ color: token.colorPrimaryActive }}>
            Click to Upload{" "}
          </span>
          or drag and drop
        </p>
        <Typography
          className={typographyStyles.body1}
          style={{ color: "#475467" }}
        >
          Maximum size: 100mb
        </Typography>
      </Dragger>
    </>
  );
}

export default UploadElem;
