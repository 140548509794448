import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../Layout";
import { Button, Col, Flex, Form, Row, Tag, Typography, message } from "antd";
import { CheckOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import GeneralDetails from "./GeneralDetails";
import VerificationDetails from "./VerificationDetails";
import MroWorkOrders from "./MroWorkOrders";
import {
  createMroApi,
  getMroByIDApi,
  updateMroApi,
} from "../../../services/work-orders.services";
import dayjs from "dayjs";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
} from "../../../helpers/utility";
import { UserContext } from "../../../context/UserProvider";

const CreateMRO = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [mroDetails, setMroDetails] = useState({});
  //   const [loading, setLoading] = useState(false);

  const { userDetails } = useContext(UserContext);

  const fetchMroDetails = async (id) => {
    try {
      const res = await getMroByIDApi(id);
      let data = res?.data;
      setMroDetails(res?.data);
      form.setFieldsValue({
        description: data?.description,
        asset: data?.asset?._id,
        is_breakdown: data?.is_breakdown ? true : false,
        start_date: data?.start_date
          ? dayjs(convertUTCToLocalFormat(data?.start_date))
          : null,
        type: data?.type ? data?.type : undefined,
      });
    } catch (error) {
      navigate("/mro");
    }
  };

  useEffect(() => {
    params?.id && fetchMroDetails(params?.id);
    !params?.id && form.setFieldValue("start_date", dayjs());
  }, [params]);

  const onFinish = async (values) => {
    console.log("mro-form-values", values);
    let payload = {
      description: values?.description,
      asset_id: values?.asset,
      is_breakdown: values?.is_breakdown ? true : false,
      start_date: values?.start_date
        ? convertToUTCFormat(values?.start_date)
        : "",
    };
    if (values?.type) {
      payload = { ...payload, type: values?.type };
    }

    try {
      const resp = await createMroApi(payload);
      if (resp?.data) {
        message.success("MRO created successfully");
        navigate("/mro");
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const onUpdateMro = async (type) => {
    let payload = {};
    if (type === "resolve") {
      payload = {
        ...payload,
        status: "resolved",
        ended_at: convertToUTCFormat(dayjs()),
      };
    } else if (type === "verify") {
      payload = { ...payload, mark_as_verified: true };
    }

    try {
      const res = await updateMroApi(params?.id, payload);
      if (res?.data) {
        message.success(
          `MRO is ${type === "resolve" ? "resolved" : "verified"}`
        );
        fetchMroDetails(params?.id);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <CustomLayout
      header={
        <Flex justify="flex-start" align="center" style={{ gap: 6 }}>
          <LeftOutlined
            className={typoStyles.h6}
            onClick={() => navigate("/mro")}
          />
          <Typography className={typoStyles.h6}>
            {params?.id ? mroDetails?.code : "Create MRO"}
          </Typography>
          {mroDetails?.mark_as_verified && (
            <Tag className={tagStyles.mdRndTag} color="green">
              Verified
            </Tag>
          )}
        </Flex>
      }
      footer={
        <>
          {!params?.id && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={btnStyles.mdBtn}
                onClick={() => form.resetFields()}
              >
                Cancel
              </Button>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                onClick={form.submit}
                // loading={loading}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <Form
        layout="vertical"
        requiredMark={false}
        // disabled={params?.id ? true : false}
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} md={24} lg={24} xl={16}>
            <Flex style={{ width: "100%", gap: 24 }} vertical>
              <GeneralDetails form={form} mroDetails={mroDetails} />
              {/* {params?.id && <MroWorkOrders mroDetails={mroDetails} />} */}
            </Flex>
          </Col>
          {params?.id && (
            <Col xs={24} md={24} lg={24} xl={8}>
              <Flex style={{ width: "100%", gap: 24 }} vertical>
                <MroWorkOrders mroDetails={mroDetails} />
                <VerificationDetails mroDetails={mroDetails} />
              </Flex>
            </Col>
          )}
          {params?.id &&
            (mroDetails?.verification_steps?.[0]?.verifiers
              ?.map((i) => i?._id)
              ?.includes(userDetails?._id) ||
              mroDetails?.creator?._id === userDetails?._id) &&
            mroDetails?.status !== "resolved" &&
            mroDetails?.workorders?.[0]?.status === "closed" && (
              <Col
                xs={24}
                md={24}
                lg={24}
                xl={16}
                style={{ textAlign: "center" }}
              >
                <Button
                  className={btnStyles.mdBtn}
                  style={{ width: "40vw" }}
                  type="primary"
                  onClick={() => onUpdateMro("resolve")}
                >
                  Resolved
                </Button>
              </Col>
            )}
          {params?.id &&
            mroDetails?.verification_steps?.[0]?.verifiers
              ?.map((i) => i?._id)
              ?.includes(userDetails?._id) &&
            mroDetails?.status === "resolved" &&
            !mroDetails?.mark_as_verified && (
              <Col
                xs={24}
                md={24}
                lg={24}
                xl={16}
                style={{ textAlign: "center" }}
              >
                <Button
                  className={btnStyles.mdBtn}
                  style={{ width: "40vw", backgroundColor: "#87d068" }}
                  type="primary"
                  onClick={() => onUpdateMro("verify")}
                >
                  Mark as Verified <CheckOutlined />
                </Button>
              </Col>
            )}
        </Row>
      </Form>
    </CustomLayout>
  );
};

export default CreateMRO;
