import axiosInstance from "../config/axios/axios.config";

export const createMaintananceApi = (payload) => {
  return axiosInstance.post("/maintenance", payload);
};
export const getMaintanancesApi = (query) => {
  return axiosInstance.get(`/maintenance`, { params: query });
};
export const getMaintananceByIDApi = (id, date) => {
  return date
    ? axiosInstance.get(`/maintenance/${id}?date=${date}`)
    : axiosInstance.get(`/maintenance/${id}`);
};
export const updateMaintananceApi = (id, payload) => {
  return axiosInstance.put(`/maintenance/${id}`, payload);
};
export const deleteMaintananceApi = (id, query) => {
  return axiosInstance.delete(`/maintenance/${id}`, { params: query });
};

export const updateOccurenceApi = (id, payload) => {
  return axiosInstance.put(`/maintenance/occurance/${id}`, payload);
};

export const getAllJobsApi = (query) => {
  return axiosInstance.get(`/maintenance/jobs`, { params: query });
};
export const getOccurenceByIDApi = (id) => {
  return axiosInstance.get(`/maintenance/occurance/${id}`);
};
