import { Typography } from "antd";
import React from "react";
import CustomLayout from "../Layout";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";

const Analysis = () => {
  return (
    <CustomLayout
      header={<Typography className={typoStyles.h6}>Analysis </Typography>}
    >
      <Typography>Analysis</Typography>
    </CustomLayout>
  );
};

export default Analysis;
