import React, { useContext } from "react";
import { Radio, Typography, Row, Col, Form, Input } from "antd";
import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import OptionInput from "./OptionInput";
import inputStyles from "../../../../../assets/styles/Custom/Input.module.css";
import {
  deleteDocs,
  generateRandomString,
  getFullPathFromEncoded,
  renameFile,
} from "../../../../../helpers/utility";
import "../../../SOP.css";
import { UserContext } from "../../../../../context/UserProvider";
import { ONPREMISE } from "../../../../../constants/defaultKeys";

function SAQ({
  saq,
  formRef,
  sopContent,
  setSopContent,
  choices,
  isAnsSelected,
  setAnsSelected,
  setChoices,
  questionnaire,
  setQuestionnaire,
  optionsType = "text",
}) {
  const [form] = Form.useForm();
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  return (
    <Row id={saq.id}>
      <Form
        requiredMark={false}
        style={{ width: "100%" }}
        ref={formRef}
        form={form}
        name="choose-saq"
        layout="vertical"
        onFinish={(val) => {
          // if (val.answers !== null) {
          if (saq.id) {
            sopContent.map((eachTyut) => {
              if (eachTyut.id === saq.id) {
                eachTyut.choices = Object.values(choices)
                  .filter((choice) => choice && choice?.trim() !== "")
                  .map((choice) =>
                    optionsType == "image" ? choice : choice?.split("<__>")[0]
                  );
                eachTyut.answers = val.answers.split("<__>")[0];
                eachTyut.question = val.question;
                eachTyut.type = "radio";
                eachTyut.ansDesc = questionnaire.ansDesc;
                eachTyut.optionType = optionsType;
              }
            });
            setSopContent([...sopContent]);
          } else {
            let newTyuts = sopContent.filter(
              (eachTyut) => eachTyut.edit != true
            );
            setSopContent([
              ...newTyuts,
              {
                id: generateRandomString(12),
                choices: Object.values(choices)
                  .filter((choice) => choice && choice?.trim() !== "")
                  .map((choice) => choice?.split("<__>")[0]),
                answers: val.answers?.split("<__>")[0],
                question: val.question,
                ansDesc: questionnaire.ansDesc,
                type: "radio",
                optionType: optionsType,
              },
            ]);
          }
          setAnsSelected(true);
          // } else {
          //   setAnsSelected(false);
          // }
        }}
        initialValues={{
          question: questionnaire.question || null,
          answers: saq.answers || null,
        }}
      >
        <Form.Item
          name="question"
          rules={[
            {
              required: true,
              message: "Please add question title!",
            },
          ]}
        >
          <Input
            className={inputStyles.smInput}
            style={{ height: "40px", minWidth: "100%", marginBottom: "12px" }}
            placeholder="Enter your question here"
            value={questionnaire.question}
            onChange={(e) =>
              setQuestionnaire({ ...questionnaire, question: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          // label="Choose an option"
          name="answers"
          // rules={[
          //   {
          //     required: true,
          //     message: "Select an answer key",
          //   },
          // ]}
        >
          <Radio.Group
            style={{
              minWidth: "100%",
            }}
            onChange={(val) => setAnsSelected(!val.length > 0)}
          >
            <Row gutter={[24, 24]}>
              {Object.keys(choices).map((eachChoice, index) => {
                return (
                  <Col
                    key={index}
                    span={optionsType == "images" ? 12 : 24}
                    style={{
                      display: "flex",
                      justifyContent:
                        optionsType == "images"
                          ? "flex-start"
                          : "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Radio
                      value={choices?.[eachChoice]}
                      key={eachChoice}
                      disabled={!choices?.[eachChoice]}
                      className={"optionCheckbox"}
                      style={{
                        width: optionsType == "images" ? "auto" : "100%",
                      }}
                    >
                      <OptionInput
                        choices={choices}
                        setChoices={setChoices}
                        eachChoice={eachChoice}
                        optionType={optionsType}
                      />
                    </Radio>
                    {Object.keys(choices).length > 2 && (
                      <CloseOutlined
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          const deleteThisChoice = choices?.[eachChoice];
                          if (optionsType == "images" && deleteThisChoice) {
                            deleteDocs(
                              storageType == ONPREMISE
                                ? getFullPathFromEncoded(deleteThisChoice)
                                : renameFile(deleteThisChoice),
                              storageType
                            );
                          }
                          delete choices?.[eachChoice];

                          // Object.keys(choices).map((each) => {
                          //   if (Object.keys(choices).length == 2) {
                          //     return false;
                          //   }
                          //   if (deleteThisChoice == choices?.[each]) {
                          //     delete choices?.[each];
                          //     return true;
                          //   }
                          // });
                          if (
                            (form.getFieldsValue().answers = deleteThisChoice)
                          ) {
                            setAnsSelected(false);
                            form.setFieldsValue({ answers: null });
                            // form.resetFields(["answers"]);
                          }
                          // delete choices?.[eachChoice];
                          setChoices({ ...choices });

                          // if (optionsType == "images" && deleteThisChoice) {
                          //   deleteDocs(
                          //     storageType == ONPREMISE
                          //       ? getFullPathFromEncoded(deleteThisChoice)
                          //       : renameFile(deleteThisChoice),
                          //     storageType
                          //   );
                          // }
                        }}
                      />
                    )}
                  </Col>
                );
              })}
            </Row>
          </Radio.Group>
        </Form.Item>
      </Form>

      <Col span={24} style={{ marginBottom: "12px" }}>
        <span
          className={"addOptBtn"}
          type="link"
          onClick={() => {
            const len = Object.keys(choices).length + 1;
            setChoices({
              ...choices,
              [`option${len}_${generateRandomString()}`]:
                optionsType == "images" ? "" : `Option ${len}`,
            });
          }}
        >
          <PlusCircleOutlined style={{ marginRight: "10px" }} />
          Add Option
        </span>
      </Col>
    </Row>
  );
}

export default SAQ;
