import {
  Breadcrumb,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Empty,
  Flex,
  Form,
  Image,
  Input,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import dayjs from "dayjs";
import {
  getAllAssetsApi,
  getAssetLocationByIDApi,
} from "../../../services/assets.services";
import { useParams } from "react-router-dom";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import { getAssetsPath } from "../../../helpers/utility";
import { UserContext } from "../../../context/UserProvider";

const GeneralDetails = ({ form, mroDetails }) => {
  const params = useParams();
  const [assets, setAssets] = useState([]);
  const [location, setLocation] = useState([]);
  const [image, setImage] = useState({ name: "", status: "" });

  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const getAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          value: asset?._id,
          image: asset?.file,
          status: asset?.status,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getLocation = async (id) => {
    try {
      const resp = await getAssetLocationByIDApi(id);
      if (resp?.data) {
        setLocation(resp?.data?.map((each) => ({ title: each?.name })));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    const assetId = mroDetails?.asset?._id;
    if (assetId) {
      getLocation(assetId);
      let currAssetObj = assets?.filter((i) => i?.value === assetId)?.[0];
      setImage({
        name: currAssetObj?.image,
        status: currAssetObj?.status,
      });
    }
  }, [mroDetails]);

  const handleAssetChange = async (value, e) => {
    getLocation(value);
    if (e?.image) {
      // const [, afterCmms] = e?.image?.split("cmms/");
      // const encodedImage = encodeURIComponent(afterCmms);
      setImage({
        name: e?.image,
        // name: storageType == ONPREMISE ? encodedImage : e?.image,
        status: e?.status,
      });
    } else {
      setImage({ name: "", status: "" });
    }
  };

  const mroTypes = [
    {
      label: "Mechanical",
      value: "Mechanical",
    },
    {
      label: "Electrical",
      value: "Electrical",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  return (
    <Card
      title="MRO Basic Details"
      styles={{ header: { backgroundColor: "#fff" } }}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Flex style={{ width: "100%", gap: 6 }} vertical>
            <Form.Item
              label="Issue description"
              name={"description"}
              rules={[
                {
                  whitespace: true,
                  message:
                    "Issue description cannot be empty or contain only spaces",
                },
                { required: true, message: "Please enter issue description" },
              ]}
            >
              <Input.TextArea
                style={{ width: "100%" }}
                placeholder="Enter issue description here"
                disabled={params?.id ? true : false}
                className={inputStyles.smInput}
                rows={4}
              />
            </Form.Item>
            <Form.Item
              // style={{
              //   width: "100%",
              //   marginBlock: "0px 6px",
              // }}
              label={"Asset"}
              name={"asset"}
              rules={[{ required: true, message: "Please select asset" }]}
            >
              <Select
                disabled={params?.id ? true : false}
                placeholder="Select asset"
                style={{ width: "100%" }}
                className={inputStyles.smSelect}
                showSearch={true}
                // optionFilterProp="label"
                filterOption={(inputValue, option) =>
                  option.name
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={handleAssetChange}
                options={assets}
              />
            </Form.Item>
            {location?.length > 0 && (
              <Form.Item label="Location">
                <div
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #d0d5dd",
                    padding: "4px 12px",
                    width: "fit-content",
                  }}
                >
                  <ConfigProvider
                    theme={{
                      components: {
                        Breadcrumb: {
                          itemColor: "#2f3542",
                          lastItemColor: "#2f3542",
                        },
                      },
                    }}
                  >
                    <Breadcrumb separator=">" items={location} />
                  </ConfigProvider>
                </div>
              </Form.Item>
            )}
            <Form.Item label={"Type"} name={"type"}>
              <Select
                disabled={params?.id ? true : false}
                placeholder="Select type"
                style={{ width: "100%" }}
                className={inputStyles.smSelect}
                showSearch={true}
                optionFilterProp="label"
                options={mroTypes}
              />
            </Form.Item>
            <Form.Item
              valuePropName="checked"
              name={"is_breakdown"}
              style={{ cursor: params?.id ? "not-allowed" : "auto" }}
            >
              <Checkbox style={{ pointerEvents: params?.id ? "none" : "auto" }}>
                Production impacted and breakdown occurred
              </Checkbox>
            </Form.Item>
            <Form.Item
              label="Start date & time"
              name={"start_date"}
              rules={[
                { required: true, message: "Please select start date & time" },
              ]}
            >
              <DatePicker
                disabled={params?.id ? true : false}
                format="YYYY-MM-DD HH:mm"
                placeholder="Select start date & time"
                size="medium"
                showTime={{
                  defaultValue: dayjs("00:00", "HH:mm"),
                }}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Flex>
        </Col>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
            }}
          >
            {image?.status && (
              <Typography>
                Asset status:{" "}
                <Tag className={tagStyles?.smTag}> {image.status}</Tag>
              </Typography>
            )}
            {image?.name ? (
              <Image
                // preview={false}
                src={getAssetsPath(image.name, storageType)}
                alt="Asset"
                style={{
                  width: "100%",
                  height: "400px",
                  borderRadius: "12px",
                }}
              />
            ) : (
              <Empty
                description="No asset images"
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default GeneralDetails;
