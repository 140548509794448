import React, { useContext } from "react";
import { Checkbox, Row, Col, Form, Input } from "antd";
import OptionInput from "./OptionInput";
import "../../../SOP.css";
import {
  deleteDocs,
  generateRandomString,
  getFullPathFromEncoded,
  renameFile,
} from "../../../../../helpers/utility";
import checkStyles from "../../../../../assets/styles/Custom/Checkbox.module.css";
import inputStyles from "../../../../../assets/styles/Custom/Input.module.css";
import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ONPREMISE } from "../../../../../constants/defaultKeys";
import { UserContext } from "../../../../../context/UserProvider";

// Multiple Choice question

function MCQ({
  formRef,
  sopContent,
  setSopContent,
  mcq,
  setAnsSelected,
  choices,
  isAnsSelected,
  setChoices,
  questionnaire,
  setQuestionnaire,
  optionsType = "text",
}) {
  const [form] = Form.useForm();
  const { userDetails } = useContext(UserContext);
  const storageType =
    userDetails?.activeOrg?.organization?.settings?.storageType;

  return (
    <Row id={mcq?.id}>
      <Form
        style={{ minWidth: "100%" }}
        requiredMark={false}
        form={form}
        ref={formRef}
        name="choose-mcq"
        layout="vertical"
        onFinish={(val) => {
          // if (val.answers.length > 0) {
          if (mcq.id) {
            sopContent.map((eachTyut) => {
              if (eachTyut.id === mcq.id) {
                eachTyut.choices = Object.values(choices)
                  .filter((choice) => choice && choice?.trim() !== "")
                  .map((choice) =>
                    optionsType == "image" ? choice : choice?.split("<__>")[0]
                  );
                eachTyut.answers = val.answers.map(
                  (ans) => ans.split("<__>")[0]
                );
                eachTyut.question = val.question;
                eachTyut.type = "checkbox";
                eachTyut.ansDesc = questionnaire.ansDesc;
                eachTyut.optionType = optionsType;
              }
            });
            setSopContent([...sopContent]);
          } else {
            let newTyuts = sopContent.filter(
              (eachTyut) => eachTyut.edit != true
            );
            setSopContent([
              ...newTyuts,
              {
                id: generateRandomString(12),
                choices: Object.values(choices)
                  .filter((choice) => choice && choice?.trim() !== "")
                  .map((choice) => choice?.split("<__>")[0]),
                answers: val.answers.map((ans) => ans?.split("<__>")[0]),
                question: val.question,
                ansDesc: questionnaire.ansDesc,
                type: "checkbox",
                optionType: optionsType,
              },
            ]);
          }
          setAnsSelected(true);
          // } else {
          //   setAnsSelected(false);
          // }
        }}
        initialValues={{
          question: questionnaire.question || null,
          answers: mcq.answers || [],
        }}
      >
        <Form.Item
          // label="Question"
          style={{ minWidth: "100%" }}
          name="question"
          rules={[
            {
              required: true,
              message: "Please add checklist title!",
            },
          ]}
        >
          <Input
            className={inputStyles.smInput}
            style={{ height: "40px", minWidth: "100%", marginBottom: "12px" }}
            placeholder="Enter your checklist title here"
            value={questionnaire.question}
            onChange={(e) =>
              setQuestionnaire({ ...questionnaire, question: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          style={{ minWidth: "100%", marginBottom: "0" }}
          // label="Choose one or more options"
          name="answers"
          // rules={[
          //   {
          //     required: true,
          //     message: "Select an answer key!",
          //   },
          // ]}
        >
          <Checkbox.Group
            style={{ minWidth: "100%", marginBottom: "0" }}
            onChange={(val) => {
              setAnsSelected(!val.length == 0);
            }}
          >
            <Row gutter={[24, 24]}>
              {Object.keys(choices).map((eachChoice) => {
                return (
                  <>
                    <Col
                      span={optionsType == "images" ? 12 : 24}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:
                          optionsType == "images"
                            ? "flex-start"
                            : "space-between",
                      }}
                    >
                      <Checkbox
                        disabled={!choices?.[eachChoice]}
                        value={choices?.[eachChoice]}
                        key={eachChoice}
                        className={`optionCheckbox ${checkStyles.mdCheck}`}
                        style={{
                          width: optionsType == "images" ? "auto" : "100%",
                        }}
                      >
                        <OptionInput
                          choices={choices}
                          setChoices={setChoices}
                          eachChoice={eachChoice}
                          checklist={true}
                          optionType={optionsType}
                        />
                      </Checkbox>

                      {Object.keys(choices).length > 2 && (
                        <CloseOutlined
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            const deleteThisChoice = choices?.[eachChoice];
                            if (optionsType == "images" && deleteThisChoice) {
                              deleteDocs(
                                storageType == ONPREMISE
                                  ? getFullPathFromEncoded(deleteThisChoice)
                                  : renameFile(deleteThisChoice),
                                storageType
                              );
                            }
                            delete choices?.[eachChoice];
                            // Object.keys(choices).map((each) => {
                            //   if (Object.keys(choices).length == 2) {
                            //     return false;
                            //   }
                            //   if (deleteThisChoice == choices?.[each]) {
                            //     delete choices?.[each];
                            //     return true;
                            //   }
                            // });
                            const selectedAns = form
                              .getFieldsValue()
                              .answers?.filter(
                                (each) => each !== deleteThisChoice
                              );
                            if (selectedAns.length == 0) {
                              setAnsSelected(false);
                            }
                            form.setFieldsValue({ answers: selectedAns });
                            setChoices({ ...choices });
                            // if (optionsType == "images" && deleteThisChoice) {
                            //   deleteDocs(
                            //     storageType == ONPREMISE
                            //       ? getFullPathFromEncoded(deleteThisChoice)
                            //       : renameFile(deleteThisChoice),
                            //     storageType
                            //   );
                            // }
                          }}
                        />
                      )}
                    </Col>
                  </>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>

      <Col span={24} style={{ marginBottom: "12px" }}>
        <span
          className={"addOptBtn"}
          type="link"
          onClick={() => {
            const len = Object.keys(choices).length + 1;
            setChoices({
              ...choices,
              [`option${len}_${generateRandomString()}`]:
                optionsType == "images" ? "" : `Item ${len}`,
            });
          }}
        >
          <PlusCircleOutlined style={{ marginRight: "10px" }} />
          Add item
        </span>
      </Col>

      {/* <Input
        className={inputStyles.smInput}
        style={{ height: "44px", minWidth: "100%" }}
        placeholder="Add answer hint (Optional)"
        value={questionnaire.ansDesc}
        onChange={(e) =>
          setQuestionnaire({ ...questionnaire, ansDesc: e.target.value })
        }
      /> */}
    </Row>
  );
}

export default MCQ;
