import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../Layout";
import {
  Button,
  Card,
  Col,
  Empty,
  Image,
  Input,
  Pagination,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import buttonStyles from "../../../assets/styles/Custom/Button.module.css";
import { useNavigate } from "react-router-dom";
import { getProductionLines } from "../../../services/assets.services";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import defaultImage from "../../../assets/images/defaultImage.png";
import { ArrowRightOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserProvider";
import { PAGESIZE, createPL } from "../../../constants/defaultKeys";
import "../Assets.css";
import { debounce, getAssetsPath } from "../../../helpers/utility";

const ProductionLines = () => {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [productionLines, setProductionLines] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);

  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const fetchProductionLines = async () => {
    let query = { page: pageNumber };
    if (searchValue) {
      query.name = searchValue;
    }

    setLoading(true);
    try {
      const res = await getProductionLines(query);
      setProductionLines(res?.data);
    } catch (error) {
      setProductionLines(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductionLines();
  }, [pageNumber, refresh]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [searchValue]);

  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
  };

  const tableColumns = [
    {
      title: <Typography className={"tableHeader"}>Title</Typography>,
      dataIndex: "name",
      key: "name",
      width: 250,
      fixed: "left",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      render: (val) => <Typography>{val ? val : "-"}</Typography>,
    },
    {
      title: <Typography className={"tableHeader"}>Location</Typography>,
      dataIndex: "location",
      key: "location",
      width: 200,
      render: (val, record) => (
        <Typography>
          {record?.location?.name ? record?.location?.name : "-"}
        </Typography>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>Assets</Typography>,
      dataIndex: "assets",
      key: "assets",
      render: (values) =>
        values && values?.length > 0 ? (
          <Space
            style={{
              gap: 4,
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "stretch",
            }}
          >
            {values?.map((item, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                {index > 0 && <ArrowRightOutlined />}
                <div
                  style={{
                    padding: 8,
                    textAlign: "center",
                    width: 150,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Space direction="vertical" style={{ gap: 2 }}>
                    <Image
                      src={
                        item?.asset?.file
                          ? getAssetsPath(item?.asset?.file, storageType)
                          : defaultImage
                      }
                      preview={true}
                      style={{ height: "80px", width: "80px", borderRadius: 8 }}
                    />
                    <Typography
                      className="assetName"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          // `/assets/${item?.asset?._id}?type=table&page=1`,
                          `/assets/${item?.asset?._id}`,
                          {
                            state: {
                              source: `/production-lines`,
                            },
                          }
                        )
                      }
                    >
                      {item?.asset?.name}
                    </Typography>
                  </Space>
                </div>
              </div>
            ))}
          </Space>
        ) : (
          "-"
        ),
    },
  ];

  return (
    <CustomLayout
      header={
        <Typography className={typoStyles.h6}>
          Production Lines ({productionLines?.productionUnitCount || 0})
        </Typography>
      }
    >
      <Row gutter={[24, 16]}>
        <Col
          span={24}
          style={{ textAlign: "right", justifyContent: "flex-end" }}
          className="flex-center"
        >
          <Input.Search
            size="medium"
            onChange={debounce(handleSearch, 500)}
            placeholder="Search by production line name"
            style={{ width: 300 }}
          />
          {createPL.includes(userDetails?.activeOrg?.role) && (
            // <Col span={24} style={{ textAlign: "right" }}>
            <Button
              id="create-production-line"
              type="primary"
              className={buttonStyles.mdBtn}
              onClick={() => navigate("/production-lines/create")}
            >
              + Create Production Line
            </Button>
            // </Col>
          )}
        </Col>
        <Col span={24}>
          <Table
            bordered
            loading={
              loading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
            columns={tableColumns}
            dataSource={productionLines?.assets || []}
            // dataSource={productionLines?.inventories || []}
            pagination={false}
            onRow={(item) => {
              return {
                onClick: (e) => {
                  navigate(`/production-lines/${item?._id}`);
                },
                style: { cursor: "pointer" },
              };
            }}
            scroll={{
              x: "max-content",
              y: "60vh",
            }}
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No Production Lines found</Typography>}
                />
              ),
            }}
          />
        </Col>
        {productionLines?.productionUnitCount > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={productionLines?.productionUnitCount}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
      </Row>
    </CustomLayout>
  );
};

export default ProductionLines;
