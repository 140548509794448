import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { clear, clearAllCookies } from "../../helpers/localStorage";
import { UserContext } from "../../context/UserProvider";

const Logout = () => {
  const [loading, setLoading] = useState(false);
  const { setUserDetails } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    clearAllCookies();
    clear();
    setUserDetails({});
    setTimeout(() => {
      setLoading(false);
    }, 5000);
    navigate("/login");
  }, []);

  return (
    <div>
      {loading && (
        <div className="flex-center" style={{ height: "100vh" }}>
          <Spin />
        </div>
      )}
    </div>
  );
};

export default Logout;
