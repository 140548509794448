import { Tour } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteCookie, getCookie, setCookie } from "../../helpers/localStorage";
const OnboardingTour = () => {
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const isTourStarted = getCookie("isTourStarted");

  useEffect(() => {
    const present = getCookie("tour");
    setCurrent(parseInt(present) || 0);
  }, [location]);

  useEffect(() => {
    const present = getCookie("tour");
    if (parseInt(present) == 0) {
      navigate("/assets");
    }
  }, []);

  const steps = [
    {
      title: "Assets",
      description:
        "An Asset is any equipment or machine that needs to be tracked and maintained for optimal functionality.",
      target: () => document.querySelector('[data-menu-id*="assets"]'),
      placement: "right",
      nextButtonProps: {
        onClick: (e) => {},
      },
    },
    {
      title: "Create Asset",
      description: "Here you can add your Asset details",
      target: () => document.querySelector("#create-asset"),
      placement: "right",
      nextButtonProps: {
        onClick: () => {},
      },
    },
    {
      title: "Location-wise Assets",
      description:
        "This toggle provides a hierarchical view of your assets, organized by their designated physical Locations.",
      target: () => document.querySelector("#asset-tree-view"),
      placement: "right",
      nextButtonProps: {
        onClick: () => {
          navigate("/assets?type=table");
        },
      },
    },
    {
      title: "All Assets",
      description: "This toggle provides a simple list of all your assets.",
      target: () => document.querySelector("#asset-table-view"),
      placement: "right",
      nextButtonProps: {
        onClick: () => {
          navigate("/work-orders");
        },
      },
      prevButtonProps: {
        onClick: () => {
          navigate("/assets?type=tree");
        },
      },
    },
    {
      title: "Work Orders",
      description:
        "This allows you to create a digital request for immediate repairs or tasks.",
      target: () => () =>
        document.querySelector('[data-menu-id*="work-orders"]'),
      placement: "right",
      prevButtonProps: {
        onClick: () => {
          navigate("/assets?type=table");
        },
      },
    },
    {
      title: "Create Work Order",
      description:
        "Here you can create your Work Order request and assign technicians.",
      target: () => () => document.querySelector("#create-wo"),
      placement: "right",
      nextButtonProps: {
        onClick: () => {
          navigate("/maintenances?type=conf");
        },
      },
    },

    {
      title: "Preventive Maintenance",
      description:
        "Allows you to configure routine maintenance tasks for your assets at daily, weekly, monthly, or yearly intervals.",
      target: () => document.querySelector('[data-menu-id*="maintenances"]'),
      placement: "right",
      prevButtonProps: {
        onClick: () => {
          navigate("/work-orders");
        },
      },
    },
    {
      title: "PMs configured",
      description:
        "Provides a list of all the Preventive Maintenance tasks configured for your Assets, along with their scheduled date, time and priority details.",
      target: () => () => document.querySelector("#pm-conf-table"),
      placement: "right",
    },
    {
      title: "Create PM",
      description:
        "Here you can schedule your Preventive Maintenance and assign technicians.",
      target: () => document.querySelector("#create-pm"),
      placement: "right",
      nextButtonProps: {
        onClick: () => {
          navigate("/maintenances?type=jobs");
        },
      },
    },
    {
      title: "All PM Jobs",
      description:
        "Offers a scheduled view of all your Preventive Maintenance tasks, listed by their due date for easy tracking and monitoring.",
      target: () => document.querySelector("#pm-jobs-table"),
      placement: "right",
      nextButtonProps: {
        onClick: () => {
          navigate("/maintenances?type=jobs");
        },
      },
      prevButtonProps: {
        onClick: () => {
          navigate("/maintenances?type=conf");
        },
      },
    },
    {
      title: "Date Range Filter",
      description:
        "Lets you focus on specific tasks within a chosen timeframe.",
      target: () => document.querySelector("#pm-jobs-filter"),
      placement: "right",
      nextButtonProps: {
        onClick: () => {
          navigate("/procedures");
        },
      },
    },
    {
      title: "Standard Operating Procedures",
      description:
        "Allows to create SOPs for Maintenance and Inspections. Bolt, auto generates SOPs for your assets using AI, based on make, model and other asset related documents.",
      target: () => document.querySelector('[data-menu-id*="sops"]'),
      placement: "right",
      nextButtonProps: {
        onClick: () => {},
      },
      prevButtonProps: {
        onClick: () => {
          navigate("/maintenances?type=jobs");
        },
      },
    },
    {
      title: "Create SOP",
      description:
        "Here you can create SOP and later these SOPs can be assigned to Work Orders and Preventive Maintenances.",
      target: () => document.querySelector("#create-sop"),
      placement: "right",
      nextButtonProps: {
        onClick: () => {
          navigate("/trainings");
        },
      },
    },
    {
      title: "Trainings",
      description:
        "This is a Learning Management System, that allows you to create short videos called as Tyuts. Multiple Tyuts (videos) can be combined into a Course.",
      target: () => document.querySelector('[data-menu-id*="trainings"]'),
      placement: "right",
      nextButtonProps: {
        onClick: () => {
          navigate("/trainings?type=tyut");
        },
      },
      prevButtonProps: {
        onClick: () => {
          navigate("/procedures");
        },
      },
    },
    // {
    //   title: "Tyuts",
    //   description:
    //     "Lets you focus on specific tasks within a chosen timeframe.",
    //   target: () => document.querySelector("#tyuts-view"),
    //   placement: "right",
    //   nextButtonProps: {
    //     onClick: () => {},
    //   },
    // },
    {
      title: "Create Tyut",
      description:
        "Here you can upload your videos, AI auto-generates Questionnaire for your videos.",
      target: () => document.querySelector("#create-tyut"),
      placement: "right",
      nextButtonProps: {
        onClick: () => {
          deleteCookie("isTourStarted");
          deleteCookie("tour");
        },
      },
    },
  ];
  if (!isTourStarted) {
    return null;
  }
  return (
    <>
      <Tour
        onChange={(e) => {
          setCurrent(e);
          setCookie("tour", e, `.${process.env.REACT_APP_PUBLIC_DOMAIN}`, "/");
        }}
        disabledInteraction
        open={open}
        current={current}
        closeIcon={null}
        onClose={() => {
          deleteCookie("isTourStarted");
          deleteCookie("tour");
          setOpen(false);
        }}
        steps={steps}
      />
    </>
  );
};
export default OnboardingTour;
