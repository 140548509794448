import { Tag, message } from "antd";
import {
  CLOUD,
  INSPECTION,
  ONPREMISE,
  ORGANISATION,
} from "../../../constants/defaultKeys";
import { getCookie } from "../../../helpers/localStorage";
import {
  generateRandomString,
  getAssetsPath,
  getEncodedImage,
  reverseCapitalizeWords,
} from "../../../helpers/utility";
import { sectionOptionsList } from "./AddElem/addElemHelper";
import {
  getAllAssetsApi,
  getAssetByIdApi,
} from "../../../services/assets.services";
import axios from "axios";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";

export const generateSOP = async (
  existingSOPs,
  storageType,
  dummyForm,
  assetDocs,
  selectedAsset,
  setLoading,
  setSopContent,
  setsopMetaData,
  setSopReady,
  setEmailModal,
  setEnableAI,
  contentType
) => {
  setLoading(true);

  if (!selectedAsset && existingSOPs?.length === 0) {
    setLoading(false);
    return;
  }

  let data = {};
  const lang = dummyForm.getFieldValue("sop_lang") || "hindi";
  const mail = dummyForm.getFieldValue("mail");
  const type = dummyForm.getFieldValue("typeOfProc");

  if (existingSOPs?.length > 0) {
    data = {
      source: storageType === ONPREMISE ? "local" : "s3",
      lang,
      path: existingSOPs?.[0],
      mail,
    };
  } else if (assetDocs?.length > 0) {
    data = {
      type: "related-docs",
      // pdf_docs: ["/tmp/cmms/1711456151996-SH_en_en-US_compressed.pdf"],
      pdf_docs: assetDocs,
      make: selectedAsset?.make,
      model: selectedAsset?.model,
      asset_name: selectedAsset?.name,
      source: storageType === CLOUD ? "cloud" : "on-premise",
      org_id: getCookie(ORGANISATION),
      sop_type: type == "Repair" ? "Troubleshooting" : type,
      issue: dummyForm.getFieldValue("troubleshooting_issue") || "",
    };
  } else {
    data = {
      type: "make-model",
      make: selectedAsset?.make,
      model: selectedAsset?.model,
      asset_name: selectedAsset?.name,
      sop_type: type == "Repair" ? "Troubleshooting" : type,
      issue: dummyForm.getFieldValue("troubleshooting_issue") || "",
    };
  }

  try {
    const url = `${process.env.REACT_APP_BASE_URL_DOMAIN}/cmms-api/${
      existingSOPs?.length > 0 ? "translate-sop" : "generate-sop"
    }/`;
    const resp = await axios.post(url, data);

    if (!existingSOPs || existingSOPs.length === 0) {
      const newQues = resp?.data?.data?.procedures?.map((eachQ) => {
        const parentId = generateRandomString(12);
        const optionsList = sectionOptionsList[eachQ?.heading?.toLowerCase()];

        return {
          id: parentId,
          procedures: eachQ?.procedures?.map((eachPro) => {
            const updatedOptions = Object.keys(optionsList).reduce(
              (acc, option) => {
                acc[option] = {
                  enable: [],
                  helperText: eachPro.choices[reverseCapitalizeWords(option)],
                  images: [],
                  color: optionsList[option]?.color,
                };
                return acc;
              },
              {}
            );

            return {
              id: generateRandomString(12),
              type: "section",
              parentId,
              question: eachPro?.procedure,
              things: updatedOptions,
              options: Object.keys(optionsList) || ["Yes", "No"],
              images: [],
              answers: [],
            };
          }),
          type: "section",
          title: eachQ?.heading,
          answers: [],
        };
      });

      setsopMetaData((prev) => ({
        ...prev,
        name: resp?.data?.data?.title,
        description: resp?.data?.data?.description,
        usage_data: resp?.data?.usage_data,
      }));

      setSopContent(newQues);
      if (newQues) setSopReady(true);
    }
  } catch (error) {
    console.log(error);
    if (!existingSOPs || existingSOPs.length === 0) {
      message.error(error?.response?.data?.message || "Something went wrong!!");
    }
  } finally {
    setLoading(false);
    if (existingSOPs?.length > 0) {
      setEmailModal(true);
      setEnableAI(true);
    }
  }
};

export const createFileLists = (files, storageType) => {
  const fileList = files?.map((doc, index) => {
    return {
      uid: index,
      name: doc,
      status: "done",
      url: getAssetsPath(doc, storageType),
      // url:
      //   storageType == ONPREMISE
      //     ? getAssetsPath(getEncodedImage(doc), storageType)
      //     : doc,
    };
  });
  return fileList;
};
export const getAssets = async (setAssets) => {
  try {
    const resp = await getAllAssetsApi();
    setAssets(
      resp?.data?.assets?.map(({ name, _id, code }) => ({
        value: _id,
        name: name,
        label: (
          <div
            style={{
              gap: 8,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tag className={tagStyles.smTag} color="blue">
              {code}
            </Tag>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {name}
            </span>
          </div>
        ),
      })) || []
    );
  } catch (error) {
    console.log(error);
  }
};

export const getAsset = async (id, setSelectedAsset) => {
  try {
    const resp = await getAssetByIdApi(id);
    setSelectedAsset(resp?.data);
  } catch (error) {
    console.log(error);
  }
};
