import React from "react";
import CreateOrgForm from "../../Organisations/CreateOrgForm";
import { Typography } from "antd";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";

const CreateOrg = ({ current }) => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Typography style={{ marginBottom: "12px" }} className={typoStyles.h5}>
          Create Organisation
        </Typography>
        <Typography
          className={`${typoStyles.body3} grey-text`}
          style={{ marginBottom: "32px" }}
        >
          Please enter your organisation name.
        </Typography>
      </div>
      <CreateOrgForm current={current} />
    </>
  );
};

export default CreateOrg;
