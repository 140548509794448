import { AutoComplete, Input, Spin } from "antd";
import React, { useState } from "react";
import { debounce } from "../../helpers/utility";

function CustomSearch({
  placeholder,
  width = "100%",
  size = "medium",
  options,
  onSearch,
  onSelect,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchedVal, setSearchedVal] = useState(null);
  const handleSearch = (value) => {
    // setIsLoading(true);
    onSearch(value, setIsLoading)?.finally(() => {
      //   setIsLoading(false);
    });
  };
  return (
    <AutoComplete
      style={{ width: width }}
      options={options}
      onSelect={(val, option) => {
        onSelect(val, option);
        setSearchedVal(null);
        setIsLoading(false);
      }}
      onSearch={debounce(handleSearch, 300)}
      onChange={(value) => setSearchedVal(value)}
      value={searchedVal}
    >
      <Input.Search
        size={size}
        placeholder={placeholder}
        suffix={
          <Spin
            size="small"
            style={{ visibility: isLoading ? "visible" : "hidden" }}
          />
        }
      />
    </AutoComplete>
  );
}

export default CustomSearch;
