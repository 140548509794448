import {
  Empty,
  Result,
  Table,
  Tag,
  Typography,
  Popover,
  Skeleton,
  Col,
  Pagination,
  Space,
  Input,
  Button,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { getWorkordersApi } from "../../services/work-orders.services";
import { useNavigate } from "react-router-dom";
import "./WorkOrder.css";
import {
  workOrderstatusList,
  capitalizeFirstLetter,
  priorityOptions,
  convertUtcToIst,
  convertUTCToLocalFormat,
  debounce,
} from "../../helpers/utility";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { UserContext } from "../../context/UserProvider";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { PAGESIZE } from "../../constants/defaultKeys";
import btnStyles from "../../assets/styles/Custom/Button.module.css";

const WorkOrdersTable = ({
  viewType,
  dateFilter,
  // search,
  // selectedAsset,
  setTotalCOunt,
  totalCOunt,
  filters,
  // selectedProductionLine,
  // selectedWoTypes,
  // selectedWoPriority,
  // selectedWoStatus,
  // selectedWoVerify,
}) => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: <Typography className={"tableHeader"}>Code</Typography>,
      dataIndex: "code",
      key: "code",
      width: 140,
      fixed: "left",
    },
    {
      title: <Typography className={"tableHeader"}>Title</Typography>,
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: <Typography className={"tableHeader"}>Type</Typography>,
      dataIndex: "type",
      key: "type",
      width: 140,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // filters: [
      //   {
      //     label: "Mechanical",
      //     text: "Mechanical",
      //     value: "Mechanical",
      //   },
      //   {
      //     label: "Electrical",
      //     text: "Electrical",
      //     value: "Electrical",
      //   },
      //   {
      //     label: "Others",
      //     text: "Others",
      //     value: "Others",
      //   },
      // ],
      // onFilter: (value, record) => record?.type?.startsWith(value),
      // filterSearch: true,
      render: (val) => (val ? val : "-"),
    },
    {
      title: (
        <Typography className={"tableHeader"}>
          Asset/ Production line
        </Typography>
      ),
      dataIndex: "asset",
      key: "asset",
      sorter: (a, b) =>
        (a?.asset?.name || a?.production_line?.name || "")?.localeCompare(
          b?.asset?.name || b?.production_line?.name || ""
        ),
      render: (val, record) => (
        <>
          {record?.asset
            ? record?.asset?.name || "-"
            : record?.production_line?.name || "-"}
        </>
      ),
      // render: (val, record) => <>{record?.asset?.name || "-"}</>,
      width: 250,
    },
    {
      title: <Typography className={"tableHeader"}>Location</Typography>,
      dataIndex: "site",
      key: "site",
      width: 200,
      render: (val, record) => (
        <>
          {record?.asset
            ? record?.asset?.location?.name || "-"
            : record?.production_line?.location?.name || "-"}
        </>
      ),
      // render: (val, record) => <>{record?.asset?.location?.name || "-"}</>,
    },
    // {
    //   title: <Typography className={"tableHeader"}>WO type</Typography>,
    //   dataIndex: "site",
    //   key: "site",
    //   width: 140,
    //   render: (val, record) => (
    //     <>{record?.sop?.is_inspection ? "Inspection" : "Break Fix"}</>
    //   ),
    // },
    {
      title: <Typography className={"tableHeader"}>Priority</Typography>,
      dataIndex: "priority",
      key: "priority",
      width: 140,
      // filters: priorityOptions,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // onFilter: (value, record) => record?.priority?.startsWith(value),
      // filterSearch: true,
      render: (val, record) => {
        const color =
          priorityOptions.filter((pri) => pri.value === val)[0]?.color ||
          "blue";
        return (
          <Tag
            style={{ minWidth: "80px", border: "0px" }}
            className={tagStyles.smTag}
            color={color}
          >
            {capitalizeFirstLetter(val) || "-"}
          </Tag>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Created at</Typography>,
      dataIndex: "created_at",
      key: "created_at",
      width: 220,
      render: (val, record) => (
        <>
          {val ? (
            <>
              <span>
                {dayjs(convertUTCToLocalFormat(val)).format("DD MMM")}
              </span>
              <span style={{ marginLeft: 10 }}>
                {dayjs(convertUTCToLocalFormat(val)).format("hh:mm A")}
              </span>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    // {
    //   title: <Typography className={"tableHeader"}>Assigned to</Typography>,
    //   dataIndex: "assigned_users",
    //   key: "assigned_users",
    //   width: 140,
    //   render: (val, record) => {
    //     const list = [
    //       ...(record?.assigned_users || []),
    //       ...(record?.assigned_groups || []),
    //     ];
    //     return (
    //       <>
    //         {list?.length > 0 ? (
    //           <div
    //             className="flex-center"
    //             style={{
    //               alignItems: "flex-start",
    //               flexDirection: "column",
    //               gap: "6px",
    //             }}
    //           >
    //             {list?.slice(0, 2)?.map((group) => (
    //               <Typography>{group?.name}</Typography>
    //             ))}
    //             {list?.length > 2 && (
    //               <Popover
    //                 content={
    //                   <div
    //                     className="flex-center"
    //                     style={{ flexDirection: "column", gap: "6px" }}
    //                   >
    //                     {list?.map((group) => (
    //                       <Typography>{group?.name}</Typography>
    //                     ))}
    //                   </div>
    //                 }
    //               >
    //                 <Tag>+{list?.length - 2}</Tag>
    //               </Popover>
    //             )}
    //           </div>
    //         ) : (
    //           <>-</>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      title: <Typography className={"tableHeader"}>Status</Typography>,
      dataIndex: "status",
      key: "status",
      width: 140,
      // filters: workOrderstatusList,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // onFilter: (value, record) => record?.status?.startsWith(value),
      // filterSearch: true,
      render: (val, record) => {
        const value = workOrderstatusList?.filter(
          (status) => status?.value == val
        )?.[0];
        return (
          <>
            {value?.label ? (
              <Tag
                className={tagStyles.smTag}
                style={{
                  minWidth: "80px",
                  textAlign: "left",
                }}
                color={value?.color}
              >
                {value?.label}{" "}
              </Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Verification</Typography>,
      width: 140,
      filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      dataIndex: "mark_as_verified",
      key: "mark_as_verified",
      // filters: [
      //   {
      //     label: "Not verified",
      //     text: "Not verified",
      //     value: false,
      //   },
      //   {
      //     value: true,
      //     label: "Verified",
      //     text: "Verified",
      //   },
      // ],
      // onFilter: (value, record) => record?.mark_as_verified == value,
      // filterSearch: true,
      render: (val, record) => (
        <div className="flex-center" style={{ justifyContent: "flex-start" }}>
          <Tag
            className={tagStyles.smTag}
            color={val ? "success" : "error"}
            style={{ minWidth: "90px", border: "0px" }}
          >
            {val ? "Verified" : "Not verified"}
          </Tag>
        </div>
      ),
    },
  ];

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const getOrders = async (viewType) => {
    // console.log(selectedWoStatus, "sssss");
    let params = { page: pageNumber };
    if (search) {
      params.name = search;
    }
    // if (selectedAsset) {
    //   params.asset = selectedAsset;
    // }
    if (dateFilter?.start_date) {
      params.start_date = dayjs(dateFilter?.start_date).format("YYYY-MM-DD");
    }
    if (dateFilter?.end_date) {
      params.end_date = dayjs(dateFilter?.end_date).format("YYYY-MM-DD");
    }
    filters?.asset && (params.asset = filters?.asset);
    filters?.production_line &&
      (params.production_line = filters?.production_line);
    filters?.types?.length > 0 && (params.type = filters?.types);
    filters?.priorities?.length > 0 && (params.priority = filters?.priorities);
    filters?.statuses?.length > 0 && (params.status = filters?.statuses);
    filters?.verification &&
      (params.mark_as_verified =
        filters?.verification === "verified" ? true : false);
    // const queryString = new URLSearchParams(params);
    try {
      setLoading(true);
      const resp = await getWorkordersApi(params);
      if (viewType == "assigned_to_me") {
        let ordersList = [];
        resp?.data?.workorders?.filter((each) => {
          if (each?.supervisor == userDetails?._id) {
            ordersList.push(each);
          } else {
            let present = each?.assigned_groups?.some((group) => {
              const memberIds = new Set(
                group?.members?.map((member) => member?._id)
              );
              return memberIds.has(userDetails?._id); // Stops early if a match is found
            });
            if (present) {
              ordersList.push(each);
            }
            // each?.assigned_groups?.map((g) => {
            //   g?.members?.map((m) => {
            //     if (m?._id == userDetails?._id) {
            //       ordersList.push(each);
            //     }
            //   });
            // });
          }
        });
        setOrders(ordersList);
        // setOrders(
        //   resp?.data?.filter((each) => each?.supervisor == userDetails?._id)
        // );
      } else if (viewType == "created_by_me") {
        setOrders(
          resp?.data?.workorders?.filter(
            (each) => each?.creator == userDetails?._id
          )
        );
      } else {
        setOrders(resp?.data?.workorders);
      }
      setTotalCOunt(resp?.data?.totalWorkorders);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders(viewType);
  }, [
    viewType,
    userDetails,
    // dateFilter,
    // search,
    // selectedAsset,
    pageNumber,
    refresh,
  ]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [
    search,
    dateFilter,
    filters,
    // selectedAsset,
    // selectedProductionLine,
    // selectedWoTypes,
    // selectedWoPriority,
    // selectedWoStatus,
    // selectedWoVerify,
  ]);

  // useEffect(() => {
  //   setTotalCOunt(orders?.length);
  // }, [orders]);

  return (
    <>
      <Col span={24}>
        <Table
          title={() => (
            <Space
              align="center"
              style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
            >
              <Input.Search
                size="large"
                onChange={debounce(handleSearch, 500)}
                placeholder="Search by WO name or code"
                prefix={
                  <SearchOutlined style={{ fontSize: 16, color: "#667085" }} />
                }
                style={{ width: "300px" }}
                className="custom-input-search"
              />
              <Button
                id="create-wo"
                type="primary"
                className={btnStyles.mdBtn}
                onClick={() => navigate("/work-orders/create")}
              >
                + Create WO
              </Button>
            </Space>
          )}
          onRow={(item) => {
            return {
              onClick: (e) => {
                !item?.children?.length > 0 &&
                  navigate(`/work-orders/${item?._id}`);
              },
            };
          }}
          scroll={{
            x: 1200,
            y: "55vh",
          }}
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No WO's found</Typography>}
              />
            ),
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          rowClassName={"rowClassName"}
          bordered
          columns={columns}
          dataSource={orders}
          // onChange={onChange}
          pagination={false}
        />
      </Col>
      {totalCOunt > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={totalCOunt}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </>
  );
};

export default WorkOrdersTable;
