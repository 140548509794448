import { Button, Col, Empty, Input, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../Layout";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import GroupsTable from "./GroupsTable";
import CreateOrEditGroup from "./CreateOREditGroup";
import { addGroups, readGroups } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";
import { debounce } from "../../helpers/utility";
import OrgSettingsHeader from "../Organisations/OrgSettingsHeader";

const Groups = () => {
  const [open, setOpen] = useState(false);
  const [currGroup, setCurrGroup] = useState({});
  const { userDetails } = useContext(UserContext);
  const [searchValue, setSearchValue] = useState("");
  const [groupsCount, setGroupsCount] = useState(0);

  const showDrawer = () => {
    setOpen(true);
  };

  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
  };

  return (
    <CustomLayout
      header={<OrgSettingsHeader count={groupsCount} />}
      // header={
      //   <Typography className={typoStyles.h6}>Teams ({groupsCount})</Typography>
      // }
    >
      <Row gutter={[0, 18]}>
        {addGroups.includes(userDetails?.activeOrg?.role) && (
          <Col
            offset={12}
            span={12}
            style={{
              textAlign: "right",
              gap: "12px",
              justifyContent: "flex-end",
            }}
            className="flex-center"
          >
            {readGroups.includes(userDetails?.activeOrg?.role) && (
              <Input.Search
                size="medium"
                onChange={debounce(handleSearch, 500)}
                style={{ width: "50%" }}
                placeholder="Search by team name"
              />
            )}
            <Button
              type="primary"
              className={btnStyles.mdBtn}
              onClick={showDrawer}
            >
              + Create Team
            </Button>
          </Col>
        )}
        {readGroups.includes(userDetails?.activeOrg?.role) ? (
          <GroupsTable
            open={open}
            setOpen={setOpen}
            currGroup={currGroup}
            setCurrGroup={setCurrGroup}
            searchValue={searchValue}
            setGroupsCount={setGroupsCount}
          />
        ) : (
          <div
            className="flex-center"
            style={{ minHeight: "50vh", width: "100%" }}
          >
            <Empty description="You are not authorised to view users and teams" />
          </div>
        )}
      </Row>
      <CreateOrEditGroup
        open={open}
        setOpen={setOpen}
        currGroup={currGroup}
        setCurrGroup={setCurrGroup}
      />
    </CustomLayout>
  );
};

export default Groups;
