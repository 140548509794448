import { Button, Col, Divider, Form, Image, Input, Typography } from "antd";
import React from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import darkLogo from "../../../assets/images/cmms-logos/CMMSLogo.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const MobileScreen = ({ sendOtp, setLoginType, orgDetails }) => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Image
          src={orgDetails.logo || darkLogo}
          style={{ height: "100px", width: "auto" }}
          preview={false}
        />
        <Typography style={{ marginBlock: "12px" }} className={typoStyles.h6}>
          Mobile Login
        </Typography>
      </div>

      <Form layout="vertical" onFinish={sendOtp} requiredMark={false}>
        <Form.Item
          label="Phone number"
          name={"mobile_number"}
          rules={[
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit phone number",
            },
            { required: true, message: "Please enter mobile number" },
          ]}
        >
          <Input
            className={inputStyles.smInput}
            style={{ width: "100%" }}
            placeholder="Enter phone number"
          />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
          >
            Send OTP
          </Button>
        </Form.Item>
      </Form>
      <div className="text-center">
        <Divider>Or</Divider>
        <Button
          onClick={() => {
            setLoginType("email");
            navigate("/login");
          }}
          type="link"
          style={{ fontSize: "16px", lineHeight: "24px" }}
        >
          <ArrowLeftOutlined />
          Login with email
        </Button>
        {window.location.hostname == process.env.REACT_APP_PUBLIC_DOMAIN && (
          <>
            <br />
            <Button
              onClick={() => navigate("/signup/0")}
              type="link"
              style={{ marginBottom: "24px", paddingInline: "0px" }}
            >
              <span style={{ color: "#2f3542", marginRight: "6px" }}>
                Don't have an account?{" "}
              </span>
              Sign Up
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default MobileScreen;
