import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../Layout";
import {
  Form,
  Col,
  Row,
  Card,
  Flex,
  Button,
  message,
  Space,
  Tag,
  Typography,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GeneralDetails from "./GeneralDetails";
import SOPDetails from "./SOPDetails";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import Comments from "./Comments";
import MaintananceStatus from "./MaintanaceStatus.js";
import { updateOccurenceApi } from "../../../services/maintenance.services.js";
import Recurrence from "./Recurrence.js";
import History from "./History.js";
import {
  generateInitialScheduleString,
  getIntialValues,
  getJob,
  getMaintanace,
  handleDynamicValidations,
  handleResponse,
  initializeParams,
  prepareValues,
  submitForm,
  updateScheduleString,
} from "./helper.js";
import {
  INSPECTION,
  MAINTANANCETEXT,
  verifyPM,
} from "../../../constants/defaultKeys.js";
import { UserContext } from "../../../context/UserProvider.js";
import Header from "./Header.js";
import { getProductionLines } from "../../../services/assets.services";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import SparePartSection from "../../Blocks/SparePartSection";
import TaskInfo from "../../Blocks/TaskInfo";

const CreatePM = ({ type = MAINTANANCETEXT }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const [woDocuments, setWoDocuments] = useState([]);
  const [edit, setEdit] = useState(false);
  const [workOrderID, setWorkOrderID] = useState(null);
  const [maintanaceDetails, setMaintanceDetails] = useState({});
  const [typeofRec, setTypeOfRec] = useState(1);
  const [occurString, setOccurString] = useState("");
  const { userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [sopData, setSopData] = useState({});
  const [answers, setAnswers] = useState({});
  const [occDate, setOccDate] = useState(null);
  const location = useLocation();
  const [isInspection, setIsInspection] = useState(false);
  const [productionLines, setProductionLines] = useState([]);
  const [plId, setPlId] = useState(null);

  const { source } = location.state || {};

  const fetchProductionLines = async () => {
    try {
      const res = await getProductionLines();
      setProductionLines(
        res?.data?.assets?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const setFunctions = {
    setWorkOrderID,
    setMaintanceDetails,
    setSopData,
    setTypeOfRec,
    setWoDocuments,
    setAnswers,
    setOccurString,
    setEdit,
    setIsInspection,
    setPlId,
  };

  useEffect(() => {
    initializeParams(params, form, navigate, setFunctions, occDate);
  }, [params, occDate]);

  useEffect(() => {
    generateInitialScheduleString(setOccurString, typeofRec);
  }, []);

  useEffect(() => {
    updateScheduleString(form, setOccurString, typeofRec);
  }, [typeofRec]);

  useEffect(() => {
    fetchProductionLines();
  }, [maintanaceDetails]);

  const onFinish = async (values) => {
    let selectedAsset = form.getFieldValue("asset");
    let selectedProductionLine = form.getFieldValue("production_line");
    if (!selectedAsset && !selectedProductionLine) {
      message.error("Please enter either asset or production line");
    } else {
      const cleanedValues = prepareValues(values, typeofRec);
      try {
        const resp = await submitForm(params, cleanedValues, type);
        await handleResponse(
          resp,
          params,
          form,
          navigate,
          occDate,
          setEdit,
          setFunctions,
          type
        );
      } catch (error) {
        console.log(error);
        message.error(
          error?.response?.data?.message || "Something went wrong!!"
        );
      }
    }
  };

  // const submitSOP = async (sopId, id) => {
  //   try {
  //     const resp = await submitSOPApi(sopId, {
  //       maintenance: maintanaceDetails?.maintenance?._id,
  //       occurrence: id,
  //       responses: answers,
  //       submitting_for_date: maintanaceDetails?.occurrence_date,
  //     });
  //   } catch (error) {
  //     message.error(error?.response?.data?.message || "Something went wrong!!");
  //   }
  // };

  const verifyOcuurence = async () => {
    try {
      const resp = await updateOccurenceApi(params?.id, {
        mark_as_verified: true,
        completed: true,
      });
      message.success(resp?.data?.message);
      refreshCall();
      // getJob(
      //   form,
      //   params,
      //   setMaintanceDetails,
      //   setSopData,
      //   setWorkOrderID,
      //   setAnswers,
      //   setIsInspection,
      //   setPlId
      // );
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  const refreshCall = () => {
    getJob(
      form,
      params,
      setMaintanceDetails,
      setSopData,
      setWorkOrderID,
      setAnswers,
      setIsInspection,
      setPlId
    );
  };

  return (
    <CustomLayout
      header={
        <>
          <Header
            maintanaceDetails={maintanaceDetails}
            edit={edit}
            type={type}
            setEdit={setEdit}
            source={source}
          />
        </>
      }
      footer={
        <>
          {(!params?.id || (params?.id && edit)) && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (params?.id) {
                    setEdit(false);
                    getMaintanace(
                      navigate,
                      form,
                      params,
                      setTypeOfRec,
                      setWoDocuments,
                      setMaintanceDetails,
                      setSopData,
                      setAnswers,
                      occDate,
                      setOccurString,
                      setIsInspection,
                      setPlId
                    );
                  } else {
                    form.resetFields();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                onClick={form.submit}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <Form
        layout="vertical"
        requiredMark={false}
        form={form}
        onFinish={onFinish}
        initialValues={getIntialValues()}
        onValuesChange={(changedValues, values) =>
          handleDynamicValidations(
            changedValues,
            values,
            form,
            setOccurString,
            typeofRec
          )
        }
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} md={24} lg={24} xl={16}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <GeneralDetails
                  edit={edit}
                  form={form}
                  setOccDate={setOccDate}
                  maintanaceDetails={maintanaceDetails}
                  type={type}
                  isInspection={isInspection}
                  setIsInspection={setIsInspection}
                  setSopData={setSopData}
                  productionLines={productionLines}
                  setPlId={setPlId}
                />
              </Col>
              {/* {params?.id && (
                <Col xs={24} md={24} lg={24} xl={0}>
                  {params?.type && (
                    <MaintananceStatus
                      form={form}
                      onFinish={onFinish}
                      maintanaceDetails={maintanaceDetails}
                      getJob={() =>
                        getJob(
                          form,
                          params,
                          setMaintanceDetails,
                          setSopData,
                          setWorkOrderID,
                          setAnswers,
                          setIsInspection
                        )
                      }
                      setOccDate={setOccDate}
                    />
                  )}
                  {!params.type && (
                    <History maintanaceDetails={maintanaceDetails} />
                  )}
                </Col>
              )} */}
              {!params?.type && (
                <Col span={24}>
                  <Card
                    title="Recurrence"
                    styles={{
                      header: { backgroundColor: "#fff" },
                    }}
                  >
                    <Recurrence
                      occurString={occurString}
                      typeofRec={typeofRec}
                      setTypeOfRec={setTypeOfRec}
                      form={form}
                      edit={edit}
                      onFinish={onFinish}
                      maintanaceDetails={maintanaceDetails}
                      setOccurString={setOccurString}
                      type1={type}
                    />
                  </Card>
                </Col>
              )}
              {params?.type && (
                <Col span={24}>
                  <SparePartSection
                    form={form}
                    edit={edit}
                    productionLines={productionLines}
                    plId={plId}
                    inventoryRequestData={maintanaceDetails?.inventory_request}
                    orderId={maintanaceDetails?._id}
                    type={"PM"}
                    refreshCall={refreshCall}
                    verifyStatus={
                      maintanaceDetails?.mark_as_verified ||
                      userDetails?.activeOrg?.role === "operator"
                    }
                    taskStatus={maintanaceDetails?.status}
                  />
                </Col>
              )}
              <Col span={24}>
                <SOPDetails
                  form={form}
                  edit={edit}
                  maintanaceDetails={maintanaceDetails}
                  sopData={sopData}
                  setSopData={setSopData}
                  answers={answers}
                  setAnswers={setAnswers}
                  isInspection={isInspection}
                  type={type}
                />
              </Col>
            </Row>
          </Col>
          {params?.id && (
            <Col xs={24} md={24} lg={24} xl={8}>
              {params?.type && (
                <MaintananceStatus
                  // form={form}
                  // onFinish={onFinish}
                  type={type}
                  maintanaceDetails={maintanaceDetails}
                  refreshCall={refreshCall}
                  // getJob={() =>
                  //   getJob(
                  //     form,
                  //     params,
                  //     setMaintanceDetails,
                  //     setSopData,
                  //     setWorkOrderID,
                  //     setAnswers,
                  //     setIsInspection
                  //   )
                  // }
                  // setOccDate={setOccDate}
                />
              )}
              {params?.type && (
                <TaskInfo details={maintanaceDetails} typeValue="PM" />
                // <Card
                //   styles={{ body: { padding: "16px" } }}
                //   style={{ marginTop: 12 }}
                // >
                //   <Space style={{ width: "100%", gap: 18 }}>
                //     <Typography>PM Created By</Typography>
                //     {maintanaceDetails?.creator?.first_name ||
                //     maintanaceDetails?.creator?.last_name ? (
                //       <Tag className={tagStyles.mdTag} color="purple">
                //         {`${maintanaceDetails?.creator?.first_name} ${maintanaceDetails?.creator?.last_name}`}
                //       </Tag>
                //     ) : (
                //       "-"
                //     )}
                //   </Space>
                //   <Space style={{ width: "100%", gap: 24, marginTop: 12 }}>
                //     <Typography>PM Closed By</Typography>
                //     {maintanaceDetails?.closed_by?.first_name ||
                //     maintanaceDetails?.closed_by?.last_name ? (
                //       <Tag className={tagStyles.mdTag} color="green">
                //         {`${maintanaceDetails?.closed_by?.first_name} ${maintanaceDetails?.closed_by?.last_name}`}
                //       </Tag>
                //     ) : (
                //       "-"
                //     )}
                //   </Space>
                //   <Space style={{ width: "100%", gap: 20, marginTop: 12 }}>
                //     <Typography>PM Verified By</Typography>
                //     {maintanaceDetails?.verified_by?.first_name ||
                //     maintanaceDetails?.verified_by?.last_name ? (
                //       <Tag className={tagStyles.mdTag} color="blue">
                //         {`${maintanaceDetails?.verified_by?.first_name} ${maintanaceDetails?.verified_by?.last_name}`}
                //       </Tag>
                //     ) : (
                //       "-"
                //     )}
                //   </Space>
                // </Card>
              )}
              {!params.type && (
                <History maintanaceDetails={maintanaceDetails} />
              )}
            </Col>
          )}

          {params?.type && (
            <Col xs={24} md={24} lg={24} xl={16}>
              <Comments
                form={form}
                workOrderID={workOrderID}
                setWorkOrderID={setWorkOrderID}
                edit={edit}
                // verifyStatus={maintanaceDetails?.mark_as_verified}
                verifyStatus={
                  maintanaceDetails?.mark_as_verified &&
                  !(userDetails?.activeOrg?.role === "manager")
                }
              />
            </Col>
          )}
          {maintanaceDetails?.status == "closed" &&
            verifyPM.includes(userDetails?.activeOrg?.role) && (
              <Col
                xs={24}
                md={24}
                lg={24}
                xl={16}
                style={{ textAlign: "center" }}
              >
                <Button
                  size="medium"
                  style={{
                    color: maintanaceDetails?.mark_as_verified && "green",
                    width: "40vw",
                    margin: "auto",
                  }}
                  type={!maintanaceDetails?.mark_as_verified && "primary"}
                  onClick={() =>
                    !maintanaceDetails?.mark_as_verified && verifyOcuurence()
                  }
                >
                  {!maintanaceDetails?.mark_as_verified && (
                    <>
                      Mark as Verified <CheckOutlined />
                    </>
                  )}
                </Button>
              </Col>
            )}
        </Row>
      </Form>
    </CustomLayout>
  );
};

export default CreatePM;
