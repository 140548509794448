import { Segmented } from "antd";
import React from "react";
import segmentedStyles from "../../assets/styles/Custom/Segmented.module.css";

const AssetSegemented = ({ navigate, viewType }) => {
  return (
    <Segmented
      id="asset-table-view"
      className={segmentedStyles.basicOne}
      options={[
        { value: "table", label: "All Assets" },
        { value: "tree", label: "Location-wise Assets" },
        // { value: "asset-groups", label: "Asset Categories" },
      ]}
      onChange={(e) => navigate(`/assets?type=${e}`)}
      // onChange={(e) =>
      //   e === "asset-groups"
      //     ? navigate(`/asset-groups`)
      //     : navigate(`/assets?type=${e}`)
      // }
      value={viewType}
    />
  );
};

export default AssetSegemented;
