import React from "react";
import CustomCard from "../../../Blocks/CustomCard";
import PrecQuestion from "./SectionQuestion";
import { Button } from "antd";
import { getButtonsLsit } from "./addElemHelper";

function SectionComp(
  {
    sopContent,
    setSopContent,
    isUnfilled,
    setIsUnfilled,
    editing,
    setEditing,
    data,
  },
  ref
) {
  const buttonData = getButtonsLsit(
    sopContent,
    setSopContent,
    setEditing,
    ref,
    null,
    null
  )[data?.title?.toLowerCase()];

  return (
    <CustomCard title={data?.title} ref={ref}>
      {data?.procedures?.map((each, index) => (
        <PrecQuestion
          key={index}
          questionData={each}
          index={index}
          sopContent={sopContent}
          setSopContent={setSopContent}
        />
      ))}

      <div style={{ textAlign: "right" }}>
        <Button
          style={{ textAlign: "left" }}
          type="primary"
          ghost
          onClick={buttonData?.onClick}
        >
          + Add one more
        </Button>
      </div>
    </CustomCard>
  );
}

export default React.forwardRef(SectionComp);
