import { Form, Input, Skeleton, Spin } from "antd";
import React from "react";
import VideoPlayer from "../../../Blocks/VideoPlayer";
import inputStyles from "../../../../assets/styles/Custom/Input.module.css";

export const youtubeRegex =
  /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
export const vimeoRegex = /^(https?:\/\/)?(www\.)?vimeo\.com\/(\d+)(\/?)$/;
export const regularLink =
  /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]+)([\/\w .-]*)([\/\w !@#$%^&*()_+=\-{}\[\]:;"'<>,.?~]*)*(\?[^\s]*)?$/;
function LinkElem({ tab, form, setURL, url, loading, setLoading }) {
  const contentSource =
    tab == "add_yt_link"
      ? "youtube"
      : tab == "add_vimeo_link"
      ? "vimeo"
      : "document";

  const contentRegex =
    contentSource == "youtube"
      ? youtubeRegex
      : contentSource == "vimeo"
      ? vimeoRegex
      : regularLink;

  const validateURL = (_, value) => {
    const regex = contentRegex;
    if (regex.test(value) || !value) {
      // We check for !value to allow empty field
      setURL(value);
      return Promise.resolve();
    }
    setURL(null);
    return Promise.reject(new Error("Invalid URL!"));
  };

  return (
    <>
      <Form.Item
        name="src"
        label="URL"
        rules={[
          {
            validator: validateURL,
          },
          {
            required: true,
            message: `Add your ${contentSource} link`,
          },
        ]}
      >
        <Input
          className={inputStyles.smInput}
          placeholder={`Paste your ${contentSource} link`}
          allowClear={true}
        />
      </Form.Item>
      {url && contentSource !== "document" && (
        <>
          {loading && (
            <center style={{ overflow: "hidden" }}>
              <Skeleton.Input
                active
                style={{
                  minWidth: "100vh",
                  height: "200px",
                  marginBottom: "5px",
                }}
              />
            </center>
          )}
          <div style={{ display: loading ? "none" : "block" }}>
            <VideoPlayer
              url={form.getFieldValue("src")}
              width={"100%"}
              height={"500px"}
              setLoading={setLoading}
            />
          </div>
        </>
      )}
    </>
  );
}

export default LinkElem;
