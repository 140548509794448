import { Col, ConfigProvider, Flex, Image, Menu, Row } from "antd";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import logo from "../../assets/images/cmms-logos/CMMSLogo.svg";
import bolt from "../../assets/images/cmms-logos/bolt.png";

import dashboardInactive from "../../assets/images/sidemenuIcons/dashboardInactive.svg";
import dashboardActive from "../../assets/images/sidemenuIcons/dashboardActive.svg";
import usersActive from "../../assets/images/sidemenuIcons/usersActive.svg";
import usersInactive from "../../assets/images/sidemenuIcons/usersInactive.svg";
import groupsActive from "../../assets/images/sidemenuIcons/groupsActive.svg";
import groupsInactive from "../../assets/images/sidemenuIcons/groupsInactive.svg";
import pmActive from "../../assets/images/sidemenuIcons/pmActive.svg";
import pmInactive from "../../assets/images/sidemenuIcons/pmInactive.svg";
import sopsActive from "../../assets/images/sidemenuIcons/sopsActive.svg";
import sopsInactive from "../../assets/images/sidemenuIcons/sopsInactive.svg";
import woActive from "../../assets/images/sidemenuIcons/woActive.svg";
import woInactive from "../../assets/images/sidemenuIcons/woInactive.svg";
import assetsActive from "../../assets/images/sidemenuIcons/assetActive.svg";
import assetsInactive from "../../assets/images/sidemenuIcons/assetInactive.svg";
import locationsActive from "../../assets/images/sidemenuIcons/locationsActive.svg";
import locationsInactive from "../../assets/images/sidemenuIcons/locationsInactive.svg";
import schedulerActive from "../../assets/images/sidemenuIcons/schedulerActive.svg";
import schedulerInactive from "../../assets/images/sidemenuIcons/schedulerInactive.svg";
import coursesActive from "../../assets/images/sidemenuIcons/trainingsActive.svg";
import coursesInactive from "../../assets/images/sidemenuIcons/trainingsInActive.svg";
import inspectionsActive from "../../assets/images/sidemenuIcons/inspectionsActive.svg";
import inspectionsInactive from "../../assets/images/sidemenuIcons/inspectionsInactive.svg";
import mroActive from "../../assets/images/sidemenuIcons/mroActive.svg";
import mroInactive from "../../assets/images/sidemenuIcons/mroInactive.svg";
import inventoryActive from "../../assets/images/sidemenuIcons/inventoryActive.svg";
import inventoryInactive from "../../assets/images/sidemenuIcons/inventoryInactive.svg";
import auditLogsActive from "../../assets/images/sidemenuIcons/auditLogsActive.svg";
import auditLogsInactive from "../../assets/images/sidemenuIcons/auditLogsInactive.svg";

import "./Layout.css";
import { UserContext } from "../../context/UserProvider";
import RandomAvatar from "../Blocks/RandomAvatar";
import { capitalizeFirstLetter } from "../../helpers/utility";
import {
  ArrowRightOutlined,
  SettingOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
} from "@ant-design/icons";
import ChooseOrgModal from "../Organisations/ChooseOrgModal";

const SideMenu = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const { userDetails } = useContext(UserContext);
  const finalLogo = userDetails?.activeOrg?.organization?.profile_pic || logo;

  const handleOnOpen = () => {
    setOpenModal(true);
  };

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  function getImage(path, activeimage, inactiveImage) {
    return (
      <Image
        preview={false}
        src={
          path === location.pathname.split("/")[1] ? activeimage : inactiveImage
        }
        id={path}
        alt={path}
        style={{
          height: collapsed ? "22px" : "18px",
          width: "auto",
          verticalAlign: "text-bottom",
          marginRight: "18px",
        }}
      />
    );
  }
  const items = [
    getItem(
      "Dashboard",
      "dashboard",
      getImage("dashboard", dashboardActive, dashboardInactive)
    ),
    getItem(
      "Scheduler",
      "scheduler",
      getImage("scheduler", schedulerActive, schedulerInactive)
    ),
    getItem("MROs", "mro", getImage("mro", mroActive, mroInactive)),
    getItem(
      "Work Orders",
      "work-orders",
      getImage("work-orders", woActive, woInactive)
    ),

    getItem(
      "Maintenance",
      "maintenances",
      getImage("maintenances", pmActive, pmInactive)
    ),
    getItem(
      "Inspections",
      "inspections",
      getImage("inspections", inspectionsActive, inspectionsInactive)
    ),
    getItem(
      "Procedures",
      "procedures",
      getImage("procedures", sopsActive, sopsInactive)
    ),
    getItem(
      "Trainings",
      "trainings",
      getImage("trainings", coursesActive, coursesInactive)
    ),
    getItem(
      "Audit Logs",
      "audit-logs",
      getImage("audit-logs", auditLogsActive, auditLogsInactive)
    ),
    getItem(
      "Inventory",
      "inventory",
      getImage("inventory", inventoryActive, inventoryInactive)
    ),
    getItem(
      "Asset Registry",
      "asset-registry",
      getImage("assets", assetsActive, assetsInactive),
      [
        getItem(
          "Assets",
          "assets"
          // getImage("assets", assetsActive, assetsInactive)
        ),
        getItem(
          "Production Lines",
          "production-lines"
          // getImage("assets", assetsActive, assetsInactive)
        ),
        getItem("Asset Groups", "asset-groups"),
      ]
    ),
    getItem(
      "Locations",
      "locations",
      getImage("locations", locationsActive, locationsInactive)
    ),
    // getItem(
    //   "Assets",
    //   "assets",
    //   getImage("assets", assetsActive, assetsInactive)
    // ),
    // getItem(
    //   "Asset Risk Analysis",
    //   "analysis",
    //   getImage("analysis", assetsActive, assetsInactive)
    // ),

    // getItem(
    //   "Audit Logs",
    //   "logs",
    //   getImage("logs", assetsActive, assetsInactive)
    // ),
    // getItem(
    //   "Teams",
    //   "groups",
    //   getImage("groups", groupsActive, groupsInactive)
    // ),
    // !userDetails?.activeOrg?.role !== "technician" &&
    //   getItem("Users", "users", getImage("users", usersActive, usersInactive)),
    getItem(
      "Organization",
      "org",
      <SettingOutlined
        style={{
          color:
            location.pathname.split("/")[1] === "org" ? "#7369F4" : "#667085",
          fontSize: collapsed ? 20 : 18,
          marginRight: 7,
        }}
      />
    ),
  ];

  return (
    <Row style={{ height: "100%" }} align={"top"} gutter={[0, 24]}>
      <ChooseOrgModal
        openModal={openModal}
        onOpenChange={handleOnOpen}
        closable={true}
        onCancel={() => setOpenModal(false)}
      />
      <Col span={24}>
        <ConfigProvider
          theme={{
            // algorithm: theme.darkAlgorithm,
            theme: {
              fontFamily: "Montserrat",
            },
            components: {
              Menu: {
                itemBg: "#2f3542",
                itemColor: "#2f3542",
                itemSelectedColor: "#7369F4",
                itemSelectedBg: "#f0f1f6",
                itemHoverBg: "#fff",
                itemHoverColor: "#2f3542",
              },
            },
          }}
        >
          <Flex
            align="center"
            justify={collapsed ? "center" : "space-between"}
            style={{
              marginBottom: "24px",
              minHeight: "50px",
              gap: "12px",
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >
            {!collapsed ? (
              <>
                <Image
                  preview={false}
                  className={
                    collapsed
                      ? "sideMenuLogoWithCollapse"
                      : "sideMenuLogoWithNoCollapse"
                  }
                  // height={"auto"}
                  src={collapsed ? bolt : finalLogo ? finalLogo : logo}
                  alt="Bolt Logo"
                  style={{ marginLeft: "20px", height: "50px", width: "auto" }}
                />
                <VerticalLeftOutlined
                  style={{ fontSize: "20px", color: "#2f3542" }}
                  onClick={() => setCollapsed((prev) => !prev)}
                />
              </>
            ) : (
              <VerticalRightOutlined
                style={{ fontSize: "20px", color: "#2f3542" }}
                onClick={() => setCollapsed((prev) => !prev)}
              />
            )}
          </Flex>

          <Menu
            className={collapsed ? "sideMenuCollapsed" : "sideMenu"}
            style={{ border: "0px", maxHeight: "75vh", overflowY: "auto" }}
            selectedKeys={[location.pathname.split("/")[1]]}
            defaultSelectedKeys={[location.pathname.split("/")[1]]}
            mode="inline"
            defaultOpenKeys={["asset-registry"]}
            items={items}
            onClick={(e) => {
              if (e.key == "org") {
                navigate(`/org/groups`);
              } else {
                navigate(`/${e.key}`);
              }
            }}
          />
        </ConfigProvider>
      </Col>
      {/* {userDetails?.organizations?.length > 1 && ( */}
      <Col span={24} style={{ placeSelf: "end" }}>
        <div
          onClick={() => {
            setOpenModal(true);
          }}
          className="flex-between"
          style={{
            paddingTop: "24px",
            cursor: "pointer",
            gap: "12px",
            paddingLeft: collapsed ? 24 : 16,
            paddingRight: collapsed ? 24 : 16,
          }}
        >
          <RandomAvatar
            size={24}
            url={userDetails?.activeOrg?.organization?.profile_pic}
            name={capitalizeFirstLetter(
              userDetails?.activeOrg?.organization?.name
            )}
            displayName={!collapsed}
            fontStyle={{ fontSize: "14px", width: "80%" }}
            indexId={userDetails?.activeOrg?.user_org_id}
          />
          <ArrowRightOutlined />
        </div>
      </Col>
      {/* )} */}
    </Row>
  );
};

export default SideMenu;
