import { Card, Col, DatePicker, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "../Maintanance.css";
import { useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import {
  convertUTCToLocalFormat,
  maintanceStatuses,
} from "../../../helpers/utility";

const History = ({ maintanaceDetails }) => {
  const [dates, setDates] = useState([]);
  const params = useParams();

  useEffect(() => {
    setDates(
      maintanaceDetails?.occurrences?.map((date) => ({
        date: date?.occurrence_date
          ? dayjs(convertUTCToLocalFormat(date?.occurrence_date))
          : null,
        status: date?.status,
      })) || []
    );
  }, [maintanaceDetails]);

  const dateCellRender = (current, info) => {
    const currentDate = dayjs(current);
    const isPastDate = currentDate.isBefore(dayjs(), "day");
    const matchingDate = dates.find((date) =>
      date.date.isSame(currentDate, "day")
    );
    let backgroundColor = "";

    if (matchingDate) {
      switch (matchingDate.status) {
        case "closed":
          backgroundColor = "#87d068";
          break;
        case "pending":
          backgroundColor = "#f08080";
          break;
        case "open":
          backgroundColor = "#108ee9";
          break;
        case "on-hold":
          backgroundColor = "#f50";
          break;
        case "in-progress":
          backgroundColor = "#FFBE0B";

          break;
        // Add more cases as needed
        default:
          backgroundColor = "#363F72";
          // Default color or no color change
          break;
      }
    }
    if (info.type !== "date") {
      return info.originNode;
    } else {
      return (
        <div
          style={{
            backgroundColor,
            color: backgroundColor && "#fff",
            padding: "8px",
            width: "38px",
            height: "38px",
            borderRadius: "6px",
          }}
        >
          {currentDate.date()}
        </div>
      );
    }
  };
  const getPopupContainer = (trigger) => trigger.parentElement;

  return (
    <Card
      title="PM Schedule"
      style={{ marginTop: params?.type && "24px" }}
      styles={{
        header: { backgroundColor: "#fff" },
        body: { minHeight: "200px" },
      }}
    >
      <div style={{ position: "relative" }}>
        <DatePicker
          maxTagCount={0}
          style={{ width: "20vw" }}
          cellRender={dateCellRender}
          dropdownMatchSelectWidth={false}
          getPopupContainer={getPopupContainer}
          popupClassName="customDatePickerWidth"
          size="medium"
          suffixIcon={null}
          allowClear={false}
          open
          inputReadOnly
          placement="bottomRight"
          popupStyle={{
            top: 0,
            position: "absolute",
            left: 0,
            zIndex: 10,
          }}
          multiple
          format="MM-DD-YYYY"
        />
      </div>
      <Row
        gutter={[12, 12]}
        style={{
          marginBottom: "24px",
          marginTop: "400px",
        }}
        justify={"flex-start"}
      >
        {maintanceStatuses?.map((each) => {
          return (
            <Col span={12}>
              <Typography className="flex">
                <div
                  style={{
                    height: "24px",
                    width: "24px",
                    borderRadius: "6px",
                    backgroundColor: each?.color,
                  }}
                ></div>
                {each?.label}
              </Typography>
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

export default History;
