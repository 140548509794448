import {
  Button,
  Col,
  Flex,
  Image,
  Modal,
  Popconfirm,
  Row,
  Typography,
  message,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router";

import CustomLayout from "../../../Layout";
import SideMenu from "../../../Layout/SideMenu";
import EachTyut from "../CreateOrEditCourse/EachTyut";
import EditTyut from "../../Tyuts/EditTyut";
import ShareCourse from "../ShareCourse";
import {
  getALlCoursesApi,
  getCourseByIDApi,
  markTyutInCourseCompleteApi,
} from "../../../../services/courses.services";

import "../Course.css";
import notyuts from "../../../../assets/images/tyuts/notyuts.svg";

import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../../assets/styles/Custom/Button.module.css";
import { deleteCond, editCond } from "../../../../helpers/utility";
import { editTyut } from "../../../../constants/defaultKeys";
import { UserContext } from "../../../../context/UserProvider";
import { deleteCourseApi } from "../../../../services/tyuts.services";

function EditCourse() {
  const tyutsRefs = useRef([]);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const tyutsScrollRef = useRef(null);
  const activeTyutIndex = useRef(null);
  const [activeTyut, setActiveTyut] = useState(null);
  const [courseDetail, setCourseDetails] = useState({});
  const [isTyutCompleted, setIsTyutCompleted] = useState({});
  const [shareCourse, setShareCourse] = useState({
    share: false,
    courseId: null,
  });
  const [isCourseCompleted, setCourseCompleted] = useState(false);
  const courseId = useRef(params?.id);
  const lastTyut = useRef(null);
  const nextCourseId = useRef(null);
  const { userDetails } = useContext(UserContext);

  useLayoutEffect(() => {
    courseId.current = params?.id;
    getCourseByIDApi(params?.id)
      .then((res) => {
        setCourseDetails(res?.data);
        tyutsRefs.current = (res?.data?.tyuts || []).map(
          (_, i) => tyutsRefs?.current[i] ?? React.createRef()
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, [location]);

  useEffect(() => {
    if (courseDetail.tyuts) {
      activeTyutIndex.current = 0;
      // setActiveTyut(courseDetail.tyuts[0]);
    }
  }, [courseDetail]);

  useEffect(() => {
    if (tyutsRefs.current[activeTyutIndex.current]) {
      tyutsRefs.current[activeTyutIndex.current].current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [activeTyut]);

  const isIndexAtFirstTyut = activeTyutIndex.current === 0;
  const isIndexAtLastTyut =
    activeTyutIndex.current === courseDetail?.tyuts?.length - 1;

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "ArrowLeft" && activeTyutIndex.current > 0) prevTyut();
      else if (
        e.key === "ArrowRight" &&
        activeTyutIndex.current < courseDetail?.tyuts?.length - 1
      )
        nextTyut();
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [activeTyutIndex.current]);

  useEffect(() => {
    getALlCoursesApi()
      .then((res) => {
        const courseList = res.data || [];
        courseList.some((eachVal, index) => {
          if (eachVal?._id == params?.id) {
            nextCourseId.current = courseList[index + 1] ?? courseList[0];
            return true;
          }
        });
      })
      .catch((err) => console.log(err));
  }, [location]);

  function nextTyut() {
    setActiveTyut(courseDetail?.tyuts[activeTyutIndex.current + 1]);
    activeTyutIndex.current++;
  }

  function prevTyut() {
    setActiveTyut(courseDetail?.tyuts[activeTyutIndex.current - 1]);
    activeTyutIndex.current--;
  }

  async function markTyutAsCompleted({ courseId, tyutId }) {
    try {
      const res = await markTyutInCourseCompleteApi(courseId, tyutId);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (isTyutCompleted?.[activeTyut?._id]) {
      markTyutAsCompleted({
        courseId: courseId.current,
        tyutId: activeTyut._id,
      });
    }
  }, [isTyutCompleted]);

  useEffect(() => {
    if (typeof window != undefined) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = lastTyut.current;
        const isBottom = scrollTop + clientHeight >= scrollHeight;
        if (
          Object.keys(isTyutCompleted).length === courseDetail.tyuts?.length &&
          isBottom &&
          !isCourseCompleted
        ) {
          setCourseCompleted(true);
        }
      };
      if (lastTyut.current) {
        const hasVerticalScrollbar =
          lastTyut.current.clientHeight < lastTyut.current.scrollHeight;
        const hasHorizontalScrollbar =
          lastTyut.current.clientWidth < lastTyut.current.scrollWidth;
        if (
          !hasVerticalScrollbar &&
          Object.keys(isTyutCompleted).length === courseDetail.tyuts?.length
        ) {
          setCourseCompleted(true);
        }
      }

      lastTyut.current?.addEventListener("scroll", (e) => {
        handleScroll();
      });

      return () => {
        lastTyut.current?.removeEventListener("scroll", (e) => {
          handleScroll();
        });
      };
    }
  }, [lastTyut.current]);

  //   const canShareCourse = usePermission("can_share_tyut");
  const canShareCourse = true;
  const canShare = canShareCourse && [
    <Button
      key={1}
      className={btnStyles.mdBtn}
      type="primary"
      onClick={() =>
        setShareCourse({ share: true, courseId: courseId.current })
      }
    >
      Share
      <ShareAltOutlined />
    </Button>,
  ];

  //   const canEditCourse = usePermission(
  //     "can_edit_tyuts_created_by_others_but_not_delete"
  //   ) && [
  //     <Button
  //       className={btnStyles.mdBtn}
  //       key={1}
  //       type="primary"
  //       onClick={() => navigate(`/lms/course/edit/${courseId.current}`)}
  //     >
  //       Edit
  //       <Image
  //         src={"/images/LMS/images/edit-icon.svg"}
  //         alt="edit"
  //         height={18}
  //         width={18}
  //       />
  //     </Button>,
  //   ];

  const deletCourseFunc = async () => {
    try {
      const resp = await deleteCourseApi(params?.id);
      resp && navigate(`/trainings?type=course`);
      message.success(resp?.data?.message);
    } catch (error) {
      console.log(error);
      message.error(error?.repsonse?.data?.message || "Something went wrong!!");
    }
  };

  return (
    <CustomLayout
      sideContent={<SideMenu />}
      header={
        <Flex justify="space-between" align="center">
          <Typography className={typographyStyles.h6}>
            <LeftOutlined
              style={{ marginRight: "6px" }}
              onClick={() => navigate("/trainings?type=course")}
            />
            {courseDetail?.name || "Edit Course"}
          </Typography>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {deleteCond(
              editTyut,
              courseDetail?.creator,
              userDetails?.activeOrg?.role,
              userDetails?._id
            ) && (
              <Popconfirm
                icon={null}
                description="Are you sure to delete this course?"
                onConfirm={(e) => {
                  deletCourseFunc();
                }}
              >
                <Button className={btnStyles.mdBtn}>
                  <span className="hiddenBelowTab">Delete</span>

                  <DeleteOutlined style={{ margin: "0px" }} />
                </Button>
              </Popconfirm>
            )}
            {editCond(
              editTyut,
              courseDetail?.creator,
              userDetails?.activeOrg?.role,
              userDetails?._id
            ) && (
              <Button
                className={btnStyles.mdBtn}
                key={1}
                type="primary"
                onClick={() =>
                  navigate(`/trainings/course/edit/${courseId.current}`)
                }
              >
                Edit
                <EditOutlined style={{ margin: "0px" }} />
                {/* <Image
              src={"/images/LMS/images/edit-icon.svg"}
              alt="edit"
              height={18}
              width={18}
            /> */}
              </Button>
            )}
          </div>
        </Flex>
      }
      //   footer={undefined}
    >
      <Row>
        <Col span={10} offset={0} style={{ padding: "24px" }}>
          <Typography.Paragraph
            className={typographyStyles.subtext2}
            ellipsis={{ rows: 2, expandable: true, symbol: "read more" }}
          >
            {courseDetail.name}
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{ color: "#9BA5B7" }}
            className={typographyStyles.body2}
            ellipsis={{ rows: 2, expandable: true, symbol: "read more" }}
          >
            {courseDetail.description || ""}
          </Typography.Paragraph>

          <div
            style={{
              minHeight: "calc(100vh - 260px)",
              maxHeight: "calc(100vh - 260px)",
              overflowY: "auto",
              overflowX: "hidden",
              marginTop: "10px",
              padding: "5px",
            }}
            ref={tyutsScrollRef}
          >
            {courseDetail.tyuts?.map((eachTyut, index) => (
              <EachTyut
                index={index}
                ref={tyutsRefs.current[index]}
                allowDelete={false}
                item={eachTyut}
                activeTyut={activeTyut}
                setActiveTyut={setActiveTyut}
                activeTyutIndex={activeTyutIndex}
              />
            ))}
          </div>
        </Col>
        {activeTyut ? (
          <Col
            key={activeTyut?._id}
            span={13}
            offset={1}
            style={{
              minWidth: "0%",
              width: "100%",
              background: "#fff",
              position: "relative",
              minHeight: "calc(100vh - 250px)",
              maxHeight: "calc(100vh - 200px)",
            }}
          >
            <div
              ref={lastTyut}
              style={{
                minHeight: "calc(100vh - 200px) ",
                maxHeight: "calc(100vh - 200px)",
                overflowY: "auto",
                overflowX: "hidden",
                minWidth: "100%",
                paddingBlock: "24px",
              }}
            >
              <EditTyut
                key={activeTyut?._id}
                isTyutCompleted={isTyutCompleted}
                setIsTyutCompleted={setIsTyutCompleted}
                compTyutContent={activeTyut.content}
                compTyutMetaData={activeTyut}
                compTyutData={activeTyut}
                shouldFetchTyut={false}
              />
            </div>
            <div
              className={"floatingActionButtons"}
              style={{ marginBlock: "24px" }}
            >
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                style={{ marginRight: "10px" }}
                disabled={isIndexAtFirstTyut}
                onClick={prevTyut}
              >
                Previous
              </Button>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                disabled={isIndexAtLastTyut}
                onClick={nextTyut}
              >
                Next
              </Button>
            </div>
          </Col>
        ) : (
          <Col
            span={13}
            offset={1}
            style={{
              width: "100%",
              background: "#FAF9FB",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingBlock: "25px",
            }}
          >
            <Image
              preview={false}
              src={notyuts}
              alt="No tyuts"
              style={{ width: "100%", height: "25vh" }}
            />
            <Typography className={typographyStyles.h6}>
              Select a tyut to preview
            </Typography>
            <Typography className={typographyStyles.body2}>
              Add your tyuts combined to create a course
            </Typography>
          </Col>
        )}
      </Row>

      {shareCourse.share && (
        <ShareCourse
          creator_id={courseDetail?.creator?._id}
          setShareCourse={setShareCourse}
          shareCourse={shareCourse}
        />
      )}

      {isCourseCompleted && (
        <Modal
          // closeIcon={false}
          // closable={false}
          onCancel={() => setCourseCompleted(false)}
          open={isCourseCompleted}
          footer={null}
          centered
          styles={{
            body: {
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "75%",
              margin: "auto",
            },
          }}
        >
          {/* <Image
            src={"/images/LMS/images/courseCompleted.svg"}
            alt="Course Completed"
            height={191}
            width={271}
          /> */}
          <Typography
            className={typographyStyles.h6}
            style={{ textAlignLast: "center", margin: "16px 0" }}
          >
            Congratulations on completing your course
          </Typography>

          <Button
            className={btnStyles.mdBtn}
            type="primary"
            style={{ marginBottom: "16px" }}
            onClick={() => {
              setCourseCompleted(false);
              setIsTyutCompleted({});
              navigate(`/trainings/course/${nextCourseId.current?._id}`);
            }}
          >
            Next Course
          </Button>
          <Typography.Link
            href="/trainings"
            className={typographyStyles.body2}
            style={{
              marginBottom: "16px",
              color: "#444CE7",
              textDecoration: "underline",
              textUnderlineOffset: "3px",
              cursor: "pointer",
            }}
          >
            Back to courses
          </Typography.Link>
        </Modal>
      )}
    </CustomLayout>
  );
}

export default EditCourse;
