import { Flex, Form, Input } from "antd";
import React from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import DND from "../../Blocks/DND";
import AllElems from "./AllElems";
import { TagsInput } from "react-tag-input-component";
import { debounce } from "../../../helpers/utility";
import { SOP, TYUT } from "../../../constants/defaultKeys";

const SOPContent = (
  {
    contentType = SOP,
    sopContent,
    setSopContent,
    sopFormRef,
    editing,
    setEditing,
    isUploadLimitReached,
    sopMetaData,
    setsopMetaData,
    isUnfilled,
    setIsUnfilled,
    tyutTags = [],
    setTuytTags,
  },
  ref
) => {
  function handleFormChange(_, val) {
    setsopMetaData(val);
  }

  return (
    <div style={{ paddingInlineStart: "48px" }}>
      <Form
        layout="vertical"
        requiredMark={false}
        onValuesChange={debounce(handleFormChange, 300)}
        ref={sopFormRef}
        initialValues={sopMetaData}
        validateTrigger="onChange"
      >
        <Form.Item
          name="name"
          label="Title"
          rules={[
            {
              whitespace: true,
              message: `${contentType} title cannot be empty or contain only spaces`,
            },
            {
              min: 3,
              message: "Title should contain atleast 3 characters",
            },
            { required: true, message: "Please enter title" },
          ]}
        >
          <Input
            className={inputStyles.smInput}
            maxLength={254}
            placeholder={`Enter ${contentType} title`}
            style={{ minWidth: "100%" }}
          />
        </Form.Item>
        {contentType == SOP && (
          <>
            <Form.Item
              name="asset"
              style={{ height: "0px", margin: "0px" }}
            ></Form.Item>
            <Form.Item
              name="asset_docs"
              style={{ height: "0px", margin: "0px" }}
            ></Form.Item>
            <Form.Item
              name="type"
              style={{ height: "0px", margin: "0px" }}
            ></Form.Item>
            <Form.Item
              name="troubleshooting_issue"
              style={{ height: "0px", margin: "0px" }}
            ></Form.Item>
          </>
        )}
        <Form.Item
          label="Description"
          name="description"
          // rules={[
          //   { required: true, message: "Please provide SOP description!" },
          // ]}
        >
          <Input.TextArea
            className={inputStyles.smInput}
            rows={3}
            placeholder={`Enter ${contentType} description`}
            style={{ minWidth: "100%", fontSize: "14px" }}
          />
        </Form.Item>
        {contentType == TYUT && (
          <>
            {/* <TagsInput
              classNames={{
                tag: "tyut_tags",
              }}
              separators={[",", "Enter", " "]}
              value={tyutTags}
              onChange={setTuytTags}
              placeHolder="Add tags (,) separated"
            /> */}
            {/* <small>Add tyut tags and press Enter / separate tags by (,)</small> */}
          </>
        )}
        {contentType !== TYUT && (
          <Flex justify="space-between">
            <Form.Item
              style={{ width: "49%" }}
              label="Reviewer"
              name={"reviewer"}
            >
              <Input
                style={{ width: "100%" }}
                className={inputStyles.smInput}
                placeholder="Enter reviewers"
              />
            </Form.Item>
            <Form.Item style={{ width: "49%" }} label="QA" name={"qa_reviewer"}>
              <Input
                style={{ width: "100%" }}
                className={inputStyles.smInput}
                placeholder="Enter QA"
              />
            </Form.Item>
            {/* <Form.Item label="Creator" name={"creator"}>
              <Input
                className={inputStyles.smInput}
                placeholder="Enter Creator"
              />
            </Form.Item> */}
          </Flex>
        )}
      </Form>

      <DND
        isUploadLimitReached={isUploadLimitReached}
        isUnfilled={isUnfilled}
        setIsUnfilled={setIsUnfilled}
        state={sopContent}
        setState={setSopContent}
        RenderComponent={AllElems}
        editing={editing}
        setEditing={setEditing}
        viewType="edit"
        ref={ref}
        contentType={contentType}
      />
    </div>
  );
};

export default React.forwardRef(SOPContent);
