import { Button, Checkbox, Flex } from "antd";
import React, { useState } from "react";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";

const Footer = ({
  setIsEditOpen,
  getSOP,
  isEditOpen,
  editing,
  sopFormRef,
  sopContent,
  updateSOP,
}) => {
  const [checked, setChecked] = useState(true);

  return (
    <>
      {isEditOpen && (
        <Flex justify="flex-end" gap={12}>
          <div style={{ alignSelf: "center" }}>
            <Checkbox
              value={checked}
              defaultChecked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            >
              Update in all languages
            </Checkbox>
          </div>
          <Button
            className={btnStyles.mdBtn}
            onClick={() => {
              setIsEditOpen(false);
              getSOP();
            }}
          >
            Cancel
          </Button>

          <Button
            disabled={
              editing.edit == false &&
              (sopFormRef?.current
                ? sopFormRef?.current?.getFieldsValue()?.name &&
                  sopFormRef?.current
                    ?.getFieldsValue()
                    ?.name?.replace(/\s/g, "")?.length > 3
                : true) &&
              sopContent.length > 0
                ? false
                : true
            }
            className={btnStyles.mdBtn}
            type="primary"
            onClick={() => updateSOP(checked)}
          >
            Save
          </Button>
        </Flex>
      )}
    </>
  );
};

export default Footer;
