import { Card, Col, DatePicker, Form, Input, Row } from "antd";
import React from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import dayjs from "dayjs";

const AssetDetails = () => {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const disabledDate = (current) => {
    // Disable all future years
    return current && current.isAfter(dayjs().endOf("year"), "day");
  };
  return (
    <Card
      title="Asset Specifications"
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Form.Item
            label="Make"
            name="make"
            rules={[
              { required: true, message: "Please enter make of asset" },
              {
                whitespace: true,
                message: "Make cannot be empty or contain only spaces",
              },
            ]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter make"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="OEM" name="oem">
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter OEM"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Model"
            name="model"
            rules={[
              { required: true, message: "Please enter model of asset" },
              {
                whitespace: true,
                message: "Model cannot be empty or contain only spaces",
              },
            ]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter model"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Vendor" name={"vendor"}>
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter vendor"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Serial number" name={"serial_number"}>
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter serial number"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Charge department" name="charge_dept">
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter charge department"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Year of installation" name="year_of_installation">
            <DatePicker
              format="YYYY"
              onChange={onChange}
              picker="year"
              disabledDate={disabledDate}
              size="medium"
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default AssetDetails;
