import axiosInstance from "../config/axios/axios.config";

export const createCourseApi = (payload) => {
  return axiosInstance.post("/course", payload);
};

export const getALlCoursesApi = () => {
  return axiosInstance.get("/course");
};
export const getCourseByIDApi = (id) => {
  return axiosInstance.get(`/course/${id}`);
};
export const getCoursePermissionByIDApi = (id) => {
  return axiosInstance.get(`/course/${id}/permissions`);
};

export const updateCourseApi = (id, payload) => {
  return axiosInstance.put(`/course/${id}`, payload);
};
export const updateCoursePermissionApi = (id, payload) => {
  return axiosInstance.patch(`/course/${id}`, payload);
};
export const markTyutInCourseCompleteApi = (id, tyut_id) => {
  return axiosInstance.post(`/course/${id}/${tyut_id}/complete`);
};
export const likeCourseApi = (id) => {
  return axiosInstance.post(`/course/${id}/like`);
};
