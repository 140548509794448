export const redirectToDashboard = (domain, storage = "cloud", naviagte) => {
  const baseUrl = process.env.REACT_APP_PUBLIC_SUBDOMIAN_BASE_URL;

  if (process.env.REACT_APP_PUBLIC_ENV === "STAGING" && storage === "cloud") {
    window.location.href = `${baseUrl}/dashboard`;
  } else {
    if (storage == "on-premise") {
      naviagte("/dashboard");
    } else {
      window.location.href = `https://${domain}.${process.env.REACT_APP_PUBLIC_DOMAIN}/dashboard`;
    }
  }
};
export function getSubdomain(domain) {
  // Remove the protocol (http://, https://)
  let urlWithoutProtocol = domain.replace(/(^\w+:|^)\/\//, "");

  // Split the URL by dots
  let parts = urlWithoutProtocol.split(".");

  // Check if it's a valid subdomain (more than 2 parts)
  if (parts.length > 2) {
    return parts.slice(0, parts.length - 2).join(".");
  } else {
    return null;
  }
}
