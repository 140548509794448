import React, { useState, useEffect } from "react";
import { Col, ConfigProvider, Image, Row, Steps, theme } from "antd";
import SignUpForm from "./SignUpForm";
import "./SignUp.css";
import CreateOrg from "./CreateOrg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CreateLocation from "./CreateLocation";
import darkLogo from "../../../assets/images/cmms-logos/cMMSDarkLogo.svg";
import business from "../../../assets/images/sidemenuIcons/building.png";
import passcode from "../../../assets/images/sidemenuIcons/passcode.png";
import user from "../../../assets/images/sidemenuIcons/user.png";

const SignUp = () => {
  const [current, setCurrent] = useState(1);
  const [enable, setEnable] = useState(1);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    params.step && setCurrent(parseInt(params.step));

    // parseInt(router?.query?.enable) < parseInt(router?.query?.type)
    //   ? setEnable(parseInt(router?.query?.type))
    //   : setEnable(parseInt(router?.query?.enable));
  }, [location]);

  const items = [
    {
      title: "Sign Up",
      description: "Provide email and secure password",
      content: (
        <SignUpForm
          current={current}
          setCurrent={setCurrent}
          setEnable={setEnable}
          enable={enable}
        />
      ),
      icon: (
        <div
          style={{
            padding: "10px",
            backgroundColor: "#fff",
            borderRadius: "10px",
          }}
        >
          <Image
            alt="user"
            preview={false}
            src={user}
            style={{
              height: "24px",
              width: "24px",
            }}
          />
        </div>
      ),
    },
    {
      title: "Create Organisation",
      description: "Provide your organisation name",
      disabled: enable < 1 ? true : false,
      content: (
        <CreateOrg
          current={current}
          setCurrent={setCurrent}
          setEnable={setEnable}
          enable={enable}
        />
      ),
      icon: (
        <div
          style={{
            padding: "10px",
            backgroundColor: "#fff",
            borderRadius: "10px",
          }}
        >
          <Image
            alt="user"
            preview={false}
            src={business}
            style={{
              height: "24px",
              width: "24px",
            }}
          />
        </div>
      ),
    },
    {
      title: "Create Location",
      description: "Set up your business geographical site",
      disabled: enable < 2 ? true : false,
      content: (
        <CreateLocation
          current={current}
          setCurrent={setCurrent}
          setEnable={setEnable}
          enable={enable}
        />
      ),
      icon: (
        <div
          style={{
            padding: "10px",
            backgroundColor: "#fff",
            borderRadius: "10px",
          }}
        >
          <Image
            alt="user"
            preview={false}
            src={passcode}
            style={{
              height: "24px",
              width: "24px",
            }}
          />
        </div>
      ),
    },
  ];

  const onChange = (value) => {
    // setCurrent(value);
    // setOpen(false);
    // parseInt(enable) < parseInt(value) && setEnable(value);
    //  router.push(`/auth/signup/${value}?enable=${enable}`);
  };

  return (
    <>
      <Row style={{ minHeight: "100vh" }}>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={8}
          xl={6}
          style={{
            paddingTop: "45px",
            backgroundColor: "#121926",
          }}
        >
          <Image
            alt="Logo"
            preview={false}
            src={darkLogo}
            style={{
              height: "50px",
              width: "auto",
              paddingLeft: "2vw",
              cursor: "pointer",
              alignSelf: "start",
            }}
            onClick={() => navigate("/login")}
          />
          <div style={{ height: "calc(100vh - 245px)" }}>
            <ConfigProvider
              theme={{
                algorithm: theme.darkAlgorithm,
                token: {
                  colorText: "#fff",
                },
                components: {
                  Steps: {
                    colorPrimary: "#fff",
                    fontSize: "16px",
                  },
                },
              }}
            >
              <Steps
                direction="vertical"
                size="small"
                className={"signupSteps"}
                style={{
                  paddingLeft: "2vw",
                  paddingTop: "50px",
                }}
                current={current}
                items={items}
                onChange={onChange}
              />
            </ConfigProvider>
          </div>
        </Col>
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 16, offset: 4 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 10, offset: 2 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 6, offset: 6 }}
          style={{ alignSelf: "center", paddingBlock: "24px" }}
          className={"contentContainerMobile"}
        >
          <div>{items[current]?.content}</div>
        </Col>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={0}
          xl={6}
          className={"contentContainer"}
        ></Col>
      </Row>
    </>
  );
};
export default SignUp;
