import React, { useEffect } from "react";
import "./App.css";
import ProjectRoutes from "./routes/Routes";
import { ConfigProvider } from "antd";
import { customCentralTheme } from "./config/customtheme";
import GTM from "./Components/Blocks/GTM";

const App = () => {
  const gtmId = process.env.REACT_APP_GTM_CODE;
  const zohoCode = process.env.REACT_APP_ZOHO_CODE;

  useEffect(() => {
    if (zohoCode) {
      const script = document.createElement("script");
      script.setAttribute("type", "text/javascript");

      let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${zohoCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [zohoCode]);

  return (
    <ConfigProvider theme={customCentralTheme}>
      {gtmId && <GTM gtmId={gtmId} />}
      <ProjectRoutes />
    </ConfigProvider>
  );
};

export default App;
