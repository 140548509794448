import axiosInstance from "../config/axios/axios.config";

export const createOrganisationApi = (payload) => {
  return axiosInstance.post("/organization", payload);
};
export const getOrganisationApi = () => {
  return axiosInstance.get("/organization");
};
export const updateOrganisationApi = (payload) => {
  return axiosInstance.put("/organization", payload);
};
export const getDomainDetailsApi = (domain) => {
  return axiosInstance.get(`/auth/domain/${domain}`);
};
