import { Card, Typography } from "antd";
import React from "react";

import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";
import "../../SOP.css";
import PdfViewer from "../../../Blocks/PDFViewer";

function ViewDoc({ docElem, viewType }) {
  return (
    <Card
      className={"tyutElemCard"}
      style={
        viewType === "edit"
          ? { borderRadius: "8px", padding: 0, overflow: "auto" }
          : { borderRadius: 0, boxShadow: "none", overflow: "auto" }
      }
    >
      {docElem?.title && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Paragraph
            className={typographyStyles.body2}
            ellipsis={{ rows: 1, expandable: true, symbol: "...more" }}
            style={{ maxWidth: "65%" }}
          >
            {docElem.title || "Title"}
          </Typography.Paragraph>
        </div>
      )}
      <div style={{ overflow: "auto" }}>
        <PdfViewer
          pdfUrl={docElem.previewURL || docElem.src}
          width={380}
          height={"80vh"}
        />
      </div>
    </Card>
  );
}

export default ViewDoc;
