import { FilterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Tag,
  TreeSelect,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  getAllAssetsApi,
  getProductionLines,
} from "../../services/assets.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../assets/styles/Custom/Input.module.css";

const PmFilters = ({
  type,
  viewType,
  showFilterModal,
  setShowFilterModal,
  assets,
  productionLines,
  filters,
  setFilters,
  // selectedAsset,
  // setSelectedAsset,
  // selectedProductionLine,
  // setSelectedProductionLine,
  // selectedPmTypes,
  // setSelectedPmTypes,
  // selectedPmPriority,
  // setSelectedPmPriority,
  // selectedPmStatus,
  // setSelectedPmStatus,
  // selectedPmVerify,
  // setSelectedPmVerify,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef();
  // const [assets, setAssets] = useState([]);
  // const [productionLines, setProductionLines] = useState([]);
  // const [selectedTypes, setSelectedTypes] = useState([]);
  // const [typeVisible, setTypeVisible] = useState(false);
  // const [selectedPriority, setSelectedPriority] = useState([]);
  // const [priorityVisible, setPriorityVisible] = useState(false);
  // const [selectedStatus, setSelectedStatus] = useState([]);
  // const [statusVisible, setStatusVisible] = useState(false);

  const pmTypes = [
    {
      title: "Mechanical",
      value: "Mechanical",
    },
    {
      title: "Electrical",
      value: "Electrical",
    },
    {
      title: "Others",
      value: "Others",
    },
  ];

  const priorityOptions = [
    {
      title: "Low",
      value: "low",
    },
    {
      title: "Moderate",
      value: "moderate",
    },
    {
      title: "High",
      value: "high",
    },
  ];

  const statusOptions = [
    {
      title: "Open",
      value: "open",
    },
    {
      title: "Pending",
      value: "pending",
    },
    {
      title: "In Progress",
      value: "in-progress",
    },
    {
      title: "On Hold",
      value: "on-hold",
    },
    {
      title: "Closed",
      value: "closed",
    },
  ];

  const verifyStatuses = [
    {
      label: "Verified",
      value: "verified",
    },
    {
      label: "Not Verified",
      value: "not-verified",
    },
  ];

  // const fetchAssets = async () => {
  //   try {
  //     const resp = await getAllAssetsApi();
  //     setAssets(
  //       resp?.data?.assets?.map((asset) => ({
  //         label: (
  //           <div
  //             style={{
  //               gap: 8,
  //               width: "100%",
  //               display: "flex",
  //               alignItems: "center",
  //             }}
  //           >
  //             <Tag className={tagStyles.smTag} color="blue">
  //               {asset?.code}
  //             </Tag>
  //             <span
  //               style={{
  //                 whiteSpace: "nowrap",
  //                 overflow: "hidden",
  //                 textOverflow: "ellipsis",
  //               }}
  //             >
  //               {asset?.name}
  //             </span>
  //           </div>
  //         ),
  //         name: asset?.name,
  //         value: asset?._id,
  //       }))
  //     );
  //   } catch (error) {
  //     setAssets([]);
  //   }
  // };

  // const fetchProductionLines = async () => {
  //   try {
  //     const res = await getProductionLines();
  //     setProductionLines(
  //       res?.data?.assets?.map((item) => ({
  //         ...item,
  //         label: item?.name,
  //         value: item?._id,
  //       }))
  //     );
  //   } catch (error) {
  //     setProductionLines([]);
  //   }
  // };

  // useEffect(() => {
  //   fetchAssets();
  //   fetchProductionLines();
  // }, []);

  // const handleTypeDropdownVisibleChange = (visible) => {
  //   setTypeVisible(visible);
  //   if (
  //     !visible &&
  //     (selectedTypes?.length !== selectedPmTypes?.length ||
  //       !selectedTypes?.every((i) => selectedPmTypes?.includes(i)))
  //   ) {
  //     setSelectedTypes([]);
  //     setSelectedPmTypes([]);
  //   }
  // };

  // const handlePriorityDropdownVisibleChange = (visible) => {
  //   setPriorityVisible(visible);
  //   if (
  //     !visible &&
  //     (selectedPriority?.length !== selectedPmPriority?.length ||
  //       !selectedPriority?.every((i) => selectedPmPriority?.includes(i)))
  //   ) {
  //     setSelectedPriority([]);
  //     setSelectedPmPriority([]);
  //   }
  // };

  // const handleStatusDropdownVisibleChange = (visible) => {
  //   setStatusVisible(visible);
  //   if (
  //     !visible &&
  //     (selectedStatus?.length !== selectedPmStatus?.length ||
  //       !selectedStatus?.every((i) => selectedPmStatus?.includes(i)))
  //   ) {
  //     setSelectedStatus([]);
  //     setSelectedPmStatus([]);
  //   }
  // };

  useEffect(() => {
    if (showFilterModal) {
      form.setFieldsValue(filters);
    }
  }, [showFilterModal, filters]);

  const onFinish = (values) => {
    // console.log(values);
    setShowFilterModal(false);
    let formValues = {
      asset: values?.asset,
      production_line: values?.production_line,
      types: values?.types,
      priorities: values?.priorities,
    };
    if (viewType === "job") {
      formValues = {
        ...formValues,
        statuses: values?.statuses,
        verification: values?.verification,
      };
    }
    setFilters(formValues);
  };

  return (
    <>
      <Modal
        title="Filter"
        width={"50%"}
        open={showFilterModal}
        onCancel={() => setShowFilterModal(false)}
        maskClosable={false}
        footer={
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            onClick={() => formRef?.current?.submit()}
          >
            Apply
          </Button>
        }
      >
        <Form layout="vertical" form={form} ref={formRef} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Asset" name={"asset"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Type asset name"
                  allowClear
                  showSearch={true}
                  filterOption={(inputValue, option) =>
                    option.name
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  options={assets}
                  onChange={(e) =>
                    form.setFieldValue("production_line", undefined)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Production Line" name={"production_line"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Type production line name"
                  allowClear
                  showSearch={true}
                  optionFilterProp="label"
                  options={productionLines}
                  onChange={(e) => form.setFieldValue("asset", undefined)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={`${type} Type`} name={"types"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder={`${type} Type`}
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={pmTypes}
                  popupClassName="filter-tree-dropdown"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Priority" name={"priorities"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Priority"
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={priorityOptions}
                  popupClassName="filter-tree-dropdown"
                />
              </Form.Item>
            </Col>
            {viewType === "job" && (
              <>
                <Col span={24}>
                  <Form.Item label={`${type} Status`} name={"statuses"}>
                    <TreeSelect
                      className={inputStyles.smSelect}
                      style={{ width: "100%" }}
                      placeholder={`${type} Status`}
                      allowClear
                      multiple={true}
                      treeCheckable={true}
                      showSearch={false}
                      treeData={statusOptions}
                      popupClassName="filter-tree-dropdown"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={`${type} Verification`}
                    name={"verification"}
                  >
                    <Select
                      className={inputStyles.smSelect}
                      style={{ width: "100%" }}
                      placeholder={`${type} Verification`}
                      allowClear
                      // value={selectedSeverity}
                      options={verifyStatuses}
                      // onChange={(e) => setSelectedSeverity(e)}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </Modal>
      {/* <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col
            span={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FilterOutlined style={{ fontSize: 18, color: "#7369F4" }} />
          </Col>
          <Col span={4}>
            <Select
              style={{ width: "100%" }}
              placeholder="Type asset name"
              allowClear
              showSearch={true}
              // optionFilterProp="label"
              filterOption={(inputValue, option) =>
                option.name.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              value={selectedAsset}
              options={assets}
              onChange={(e) => {
                setSelectedAsset(e);
                setSelectedProductionLine(undefined);
              }}
            />
          </Col>
          <Col span={4}>
            <Select
              style={{ width: "100%" }}
              placeholder="Type production line name"
              allowClear
              showSearch={true}
              optionFilterProp="label"
              value={selectedProductionLine}
              options={productionLines}
              onChange={(e) => {
                setSelectedProductionLine(e);
                setSelectedAsset(undefined);
              }}
            />
          </Col>
          <Col span={4}>
            <TreeSelect
              className="filter-tree"
              style={{ width: "100%" }}
              placeholder="PM Type"
              multiple={true}
              treeCheckable={true}
              showSearch={false}
              treeData={pmTypes}
              value={selectedTypes}
              maxTagCount={1}
              onChange={(e) => {
                setSelectedTypes(e);
              }}
              dropdownRender={(menu) => (
                <div onMouseDown={(e) => e.stopPropagation()}>
                  {menu}
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setSelectedPmTypes(selectedTypes);
                      setTypeVisible(false);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              )}
              popupClassName="filter-tree-dropdown"
              open={typeVisible}
              onDropdownVisibleChange={handleTypeDropdownVisibleChange}
            />
          </Col>
          <Col span={4}>
            <TreeSelect
              className="filter-tree"
              style={{ width: "100%" }}
              placeholder="Priority"
              multiple={true}
              treeCheckable={true}
              showSearch={false}
              treeData={priorityOptions}
              value={selectedPriority}
              maxTagCount={1}
              onChange={(e) => {
                setSelectedPriority(e);
              }}
              dropdownRender={(menu) => (
                <div onMouseDown={(e) => e.stopPropagation()}>
                  {menu}
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setSelectedPmPriority(selectedPriority);
                      setPriorityVisible(false);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              )}
              popupClassName="filter-tree-dropdown"
              open={priorityVisible}
              onDropdownVisibleChange={handlePriorityDropdownVisibleChange}
            />
          </Col>
          {viewType === "job" && (
            <>
              <Col span={4}>
                <TreeSelect
                  className="filter-tree"
                  style={{ width: "100%" }}
                  placeholder="PM Status"
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={statusOptions}
                  value={selectedStatus}
                  maxTagCount={1}
                  onChange={(e) => {
                    setSelectedStatus(e);
                  }}
                  dropdownRender={(menu) => (
                    <div onMouseDown={(e) => e.stopPropagation()}>
                      {menu}
                      <Button
                        style={{ width: "100%" }}
                        type="primary"
                        onClick={() => {
                          setSelectedPmStatus(selectedStatus);
                          setStatusVisible(false);
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  )}
                  popupClassName="filter-tree-dropdown"
                  open={statusVisible}
                  onDropdownVisibleChange={handleStatusDropdownVisibleChange}
                />
              </Col>
              <Col span={4}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="PM Verification"
                  allowClear
                  value={selectedPmVerify}
                  options={verifyStatuses}
                  onChange={(e) => setSelectedPmVerify(e)}
                />
              </Col>
            </>
          )}
          {(selectedAsset ||
            selectedProductionLine ||
            selectedPmTypes?.length ||
            selectedPmPriority?.length ||
            (viewType === "job" &&
              (selectedPmStatus?.length || selectedPmVerify))) && (
            <Col
              span={3}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ color: "#7369F4", paddingLeft: 10, cursor: "pointer" }}
                onClick={() => {
                  setSelectedAsset(undefined);
                  setSelectedProductionLine(undefined);
                  setSelectedPmTypes([]);
                  setSelectedTypes([]);
                  setSelectedPmPriority([]);
                  setSelectedPriority([]);
                  if (viewType === "job") {
                    setSelectedPmStatus([]);
                    setSelectedStatus([]);
                    setSelectedPmVerify(undefined);
                  }
                }}
              >
                CLEAR
              </Typography>
            </Col>
          )}
        </Row>
      </Col> */}
    </>
  );
};

export default PmFilters;
