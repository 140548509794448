import React, { useEffect, useState } from "react";
import CustomLayout from "../../Layout";
import {
  Button,
  Col,
  Empty,
  Flex,
  Result,
  Row,
  Skeleton,
  Table,
  Typography,
} from "antd";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { getAssetGroups } from "../../../services/assets.services";
import { useNavigate } from "react-router-dom";

const AssetGroups = () => {
  // const { userDetails } = useContext(UserContext);
  const navigate = useNavigate();
  const [assetGroups, setAssetGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAssetGroups = async () => {
    setLoading(true);
    try {
      const res = await getAssetGroups();
      setAssetGroups(res?.data);
    } catch (error) {
      setAssetGroups([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssetGroups();
  }, []);

  const tableColumns = [
    {
      title: (
        <Typography className={"tableHeader"}>Asset group name</Typography>
      ),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 300,
      render: (val) => (val ? val : "-"),
    },
    {
      title: <Typography className={"tableHeader"}>Description</Typography>,
      dataIndex: "description",
      key: "description",
      //   width: 300,
    },
    {
      title: <Typography className={"tableHeader"}>No. of assets</Typography>,
      dataIndex: "assets_count",
      key: "assets_count",
      //   width: 200,
      render: (_, record) => record?.assets?.length,
    },
  ];

  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography className={typoStyles.h6}>
            Asset Groups ({assetGroups?.length || 0})
          </Typography>
        </Flex>
      }
    >
      <>
        <Row gutter={[24, 18]}>
          <Col
            offset={12}
            span={12}
            className="flex-center"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => navigate("/asset-groups/create")}
            >
              + Create Asset Group
            </Button>
          </Col>
          <Col span={24}>
            <Table
              className="tableContainer"
              scroll={{
                x: 1200,
                y: "60vh",
              }}
              locale={{
                emptyText: loading ? (
                  <Skeleton active />
                ) : (
                  <Result
                    icon={Empty.PRESENTED_IMAGE_SIMPLE}
                    title={<Typography>No asset groups found</Typography>}
                  />
                ),
              }}
              pagination={false}
              onRow={(item) => {
                return {
                  onClick: (e) => {
                    navigate(`/asset-groups/${item?._id}`);
                  },
                  style: { cursor: "pointer" },
                };
              }}
              columns={tableColumns}
              dataSource={assetGroups}
              loading={
                loading
                  ? {
                      spinning: false,
                      indicator: null,
                    }
                  : false
              }
            />
          </Col>
        </Row>
      </>
    </CustomLayout>
  );
};

export default AssetGroups;
