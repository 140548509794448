import { Button, Col, Form, Input, Skeleton, Spin } from "antd";
import React, { useEffect, useState } from "react";
import UploadElem from "./UploadElem";

// responsible for show video in create view
function MediaElem({
  mediaType,
  setSignedUrl,
  uploading,
  setUploading,
  signedUrl,
  data = [],
}) {
  return (
    <Form.Item
      name="src"
      rules={[
        {
          required: !signedUrl,
          message: `Add ${mediaType} file`,
        },
      ]}
    >
      <UploadElem
        data={data}
        mediaType={mediaType}
        setSignedUrl={setSignedUrl}
        uploading={uploading}
        setUploading={setUploading}
      />
    </Form.Item>
  );
}

export default MediaElem;
