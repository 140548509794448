import { Button, Card, Col, List, Row, Table, Tag, Typography } from "antd";
import React, { useContext } from "react";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import { workOrderstatusList } from "../../../helpers/utility";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
import { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const MroWorkOrders = ({ mroDetails }) => {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);

  const tableColumns = [
    {
      title: <Typography className="tableHeader">WO code</Typography>,
      dataIndex: "code",
      key: "code",
      render: (val, record) =>
        val ? (
          <Typography
            style={{ color: "#1677ff", cursor: "pointer" }}
            onClick={() =>
              navigate(`/work-orders/${record?._id}`, {
                state: { source: `/mro/${mroDetails?._id}` },
              })
            }
          >
            {val}
          </Typography>
        ) : (
          "-"
        ),
    },
    {
      title: <Typography className="tableHeader">WO status</Typography>,
      dataIndex: "status",
      key: "status",
      render: (val) => {
        const statusObj = workOrderstatusList?.filter(
          (s) => s.value == val
        )?.[0];
        return statusObj?.label ? (
          <Tag className={tagStyles.smTag} color={statusObj?.color}>
            {statusObj?.label}
          </Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: <Typography className="tableHeader">WO verification</Typography>,
      dataIndex: "mark_as_verified",
      key: "mark_as_verified",
      render: (val) => (
        <Tag
          className={tagStyles.smTag}
          color={val ? "success" : "error"}
          style={{ border: "0px" }}
        >
          {val ? "Verified" : "Not verified"}
        </Tag>
      ),
    },
  ];

  return (
    <Card
      title="MRO Work Orders"
      styles={{ header: { backgroundColor: "#fff" }, body: { padding: "0px" } }}
    >
      <>
        {mroDetails?.workorders?.length > 0 && (
          <Row>
            <Col span={24}>
              <Table
                // scroll={{ x: 500 }}
                columns={tableColumns}
                dataSource={mroDetails?.workorders || []}
                pagination={false}
              />
            </Col>
          </Row>
          // <List
          //   bordered
          //   loading={false}
          //   dataSource={mroDetails?.workorders}
          //   header={
          //     <Row gutter={[16, 0]} style={{ width: "100%" }}>
          //       <Col span={8}>WO code</Col>
          //       <Col span={8}>WO status</Col>
          //       <Col span={8}>WO verification</Col>
          //     </Row>
          //   }
          //   renderItem={(item) => {
          //     const statusObj = workOrderstatusList?.filter(
          //       (s) => s.value == item?.status
          //     )?.[0];
          //     return (
          //       <List.Item>
          //         <Row gutter={[16, 0]} style={{ width: "100%" }}>
          //           <Col span={8}>{item?.code}</Col>
          //           <Col span={8}>
          //             {statusObj?.label ? (
          //               <Tag
          //                 className={tagStyles.smTag}
          //                 // style={{ fontWeight: 600 }}
          //                 color={statusObj?.color}
          //               >
          //                 {statusObj?.label}
          //               </Tag>
          //             ) : (
          //               "-"
          //             )}
          //           </Col>
          //           <Col span={8}>
          //             <Tag
          //               className={tagStyles.smTag}
          //               color={item?.mark_as_verified ? "success" : "error"}
          //               style={{ border: "0px" }}
          //             >
          //               {item?.mark_as_verified ? "Verified" : "Not verified"}
          //             </Tag>
          //           </Col>
          //         </Row>
          //       </List.Item>
          //     );
          //   }}
          // />
        )}
        {mroDetails?.workorders?.length === 0 &&
          ["admin", "manager", "supervisor"].includes(
            userDetails?.activeOrg?.role
          ) && (
            <div style={{ textAlign: "center", padding: 24 }}>
              <Button
                className={btnStyles.mdBtn}
                style={{ width: "100%" }}
                type="primary"
                onClick={() =>
                  navigate(`/work-orders/create`, {
                    state: {
                      // source: "",
                      mro_id: mroDetails?._id,
                      description: mroDetails?.description,
                      scheduled_start_date: mroDetails?.start_date,
                      asset_id: mroDetails?.asset?._id,
                    },
                  })
                }
              >
                <span style={{ fontSize: 18 }}>+</span> Create Work Order
              </Button>
            </div>
          )}
      </>
    </Card>
  );
};

export default MroWorkOrders;
