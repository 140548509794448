import {
  Avatar,
  Col,
  Empty,
  Pagination,
  Popconfirm,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { deleteGroupApi, getGroupsApi } from "../../services/users.services";
import RandomAvatar from "../Blocks/RandomAvatar";
import { PAGESIZE, deleteUsers, editUsers } from "../../constants/defaultKeys";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { UserContext } from "../../context/UserProvider";

const GroupsTable = ({
  setCurrGroup,
  setOpen,
  currGroup,
  searchValue,
  setGroupsCount,
}) => {
  const [groups, setGroups] = useState(null);
  const { userDetails } = useContext(UserContext);
  const [refresh, setRefresh] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    let query = { page: pageNumber };
    if (searchValue) {
      query = { ...query, name: searchValue };
    }

    setLoading(true);
    try {
      const res = await getGroupsApi(query);
      setGroups(res?.data);
      setGroupsCount(res?.data?.totalGroups);
    } catch (error) {
      console.error("Error while fetching data:", error);
      setGroups(null);
      setGroupsCount(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, refresh]);

  useEffect(() => {
    if (searchValue?.trim()?.length === 0 || searchValue?.trim()?.length > 1) {
      refreshPage("search");
    }
  }, [searchValue]);

  useEffect(() => {
    if (!Object.keys(currGroup)?.length > 0) {
      refreshPage();
    }
  }, [currGroup]);

  const refreshPage = (source = "") => {
    if (source === "search" && pageNumber > 1) {
      setPageNumber(1);
    } else if (
      source === "delete" &&
      groups?.groups?.length === 1 &&
      pageNumber > 1
    ) {
      setPageNumber((prev) => prev - 1);
    } else {
      setRefresh((prev) => !prev);
    }
  };

  const deleteGroup = async (id) => {
    try {
      const resp = await deleteGroupApi(id);
      if (resp) {
        message.success(resp?.data?.message);
        refreshPage("delete");
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  const columns = [
    {
      title: <Typography className={"tableHeader"}>Team name</Typography>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <Typography className={"tableHeader"}>Facility</Typography>,
      dataIndex: "facility",
      key: "facility",
      render: (value) => (value?.name ? value?.name : "-"),
    },
    {
      title: <Typography className={"tableHeader"}>No. of users</Typography>,
      dataIndex: "users_count",
      key: "users_count",
      render: (_, record) => record?.members?.length,
    },
    // {
    //   title: <Typography className={"tableHeader"}>Created by</Typography>,
    //   dataIndex: "creator",
    //   key: "creator",
    //   width: 180,
    //   render: (item, record, index) => (
    //     <RandomAvatar
    //       user={item}
    //       userId={item?._id}
    //       name={item?.first_name}
    //       url={item?.profile_image}
    //       displayName={true}
    //       size={30}
    //     />
    //   ),
    // },
    // {
    //   title: <Typography className={"tableHeader"}>Users</Typography>,
    //   dataIndex: "members",
    //   key: "members",
    //   render: (item, record, index) =>
    //     item?.length !== 0 ? (
    //       <Avatar.Group
    //         maxCount={5}
    //         size="medium"
    //         maxStyle={{
    //           color: "#f56a00",
    //           backgroundColor: "#fde3cf",
    //         }}
    //       >
    //         {item?.map((user, index) => (
    //           <RandomAvatar
    //             user={user}
    //             key={index}
    //             userId={user?._id}
    //             url={user?.profile_image}
    //             name={user?.first_name}
    //             size={30}
    //           />
    //         ))}
    //       </Avatar.Group>
    //     ) : (
    //       "Oops no users found"
    //     ),
    // },
    {
      title: <Typography className={"tableHeader"}>Actions</Typography>,
      dataIndex: "action",
      key: "action",
      width: 180,
      render: (_, record) => {
        return (
          <Space size="middle">
            {deleteUsers.includes(userDetails?.activeOrg?.role) && (
              <Popconfirm
                icon={null}
                description="Are you sure to delete this team?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  deleteGroup(record?._id);
                }}
                onCancel={(e) => e.stopPropagation()}
              >
                <DeleteOutlined />
              </Popconfirm>
            )}
            {editUsers.includes(userDetails?.activeOrg?.role) && (
              <EditOutlined
                onClick={() => {
                  setCurrGroup(record);
                  setOpen(true);
                }}
              />
            )}
          </Space>
        );
      },
      hidden: !(
        deleteUsers.includes(userDetails?.activeOrg?.role) ||
        editUsers.includes(userDetails?.activeOrg?.role)
      ),
    },
  ];
  return (
    <Row gutter={[0, 18]}>
      <Col span={24} className="tableContainer">
        <Table
          scroll={{
            x: 1200,
            y: "60vh",
          }}
          pagination={false}
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No teams found</Typography>}
              />
            ),
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          columns={columns}
          dataSource={groups?.groups}
        />
      </Col>
      {groups?.totalGroups > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={groups?.totalGroups}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </Row>
  );
};

export default GroupsTable;
