import React, { useEffect, useState } from "react";
import {
  Alert,
  ConfigProvider,
  Empty,
  Popover,
  Avatar,
  Typography,
  Badge,
} from "antd";
import { colors, timeAgo } from "../../helpers/utility";
import { useNotifications } from "../../context/NotificationProvider";
import { BellOutlined } from "@ant-design/icons";
import { updateNotificationApi } from "../../services/users.services";

const Notifications = () => {
  const { notifications, setNotifications } = useNotifications();

  const onNotificationClose = async (id) => {
    try {
      const resp = await updateNotificationApi([id]);
      if (resp) {
        setNotifications((prevNotifications) =>
          prevNotifications.filter((notification) => notification._id !== id)
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const clearAll = async () => {
    const ids = notifications?.map(({ _id }) => _id);
    try {
      const resp = await updateNotificationApi(ids);
      if (resp) {
        setNotifications([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // Any cleanup or additional logic
    }
  };

  // useEffect(() => {
  //   setFiltedNots(notifications?.filter((not) => !not?.is_read));
  // }, [notifications]);

  const Content = () => {
    return (
      <div>
        <Typography
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            textAlign: "right",
            width: "100%",
            marginBottom: "12px",
          }}
          onClick={clearAll}
        >
          Clear all
        </Typography>

        <ConfigProvider
          theme={{
            token: {
              colorInfo: "#fff",
            },
          }}
        >
          <div
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              display: "flex",
              alignItems: "center",
              gap: "12px",
              maxHeight: "60vh",
              overflowY: "auto",
            }}
          >
            {notifications.map((data) => {
              return (
                <Alert
                  key={data?._id}
                  message={
                    <span style={{ fontWeight: 300 }}>{data?.message}</span>
                  }
                  description={
                    <span style={{ color: "#475467", fontWeight: 300 }}>
                      {timeAgo(data?.created_at)}
                    </span>
                  }
                  style={{
                    width: "100%",
                    maxWidth: "25vw",
                    borderRadius: "12px",
                    border: "1px solid #EAECF0",
                    padding: "12px",
                  }}
                  closable
                  onClose={() => onNotificationClose(data?._id)}
                />
              );
            })}
          </div>
        </ConfigProvider>
      </div>
    );
  };

  return (
    <>
      <Popover
        placement="bottom"
        trigger={"click"}
        content={
          <div style={{ position: "relative" }}>
            {notifications?.length > 0 ? (
              <Content />
            ) : (
              <Empty description="No notifications" />
            )}
          </div>
        }
      >
        <Badge count={notifications?.length}>
          <BellOutlined style={{ fontSize: "22px" }} />
          {/* <Image
            src={bellIcon}
            alt="Icon"
            style={{ height: "25px", width: "auto", cursor: "pointer" }}
          /> */}
        </Badge>
      </Popover>
    </>
  );
};

export default Notifications;
