import { Modal, Tabs, Input, Button, Form, Popover } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { InfoCircleFilled } from "@ant-design/icons";

import LinkElem, { vimeoRegex, youtubeRegex } from "./LinkElem";
import MediaElem from "./MediaElem";

import { UserContext } from "../../../../context/UserProvider";
import { getAssetsPath, getEncodedImage } from "../../../../helpers/utility";

import "../../SOP.css";
import btnStyles from "../../../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../../../assets/styles/Custom/Input.module.css";
import { ONPREMISE } from "../../../../constants/defaultKeys";

function getIdFromVimeoURL(url) {
  return /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)[3];
}

function AddElemModal({
  isModalOpen,
  setModalOpen,
  activeTab,
  setActiveTab,
  sopContent,
  isUploadLimitReached,
  setSopContent,
  data = {},
}) {
  const [showFileUploader, setShowFileUploader] = useState(true);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const [form] = Form.useForm();
  const [url, setURL] = useState(data.src || null);
  const [signedUrl, setSignedUrl] = useState(null);
  const [uploading, setUploading] = useState({ loading: false, type: "" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (signedUrl === undefined) {
      form.resetFields(["src"]);
    } else if (typeof signedUrl?.file === "string") {
      form.setFieldsValue({ src: signedUrl?.file });
      form.setFieldValue("src", signedUrl?.file);
    }
  }, [signedUrl, form]);

  useEffect(() => {
    setURL(null);
  }, [activeTab, form]);

  useEffect(() => {
    setLoading(true);
  }, [url]);

  const videoTabItems = [
    {
      key: "add_yt_link",
      label: `Youtube`,
    },

    // {
    //   key: "add_vimeo_link",
    //   label: `Vimeo`,
    // },
    {
      key: `add_${isModalOpen.type}`,
      label: isUploadLimitReached ? (
        <>
          <span>Upload {isModalOpen.type} </span>
          <Popover placement="top" title={"Reached max file upload limit"}>
            <InfoCircleFilled />
          </Popover>
        </>
      ) : (
        `Upload ${isModalOpen.type}`
      ),
      disabled: isUploadLimitReached,
    },
  ];

  function getYouTubeVideoId(url) {
    const regex =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  const docTabItems = [
    {
      key: "add_doc_link",
      label: `Document URL`,
    },
    {
      key: `add_${isModalOpen.type}`,
      label: isUploadLimitReached ? (
        <>
          <span>Upload {isModalOpen.type} </span>
          <Popover placement="top" title={"Reached max file upload limit"}>
            <InfoCircleFilled />
          </Popover>
        </>
      ) : (
        `Upload ${isModalOpen.type}`
      ),
      disabled: isUploadLimitReached,
    },
  ];

  let mediaType = isModalOpen?.type;

  return (
    <Modal
      className={"addMediaElemModal"}
      maskClosable={false}
      style={{ minHeight: "500px", minWidth: "50%" }}
      centered
      title={null}
      open={isModalOpen.open}
      onCancel={() => {
        if (data.id) {
          let updatedsopContent = sopContent.map((eachSOP) => {
            if (eachSOP.id === data.id) {
              delete eachSOP.edit;
            }
            return eachSOP;
          });
          setSopContent([...updatedsopContent]);
        }
        setModalOpen({ open: false });
      }}
      footer={false}
    >
      <Tabs
        className={"addMediaElemTabs"}
        activeKey={activeTab}
        items={isModalOpen.type == "video" ? videoTabItems : docTabItems}
        onChange={(key) => {
          setActiveTab(key);
          form.resetFields();
        }}
      />

      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        style={{ margin: "24px 36px" }}
        onFinish={(values) => {
          values = { ...values, src: values?.src || signedUrl?.file };
          if (data.id) {
            let updatedsopContent = sopContent.map((eachSOP) => {
              if (eachSOP.id === data.id) {
                delete eachSOP.edit;
                eachSOP.src = values.src;
                eachSOP.title = values.title || "";
                eachSOP.type = mediaType;
                eachSOP.previewURL = signedUrl.previewURL || false;
                thumbnail: youtubeRegex?.test(values.src)
                  ? `https://img.youtube.com/vi/${getYouTubeVideoId(
                      values.src
                    )}/hqdefault.jpg`
                  : vimeoRegex?.test(values.src)
                  ? `https://vumbnail.com/${getIdFromVimeoURL(values.src)}.jpg`
                  : undefined;
              }
              return eachSOP;
            });
            setSopContent([...updatedsopContent]);
          } else {
            values.src =
              values.src?.slice(0, 4) !== "http"
                ? getAssetsPath(values?.src, storageType)
                : // ? storageType == ONPREMISE
                  //   ? getAssetsPath(getEncodedImage(values?.src))
                  //   : values?.src
                  values.src;
            values.previewURL = signedUrl?.previewURL || false;
            setSopContent([
              ...sopContent,
              {
                ...values,
                id: uuidv4(),
                type: mediaType,
                thumbnail: youtubeRegex?.test(values.src)
                  ? `https://img.youtube.com/vi/${getYouTubeVideoId(
                      values.src
                    )}/hqdefault.jpg`
                  : vimeoRegex?.test(values.src)
                  ? `https://vumbnail.com/${getIdFromVimeoURL(values.src)}.jpg`
                  : undefined,
              },
            ]);
          }
          setModalOpen({ open: false });
        }}
        initialValues={{ title: data?.title, src: data?.src }}
      >
        {mediaType === "document" && (
          <Form.Item
            name={`title`}
            label={` ${
              mediaType?.charAt(0).toUpperCase() + mediaType?.slice(1)
            } title`}
            // rules={[
            //   {
            //     required: true,
            //     message: `Add your ${mediaType} title`,
            //   },
            // ]}
          >
            <Input
              style={{ minWidth: "100%" }}
              className={inputStyles.smInput}
              placeholder={`Enter ${mediaType} title`}
            />
          </Form.Item>
        )}

        {activeTab == `add_${isModalOpen.type}` ? (
          // Media = any upload file
          <MediaElem
            data={[data]}
            mediaType={isModalOpen.type}
            sopContent={sopContent}
            setSopContent={setSopContent}
            setModalOpen={setModalOpen}
            setSignedUrl={setSignedUrl}
            uploading={uploading}
            signedUrl={signedUrl}
            setUploading={setUploading}
          />
        ) : (
          // anything which has to with links
          <LinkElem
            tab={activeTab}
            setLoading={setLoading}
            form={form}
            setURL={setURL}
            url={url}
            loading={loading}
          />
        )}
        <div
          style={{
            minWidth: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Popover
            placement="left"
            content={
              uploading.loading || !signedUrl?.file ? "Upload to continue" : ""
            }
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={uploading.loading}
              className={btnStyles.mdBtn}
            >
              Done
            </Button>
          </Popover>
        </div>
      </Form>
    </Modal>
  );
}

export default AddElemModal;
