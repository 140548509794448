// eslint-disable import/no-anonymous-default-export
const styles = {
  width: "100%",
  control: {
    backgroundColor: "transparent",
    fontSize: 14,
  },

  "&multiLine": {
    control: {
      fontFamily: "Arial",
      minHeight: 70,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      padding: 9,
      border: "1px solid #d9d9d9",
      borderRadius: "6px",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: "25vw",

    highlighter: {
      padding: 6,
      border: "1px solid #D0D5DD",
    },
    input: {
      padding: 6,
      border: "1px solid transparent",
    },
  },

  suggestions: {
    list: {
      border: "1px solid #F9F9F9",
      fontSize: 16,
      boxSizing: "border-box",
      overflowY: "auto",
      height: 80,
      minWidth: "300px",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

export default styles;
