import axiosInstance from "../config/axios/axios.config";

export const createTyutApi = (payload) => {
  return axiosInstance.post("/tyut", payload);
};

export const getAlTyutsApi = () => {
  return axiosInstance.get("/tyut");
};
export const getTyutByIdApi = (id) => {
  return axiosInstance.get(`/tyut/${id}`);
};
export const bookmarkTyutByIdApi = (id, payload) => {
  return axiosInstance.post(`/tyut/${id}/bookmark`, payload);
};
export const likeTyutByIdApi = (id, payload) => {
  return axiosInstance.post(`/tyut/${id}/like`, payload);
};
export const updateTyutAPi = (tyut_id, payload) => {
  return axiosInstance.put(`/tyut/${tyut_id}`, payload);
};
export const shareTyutAPi = (tyut_id, payload) => {
  return axiosInstance.patch(`/tyut/${tyut_id}`, payload);
};
export const getTyutPermissionAPi = (tyut_id) => {
  return axiosInstance.get(`/tyut/${tyut_id}/permissions`);
};
export const verifyTyutPermissionAPi = (tyut_id) => {
  return axiosInstance.get(`/tyut/${tyut_id}/verify`);
};
export const getTyutsListApi = () => {
  return axiosInstance.get(`/tyut/library`);
};
export const verifyTyutAnswerApi = (id, payload) => {
  return axiosInstance.post(`/tyut/${id}/verify`, payload);
};
export const completeTyutApi = (id) => {
  return axiosInstance.post(`/tyut/${id}/complete`);
};
export const searchTyutsAndCoursesApi = (name) => {
  return axiosInstance.get(`/search/tyuts-courses?name=${name}`);
};
export const deleteTyutApi = (id) => {
  return axiosInstance.delete(`/tyut/${id}`);
};
export const deleteCourseApi = (id) => {
  return axiosInstance.delete(`/course/${id}`);
};
