import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Empty,
  Image,
  Input,
  Pagination,
  Popover,
  Result,
  Row,
  Skeleton,
  Table,
  Tag,
  TreeSelect,
  Typography,
  message,
} from "antd";
import {
  bulkGenerateQRCodes,
  getAllAssetsForListViewApi,
  getAssetQRCodes,
} from "../../services/assets.services";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PAGESIZE, createAsset } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";
import segmentedStyles from "../../assets/styles/Custom/Segmented.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import {
  debounce,
  getAssetsPath,
  minutesToHoursAndMinutes,
  statusOptions,
} from "../../helpers/utility";
import AssetSegemented from "./AssetSegemented";

const AssetsTableView = ({ setAssetsLength, viewType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { page, searchVal } = location.state || {};
  const [assets, setAssets] = useState(null);
  // const [searchParams, _] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statusVisible, setStatusVisible] = useState(false);
  const [selectedAssetStatus, setSelectedAssetStatus] = useState([]);

  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const { page, searchVal, pageType } = location.state || {};

  const fetchData = async () => {
    let query = { page: pageNumber };
    if (searchValue) {
      query = { ...query, name: searchValue };
    }
    selectedAssetStatus?.length > 0 && (query.status = selectedAssetStatus);

    setLoading(true);
    try {
      const res = await getAllAssetsForListViewApi(query);
      setAssets(res?.data);
      setAssetsLength(res?.data?.totalAssets || 0);
    } catch (error) {
      console.error("Error while fetching data:", error);
      setAssets(null);
      setAssetsLength(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, refresh]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [selectedAssetStatus]);

  useEffect(() => {
    if (pageType === "table") {
      page && page > 1 && setPageNumber(page);
      if (searchVal && searchVal !== "") {
        setSearchValue(searchVal);
        refreshPage();
      }
      if (page || searchVal) {
        navigate(`/assets?type=table`, { state: null });
      }
    }
  }, [location.state]);

  const refreshPage = (source = "") => {
    if (source === "search" && pageNumber > 1) {
      setPageNumber(1);
    } else {
      setRefresh((prev) => !prev);
    }
  };

  const columns = [
    {
      title: <Typography className={"tableHeader"}>Code</Typography>,
      dataIndex: "code",
      key: "code",
      width: 140,
      sorter: (a, b) => a?.code?.localeCompare(b?.code),
    },
    {
      title: <Typography className={"tableHeader"}>Asset</Typography>,
      dataIndex: "name",
      key: "name",
      width: 300,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: <Typography className={"tableHeader"}>Image</Typography>,
      dataIndex: "file",
      key: "file",
      width: 150,
      render: (val) => {
        return val ? (
          <div
            style={{ width: "fit-content" }}
            onClick={(e) => e.stopPropagation()}
          >
            <Image
              src={getAssetsPath(val, storageType)}
              preview={true}
              style={{ height: "80px", width: "80px", borderRadius: 8 }}
            />
          </div>
        ) : (
          <Typography style={{ textAlign: "center" }}>-</Typography>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Asset status</Typography>,
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (val) => {
        const color = statusOptions?.filter((each) => each?.value == val)?.[0]
          ?.color;
        return (
          <>
            {val ? (
              <Tag color={color} className={tagStyles.mdTag}>
                {val}
              </Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Location</Typography>,
      dataIndex: "location",
      key: "location",
      render: (val) => <>{val?.name}</>,
      width: 200,
      sorter: (a, b) => a?.location?.name?.localeCompare(b?.location?.name),
    },

    // {
    //   title: <Typography className={"tableHeader"}>WOs created</Typography>,
    //   dataIndex: "workorderCount",
    //   key: "workorderCount",
    //   width: 140,
    //   sorter: (a, b) => a?.workorderCount - b?.workorderCount,
    // },
    // {
    //   title: <Typography className={"tableHeader"}>PMs created</Typography>,
    //   dataIndex: "maintenanceCount",
    //   key: "maintenanceCount",
    //   width: 140,
    //   sorter: (a, b) => a?.maintenanceCount - b?.maintenanceCount,
    // },
    // {
    //   title: <Typography className={"tableHeader"}>Downtime</Typography>,
    //   dataIndex: "totalDowntime",
    //   key: "totalDowntime",
    //   width: 140,
    //   sorter: (a, b) => a?.totalDowntime - b?.totalDowntime,
    //   render: (val) => {
    //     const data = minutesToHoursAndMinutes(val * 60);
    //     return <span>{`${data.hours}hr ${data?.remainingMinutes}mins`}</span>;
    //   },
    // },
    // {
    //   title: <Typography className={"tableHeader"}>SOPs</Typography>,
    //   dataIndex: "sopCodes",
    //   key: "sopCodes",
    //   width: 200,
    //   render: (val) => {
    //     return (
    //       <>
    //         {val?.length > 0 ? (
    //           <div
    //             className="flex-start"
    //             style={{ flexWrap: "wrap", gap: "2px" }}
    //           >
    //             {val?.map((eachSOP, index) => (
    //               <a
    //                 href={`/procedures/${eachSOP?._id}`}
    //                 onClick={(e) => e.stopPropagation()}
    //               >
    //                 {eachSOP?.code} {index + 1 != val?.length && ","}
    //               </a>
    //             ))}
    //           </div>
    //         ) : (
    //           "-"
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: (
    //     <Typography className={"tableHeader"}>Maintenance logs</Typography>
    //   ),
    //   dataIndex: "logs",
    //   key: "logs",
    //   width: 200,
    // },
    // {
    //   title: (
    //     <Typography className={"tableHeader"}>
    //       Mean Time To Repair (MTR)
    //     </Typography>
    //   ),
    //   dataIndex: "SOPs",
    //   key: "SOPs",
    // },
    // {
    //   title: (
    //     <Typography className={"tableHeader"}>
    //       Mean Time Between Failure (MTBF)
    //     </Typography>
    //   ), // [total uptime / # of breakdowns]",
    //   dataIndex: "SOPs",
    //   key: "SOPs",
    // },
  ];

  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
    if (
      e?.target?.value?.trim()?.length === 0 ||
      e?.target?.value?.trim()?.length > 1
    ) {
      refreshPage("search");
    }
    // setPageNumber(1);
    // navigate(`/assets/?type=table&page=1`);
  };

  const handleQRCodesDownload = async () => {
    try {
      const res = await bulkGenerateQRCodes();
      if (res) {
        const resp = await getAssetQRCodes();
        if (resp?.data) {
          const a = document.createElement("a");
          a.href = resp?.data?.file_url;
          a.setAttribute("download", "assets_qr_codes.pdf");
          a.setAttribute("target", "_blank");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          message.error("Failed to download all Asset QR codes");
        }
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to download all Asset QR codes");
    }
  };

  const handleStatusDropdownVisibleChange = (visible) => {
    setStatusVisible(visible);
    if (
      !visible &&
      (selectedStatus?.length !== selectedAssetStatus?.length ||
        !selectedStatus?.every((i) => selectedAssetStatus?.includes(i)))
    ) {
      setSelectedStatus([]);
      setSelectedAssetStatus([]);
    }
  };

  const assetStatusOptions = [
    {
      title: "Online",
      value: "Online",
    },
    {
      title: "Under Maintenance",
      value: "Under Maintenance",
    },
    {
      title: "Not Working",
      value: "Not Working",
    },
    {
      title: "Retired",
      value: "Retired",
    },
  ];

  return (
    <Row gutter={[24, 18]}>
      <Col span={8} className="flex-start">
        <AssetSegemented navigate={navigate} viewType={viewType} />
      </Col>
      <Col
        span={16}
        style={{ textAlign: "right", justifyContent: "flex-end" }}
        className="flex-center"
      >
        <Button
          type="primary"
          className={btnStyles.smBtn}
          disabled={false}
          onClick={() => handleQRCodesDownload()}
          // onClick={() => navigate("/assets/create")}
        >
          Download All QR Codes
        </Button>
        <TreeSelect
          className="filter-tree"
          style={{ width: 250, textAlign: "left" }}
          placeholder="Asset Status"
          multiple={true}
          treeCheckable={true}
          showSearch={false}
          treeData={assetStatusOptions}
          value={selectedStatus}
          maxTagCount={1}
          onChange={(e) => {
            setSelectedStatus(e);
          }}
          dropdownRender={(menu) => (
            <div onMouseDown={(e) => e.stopPropagation()}>
              {menu}
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={() => {
                  setSelectedAssetStatus(selectedStatus);
                  setStatusVisible(false);
                }}
              >
                Apply
              </Button>
            </div>
          )}
          popupClassName="filter-tree-dropdown"
          open={statusVisible}
          onDropdownVisibleChange={handleStatusDropdownVisibleChange}
        />
        <Input.Search
          size="medium"
          defaultValue={searchVal ? searchVal : ""}
          onChange={debounce(handleSearch, 500)}
          placeholder="Search by asset name or code"
          style={{ width: "40%" }}
        />
        {/* {isDisabled ? (
            <Popover
              placement="left"
              content={
                <Typography>
                  You can't add more assets in the free trial
                </Typography>
              }
              trigger="hover"
            >
              <Button
                type="primary"
                id="create-asset"
                className={btnStyles.mdBtn}
                disabled={true}
                onClick={() => navigate("/assets/create")}
              >
                + Create Asset
              </Button>
            </Popover>
          ) : ( */}
        {createAsset?.includes(userDetails?.activeOrg?.role) && (
          <Button
            type="primary"
            id="create-asset"
            className={btnStyles.mdBtn}
            disabled={false}
            onClick={() => navigate("/assets/create")}
          >
            + Create Asset
          </Button>
        )}
        {/* )} */}
      </Col>
      <Col span={24}>
        <Table
          pagination={false}
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No assets found</Typography>}
              />
            ),
          }}
          scroll={{
            x: 1200,
            y: "60vh",
          }}
          onRow={(item) => {
            return {
              onClick: (e) => {
                navigate(`/assets/${item?._id}?type=table`, {
                  state: {
                    pageType: "table",
                    page: pageNumber,
                    searchVal: searchValue,
                  },
                });
                // navigate(
                //   `/assets/${item?._id}?type=table&page=${
                //     searchParams.get("page") || 1
                //   }`
                // );
              },
              style: { cursor: "pointer" },
            };
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          columns={columns}
          dataSource={assets?.assetsData}
        />
      </Col>
      {assets?.totalAssets > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            // current={searchParams.get("page") || 1}
            current={pageNumber}
            pageSize={PAGESIZE}
            total={assets?.totalAssets}
            // onChange={(e) => navigate(`/assets/?type=table&page=${e}`)}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </Row>
  );
};

export default AssetsTableView;
