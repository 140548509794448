import { Card, Flex, Form, Select, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import { getSOPByIDApi, getSOPsApi } from "../../../services/sops.services";
import ViewSOP from "./ViewSOP";
import { debounce, workOrderstatusList } from "../../../helpers/utility";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";

const SOPDetails = ({
  edit,
  workOrderDetails,
  sopData,
  setSopData,
  answers,
  setAnswers,
  form,
}) => {
  const [sops, setSOPs] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const dummyhandleChange = (value, e) => {
    if (value) {
      getSopByID(value);
    } else {
      form.setFieldsValue({ sop: "" });
    }
  };

  const getSopByID = async (id) => {
    try {
      const resp = await getSOPByIDApi(id);
      setSopData(resp?.data || {});
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let params = {
      page: pageNumber,
    };
    if (searchValue) {
      params.name = searchValue;
    }

    const queryString = new URLSearchParams(params);
    const fetchData = async () => {
      // setLoading(true);
      try {
        const resp = await getSOPsApi(params);
        setSOPs(
          resp?.data?.sops?.map((sop) => ({
            value: sop?._id,
            label: (
              <Flex
                justify="flex-start"
                gap={"12px"}
                align="center"
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                <Typography style={{ maxWidth: "80%", whiteSpace: "wrap" }}>
                  {sop?.name}
                </Typography>
                <Tag color="blue">{sop?.code}</Tag>
              </Flex>
            ),
          }))
        );
      } catch (error) {
        console.error("Error while fetching data:", error);
      } finally {
        // setLoading(false);
      }
    };
    fetchData();
  }, [pageNumber, searchValue]);

  const handleSearch = (e) => {
    setSearchValue(e);
  };
  const status = workOrderstatusList?.filter(
    (status) => status?.value == workOrderDetails?.status
  )?.[0];

  return (
    <Card
      title={
        <Flex justify="space-between" align="center">
          <Typography style={{ fontSize: 16, fontWeight: 500 }}>
            {"Standard Operating Procedure"}
          </Typography>
          {workOrderDetails?.status && (
            <Tag
              className={tagStyles.smTag}
              style={{
                minWidth: "80px",
                textAlign: "left",
                fontSize: "14px",
              }}
              color={status?.color}
            >
              {status?.label}{" "}
            </Tag>
          )}
        </Flex>
      }
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      {edit && (
        <Form.Item label={"SOP"} name={"sop"} style={{ width: "65%" }}>
          <Select
            showSearch
            allowClear
            filterOption={false}
            placeholder={`Select SOP`}
            style={{ width: "100%" }}
            className={inputStyles.smSelect}
            onSearch={debounce(handleSearch, 500)}
            onChange={dummyhandleChange}
            options={sops}
            onClear={() => {
              setSopData({});
              form.setFieldsValue({ sop: "" });
            }}
          />
        </Form.Item>
      )}
      {!edit && !workOrderDetails?.sop && (
        <>
          <Typography>No SOP found</Typography>
        </>
      )}
      {Object.keys(sopData)?.length > 0 && (
        <ViewSOP
          edit={false}
          sopData={sopData}
          setSopData={setSopData}
          answers={answers}
          setAnswers={setAnswers}
        />
      )}
    </Card>
  );
};

export default SOPDetails;
