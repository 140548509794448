import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";
// import {
//   getTyutPermissionAPI,
//   getUserOrTeamAPI,
//   shareTyutApi,
// } from "@/src/services/LMS/tyut.services";
import {
  CloseOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Select,
  Switch,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import CustomSearch from "../../../Blocks/CustomSearch";
import RandomAvatar from "../../../Blocks/RandomAvatar";
import { UserContext } from "../../../../context/UserProvider";
import { debounce } from "../../../../helpers/utility";
import { getUsersandGroupsApi } from "../../../../services/users.services";
import {
  getTyutPermissionAPi,
  shareTyutAPi,
} from "../../../../services/tyuts.services";

function ShareTyutModal({
  creator_id,
  tyut_id,
  isModalOpen,
  handleOk,
  handleCancel,
  anyOneCanFromOrgCanView = true,
}) {
  const { userDetails } = useContext(UserContext);
  let loggedInUserRole = userDetails?.activeOrg?.role;
  let loggedInUserId = userDetails?._id;
  const userScrollRef = useRef(null);
  const teamScrollRef = useRef(null);
  const [users, setUsers] = useState({ groups: [], users: [] });
  const [anyOneCanView, setAnyoneCanView] = useState(anyOneCanFromOrgCanView);
  const [selectedUserNTeams, setSelectedUsersNTeams] = useState({
    groups: [],
    users: [],
  });
  const [removedUsersNTeams, setRemovedUsersNTeams] = useState({
    groups: [],
    users: [],
  });
  const [selectedIds, setSelectedIds] = useState({});
  useEffect(() => {
    getTyutPermissions();
  }, []);

  useEffect(() => {
    if (userScrollRef.current) {
      userScrollRef.current.scrollTop = userScrollRef.current.scrollHeight;
    }
    if (teamScrollRef.current) {
      teamScrollRef.current.scrollTop = teamScrollRef.current.scrollHeight;
    }
  }, [selectedUserNTeams]);

  async function getUserAndTeams(value, endLoading) {
    if (value.length > 2)
      try {
        let params = {};
        if (value) {
          params.name = value;
        }
        // const queryString = new URLSearchParams(params);
        const res = await getUsersandGroupsApi(params);
        if (res) {
          let groups = res?.data.groups;
          let users = res?.data.users;
          groups = groups.filter(
            (eachTeam) => !selectedIds?.[eachTeam._id] && eachTeam
          );
          users = users.filter(
            (eachUser) =>
              !selectedIds?.[eachUser._id] &&
              eachUser._id !== creator_id &&
              eachUser
          );
          setUsers({ users, groups });
          // setUsers(searchResult(res?.data?.users));
        }
      } catch (error) {
        console.error(error);
        message.error(error?.response?.data?.message || "Something went wrong");
      } finally {
        endLoading();
      }
  }

  function onSelect(val, option) {
    if (option.user) {
      const isUserSelected = selectedUserNTeams.users.some(
        (eachUser) => eachUser.id === option.id
      );
      if (!isUserSelected) {
        setSelectedUsersNTeams({
          ...selectedUserNTeams,
          users: [...selectedUserNTeams.users, option],
        });
        setSelectedIds((prev) => ({
          ...prev,
          [option?.id]: option?.id,
        }));
        let allUsers = users.users.filter(
          (eachUser) => eachUser._id !== option.id
        );
        setUsers((prev) => ({ ...prev, users: allUsers }));
      }
    } else {
      const isTeamSelected = selectedUserNTeams.groups.some(
        (eachTeam) => eachTeam._id === option.id
      );
      if (!isTeamSelected) {
        setSelectedUsersNTeams({
          ...selectedUserNTeams,
          groups: [...selectedUserNTeams.groups, option],
        });
        setSelectedIds((prev) => ({
          ...prev,
          [option?.id]: option?.id,
        }));
        let allTeams = users.groups.filter(
          (eachTeam) => eachTeam._id !== option.id
        );
        setUsers((prev) => ({ ...prev, teams: allTeams }));
      }
    }
  }

  async function shareTyut() {
    let permissions = [
      ...selectedUserNTeams.users.map((eachUser) => ({
        level: "user",
        entity: eachUser.id || eachUser?._id,
        status: "add",
      })),
      ...selectedUserNTeams.groups.map((eachTeam) => ({
        level: "group",
        entity: eachTeam._id || eachTeam.id,
        status: "add",
      })),
      ...removedUsersNTeams.users?.map((eachUser) => ({
        level: "user",
        entity: String(eachUser),
        status: "remove",
      })),
      ...removedUsersNTeams.groups?.map((eachTeam) => ({
        level: "group",
        entity: String(eachTeam),
        status: "remove",
      })),
    ];

    if (anyOneCanView)
      permissions.push({
        level: "public",
        status: "add",
      });
    else permissions = permissions;

    try {
      const res = await shareTyutAPi(tyut_id, permissions);
      if (res) message.success(res?.data?.message);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      handleCancel();
    }
  }

  async function getTyutPermissions() {
    try {
      const { data } = await getTyutPermissionAPi(tyut_id);

      setAnyoneCanView(
        (data?.filter((item) => item?.level === "public") || [])?.length > 0
          ? true
          : false
      );
      setSelectedUsersNTeams({
        users: data?.filter(
          (item) => item?.level === "user" && item._id !== creator_id
        ),
        groups: data?.filter((item) => item?.level === "group"),
      });
      let selectedIds = {};
      data?.map((each) => (selectedIds[each?._id] = each?._id));
      setSelectedIds(selectedIds);
    } catch (error) {
      console.log(error);
    }
  }
  let options = [];

  users?.users?.length > 0 &&
    options.push({
      label: <Typography.Text>Users</Typography.Text>,
      options: users?.users?.map((item) => ({
        value: (item?.first_name || "") + " " + (item?.last_name || ""),
        id: item._id,
        user: true,
        role: item?.orgs_and_permissions?.role,
        username: item.first_name + item.last_name,
        type: "view",
        ...item,
      })),
    });

  users?.groups?.length > 0 &&
    options.push({
      label: <Typography.Text>Teams</Typography.Text>,
      options: users?.groups?.map((item) => ({
        value: item?.name,
        id: item._id,
        role: item?.orgs_and_permissions?.role,
        username: item.first_name + item.last_name,
        user: false,
        type: "view",
      })),
    });

  return (
    <Modal
      maskClosable={false}
      title="Share Tyut"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          type="primary"
          key="back"
          onClick={shareTyut}
          disabled={
            (selectedUserNTeams?.groups?.length ||
              selectedUserNTeams?.users?.length) === 0 && !anyOneCanView
              ? true
              : false
          }
        >
          Share
        </Button>,
      ]}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Typography>
            {/* <UserOutlined style={{ fontSize: 20 }} /> */}
            {/* <Image
              src="/images/icons/group.png"
              alt="group"
              height={20}
              width={20}
              style={{ paddingRight: "8px" }}
            /> */}
            Search for people or groups{" "}
          </Typography>
        </Col>
        <Col span={24}>
          <CustomSearch
            placeholder="Search people or groups"
            onSearch={debounce(getUserAndTeams, 300)}
            onSelect={onSelect}
            // options={users && users}
            options={options}
          />
          <Typography
            style={{ fontSize: "10px", color: "#475467", marginTop: "4px" }}
          >
            <InfoCircleOutlined
              style={{ marginRight: "5px", color: "#007aff" }}
            />
            Search with minimum 2 characters
          </Typography>

          <div style={{ marginTop: "16px" }}>
            <Switch
              onChange={(checked) => setAnyoneCanView(checked)}
              checked={anyOneCanView}
              style={{ marginRight: "5px" }}
            />
            {anyOneCanView ? "Any " : "No "} one in the organisation can view.
          </div>
        </Col>
        {selectedUserNTeams?.users?.length > 0 && (
          <>
            <Col
              span={24}
              style={{
                marginTop: selectedUserNTeams?.groups?.length
                  ? "1rem"
                  : "0.25rem",
              }}
            >
              <Typography className={typographyStyles.subtext2}>
                People with Access
              </Typography>
            </Col>
            <Col
              span={24}
              style={{
                maxHeight:
                  selectedUserNTeams?.users?.length > 0 ? "20vh" : "60vh",
                overflowY: "auto",
                width: "100%",
              }}
              ref={userScrollRef}
            >
              {selectedUserNTeams.users.map((eachUser) => {
                return (
                  <>
                    <Row
                      style={{
                        margin: "10px 0",
                        minWidth: "100%",
                        paddingBottom: "10px",
                        borderBottom: "1px solid #EAECF0",
                      }}
                    >
                      <Col>
                        <RandomAvatar
                          user={eachUser}
                          size={24}
                          indexId={eachUser?._id}
                          name={eachUser?.first_name}
                          // url={eachUser?.profile_image}
                        />
                      </Col>
                      <Col span={14} offset={1}>
                        <Typography>{eachUser?.email}</Typography>
                        <Typography type="secondary">
                          {(eachUser?.first_name || "") +
                            " " +
                            (eachUser?.last_name || "")}
                        </Typography>
                      </Col>
                      <Col
                        span={6}
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "center",
                        }}
                      >
                        {/* <Select
                          onChange={(val) => {
                            selectedUserNTeams.users?.some((each) => {
                              if (each.id == eachUser.id) {
                                each.type = val;
                                return true;
                              }
                              return false;
                            });
                            setSelectedUsersNTeams({ ...selectedUserNTeams });
                          }}
                          style={{ minWidth: "90px" }}
                          defaultValue={eachUser.type || "view"}
                          options={[
                            { value: "view", label: "Viewer" },
                            { value: "edit", label: "Editor" },
                          ]}
                          disabled={role !== "admin"}
                        /> */}

                        {(creator_id === loggedInUserId ||
                          loggedInUserRole === "admin") && (
                          <CloseOutlined
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              const newUsers = selectedUserNTeams.users.filter(
                                (each) =>
                                  each._id !== (eachUser.id || eachUser._id)
                              );
                              setRemovedUsersNTeams((prev) => ({
                                groups: prev.groups,
                                users: [
                                  ...prev.users,
                                  eachUser.id || eachUser._id,
                                ],
                              }));
                              setSelectedUsersNTeams({
                                ...selectedUserNTeams,
                                users: newUsers,
                              });
                              delete selectedIds?.[eachUser.id];
                              setSelectedIds((prev) => ({ ...prev }));
                              // setUsers({ teams: [], users: [] });
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </>
                );
              })}
            </Col>
          </>
        )}
        {selectedUserNTeams?.groups?.length > 0 && (
          <>
            <Col
              span={24}
              style={{
                marginTop: selectedUserNTeams?.users?.length
                  ? "1rem"
                  : "0.25rem",
              }}
            >
              <Typography className={typographyStyles.subtext2}>
                Groups with Access
              </Typography>
            </Col>
            <Col
              span={24}
              style={{
                maxHeight:
                  selectedUserNTeams?.groups?.length > 0 ? "20vh" : "60vh",
                overflowY: "auto",
              }}
              ref={teamScrollRef}
            >
              {selectedUserNTeams?.groups?.map((eachTeam) => {
                return (
                  <>
                    <Row
                      style={{
                        margin: "10px 0",
                        minWidth: "100%",
                        paddingBottom: "10px",
                        borderBottom: "1px solid #EAECF0",
                      }}
                    >
                      <Col>
                        <UserOutlined />
                        {/* <Image
                          src="/images/icons/group.png"
                          alt="group"
                          height={20}
                          width={20}
                        /> */}
                      </Col>
                      <Col span={14} offset={1}>
                        <Typography>
                          {eachTeam.value || eachTeam.name}
                        </Typography>
                        {/* <Typography type="secondary">
                          {eachTeam.name}
                        </Typography> */}
                      </Col>
                      <Col>
                        <Select
                          style={{ minWidth: "90px", visibility: "hidden" }}
                          defaultValue={eachTeam.type || "view"}
                          disabled={true}
                          options={[{ value: "view", label: "Viewer" }]}
                        />
                        {(creator_id === loggedInUserId ||
                          loggedInUserRole == "admin") && (
                          <CloseOutlined
                            style={{ marginLeft: "20px" }}
                            onClick={() => {
                              const newTeams = selectedUserNTeams.groups.filter(
                                (each) => each._id !== eachTeam._id
                              );
                              setRemovedUsersNTeams((prev) => ({
                                users: prev.users,
                                groups: [...(prev.teams || []), eachTeam._id],
                              }));
                              setSelectedUsersNTeams({
                                ...selectedUserNTeams,
                                groups: newTeams,
                              });
                              delete selectedIds?.[eachTeam._id];
                              setSelectedIds((prev) => ({ ...prev }));
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </>
                );
              })}
            </Col>
          </>
        )}
      </Row>
    </Modal>
  );
}

export default ShareTyutModal;
