import { Card, Col, DatePicker, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { displayStatsCard, displayTitleValue } from "./helpers";
import dayjs from "dayjs";
import { getMWStatsApi } from "../../services/dashboard.services";
import WaffleChart from "./WaffleChart";

const { RangePicker } = DatePicker;

const BreakdownAnalysis = () => {
  const [stats, setStats] = useState({ maintenances: {}, workorders: {} });
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "month"),
    end_date: dayjs().add(1, "month"),
  });

  const getStats = async () => {
    try {
      let params = {};
      if (dateFilter?.start_date) {
        params.start_date = dayjs(dateFilter?.start_date).format("YYYY-MM-DD");
      }
      if (dateFilter?.end_date) {
        params.end_date = dayjs(dateFilter?.end_date).format("YYYY-MM-DD");
      }
      const queryString = new URLSearchParams(params);
      const resp = await getMWStatsApi(queryString);
      setStats(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStats();
  }, [dateFilter]);

  return (
    <>
      <Col
        span={24}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <RangePicker
          format="DD-MMM-YYYY"
          defaultValue={[dateFilter.start_date, dateFilter.end_date]}
          onChange={(_, dateString) => {
            setDateFilter({
              start_date: dateString[0],
              end_date: dateString[1],
            });
          }}
        />
      </Col>
      <Col
        span={24}
        style={{ paddingBottom: 20, height: "81vh", overflowY: "auto" }}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
            <Card
              className="mainCards"
              styles={{
                header: {
                  border: "0px",
                },
              }}
              title={"MROs"}
              style={{ width: "100%" }}
            >
              <Row gutter={[18, 0]}>
                {displayStatsCard(
                  "Non Critical",
                  stats?.mros?.nonCriticalMROCount || 0,
                  "warning",
                  6
                )}
                <Col span={18}>
                  <Row gutter={[18, 12]}>
                    {displayStatsCard(
                      "Critical (Breakdown events)",
                      stats?.mros?.criticalMROCount || 0,
                      "warning",
                      24
                    )}
                    {displayStatsCard(
                      "Mechanical",
                      stats?.mros?.criticalMechanicalCount || 0,
                      "warning",
                      8
                    )}
                    {displayStatsCard(
                      "Electrical",
                      stats?.mros?.criticalElectricalCount || 0,
                      "warning",
                      8
                    )}
                    {displayStatsCard(
                      "Others",
                      stats?.mros?.criticalOthersCount || 0,
                      "warning",
                      8
                    )}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
            <Card
              title="Breakdown time"
              className="mainCards"
              styles={{
                header: {
                  border: "0px",
                },
              }}
              style={{ width: "100%" }}
            >
              <Row gutter={[12, 12]}>
                <Col
                  span={14}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {displayTitleValue({
                      title: "Total Breakdown time",
                      value: stats?.mros?.totalCriticalMROTime,
                      type: "duration",
                      valueColor: "#7369F4",
                      valueFontSize: "h5",
                      mt: 8,
                    })}
                    {displayTitleValue({
                      title: "Others",
                      value: stats?.mros?.criticalOthersTime,
                      type: "duration",
                      dotColor: "#DCDCDC",
                    })}
                    {displayTitleValue({
                      title: "Electrical",
                      value: stats?.mros?.criticalElectricalTime,
                      type: "duration",
                      dotColor: "#FFA384",
                    })}
                    {displayTitleValue({
                      title: "Mechanical",
                      value: stats?.mros?.criticalMechanicalTime,
                      type: "duration",
                      dotColor: "#CB6767",
                    })}
                  </Space>
                </Col>
                <Col span={10}>
                  <WaffleChart
                    height={240}
                    total={stats?.mros?.totalCriticalMROTime || 0}
                    data={[
                      {
                        id: "Mechanical",
                        label: "Mechanical",
                        value: stats?.mros?.criticalMechanicalTime || 0,
                      },
                      {
                        id: "Electrical",
                        label: "Electrical",
                        value: stats?.mros?.criticalElectricalTime || 0,
                      },
                      {
                        id: "Others",
                        label: "Others",
                        value: stats?.mros?.criticalOthersTime || 0,
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default BreakdownAnalysis;
