import { Flex, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomLayout from "../Layout";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import Courses from "./Courses";
import Tyuts from "./Tyuts";
import { useSearchParams } from "react-router-dom";

const Trainings = () => {
  const [searchParams, _] = useSearchParams();
  const [view, setView] = useState("tyut");
  const [tyuts, setTyuts] = useState([]);

  useEffect(() => {
    if (searchParams.get("type") == "course") {
      setView("course");
    } else {
      setView("tyut");
    }
  }, [searchParams]);

  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography className={typoStyles.h6}>Trainings</Typography>
        </Flex>
      }
    >
      <>
        {view == "tyut" ? (
          <Tyuts
            setTyuts={setTyuts}
            tyuts={tyuts}
            view={view}
            setView={setView}
          />
        ) : (
          <Courses view={view} setView={setView} />
        )}
      </>
    </CustomLayout>
  );
};

export default Trainings;
