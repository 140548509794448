import React from "react";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Typography,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";

import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import "./Join.css";
import { createPasswordApi } from "../../services/users.services";
import darkLogo from "../../assets/images/cmms-logos/CMMSLogo.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";

const Join = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    delete values?.confirm_password;
    try {
      const resp = await createPasswordApi({ ...values, token: params?.id });
      if (resp) {
        navigate("/login");
        message.success(resp?.data?.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <Row
      style={{
        height: "100vh",
        alignItems: "center",
      }}
    >
      <Col
        xs={{ span: 16, offset: 4 }}
        md={{ span: 10, offset: 6 }}
        lg={{ span: 8, offset: 8 }}
        xl={{ span: 6, offset: 9 }}
      >
        <div style={{ textAlign: "center" }}>
          <Image
            src={darkLogo}
            style={{ height: "100px", width: "auto" }}
            preview={false}
          />
          <Typography
            className={`${typoStyles.h6} text-center`}
            style={{ marginBlock: "24px" }}
          >
            Create password
          </Typography>
        </div>
        <Form
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          form={form}
        >
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/,
                message:
                  "Password must contain at least 1 letter, 1 number, and be at least 8 characters long.",
              },
              // {
              //   message:
              //     "Password must have at least 8 characters, including uppercase, lowercase, digit, and special character.",
              //   pattern: new RegExp(
              //     "^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
              //   ),
              // },
            ]}
          >
            <Input.Password
              placeholder="Enter your password"
              className={inputStyles.smInput}
              style={{ width: "100%", gap: "0px" }}
            />
          </Form.Item>
          <Form.Item
            label="Confirm password"
            name="confirm_password"
            rules={[
              { required: true, message: "Please input your password!" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The new passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Re-enter your password"
              className={inputStyles.smInput}
              style={{ width: "100%", gap: "0px" }}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              className={btnStyles.mdBtn}
              htmlType="submit"
            >
              Update Password
            </Button>
          </Form.Item>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              type="link"
              className={`${btnStyles.mdBtn} ${btnStyles.textBtn}`}
              onClick={() => {
                navigate("/login");
              }}
            >
              <ArrowLeftOutlined />
              Back to log in
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default Join;
