import { Button, Card, message } from "antd";
import React, { useContext, useState } from "react";
import VideoPlayer from "../../../Blocks/VideoPlayer";
import "../../SOP.css";
import { generateRandomString } from "../../../../helpers/utility";
import { useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../../../context/UserProvider";
import axios from "axios";
import { CLOUD, SOP } from "../../../../constants/defaultKeys";

function ViewVideo({
  videoElem,
  viewType,
  setSopContent,
  sopContent,
  contentType = SOP,
}) {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [data, steData] = useState([]);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const getTyuts = async () => {
    try {
      setLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL_DOMAIN}/cmms-api/get-tyut-qa/`,
        {
          // source: storageType == CLOUD ? "cloud" : "local",
          source: (videoElem.previewURL || videoElem.src).includes(
            "youtube.com"
          )
            ? "youtube"
            : "s3",
          url: videoElem.previewURL || videoElem.src,
        }
      );
      const formattedData = resp?.data?.map((each) => ({
        ...each,
        answers:
          each?.question_type == "radio" ? each?.answer?.[0] : each?.answer,
        choices: each?.options,
        type: each?.question_type,
        id: generateRandomString(12),
      }));
      const filteredPreviousData = sopContent.filter(
        (prev) => !data?.some((newData) => newData.id === prev.id)
      );

      setSopContent((prev) => [...filteredPreviousData, ...formattedData]);
      steData(formattedData);
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong!!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      className={"tyutElemCard"}
      style={
        viewType === "edit"
          ? { borderRadius: "8px" }
          : { borderRadius: 0, boxShadow: "none" }
      }
    >
      <div
        style={{
          position: "relative",
          left: "-7px",
          bottom: 0,
          borderRadius: "16px",
        }}
      >
        <VideoPlayer
          url={videoElem.previewURL || videoElem.src}
          width="100%"
          height="500px"
        />
      </div>
      {!params?.id &&
        contentType != SOP &&
        !location?.pathname?.includes("course") && (
          <Button
            loading={loading}
            type="primary"
            onClick={getTyuts}
            style={{ marginTop: "12px", float: "right" }}
          >
            {data?.length > 0 ? "Re-generate" : "Generate"} questionnarie from
            this video using AI
          </Button>
        )}
    </Card>
  );
}

export default ViewVideo;
