import { Card, Col, Row, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getAssetSparePartHistory } from "../../../services/assets.services";
import dayjs from "dayjs";
import { convertUTCToLocalFormat } from "../../../helpers/utility";

const SparePartHistory = ({ assetDetails }) => {
  console.log(assetDetails?._id);
  const [sparePartData, setSparePartData] = useState([]);

  const fetchAssetSparePartHistory = async () => {
    try {
      const res = await getAssetSparePartHistory(assetDetails?._id);
      setSparePartData(res?.data);
    } catch (error) {
      setSparePartData([]);
    }
  };

  useEffect(() => {
    if (assetDetails?._id) {
      fetchAssetSparePartHistory();
    }
  }, [assetDetails]);

  const tableColumns = [
    {
      title: <Typography className="tableHeader">Code</Typography>,
      dataIndex: "code",
      key: "code",
    },
    {
      title: <Typography className="tableHeader">Requested date</Typography>,
      dataIndex: "requested_date",
      key: "requested_date",
      render: (val, record) => (
        <>
          {val
            ? dayjs(convertUTCToLocalFormat(val)).format("DD MMM YYYY")
            : "-"}
        </>
      ),
    },
    {
      title: <Typography className="tableHeader">Quantity used</Typography>,
      dataIndex: "requested_quantity",
      key: "requested_quantity",
    },
    {
      title: <Typography className="tableHeader">Cost</Typography>,
      dataIndex: "cost",
      key: "cost",
    },
  ];
  return (
    <Card
      title="Spare Parts History"
      // style={{ height: "100%" }}
      styles={{
        header: { backgroundColor: "#fff" },
        body: { padding: "0px" },
      }}
    >
      <Row>
        <Col span={24}>
          <Table
            scroll={{ x: 500, y: 260 }}
            columns={tableColumns}
            dataSource={sparePartData}
            pagination={false}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default SparePartHistory;
