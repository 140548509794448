export const setItem = (key, value, isObject = false) => {
  const data = isObject ? JSON.stringify(value) : value;
  localStorage.setItem(key, data);
};

export const getItem = (key, isObject = false) => {
  const value = localStorage.getItem(key);
  const data = isObject ? JSON.parse(value) : value;
  return data;
};

export const clear = () => {
  localStorage.clear();
};
export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export function getCookie(cookieName) {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }
  return null; // Return null if the cookie is not found
}

export function setCookie(cookieName, cookieValue, domain, path) {
  document.cookie = `${cookieName}=${cookieValue}; domain=${domain}; path=${path}`;
}

export function clearAllCookies() {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [name, _] = cookie.split("=");
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.${process.env.REACT_APP_PUBLIC_DOMAIN}; path=/`;
  }
}

export function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.${process.env.REACT_APP_PUBLIC_DOMAIN}; path=/`;
}
