import { useEffect, useMemo, useState } from "react";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import "./index.css";

import {
  Button,
  ConfigProvider,
  DatePicker,
  Drawer,
  Flex,
  Form,
  Input,
  Segmented,
  Select,
  Tag,
  TimePicker,
  TreeSelect,
  Typography,
  message,
} from "antd";
import { getUsersandGroupsApi } from "../../services/users.services";
import {
  convertUTCToLocalFormat,
  options,
  workOrderstatusList,
} from "../../helpers/utility";
import {
  getAllAssetsApi,
  getProductionLines,
} from "../../services/assets.services";
import dayjs from "dayjs";
import { updateOccurenceApi } from "../../services/maintenance.services";
import { updateWorkOrderApi } from "../../services/work-orders.services";
import segmentedStyles from "../../assets/styles/Custom/Segmented.module.css";

export default function EventForm({ currEvent, onClose, pageType = "" }) {
  const [technicians, setTechinicans] = useState([]);
  const [form] = Form.useForm();
  const [assets, setAssets] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [currStatus, setCurrStatus] = useState("");
  const [productionLines, setProductionLines] = useState([]);
  // console.log(selectedTab, "stt");

  const getTachinicans = async () => {
    try {
      const params = {};
      params.role = "technician";

      // const queryString = new URLSearchParams(params);
      const resp = await getUsersandGroupsApi(params);

      const users = resp?.data?.users?.map((user) => ({
        value: user._id,
        title: `${user.first_name} ${user.last_name || ""}`,
        group: false,
        id: user?._id,
      }));

      const groups = resp?.data?.groups?.map((group) => ({
        value: group._id,
        title: group.name,
        group: true,
        children: group.members.map((member) => ({
          value: `${member._id}-${group?._id}`,
          title: `${member?.first_name} ${member?.last_name || ""}`,
          group: false,
          id: member?._id,
        })),
      }));

      setTechinicans([...users, ...groups]);
    } catch (error) {
      console.error(error);
    }
  };

  const getAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: asset?.name,
          value: asset?._id,
          image: asset?.file,
          status: asset?.status,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductionLines = async () => {
    try {
      const resp = await getProductionLines();
      setProductionLines(
        resp?.data?.assets?.map((pl) => ({
          label: pl?.name,
          value: pl?._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTachinicans();
    getAssets();
    fetchProductionLines();
  }, []);

  useEffect(() => {
    const initialValues = {
      ...currEvent?.[selectedTab],
      sop:
        currEvent?.[selectedTab]?.type == "pm"
          ? currEvent?.[selectedTab].maintenance?.sop
          : currEvent?.[selectedTab].sop,
      name:
        currEvent?.[selectedTab]?.type == "pm"
          ? currEvent?.[selectedTab].maintenance?.name
          : currEvent?.[selectedTab].name,
      asset:
        currEvent?.[selectedTab]?.type == "pm"
          ? currEvent?.[selectedTab].maintenance?.asset?._id
          : currEvent?.[selectedTab].asset?._id,
      production_line:
        currEvent?.[selectedTab]?.type == "pm"
          ? currEvent?.[selectedTab].maintenance?.production_line?._id
          : currEvent?.[selectedTab].production_line?._id,
      start_date:
        currEvent?.[selectedTab]?.type == "pm"
          ? currEvent?.[selectedTab]?.occurrence_date
            ? dayjs(
                convertUTCToLocalFormat(
                  currEvent?.[selectedTab]?.occurrence_date
                )
              )
            : null
          : currEvent?.[selectedTab]?.start_date
          ? dayjs(convertUTCToLocalFormat(currEvent?.[selectedTab]?.start_date))
          : null,
      end_date:
        currEvent?.[selectedTab]?.type == "pm"
          ? currEvent?.[selectedTab]?.occurrence_end_date
            ? dayjs(
                convertUTCToLocalFormat(
                  currEvent?.[selectedTab]?.occurrence_end_date
                )
              )
            : null
          : currEvent?.[selectedTab]?.end_date
          ? dayjs(convertUTCToLocalFormat(currEvent?.[selectedTab]?.end_date))
          : null,
      start_time:
        currEvent?.[selectedTab]?.type == "pm"
          ? currEvent?.[selectedTab]?.occurrence_date
            ? dayjs(
                convertUTCToLocalFormat(
                  currEvent?.[selectedTab]?.occurrence_date
                )
              )
            : null
          : currEvent?.[selectedTab]?.start_date
          ? dayjs(convertUTCToLocalFormat(currEvent?.[selectedTab]?.start_date))
          : null,
      end_time:
        currEvent?.[selectedTab]?.type == "pm"
          ? currEvent?.[selectedTab]?.occurrence_end_date
            ? dayjs(
                convertUTCToLocalFormat(
                  currEvent?.[selectedTab]?.occurrence_end_date
                )
              )
            : null
          : currEvent?.[selectedTab]?.end_date
          ? dayjs(convertUTCToLocalFormat(currEvent?.[selectedTab]?.end_date))
          : null,
      // start_time: currEvent?.[selectedTab]?.start_time
      //   ? dayjs(currEvent?.[selectedTab]?.start_time, "HH:mm")
      //   : null,
      // end_time:
      //   currEvent?.[selectedTab]?.type == "pm"
      //     ? currEvent?.[selectedTab]?.occurrence_end_date
      //       ? dayjs(
      //           new Date(currEvent?.[selectedTab]?.occurrence_end_date)
      //             .toISOString()
      //             .split("T")[1]
      //             .split(".")[0],
      //           "HH:mm"
      //         )
      //       : null
      //     : currEvent?.[selectedTab]?.end_time
      //     ? dayjs(currEvent?.[selectedTab]?.end_time, "HH:mm")
      //     : null,
      assigned_groups:
        currEvent?.[selectedTab]?.occ?.assigned_groups?.map(
          (each) => each?._id
        ) || [],
      assigned_users:
        currEvent?.[selectedTab]?.occ?.assigned_users?.map(
          (each) => each?._id
        ) || [],
      assigned_list: [
        ...(currEvent?.[selectedTab]?.occ?.assigned_groups?.map(
          (each) => each?._id
        ) || []),
        ...(currEvent?.[selectedTab]?.occ?.assigned_users?.map(
          (each) => each?._id
        ) || []),
      ],
    };

    const status = workOrderstatusList?.filter(
      (status) => status?.value == currEvent?.[selectedTab]?.status
    )?.[0];

    setCurrStatus(status);
    form.setFieldsValue(initialValues);
  }, [selectedTab]);

  useEffect(() => {
    if (technicians.length) {
      let assignedUsers = [],
        assignedGroups = [],
        currAssignedUsers =
          currEvent?.[selectedTab]?.occ?.assigned_users?.map(
            (each) => each?._id
          ) || [],
        currAssignedGroups =
          currEvent?.[selectedTab]?.occ?.assigned_groups?.map(
            (each) => each?._id
          ) || [];
      technicians?.map((t) => {
        if (t?.group && currAssignedGroups?.includes(t?.value)) {
          assignedGroups.push(t?.value);
        } else if (!t?.group && currAssignedUsers?.includes(t?.value)) {
          assignedUsers.push(t?.value);
        }
      });
      form.setFieldsValue({
        assigned_users: assignedUsers,
        assigned_groups: assignedGroups,
        assigned_list: [...assignedGroups, ...assignedUsers],
      });
    }
  }, [currEvent, technicians, selectedTab]);

  const onFinish = (values) => {
    if (currEvent?.[selectedTab]?.type == "pm") {
      updateOcuurenceStatus(currEvent?.[selectedTab]?.occ?._id, {
        assigned_users: values?.assigned_users || [],
        assigned_groups: values?.assigned_groups || [],
      });
    } else if (currEvent?.[selectedTab]?.type == "wo") {
      updateWO(currEvent?.[selectedTab]?._id, {
        assigned_users: values?.assigned_users,
        assigned_groups: values?.assigned_groups,
      });
    }
  };

  const updateWO = async (id, data) => {
    try {
      const resp = await updateWorkOrderApi(id, data);
      resp && onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const updateOcuurenceStatus = async (id, data) => {
    try {
      const resp = await updateOccurenceApi(id, data);
      // resp && onClose();
      if (resp) {
        if (pageType === "assetView" || pageType === "plView") {
          onClose("refresh");
        } else {
          onClose();
        }
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <Drawer
      width={"30vw"}
      footer={
        currEvent?.[selectedTab]?.status !== "closed" && (
          <Flex justify="flex-end" gap={24}>
            <Button
              onClick={() => {
                form.resetFields([
                  "assigned_list",
                  "assigned_groups",
                  "assigned_users",
                ]);
              }}
              className={btnStyles.mdBtn}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => form.submit()}
              className={btnStyles.mdBtn}
            >
              Save
            </Button>
          </Flex>
        )
      }
      open={currEvent}
      onClose={onClose}
      title={
        <Flex justify="flex-start" gap={12}>
          <Typography>{currEvent?.[selectedTab].code}</Typography>
          <Tag
            className={tagStyles.smRndTag}
            style={{
              fontWeight: "600",
              textAlign: "center",
            }}
            color={currStatus?.color}
          >
            {currStatus?.label}{" "}
          </Tag>
        </Flex>
      }
    >
      {currEvent?.length > 1 && (
        <ConfigProvider
          theme={{
            components: {
              Segmented: {
                itemSelectedBg: "#7369f4",
                itemSelectedColor: "#ffffff",
              },
            },
          }}
        >
          <Segmented
            id="date-wise-view"
            style={{ marginBottom: 20 }}
            options={currEvent?.map((option, index) => {
              return {
                value: index,
                label: dayjs(option?.occurrence_date).format("DD MMM"),
              };
            })}
            onChange={(value) => setSelectedTab(value)}
            value={selectedTab}
          />
        </ConfigProvider>
      )}
      <Form
        form={form}
        // initialValues={initialValues}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
      >
        <Form.Item label={"Title"} name="name">
          <Input
            disabled={true}
            style={{
              border: "0px",
              backgroundColor: "transparent",
              padding: "0px",
              fontSize: "16px",
            }}
          />
        </Form.Item>
        {pageType === "plView" ? (
          <Form.Item
            style={{
              width: "100%",
            }}
            label={"Production Line"}
            name={"production_line"}
          >
            {currEvent?.[selectedTab].production_line?._id ||
            currEvent?.[selectedTab].maintenance?.production_line?._id ? (
              <Select
                disabled={true}
                placeholder="Select production line"
                options={productionLines}
                className="selectLikeText"
              />
            ) : (
              "-"
            )}
          </Form.Item>
        ) : (
          <Form.Item
            style={{
              width: "100%",
            }}
            label={"Asset"}
            name={"asset"}
          >
            {currEvent?.[selectedTab].asset?._id ||
            currEvent?.[selectedTab].maintenance?.asset?._id ? (
              <Select
                disabled={true}
                placeholder="Select asset"
                options={assets}
                className="selectLikeText"
              />
            ) : (
              "-"
            )}
          </Form.Item>
        )}
        <Flex
          style={{
            width: "100%",
          }}
          gap={12}
        >
          <Form.Item
            style={{
              width: "100%",
            }}
            label="Start date"
            name={"start_date"}
          >
            <DatePicker
              disabled={true}
              className="dateLikeText"
              format="YYYY-MM-DD"
              placeholder=""
            />
          </Form.Item>
          <Form.Item
            style={{
              width: "100%",
            }}
            name={"end_date"}
            label="End date"
          >
            <DatePicker
              disabled={true}
              format="YYYY-MM-DD"
              className="dateLikeText"
              placeholder=""
            />
          </Form.Item>
        </Flex>
        <Flex gap={12}>
          <Form.Item
            style={{
              width: "50%",
            }}
            label={"Start time"}
            name={"start_time"}
          >
            <TimePicker
              format="hh:mm A"
              className="dateLikeText"
              placeholder=""
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            style={{
              width: "50%",
            }}
            label={"End time"}
            name={"end_time"}
          >
            <TimePicker
              format="hh:mm A"
              placeholder=""
              disabled={true}
              className="dateLikeText"
            />
          </Form.Item>
        </Flex>
        <Typography style={{ fontSize: "12px" }}>SOP</Typography>
        {currEvent?.[selectedTab]?.sop?._id ||
        currEvent?.[selectedTab]?.maintenance?.sop?._id ? (
          <a
            style={{ textDecoration: "none" }}
            className="hyperlink"
            href={`/procedures/${
              currEvent?.[selectedTab]?.sop?._id ||
              currEvent?.[selectedTab]?.maintenance?.sop?._id
            }`}
          >
            <span style={{ fontSize: "14px" }}>
              {currEvent?.[selectedTab]?.sop?.code ||
                currEvent?.[selectedTab]?.maintenance?.sop?.code}
            </span>
          </a>
        ) : (
          "-"
        )}
        <Form.Item
          style={{
            height: "0px",
            margin: "0px",
          }}
          name={"assigned_users"}
        ></Form.Item>
        <Form.Item
          style={{
            height: "0px",
            margin: "0px",
          }}
          name={"assigned_groups"}
        ></Form.Item>
        <Form.Item
          style={{
            width: "100%",
            marginTop: "20px",
          }}
          label={"Assign technician"}
          name={"assigned_list"}
        >
          <TreeSelect
            disabled={currEvent?.[selectedTab]?.status === "closed" && true}
            allowClear
            multiple={true}
            treeCheckable={true}
            className={inputStyles.smSelect}
            showSearch
            placeholder={"Assign technician"}
            treeData={technicians}
            style={{ width: "100%" }}
            showCheckedStrategy="SHOW_PARENT"
            filterTreeNode={(input, treeNode) => {
              const inputValue = input.toUpperCase();
              const title = treeNode.title.toUpperCase();
              const match = title.indexOf(inputValue) !== -1;

              return match;
            }}
            onSelect={(e, values) => {
              const id = values.value.split("-")[1];
              if (values?.group) {
                if (values?.children?.length === 0) {
                  message.info(
                    `There are no technicians present in "${values?.title}" group.`
                  );
                }
                form.setFieldsValue({
                  assigned_groups: [
                    ...(form.getFieldValue("assigned_groups") || []),
                    e,
                  ],
                });
              } else {
                form.setFieldsValue({
                  assigned_users: [
                    ...(form.getFieldValue("assigned_users") || []),
                    id || e,
                  ],
                });
              }
              // values?.group
              //   ? form.setFieldsValue({
              //       assigned_groups: [
              //         ...(form.getFieldValue("assigned_groups") || []),
              //         e,
              //       ],
              //     })
              //   : form.setFieldsValue({
              //       assigned_users: [
              //         ...(form.getFieldValue("assigned_users") || []),
              //         id || e,
              //       ],
              //     });
            }}
            onDeselect={(e, values) => {
              const id = values.value.split("-")[1];
              const updatedAssignedGroups = form
                .getFieldValue("assigned_groups")
                ?.filter((val) => val !== e);
              const updatedAssignedUsers = form
                .getFieldValue("assigned_users")
                ?.filter((val) => val !== (id || e));

              if (values?.group) {
                form.setFieldsValue({
                  assigned_groups: updatedAssignedGroups,
                });
              } else {
                form.setFieldsValue({
                  assigned_users: updatedAssignedUsers,
                });
              }
            }}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
