import {
  Empty,
  Result,
  Table,
  Tag,
  Typography,
  Col,
  Segmented,
  Popover,
  Input,
  DatePicker,
  Select,
  Skeleton,
  Pagination,
  Space,
  Button,
  Image,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Maintanance.css";

import {
  workOrderstatusList,
  capitalizeFirstLetter,
  priorityOptions,
  convertUtcToIst,
  debounce,
  convertUTCToLocalFormat,
  displayFilterTag,
} from "../../helpers/utility";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { UserContext } from "../../context/UserProvider";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { getAllJobsApi } from "../../services/maintenance.services";
import dayjs from "dayjs";
import {
  getAllAssetsApi,
  getProductionLines,
} from "../../services/assets.services";
// import MaintenanceSegmented from "./MaintenanceSegmented";
import { INSPECTION, PAGESIZE, SOP } from "../../constants/defaultKeys";
import PmFilters from "./PmFilters";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import filterActiveIcon from "../../assets/images/filterActiveIcon.svg";

const { RangePicker } = DatePicker;

const JobsTable = ({ viewType, setViewType, type }) => {
  const [jobs, setJobs] = useState(null);
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "month"),
    end_date: dayjs().add(1, "month"),
  });
  // const [assets, setAssets] = useState([]);
  const [search, setSearch] = useState(null);
  // const [selectedAsset, setSelectedAsset] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  // const [selectedProductionLine, setSelectedProductionLine] = useState(undefined);
  // const [selectedPmTypes, setSelectedPmTypes] = useState([]);
  // const [selectedPmPriority, setSelectedPmPriority] = useState([]);
  // const [selectedPmStatus, setSelectedPmStatus] = useState([]);
  // const [selectedPmVerify, setSelectedPmVerify] = useState(undefined);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    asset: undefined,
    production_line: undefined,
    types: [],
    priorities: [],
    statuses: [],
    verification: undefined,
  });
  const [assets, setAssets] = useState([]);
  const [productionLines, setProductionLines] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const fetchAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          value: asset?._id,
        }))
      );
    } catch (error) {
      setAssets([]);
    }
  };

  const fetchProductionLines = async () => {
    try {
      const res = await getProductionLines();
      setProductionLines(
        res?.data?.assets?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?._id,
        }))
      );
    } catch (error) {
      setProductionLines([]);
    }
  };

  useEffect(() => {
    fetchAssets();
    fetchProductionLines();
  }, []);

  // const getAssets = async () => {
  //   try {
  //     const resp = await getAllAssetsApi();
  //     setAssets(
  //       resp?.data?.assets?.map((asset) => ({
  //         label: asset?.name,
  //         value: asset?._id,
  //         image: asset?.file,
  //         status: asset?.status,
  //       }))
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   getAssets();
  // }, []);

  useEffect(() => {
    setSearch(null);
    // setSelectedAsset(null);
    setDateFilter({
      start_date: dayjs().subtract(1, "month"),
      end_date: dayjs().add(1, "month"),
    });
    setFilters({
      asset: undefined,
      production_line: undefined,
      types: [],
      priorities: [],
      statuses: [],
      verification: undefined,
    });
  }, [type]);

  const columns = [
    {
      title: (
        <Typography className={"tableHeader"}>
          {type == INSPECTION ? INSPECTION : "PM "} code
        </Typography>
      ),
      dataIndex: "code",
      key: "code",
      width: 140,
      fixed: "left",
      render: (val, record) => <>{record?.maintenance?.code || "-"}</>,
    },
    {
      title: <Typography className={"tableHeader"}>Job date</Typography>,
      dataIndex: "occurrence_date",
      key: "occurrence_date",
      width: 140,
      render: (val, record) => (
        <>
          {record?.occurrence_date
            ? dayjs(convertUTCToLocalFormat(record?.occurrence_date)).format(
                "DD MMM YYYY"
              )
            : // ? convertUtcToIst(record?.occurrence_date, false)
              "-"}
        </>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>Title</Typography>,
      dataIndex: "name",
      key: "name",
      width: 250,
      render: (val, record) => <>{record?.maintenance?.name || "-"}</>,
    },
    {
      title: <Typography className={"tableHeader"}>Type</Typography>,
      dataIndex: "sub_type",
      key: "sub_type",
      width: 140,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // filters: [
      //   {
      //     label: "Mechanical",
      //     text: "Mechanical",
      //     value: "Mechanical",
      //   },
      //   {
      //     label: "Electrical",
      //     text: "Electrical",
      //     value: "Electrical",
      //   },
      //   {
      //     label: "Others",
      //     text: "Others",
      //     value: "Others",
      //   },
      // ],
      // onFilter: (value, record) =>
      //   record?.maintenance?.sub_type?.startsWith(value),
      // filterSearch: true,
      render: (val, record) => <>{record?.maintenance?.sub_type || "-"}</>,
    },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    // },
    {
      title: (
        <Typography className={"tableHeader"}>
          Asset/ Production line
          {/* {type == INSPECTION ? "Asset" : "Asset/ Production line"} */}
        </Typography>
      ),
      dataIndex: "asset",
      key: "asset",
      width: 250,
      sorter: (a, b) =>
        (
          a?.maintenance?.asset?.name ||
          a?.maintenance?.production_line?.name ||
          ""
        )?.localeCompare(
          b?.maintenance?.asset?.name ||
            b?.maintenance?.production_line?.name ||
            ""
        ),
      // render: (val, record) => (
      //   <>
      //     {type == INSPECTION
      //       ? record?.maintenance?.asset?.name || "-"
      //       : record?.maintenance?.asset
      //       ? record?.maintenance?.asset?.name || "-"
      //       : record?.maintenance?.production_line?.name || "-"}
      //   </>
      // ),
      render: (val, record) => (
        <>
          {record?.maintenance?.asset?.name ||
            record?.maintenance?.production_line?.name ||
            "-"}
        </>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>Location</Typography>,
      dataIndex: "site",
      key: "site",
      width: 200,
      render: (val, record) => (
        <>
          {type == INSPECTION
            ? record?.maintenance?.asset?.location?.name || "-"
            : record?.maintenance?.asset
            ? record?.maintenance?.asset?.location?.name || "-"
            : record?.maintenance?.production_line?.location?.name || "-"}
        </>
      ),
      // render: (val, record) => (
      //   <>{record?.maintenance?.asset?.location?.name || "-"}</>
      // ),
    },
    {
      title: <Typography className={"tableHeader"}>Priority</Typography>,
      dataIndex: "priority",
      key: "priority",
      width: 140,
      // filters: priorityOptions,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // onFilter: (value, record) => record?.priority?.startsWith(value),
      // filterSearch: true,
      render: (val, record) => {
        const color =
          priorityOptions.filter((pri) => pri.value === val)[0]?.color ||
          "blue";
        return (
          <Tag
            style={{ minWidth: "80px", border: "0px" }}
            className={tagStyles.smTag}
            color={color}
          >
            {capitalizeFirstLetter(val) || "-"}
          </Tag>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Assigned to</Typography>,
      dataIndex: "assigned_users",
      key: "assigned_users",
      width: 200,
      render: (val, record) => {
        const list = [
          ...(record?.assigned_users || []),
          ...(record?.assigned_groups || []),
        ];

        return (
          <>
            {list?.length > 0 ? (
              <div
                className="flex-center"
                style={{
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                {list?.slice(0, 2)?.map((group, index) => (
                  <Typography key={index}>
                    {group?.first_name || group?.name}
                    {list?.length > 1 ? "," : ""}
                  </Typography>
                ))}
                {list?.length > 2 && (
                  <Popover
                    content={
                      <div
                        className="flex-center"
                        style={{
                          flexDirection: "column",
                          gap: "6px",
                          alignItems: "flex-start",
                        }}
                      >
                        {list?.map((group, index) => (
                          <Typography key={index}>
                            {group?.first_name || group?.name}
                          </Typography>
                        ))}
                      </div>
                    }
                  >
                    <Tag>+{list?.length - 2}</Tag>
                  </Popover>
                )}
              </div>
            ) : (
              <div
                className="flex-center"
                style={{
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                -
              </div>
            )}
          </>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Status</Typography>,
      dataIndex: "status",
      key: "status",
      width: 140,
      // filters: workOrderstatusList,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // onFilter: (value, record) => record?.status?.startsWith(value),
      // filterSearch: true,
      render: (val, record) => {
        const value = workOrderstatusList?.filter(
          (status) => status?.value == val
        )?.[0];
        return (
          <>
            {value?.label ? (
              <Tag
                className={tagStyles.smTag}
                style={{
                  // fontWeight: "600",
                  minWidth: "80px",
                  textAlign: "left",
                }}
                color={value?.color}
              >
                {value?.label}{" "}
              </Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Verification</Typography>,
      width: 140,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      dataIndex: "mark_as_verified",
      key: "mark_as_verified",
      // filters: [
      //   {
      //     label: "Not verified",
      //     text: "Not verified",
      //     value: false,
      //   },
      //   {
      //     value: true,
      //     label: "Verified",
      //     text: "Verified",
      //   },
      // ],
      // onFilter: (value, record) => record?.mark_as_verified == value,
      // filterSearch: true,
      render: (val, record) => (
        <div className="flex-center" style={{ justifyContent: "flex-start" }}>
          <Tag
            className={tagStyles.smTag}
            color={val ? "success" : "error"}
            style={{ minWidth: "90px", border: "0px" }}
          >
            {val ? "Verified" : "Not verified"}
          </Tag>
        </div>
      ),
    },
  ];

  // const onChange = (pagination, filters, sorter, extra) => {
  //   console.log("params", pagination, filters, sorter, extra);
  // };

  const getAllJobs = async () => {
    let params = { page: pageNumber };
    if (search) {
      params.name = search;
    }
    if (type) {
      params.type = type;
    }
    // if (selectedAsset) {
    //   params.asset = selectedAsset;
    // }
    if (dateFilter?.start_date) {
      params.start_date = dayjs(dateFilter?.start_date).format("YYYY-MM-DD");
    }
    if (dateFilter?.end_date) {
      params.end_date = dayjs(dateFilter?.end_date).format("YYYY-MM-DD");
    }
    filters?.asset && (params.asset = filters?.asset);
    filters?.production_line &&
      (params.production_line = filters?.production_line);
    filters?.types?.length > 0 && (params.sub_type = filters?.types);
    filters?.priorities?.length > 0 && (params.priority = filters?.priorities);
    filters?.statuses?.length > 0 && (params.status = filters?.statuses);
    filters?.verification &&
      (params.mark_as_verified =
        filters?.verification === "verified" ? true : false);

    // selectedProductionLine && (params.production_line = selectedProductionLine);
    // selectedPmTypes?.length > 0 && (params.sub_type = selectedPmTypes);
    // selectedPmPriority?.length > 0 && (params.priority = selectedPmPriority);
    // selectedPmStatus?.length > 0 && (params.status = selectedPmStatus);
    // selectedPmVerify &&
    //   (params.mark_as_verified =
    //     selectedPmVerify === "verified" ? true : false);
    // const queryString = new URLSearchParams(params);
    try {
      setLoading(true);
      const resp = await getAllJobsApi(params);
      setJobs(resp?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  useEffect(() => {
    viewType == "jobs" && getAllJobs();
  }, [
    viewType,
    userDetails?.currFacility,
    // dateFilter,
    // search,
    // selectedAsset,
    type,
    pageNumber,
    refresh,
  ]);

  // useEffect(() => {
  //   setCount((prev) => ({ ...prev, jobs: jobs?.length }));
  // }, [jobs]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [
    search,
    dateFilter,
    filters,
    // selectedAsset,
    // selectedProductionLine,
    // selectedPmTypes,
    // selectedPmPriority,
    // selectedPmStatus,
    // selectedPmVerify,
  ]);

  return (
    <>
      {/* <Col span={24}>
        <MaintenanceSegmented viewType={viewType} type={type} />
      </Col> */}
      <Col span={10}>
        <Space style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}>
          <Button
            style={{
              color: "#6941C6",
              // backgroundColor: "#fff",
              // borderColor: "#E9D7FE",
            }}
            className={btnStyles.smBtn}
            type="default"
            icon={
              <Image
                src={filterActiveIcon}
                width={18}
                height={18}
                alt="filter"
                preview={false}
              />
            }
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </Button>
        </Space>
        {showFilterModal && (
          <PmFilters
            type={type === INSPECTION ? "Inspection" : "PM"}
            viewType={"job"}
            showFilterModal={showFilterModal}
            setShowFilterModal={setShowFilterModal}
            assets={assets}
            productionLines={productionLines}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </Col>
      <Col offset={4} span={10} style={{ textAlign: "right" }}>
        <RangePicker
          id="pm-jobs-filter"
          format="DD-MMM-YYYY"
          defaultValue={[dateFilter.start_date, dateFilter.end_date]}
          onChange={(_, dateString) => {
            setDateFilter({
              start_date: dateString[0],
              end_date: dateString[1],
            });
          }}
        />
      </Col>
      {(filters?.asset ||
        filters?.production_line ||
        filters?.types?.length > 0 ||
        filters?.priorities?.length > 0 ||
        filters?.statuses?.length > 0 ||
        filters?.verification) && (
        <Col
          span={24}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 6,
            flexWrap: "wrap",
          }}
        >
          {filters?.asset && displayFilterTag("Asset", "purple")}
          {filters?.production_line &&
            displayFilterTag("Production Line", "purple")}
          {filters?.types?.length > 0 &&
            displayFilterTag(
              `${type === INSPECTION ? "Inspection" : "PM"} Type`,
              "volcano",
              filters?.types?.length
            )}
          {filters?.priorities?.length > 0 &&
            displayFilterTag(
              "Priority",
              "magenta",
              filters?.priorities?.length
            )}
          {filters?.statuses?.length > 0 &&
            displayFilterTag(
              `${type === INSPECTION ? "Inspection" : "PM"} Status`,
              "blue",
              filters?.statuses?.length
            )}
          {filters?.verification &&
            displayFilterTag(
              `${type === INSPECTION ? "Inspection" : "PM"} Verification`,
              "orange"
            )}
          <Typography
            style={{ color: "#7369F4", cursor: "pointer", fontSize: 12 }}
            onClick={() =>
              setFilters({
                asset: undefined,
                production_line: undefined,
                types: [],
                priorities: [],
                statuses: [],
                verification: undefined,
              })
            }
          >
            Clear All
          </Typography>
        </Col>
      )}

      {/* <Col
        span={16}
        style={{
          textAlign: "right",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Select
          placeholder="Select asset"
          style={{
            textAlign: "left",
            width: "280px",
          }}
          allowClear
          value={selectedAsset}
          showSearch={true}
          optionFilterProp="label"
          // className={inputStyles.smSelect}
          onChange={(e) => setSelectedAsset(e)}
          options={assets}
        />
        <Input.Search
          size="medium"
          // value={search}
          onChange={debounce(handleSearch, 500)}
          placeholder={`Search by ${
            type == INSPECTION ? INSPECTION : "PM"
          } code`}
          style={{ width: "280px" }}
        />
        <div id="pm-jobs-filter">
          <RangePicker
            // defaultValue={[dateFilter.start_date, dateFilter.end_date]}
            value={[dayjs(dateFilter?.start_date), dayjs(dateFilter?.end_date)]}
            format="DD-MMM-YYYY"
            onChange={(_, dateString) => {
              setDateFilter({
                start_date: dateString[0],
                end_date: dateString[1],
              });
            }}
          />
        </div>
      </Col> */}
      {/* <PmFilters
        viewType={"job"}
        selectedAsset={selectedAsset}
        setSelectedAsset={setSelectedAsset}
        selectedProductionLine={selectedProductionLine}
        setSelectedProductionLine={setSelectedProductionLine}
        selectedPmTypes={selectedPmTypes}
        setSelectedPmTypes={setSelectedPmTypes}
        selectedPmPriority={selectedPmPriority}
        setSelectedPmPriority={setSelectedPmPriority}
        selectedPmStatus={selectedPmStatus}
        setSelectedPmStatus={setSelectedPmStatus}
        selectedPmVerify={selectedPmVerify}
        setSelectedPmVerify={setSelectedPmVerify}
      /> */}
      <Col span={24}>
        <Table
          title={() => (
            <Space
              align="center"
              style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
            >
              <Input.Search
                size="large"
                onChange={debounce(handleSearch, 500)}
                placeholder={`Search by ${
                  type == INSPECTION ? INSPECTION : "PM"
                } code`}
                prefix={
                  <SearchOutlined style={{ fontSize: 16, color: "#667085" }} />
                }
                style={{ width: "300px" }}
                className="custom-input-search"
              />
            </Space>
          )}
          key={`${type}_jobs`}
          scroll={{
            x: 1200,
            y: "60vh",
          }}
          onRow={(item) => {
            return {
              onClick: (e) => {
                !item?.children?.length > 0 &&
                  navigate(
                    `/${type == INSPECTION ? "inspections" : "maintenances"}/${
                      item?._id
                    }/job`
                  );
              },
            };
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No Jobs's found</Typography>}
              />
            ),
          }}
          rowClassName={"rowClassName"}
          bordered
          columns={columns}
          dataSource={jobs?.occurrences || []}
          // onChange={onChange}
          pagination={false}
        />
      </Col>
      {jobs?.totalOccurrences > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={jobs?.totalOccurrences}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </>
  );
};

export default JobsTable;
