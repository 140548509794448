import { PictureOutlined, UploadOutlined } from "@ant-design/icons";
import { Popover, Upload, message } from "antd";
import React, { useContext, useState } from "react";
import { uploadFunc } from "../../../../helpers/utility";
import { UserContext } from "../../../../context/UserProvider";
import Compressor from "compressorjs";

const UploadSectionDocs = ({ sopContent, setSopContent, questionData }) => {
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const OneMB = 1024 * 1024;
  const uploadFileType = [
    "image/jpeg", // JPG and JPEG
    "image/png", // PNG
    "image/gif", // GIF
    "image/bmp", // BMP
    "image/webp", // WEBP
    "image/svg+xml", // SVG
  ];
  const props = {
    name: "file",
    multiple: false,
    beforeUpload(file) {
      // console.log(file.size);
      return new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.8, // Adjust quality between 0 and 1
          convertSize: 1000000,
          success(compressedFile) {
            // console.log(compressedFile, "cf");
            resolve(compressedFile); // Resolve with the compressed file
          },
          error(err) {
            console.error("Compression failed:", err.message);
          },
        });
      });
    },
    // beforeUpload(file) {
    //   const fileSize = file.size / OneMB;
    //   if (fileSize > 100) {
    //     message.error(`File too large ${fileSize?.toFixed(0)} mb, max 100mb`);
    //     return Upload.LIST_IGNORE;
    //   }
    //   if (!uploadFileType.includes(file.type)) {
    //     message.error("Cannot upload this file");
    //     return Upload.LIST_IGNORE;
    //   }
    //   return true;
    // },
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const customOnChange = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      uploadImage(info);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const uploadImage = async (info, key) => {
    const resp = await uploadFunc(info, storageType);
    if (resp) {
      if (questionData.id) {
        setSopContent((prevSopContent) =>
          prevSopContent.map((eachSOP) =>
            eachSOP.id === questionData.parentId
              ? {
                  ...eachSOP,
                  procedures: eachSOP.procedures.map((eachQ) =>
                    eachQ.id === questionData.id
                      ? {
                          ...eachQ,
                          images: [...(eachQ?.images || []), resp?.path],
                        }
                      : eachQ
                  ),
                }
              : eachSOP
          )
        );
      }
    }
  };

  return (
    <>
      {questionData?.images?.length > 1 ? (
        <Popover content="Maximum 2 images can be uploaded">
          {" "}
          <PictureOutlined />
        </Popover>
      ) : (
        <Upload
          showUploadList={false}
          maxCount={4}
          accept="image/*"
          className="assetsUpload"
          {...props}
          onChange={customOnChange}
          customRequest={dummyRequest}
        >
          <PictureOutlined />
        </Upload>
      )}
    </>
  );
};

export default UploadSectionDocs;
