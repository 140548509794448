import { Form } from "antd";
import React, { useContext } from "react";
import "../../Assets/Assets.css";

import CommonUpload from "../../Blocks/CommonUpload";
import { deleteDocs } from "../../../helpers/utility";
import { UserContext } from "../../../context/UserProvider";

const WODocuments = ({ form, woDocuments, setWoDocuments, edit }) => {
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const onUpload = async (resp) => {
    if (!resp) return;

    const updatedFiles = [...(woDocuments || []), resp?.path];
    await form.setFieldValue("files", updatedFiles);
    setWoDocuments(updatedFiles);
  };

  const onDelete = (file) => {
    deleteDocs(file?.name, storageType);
    form.setFieldValue(
      "files",
      woDocuments?.filter((id) => id != file?.name)
    );
    setWoDocuments(woDocuments?.filter((id) => id != file?.name));
    // deleteDocs(file?.name, storageType);
  };

  return (
    <>
      <Form.Item
        name={"files"}
        style={{ margin: "0px", height: "0px" }}
      ></Form.Item>
      <Form.Item label="" style={{ width: "100%" }}>
        <CommonUpload
          documents={woDocuments}
          onUpload={onUpload}
          dragger={false}
          disabled={!edit}
          onDelete={onDelete}
        />
      </Form.Item>
    </>
  );
};

export default WODocuments;
