import {
  CloudUploadOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Space,
  Form,
  Input,
  Upload,
  message,
  Flex,
  Image,
  Collapse,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import {
  getAssetsPath,
  getEncodedImage,
  uploadFunc,
} from "../../../helpers/utility";
import { updateAssetByIdApi } from "../../../services/assets.services";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
import { ONPREMISE } from "../../../constants/defaultKeys";
import Compressor from "compressorjs";
const { Dragger } = Upload;

const Parts = ({ form, edit }) => {
  const params = useParams();
  const [formStates, setFormStates] = useState([]);

  const OneMB = 1024 * 1024;
  const props = {
    name: "file",
    multiple: false,
    // beforeUpload(file) {
    //   const fileSize = file.size / OneMB;
    //   console.log(file?.type, ["image/*"].includes(file.type));
    //   if (fileSize > 100) {
    //     message.error(`File too large ${fileSize?.toFixed(0)} mb`);

    //     return Upload.LIST_IGNORE;
    //   }
    //   if (!file.type.startsWith("image/")) {
    //     message.error("Cannot upload this file");

    //     return Upload.LIST_IGNORE;
    //   }
    //   return true;
    // },
    beforeUpload(file) {
      // console.log(file.size);
      return new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.8, // Adjust quality between 0 and 1
          convertSize: 1000000,
          success(compressedFile) {
            // console.log(compressedFile, "cf");
            resolve(compressedFile); // Resolve with the compressed file
          },
          error(err) {
            console.error("Compression failed:", err.message);
          },
        });
      });
    },
  };

  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const uploadImage = async (info, name) => {
    const resp = await uploadFunc(info, storageType);
    if (resp) {
      await form.setFieldValue(
        ["parts", name, "files"],
        resp?.path ? [resp?.path] : []
      );
    }
    return resp?.path || "";
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const updateAsset = async ({ _id, ...data }) => {
    try {
      const resp = await updateAssetByIdApi(params?.id, {
        parts: [{ ...data, partId: _id, status: "remove" }],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setAssetImage = (index, imgPath) => {
    setFormStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = imgPath;
      return updatedStates;
    });
  };

  useEffect(() => {
    const apiData = form.getFieldValue("parts");
    const initialFormStates =
      apiData?.map((part) => part?.files?.[0] || null) || [];
    setFormStates(initialFormStates);
  }, [form.getFieldValue("parts")]);
  function removeElementAtIndex(array, index) {
    if (index > -1 && index < array.length) {
      array.splice(index, 1);
    }
    return array;
  }

  return (
    <Card
      title="Parts"
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Form.List name="parts">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => {
              const assetImage = formStates[index];
              return (
                <Space
                  key={key}
                  style={{
                    display: "flex",
                    borderBottom: "1px dashed grey",
                    marginBottom: 24,
                  }}
                  direction="vertical"
                  // align="baseline"
                >
                  <Collapse
                    size="small"
                    bordered={false}
                    expandIcon={({ isActive }) =>
                      !isActive ? (
                        <PlusCircleOutlined style={{ fontSize: "20px" }} />
                      ) : (
                        <MinusCircleOutlined style={{ fontSize: "20px" }} />
                      )
                    }
                    items={[
                      {
                        key: "1",
                        label: (
                          <Flex gap={12} align="flex-start">
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing part name",
                                },
                                {
                                  whitespace: true,
                                  message:
                                    "Part name cannot be empty or contain only spaces",
                                },
                              ]}
                              style={{ width: "96%" }}
                            >
                              <Input
                                // onChange={(e) => {
                                //   form.setFieldValue(["parts", name, "status"], "add");
                                // }}s

                                style={{ width: "100%" }}
                                className={inputStyles.smInput}
                                placeholder="Part name"
                              />
                            </Form.Item>
                            {edit && (
                              <DeleteOutlined
                                style={{
                                  fontSize: "24px",
                                  width: "2%",
                                  justifySelf: "center",
                                }}
                                onClick={(e) => {
                                  if (edit) {
                                    const id = form.getFieldValue([
                                      "parts",
                                      name,
                                    ])?._id;
                                    id &&
                                      updateAsset(
                                        form.getFieldValue(["parts", name])
                                      );
                                    remove(name);
                                    removeElementAtIndex(formStates, index);
                                  } else {
                                  }
                                }}
                              />
                            )}
                          </Flex>
                        ),
                        children: (
                          <>
                            <Flex
                              gap={24}
                              style={{
                                width: "calc(98% - 34px)",
                                marginLeft: "34px",
                              }}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "vendor"]}
                                style={{ width: "50%" }}
                              >
                                <Input
                                  className={inputStyles.smInput}
                                  placeholder="Vendor"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                              <Form.Item
                                rules={[
                                  {
                                    pattern: /^[0-9]{10}$/,
                                    message:
                                      "Please enter a valid 10-digit phone number",
                                  },
                                ]}
                                style={{ width: "50%" }}
                                {...restField}
                                name={[name, "vendor_contact"]}
                              >
                                <Input
                                  style={{ width: "100%" }}
                                  className={inputStyles.smInput}
                                  placeholder="Vendor contact"
                                />
                              </Form.Item>
                            </Flex>
                            <div
                              style={{
                                width: "calc(98% - 34px)",
                                marginLeft: "34px",
                                display: "flex",
                                gap: "12px",
                              }}
                            >
                              <Form.Item
                                style={{ width: "50%" }}
                                {...restField}
                                name={[name, "description"]}
                              >
                                <Input.TextArea
                                  style={{ width: "100%" }}
                                  rows={5}
                                  className={inputStyles.smInput}
                                  placeholder="Description"
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "files"]}
                                style={{
                                  height: "0px",
                                  width: "0px",
                                  margin: "0px",
                                }}
                              >
                                <div></div>
                              </Form.Item>

                              <Form.Item
                                style={{ width: "50%" }}
                                {...restField}
                              >
                                <div>
                                  {assetImage ? (
                                    <Image
                                      style={{
                                        height: "120px",
                                        width: "120px",
                                      }}
                                      src={getAssetsPath(
                                        assetImage,
                                        storageType
                                      )}
                                      // src={getAssetsPath(
                                      //   storageType == ONPREMISE
                                      //     ? getEncodedImage(assetImage)
                                      //     : assetImage,
                                      //   storageType
                                      // )}
                                      alt="Part Image"
                                    />
                                  ) : (
                                    <Dragger
                                      customRequest={dummyRequest}
                                      {...props}
                                      onChange={async (info) => {
                                        const { status } = info.file;
                                        if (status !== "uploading") {
                                          const imgPath = await uploadImage(
                                            info,
                                            name
                                          );
                                          setAssetImage(index, imgPath);
                                        }
                                        if (status === "done") {
                                          message.success(
                                            `${info.file.name} file uploaded successfully.`
                                          );
                                        } else if (status === "error") {
                                          message.error(
                                            `${info.file.name} file upload failed.`
                                          );
                                        }
                                      }}
                                      accept="image/*"
                                      maxCount={1}
                                      style={{
                                        backgroundColor: "#fff",
                                        border: "1px solid #EAECF0",
                                      }}
                                    >
                                      <CloudUploadOutlined
                                        style={{ fontSize: 30 }}
                                      />
                                      <p className="ant-upload-text">
                                        Click or drag file to this area to
                                        upload
                                      </p>
                                      <p className="ant-upload-hint">
                                        Asset Images
                                        {/* <br />
                                        JPG (max. 800x400px) */}
                                      </p>
                                    </Dragger>
                                  )}
                                </div>
                              </Form.Item>
                            </div>
                          </>
                        ),
                      },
                    ]}
                  />
                </Space>
              );
            })}
            <Form.Item>
              <Button
                type="primary"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add part
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Card>
  );
};

export default Parts;
