import { Button, Flex, message, Popconfirm, Select, Typography } from "antd";
import React, { useContext } from "react";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";

import { useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined, EditOutlined, LeftOutlined } from "@ant-design/icons";
import { deleteSOP, editSOP, INSPECTION } from "../../../constants/defaultKeys";
import {
  capitalizeFirstLetter,
  deleteCond,
  editCond,
} from "../../../helpers/utility";
import { UserContext } from "../../../context/UserProvider";
import { deleteSOPApi } from "../../../services/sops.services";
import TypingIndicator from "./TypingIndicator";

const Header = ({
  contentType,
  sopData,
  setIsEditOpen,
  isEditOpen,
  setLanguage,
}) => {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const params = useParams();

  const deleteSOPFUN = async () => {
    try {
      const resp = await deleteSOPApi(params?.id);
      if (resp) {
        message.success(resp?.data?.message);
        navigate(`/procedures?type=${sopData?.type}`);
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <Flex justify="space-between" align="center">
      <Typography className={typoStyles.h6}>
        <LeftOutlined
          style={{ marginRight: "6px" }}
          onClick={() => navigate(`/procedures?type=${sopData?.type}`)}
        />
        {sopData?.code}
      </Typography>
      {!isEditOpen && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Select
            style={{ minWidth: "200px" }}
            defaultValue="english"
            onSelect={(e) => setLanguage(e)}
            className={inputStyles.smSelect}
          >
            {sopData?.language?.map((each) => {
              const isDisabled =
                each !== "english" && !sopData?.languages_converted;
              return (
                <Option key={each} value={each} disabled={isDisabled}>
                  {isDisabled ? (
                    <div
                      className="flex-center"
                      style={{
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        gap: "6px",
                      }}
                    >
                      Translating in {capitalizeFirstLetter(each)}{" "}
                      <TypingIndicator />
                    </div>
                  ) : (
                    capitalizeFirstLetter(each)
                  )}
                </Option>
              );
            })}
          </Select>
          {deleteCond(
            deleteSOP,
            sopData?.creator,
            userDetails?.activeOrg?.role,
            userDetails?._id
          ) && (
            <Popconfirm
              icon={null}
              description={`Are you sure to delete this ${contentType}?`}
              onConfirm={(e) => {
                // deletAssetFunc();
                deleteSOPFUN();
              }}
            >
              <Button className={btnStyles.mdBtn}>
                <span className="hiddenBelowTab">Delete</span>
                <DeleteOutlined style={{ margin: "0px" }} />
              </Button>
            </Popconfirm>
          )}
          {editCond(
            editSOP,
            sopData?.creator,
            userDetails?.activeOrg?.role,
            userDetails?._id
          ) && (
            <Button
              className={btnStyles.mdBtn}
              onClick={() => {
                setLanguage("english");
                setIsEditOpen((prev) => !prev);
              }}
              type="primary"
            >
              <span className="hiddenBelowTab">Edit</span>
              <EditOutlined style={{ margin: "0px" }} />
            </Button>
          )}
        </div>
      )}
    </Flex>
  );
};

export default Header;
