import {
  Button,
  Col,
  Row,
  Segmented,
  message,
  Typography,
  Image,
  Skeleton,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CourseRender } from "../Helpers";
import ShareCourse from "./ShareCourse";
import { getALlCoursesApi } from "../../../services/courses.services";

import segmentedStyles from "../../../assets/styles/Custom/Segmented.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { UserContext } from "../../../context/UserProvider";
import { createWO } from "../../../constants/defaultKeys";
import noCourses from "../../../assets/images/tyuts/nocourses.svg";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import TyutCourseTagSearch from "../TyutsSearch";
import CustomCard from "../../Blocks/CustomCard";

const TrainingsTable = ({ view }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [shareCourse, setShareCourse] = useState({
    share: false,
    courseId: null,
    creator_id: null,
  });
  const { userDetails } = useContext(UserContext);

  useEffect(() => {
    getCourses();
  }, []);

  async function getCourses() {
    try {
      setLoading(true);
      const res = await getALlCoursesApi();
      setData(res.data);
    } catch (errors) {
      message.error(errors.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Segmented
          className={segmentedStyles.basicOne}
          options={[
            { value: "tyut", label: "Tyuts" },
            { value: "course", label: "Courses" },
          ]}
          onChange={(e) => navigate(`/trainings?type=${e}`)}
          value={view}
        />
      </Col>
      <Col
        span={12}
        className="flex-center"
        style={{ justifyContent: "flex-end" }}
      >
        <div style={{ width: "50%" }}>
          <TyutCourseTagSearch />
        </div>
        {createWO.includes(userDetails?.activeOrg?.role) && (
          <Button
            onClick={() => navigate("/trainings/course")}
            className={btnStyles.mdBtn}
            type="primary"
          >
            + Add Course
          </Button>
        )}
      </Col>
      {loading ? (
        <>
          {[...Array(4)].map((_, index) => (
            <Col
              xl={{ span: 6 }}
              lg={{ span: 6 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
              key={index}
            >
              <CustomCard style={{ minHeight: "150px" }}>
                <Skeleton.Image active />
                <Skeleton active />
              </CustomCard>
            </Col>
          ))}
        </>
      ) : data?.length > 0 ? (
        <CourseRender
          data={data}
          loading={loading}
          setShareCourse={setShareCourse}
          refresh={() => setRefresh((prev) => !prev)}
        />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // alignItems: "center",
              width: "100%",
              minHeight: "100%",
            }}
          >
            <Image
              alt="No courses found"
              width={342}
              height={342}
              preview={false}
              src={noCourses}
            />
            <Typography
              className={typoStyles.h6}
              style={{ textAlign: "center" }}
            >
              No courses found
            </Typography>
          </div>
        </>
      )}

      {shareCourse.share && (
        <ShareCourse
          creator_id={shareCourse?.creator_id}
          setShareCourse={setShareCourse}
          shareCourse={shareCourse}
        />
      )}
    </Row>
  );
};

export default TrainingsTable;
