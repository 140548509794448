import {
  Button,
  Col,
  Flex,
  Form,
  Popconfirm,
  Row,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import GeneralDetails from "./GeneralDetails";
import AssetDetails from "./AssetDetails";
import CustomLayout from "../../Layout";
import { DeleteOutlined, EditOutlined, LeftOutlined } from "@ant-design/icons";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Parts from "./Parts";
import WarrantyDetails from "./WarrantyDetails";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import Maintenance from "./Maintenance";
import AssetDocuments from "./AssetDocuments";
import { createAsset, getAsset, handleDynamicValidations } from "./helper";
import { deleteAssetByIdApi } from "../../../services/assets.services";
import { deleteAsset, editAsset } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserProvider";
import AssetDownTime from "./AssetDownTime";
import AuditLog from "./AuditLog";
import SensorData from "./SensorData";
import SparePartHistory from "./SparePartHistory";
import { deleteCond, editCond } from "../../../helpers/utility";

const CreateAssetForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { userDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [assetImage, setAssetImage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [documents, setDocuments] = useState({
    warranty: [],
    maintenance: [],
    other_docs: [],
    pid_drawings: [],
    asset_3d_model: [],
    product_manuals: [],
  });
  const [assetDetails, setAssetDetails] = useState({});
  const [assetDownTimeID, setAssetDownTimeID] = useState("");
  const [searchParams, _] = useSearchParams();

  const { source, pageType, page, searchVal } = location.state || {};

  useEffect(() => {
    params?.id
      ? getAsset(
          params?.id,
          setAssetImage,
          setDocuments,
          documents,
          form,
          navigate,
          userDetails?.activeOrg?.organization?.settings?.storage,
          assetDownTimeID,
          setAssetDetails
        )
      : setEdit(true);
  }, [refresh]);

  const deletAssetFunc = async () => {
    try {
      const resp = await deleteAssetByIdApi(params?.id);
      resp && navigate(`/assets?type=${searchParams.get("type")}`);
      message.success(resp?.data?.message);
    } catch (error) {
      console.log(error);
      message.error(error?.repsonse?.data?.message);
    }
  };

  return (
    <CustomLayout
      header={
        <>
          {!params?.id ? (
            <Typography className={typoStyles.h6}>
              <LeftOutlined
                style={{ marginRight: "6px" }}
                onClick={() => navigate("/assets")}
              />
              {params?.id ? "Update Asset" : "Create Asset"}
            </Typography>
          ) : (
            <Flex justify="space-between" align="center">
              <Typography className={typoStyles.h6}>
                {" "}
                <LeftOutlined
                  style={{ marginRight: "6px" }}
                  // onClick={() => navigate(-1)}
                  onClick={() => {
                    // let typeValue = searchParams.get("type");
                    if (source) {
                      navigate(source);
                    } else {
                      if (pageType === "table") {
                        navigate(`/assets?type=table`, {
                          state: {
                            page: page,
                            searchVal: searchVal,
                            pageType: pageType,
                          },
                        });
                      } else if (pageType === "tree") {
                        navigate(`/assets?type=tree`, {
                          state: { searchVal: searchVal, pageType: pageType },
                        });
                      }
                    }
                    // navigate(`/assets?type=${searchParams.get("type")}`)
                  }}
                />
                {`Asset-${assetDetails?.code}` || "Update Asset"}
              </Typography>
              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                {deleteCond(
                  deleteAsset,
                  assetDetails?.creator,
                  userDetails?.activeOrg?.role,
                  userDetails?._id
                ) && (
                  // {deleteAsset?.includes(userDetails?.activeOrg?.role) && (
                  <Popconfirm
                    icon={null}
                    description="Are you sure to delete this asset?"
                    onConfirm={(e) => {
                      deletAssetFunc();
                    }}
                  >
                    <Button className={btnStyles.mdBtn}>
                      <span className="hiddenBelowTab">Delete</span>
                      <DeleteOutlined style={{ margin: "0px" }} />
                    </Button>
                  </Popconfirm>
                )}
                {/* {!edit && editAsset?.includes(userDetails?.activeOrg?.role) && ( */}
                {!edit &&
                  editCond(
                    editAsset,
                    assetDetails?.creator,
                    userDetails?.activeOrg?.role,
                    userDetails?._id
                  ) && (
                    <Button
                      className={btnStyles.mdBtn}
                      onClick={() => setEdit(true)}
                      type="primary"
                    >
                      <span className="hiddenBelowTab">Edit</span>
                      <EditOutlined style={{ margin: "0px" }} />
                    </Button>
                  )}
              </div>
            </Flex>
          )}
        </>
      }
      footer={
        <>
          {(!params?.id || (params?.id && edit)) && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (params?.id) {
                    getAsset(
                      params?.id,
                      setAssetImage,
                      setDocuments,
                      documents,
                      form,
                      navigate,
                      userDetails?.activeOrg?.organization?.settings?.storage,
                      assetDownTimeID,
                      setAssetDetails
                    );
                    setEdit(false);
                  } else {
                    setDocuments({
                      warranty: [],
                      maintenance: [],
                      other_docs: [],
                      pid_drawings: [],
                      asset_3d_model: [],
                      product_manuals: [],
                    });
                    form.resetFields();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                onClick={form.submit}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <Form
        onValuesChange={(changedValues, values) =>
          handleDynamicValidations(changedValues, values, form)
        }
        layout="vertical"
        requiredMark={false}
        onFinish={(values) => {
          const errors = form.getFieldsError();
          let hasError = false;

          for (const field in errors) {
            if (errors[field].errors.length > 0) {
              hasError = true;
              break; // Stop iterating if an error is found
            }
          }

          if (!hasError) {
            // Submit the form if no errors are found
            createAsset(
              values,
              documents,
              params?.id,
              setEdit,
              navigate,
              setAssetImage,
              setDocuments,
              form,
              userDetails?.activeOrg?.organization?.settings?.storage,
              assetDownTimeID,
              setRefresh,
              assetDetails
            );
          } else {
            // Display error messages to the user
            console.error("Form has errors:");
          }
        }}
        form={form}
        disabled={!edit}
        scrollToFirstError
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} md={24} lg={24} xl={16}>
            <GeneralDetails
              form={form}
              assetImage={assetImage}
              setAssetImage={setAssetImage}
              edit={edit}
              assetDetails={assetDetails}
              setRefresh={setRefresh}
            />
          </Col>
          {params?.id && (
            <Col xs={24} md={24} lg={24} xl={8}>
              <AssetDownTime
                assetDetails={assetDetails}
                setAssetDownTimeID={setAssetDownTimeID}
                refresh={refresh}
              />
            </Col>
          )}
          <Col xs={24} md={24} lg={24} xl={16}>
            <AssetDetails />
          </Col>
          {/* {params?.id && (
            <Col xs={24} md={24} lg={24} xl={8}>
              <AuditLog
                child={true}
                pageType={pageType}
                assetCode={assetDetails?.code}
              />
            </Col>
          )} */}
          {params?.id && (
            <Col xs={24} md={24} lg={24} xl={8}>
              <SparePartHistory assetDetails={assetDetails} />
            </Col>
          )}
          <Col xs={24} md={24} lg={24} xl={16}>
            <SensorData />
          </Col>
          <Col xs={24} md={24} lg={24} xl={16}>
            <Parts form={form} edit={edit} />
          </Col>
          <Col xs={24} md={24} lg={24} xl={16}>
            <WarrantyDetails
              form={form}
              documents={documents}
              setDocuments={setDocuments}
              edit={edit}
            />
          </Col>
          <Col xs={24} md={24} lg={24} xl={16}>
            <Maintenance
              form={form}
              edit={edit}
              documents={documents}
              setDocuments={setDocuments}
            />
          </Col>
          <Col xs={24} md={24} lg={24} xl={16}>
            <AssetDocuments
              form={form}
              edit={edit}
              documents={documents}
              setDocuments={setDocuments}
            />
          </Col>
        </Row>
      </Form>
    </CustomLayout>
  );
};

export default CreateAssetForm;
