import axiosInstance from "../config/axios/axios.config";

export const createAssetApi = (payload) => {
  return axiosInstance.post("/asset", payload);
};
export const uploadApi = (payload, query) => {
  return axiosInstance.post("/upload", payload, {
    params: query,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const deleteDocsApi = (path) => {
  return axiosInstance.patch(`/upload`, { path });
};
export const deleteFileApi = (query) => {
  return axiosInstance.delete(`/upload?${query}`);
};
export const getAllAssetsWithLocsApi = () => {
  return axiosInstance.get("/asset/locations/assets");
};
export const getAllAssetsForListViewApi = (query) => {
  return axiosInstance.get(`/asset/assets/locations`, { params: query });
};
export const getAssetByIdApi = (assetId) => {
  return axiosInstance.get(`/asset/${assetId}`);
};
export const updateAssetByIdApi = (assetId, payload) => {
  return axiosInstance.put(`/asset/${assetId}`, payload);
};
export const getAllAssetsApi = (query) => {
  return axiosInstance.get("/asset/all", { params: query });
};
export const deleteAssetByIdApi = (assetId) => {
  return axiosInstance.delete(`/asset/${assetId}`);
};

export const getAssetDowntimeByIdApi = (assetId, params) => {
  return axiosInstance.get(`/asset/downtime/${assetId}`, { params });
};

export const getAssetLocationByIDApi = (id) => {
  return axiosInstance.get(`asset/asset-parents-locations/${id}`);
};
// export const getAssetAuditLogsByIDApi = (id, query, download) => {
//   // return axiosInstance.get(`analytics/audit/${id}?${query}`);
//   return axiosInstance.get(`analytics/audit/${id}`, {
//     params: query,
//     ...(download && { responseType: "blob" }),
//   });
// };
export const getAssetAuditLogsApi = (query, download) => {
  return axiosInstance.get(`analytics/audit`, {
    params: query,
    ...(download && { responseType: "blob" }),
  });
};

export const createAssetDownTimeApi = (assetId, payload) => {
  return axiosInstance.post(`/asset/downtime/${assetId}`, payload);
};
export const updateAssetDownTimeApi = (downtimeID, payload) => {
  return axiosInstance.put(`/asset/downtime/${downtimeID}`, payload);
};
export const getQrCodeforAssetApi = (assetId) => {
  return axiosInstance.post(`qrcode/generate/${assetId}`);
};

export const getProductionLines = (query) => {
  return axiosInstance.get(`asset/pu/all`, { params: query });
};
export const getProductionLineDetails = (id) => {
  return axiosInstance.get(`/asset/pu/${id}`);
};
export const createProductionLine = (payload) => {
  return axiosInstance.post(`/asset/pu/`, payload);
};
export const updateProductionLine = (id, payload) => {
  return axiosInstance.put(`/asset/pu/${id}`, payload);
};
export const deleteProductionLineApi = (id) => {
  return axiosInstance.delete(`/asset/pu/${id}`);
};
export const bulkGenerateQRCodes = () => {
  return axiosInstance.post(`/qrcode/bulk-generate`, {});
};
export const getAssetQRCodes = () => {
  return axiosInstance.get(`/qrcode/get-qrcodes`);
};

export const getAssetGroups = () => {
  return axiosInstance.get(`/asset/group/all`);
};
export const getAssetGroupDetails = (id) => {
  return axiosInstance.get(`/asset/group/${id}`);
};
export const createAssetGroup = (payload) => {
  return axiosInstance.post(`/asset/group`, payload);
};
export const updateAssetGroup = (id, payload) => {
  return axiosInstance.put(`/asset/group/${id}`, payload);
};
export const deleteAssetGroupApi = (id) => {
  return axiosInstance.delete(`/asset/group/${id}`);
};

export const getAssetSparePartHistory = (id) => {
  return axiosInstance.get(`/asset/stats/${id}`);
};
