import axiosInstance from "../config/axios/axios.config";

export const getDepartments = () => {
  return axiosInstance.get(`/department`);
};

export const createDepartmentApi = (payload) => {
  return axiosInstance.post(`/department`, payload);
};

export const updateDepartmentApi = (id, payload) => {
  return axiosInstance.patch(`/department/${id}`, payload);
};

export const deleteDepartmentApi = (id) => {
  return axiosInstance.delete(`/department/${id}`);
};
