import axiosInstance from "../config/axios/axios.config";

export const createWorkflowApi = (payload) => {
  return axiosInstance.post("/workflow", payload);
};
export const getWorkflowsApi = () => {
  return axiosInstance.get(`/workflow`);
};
export const getWorkflowByIDApi = (id) => {
  return axiosInstance.get(`/workflow/${id}`);
};
export const updateWorkflowApi = (id, payload) => {
  return axiosInstance.patch(`/workflow/${id}`, payload);
};
export const deleteWorkflowApi = (id) => {
  return axiosInstance.delete(`/workflow/${id}`);
};
