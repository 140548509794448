import {
  Col,
  Empty,
  Flex,
  Pagination,
  Popconfirm,
  Popover,
  Result,
  Row,
  Skeleton,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./Users.css";
import {
  DeleteOutlined,
  EditOutlined,
  FilterFilled,
  SendOutlined,
} from "@ant-design/icons";
import {
  deleteUserApi,
  getAllUsersApi,
  resendUserApi,
} from "../../services/users.services";
import {
  PAGESIZE,
  addUsers,
  deleteUsers,
  editUsers,
} from "../../constants/defaultKeys";
import { userRoles } from "../../helpers/utility";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { UserContext } from "../../context/UserProvider";

const UsersTable = ({
  setCurrUser,
  currUser,
  searchValue,
  setUsersCount,
  selectedUserTeams,
  selectedUserDept,
  selectedUserRoles,
}) => {
  const [users, setUsers] = useState(null);
  const { userDetails } = useContext(UserContext);
  const [refresh, setRefresh] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    let query = { page: pageNumber };
    if (searchValue) {
      query = { ...query, name: searchValue };
    }
    selectedUserTeams?.length > 0 && (query.group = selectedUserTeams);
    selectedUserDept?.length > 0 && (query.department = selectedUserDept);
    selectedUserRoles?.length > 0 && (query.role = selectedUserRoles);

    setLoading(true);
    try {
      const res = await getAllUsersApi(query);
      setUsers(res?.data);
      setUsersCount(res?.data?.totalUsers);
    } catch (error) {
      console.error("Error while fetching data:", error);
      setUsers(null);
      setUsersCount(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, refresh]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [selectedUserTeams, selectedUserDept, selectedUserRoles]);

  useEffect(() => {
    if (searchValue?.trim()?.length === 0 || searchValue?.trim()?.length > 1) {
      refreshPage("search");
    }
  }, [searchValue]);

  useEffect(() => {
    if (!Object.keys(currUser)?.length > 0) {
      refreshPage();
    }
  }, [currUser]);

  const refreshPage = (source = "") => {
    if (source === "search" && pageNumber > 1) {
      setPageNumber(1);
    } else if (
      source === "delete" &&
      users?.users?.length === 1 &&
      pageNumber > 1
    ) {
      setPageNumber((prev) => prev - 1);
    } else {
      setRefresh((prev) => !prev);
    }
  };

  const deleteUser = async (id) => {
    try {
      const resp = await deleteUserApi(id);
      message.success(resp?.data?.message);
      refreshPage("delete");
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  const sendInvite = async (record) => {
    try {
      const resp = await resendUserApi(record?._id);
      if (resp) {
        message.success(resp?.data?.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };
  const allGroupNames = users?.users?.flatMap((item) =>
    item?.groups?.map((group) => group?.name)
  );

  const uniqueGroupNames = [...new Set(allGroupNames)].map((groupName) => ({
    text: groupName,
    value: groupName,
  }));

  const columns = [
    {
      title: <Typography className={"tableHeader"}>User</Typography>,
      dataIndex: "first_name",
      key: "first_name",
      width: 280,
      fixed: "left",
      sorter: (a, b) => a?.first_name?.localeCompare(b?.first_name),
      render: (text, value) => {
        return (
          <Typography style={{ fontWeight: 500 }}>
            {text} {value?.last_name || ""}
            <br />
            <span style={{ color: "#475467", fontWeight: "normal" }}>
              {value.email}
            </span>
          </Typography>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Status</Typography>,
      dataIndex: "is_deactivated",
      key: "is_deactivated",
      width: 140,
      render: (val, record) => (
        <Tag
          className={tagStyles.mdRndTag}
          color={!record?.is_deactivated ? "green" : "red"}
        >
          {!record?.is_deactivated ? "Active" : "Inactive"}
        </Tag>
      ),
    },

    {
      title: <Typography className={"tableHeader"}>Phone</Typography>,
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: 180,
      render: (val) => <>{val ?? "-"}</>,
    },
    {
      title: <Typography className={"tableHeader"}>Teams</Typography>,
      dataIndex: "groups",
      key: "groups",
      width: 220,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // filters: uniqueGroupNames, // Initialize empty array for filters
      // onFilter: (value, record) =>
      //   record?.groups?.map((group) => group?.name).includes(value),
      render: (val, record) => {
        return (
          <>
            {record?.groups?.length > 0 ? (
              <div
                className="flex-center"
                style={{
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                {record?.groups?.slice(0, 2)?.map((group) => (
                  <Tag className={tagStyles.mdTag} color="blue">
                    {group?.name}
                  </Tag>
                ))}
                {record?.groups?.length > 2 && (
                  <Popover
                    content={
                      <div
                        className="flex-center"
                        style={{ flexDirection: "column", gap: "6px" }}
                      >
                        {record?.groups?.map((group) => (
                          <Tag className={tagStyles.mdTag} color="blue">
                            {group?.name}
                          </Tag>
                        ))}
                      </div>
                    }
                  >
                    <Tag>+{record?.groups?.length - 2}</Tag>
                  </Popover>
                )}
              </div>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Department</Typography>,
      dataIndex: "department",
      key: "department",
      // sorter: (a, b) => a?.department?.name?.localeCompare(b?.department?.name),
      render: (val) =>
        val?.name ? (
          <Tag className={tagStyles.smRndTag} color="magenta">
            {val?.name}
          </Tag>
        ) : (
          "-"
        ),
      width: 180,
    },
    {
      title: <Typography className={"tableHeader"}>Role</Typography>,
      dataIndex: "role",
      key: "role",
      width: 180,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // filters: userRoles?.map((each) => ({ ...each, text: each?.label })),
      // onFilter: (value, record) => record?.role === value,
      // filterSearch: true,
      // sorter: (a, b) => a?.role?.localeCompare(b?.role),
      render: (val, record) => {
        const role = userRoles.filter((role) => role?.value == val)?.[0];
        return (
          <Tag className={tagStyles.mdTag} color={role?.color}>
            {role?.label}
          </Tag>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Facilities</Typography>,
      dataIndex: "facilities",
      key: "facilities",
      width: 250,
      render: (val, record) => {
        return (
          <>
            {record?.facilities?.length > 0 ? (
              <div
                className="flex-center"
                style={{
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                {record?.facilities?.slice(0, 2)?.map((group) => (
                  <Typography color="blue">{group?.name}</Typography>
                ))}
                {record?.facilities?.length > 2 && (
                  <Popover
                    content={
                      <div
                        className="flex-center"
                        style={{ flexDirection: "column", gap: "6px" }}
                      >
                        {record?.facilities?.map((group) => (
                          <Typography>{group?.name}</Typography>
                        ))}
                      </div>
                    }
                  >
                    <Tag>+{record?.facilities?.length - 2}</Tag>
                  </Popover>
                )}
              </div>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Actions</Typography>,
      dataIndex: "action",
      key: "action",
      width: 140,
      render: (_, record) => {
        if (!record?.is_deactivated) {
          return (
            <Flex gap={12} align="center" justify="start">
              {deleteUsers.includes(userDetails?.activeOrg?.role) && (
                <Popconfirm
                  icon={null}
                  description="Are you sure to delete this user?"
                  onConfirm={(e) => {
                    e.stopPropagation();
                    deleteUser(record?._id);
                  }}
                  onCancel={(e) => e.stopPropagation()}
                >
                  <DeleteOutlined />
                </Popconfirm>
              )}
              {editUsers.includes(userDetails?.activeOrg?.role) &&
                (record.role !== "admin" ||
                  (record.role === "admin" &&
                    userDetails?.activeOrg?.role === "admin")) && (
                  <EditOutlined onClick={() => setCurrUser(record)} />
                )}
              {addUsers.includes(userDetails?.activeOrg?.role) && (
                <Popconfirm
                  icon={null}
                  description="Are you sure to resend invite?"
                  onConfirm={(e) => {
                    e.stopPropagation();
                    sendInvite(record);
                  }}
                  okText="Resend"
                  onCancel={(e) => e.stopPropagation()}
                >
                  <SendOutlined style={{ rotate: "320deg" }} />
                </Popconfirm>
              )}
            </Flex>
          );
        } else {
          return "";
        }
      },
      hidden: !(
        deleteUsers.includes(userDetails?.activeOrg?.role) ||
        editUsers.includes(userDetails?.activeOrg?.role)
      ),
    },
  ].filter((elem) => !elem.hidden);

  return (
    <Row gutter={[0, 18]}>
      <Col span={24} className="tableContainer">
        <Table
          scroll={{
            x: 1200,
            y: "60vh",
          }}
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No users found</Typography>}
              />
            ),
          }}
          pagination={false}
          columns={columns}
          dataSource={users?.users}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
        />
      </Col>
      {users?.totalUsers > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={users?.totalUsers}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </Row>
  );
};

export default UsersTable;
