import { Input, Button, Upload, message, Image } from "antd";
import React, { useContext, useEffect, useState } from "react";
import inputStyles from "../../../../../assets/styles/Custom/Input.module.css";
import {
  generateRandomString,
  getAssetsPath,
  uploadFunc,
} from "../../../../../helpers/utility";
import "../../../SOP.css";
import { UploadOutlined } from "@ant-design/icons";
import { UserContext } from "../../../../../context/UserProvider";
import { ONPREMISE } from "../../../../../constants/defaultKeys";
import Compressor from "compressorjs";

function OptionInput({
  choices,
  setChoices,
  eachChoice,
  checklist = false,
  optionType,
}) {
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const [imageUrl, setImageUrl] = useState(choices?.[eachChoice] || "");
  const OneMB = 1024 * 1024;

  useEffect(() => {
    setImageUrl(choices?.[eachChoice]);
  }, [choices?.[eachChoice]]);

  const handleChange = (e) => {
    setChoices({
      ...choices,
      [eachChoice]:
        `${e.target.value}<__>${generateRandomString()}` || undefined,
    });
  };

  const handleBlur = (e) => {
    if (!e.target.value)
      setChoices({
        ...choices,
        [eachChoice]: checklist
          ? `Checklist ${eachChoice?.split("_")[0]?.slice(-1)}`
          : `Option ${eachChoice?.split("_")[0]?.slice(-1)}`,
      });
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" || e.keyCode === 8) {
      const input = e.target;
      if (
        input.selectionStart === 0 &&
        input.selectionEnd === input.value.length
      )
        input.value = "";
    }
  };

  const handleUpload = (info) => {
    if (info.file.status === "uploading") {
      uploadImage(info);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (info.file.status === "error") {
      message.error("Image upload failed.");
    }
  };

  const uploadImage = async (info, key) => {
    try {
      // setLoading(true);
      const resp = await uploadFunc(info, storageType);
      if (resp) {
        setImageUrl(resp?.path || "");
        setChoices({
          ...choices,
          [eachChoice]: resp?.path || "",
        });
        // if (storageType == ONPREMISE) {
        //   const [, afterCmms] = resp?.path?.split("cmms/");
        //   const encodedImage = encodeURIComponent(afterCmms);
        //   setImageUrl(encodedImage || "");
        //   setChoices({
        //     ...choices,
        //     [eachChoice]: encodedImage || "",
        //   });
        // } else {
        //   setImageUrl(resp?.path || "");
        //   setChoices({
        //     ...choices,
        //     [eachChoice]: resp?.path || "",
        //   });
        // }
      }
    } catch (error) {
    } finally {
      // setLoading(false);
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  return (
    <div className={`${inputStyles.optionInputContainer}`}>
      {optionType === "text" ? (
        <Input
          style={{ minWidth: "100%" }}
          className={`${inputStyles.smInput} optionInput`}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          value={choices?.[eachChoice]?.split("<__>")[0]}
          placeholder={choices?.[eachChoice]?.split("<__>")[0]}
        />
      ) : (
        <>
          {imageUrl ? (
            <Image
              src={getAssetsPath(imageUrl, storageType)}
              alt="Uploaded"
              style={{ width: "100px", marginBlock: "12px", height: "100px" }}
            />
          ) : (
            <Upload
              beforeUpload={(file) => {
                // console.log(file.size);
                return new Promise((resolve) => {
                  new Compressor(file, {
                    quality: 0.8, // Adjust quality between 0 and 1
                    convertSize: 1000000,
                    success(compressedFile) {
                      // console.log(compressedFile, "cf");
                      resolve(compressedFile); // Resolve with the compressed file
                    },
                    error(err) {
                      console.error("Compression failed:", err.message);
                    },
                  });
                });
              }}
              // beforeUpload={(file) => {
              //   const fileSize = file.size / OneMB;
              //   console.log(file?.type, ["image/*"].includes(file.type));
              //   if (fileSize > 100) {
              //     message.error(`File too large ${fileSize?.toFixed(0)} mb`);

              //     return Upload.LIST_IGNORE;
              //   }
              //   if (!file.type.startsWith("image/")) {
              //     message.error("Cannot upload this file");

              //     return Upload.LIST_IGNORE;
              //   }
              //   return true;
              // }}
              customRequest={dummyRequest}
              name="file"
              action="/upload" // URL for handling the file upload request
              listType="picture"
              showUploadList={false}
              onChange={handleUpload}
              accept="image/*"
              multiple={false}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          )}
        </>
      )}
    </div>
  );
}

export default OptionInput;
