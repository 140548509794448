import {
  Button,
  Col,
  DatePicker,
  Empty,
  Radio,
  Result,
  Table,
  Tag,
  Typography,
  Segmented,
  Popover,
  Input,
  Select,
  Skeleton,
  Pagination,
  Space,
  Image,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "./Maintanance.css";
import {
  capitalizeFirstLetter,
  priorityOptions,
  options,
  debounce,
  convertUtcToIst,
  convertUTCToLocalFormat,
  displayFilterTag,
} from "../../helpers/utility";
import { getMaintanancesApi } from "../../services/maintenance.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import {
  INSPECTION,
  PAGESIZE,
  USER,
  createPM,
} from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import {
  getAllAssetsApi,
  getProductionLines,
} from "../../services/assets.services";
// import MaintenanceSegmented from "./MaintenanceSegmented";
import PmFilters from "./PmFilters";
import filterActiveIcon from "../../assets/images/filterActiveIcon.svg";

const { RangePicker } = DatePicker;

const MaintenanceTable = ({ viewType, setViewType, type }) => {
  const navigate = useNavigate();
  const [maintanaces, setMaintanances] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);
  // const [selectedDate, setSelectedDate] = useState(null);

  const { userDetails } = useContext(UserContext);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "month"),
    end_date: dayjs().add(1, "month"),
  });
  // const [assets, setAssets] = useState([]);
  const [search, setSearch] = useState(null);
  // const [selectedAsset, setSelectedAsset] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  // const [totalCount, setTotalCount] = useState(0);
  // const [selectedProductionLine, setSelectedProductionLine] = useState(undefined);
  // const [selectedPmTypes, setSelectedPmTypes] = useState([]);
  // const [selectedPmPriority, setSelectedPmPriority] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    asset: undefined,
    production_line: undefined,
    types: [],
    priorities: [],
  });
  const [assets, setAssets] = useState([]);
  const [productionLines, setProductionLines] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const fetchAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          value: asset?._id,
        }))
      );
    } catch (error) {
      setAssets([]);
    }
  };

  const fetchProductionLines = async () => {
    try {
      const res = await getProductionLines();
      setProductionLines(
        res?.data?.assets?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?._id,
        }))
      );
    } catch (error) {
      setProductionLines([]);
    }
  };

  useEffect(() => {
    fetchAssets();
    fetchProductionLines();
  }, []);

  // const getAssets = async () => {
  //   try {
  //     const resp = await getAllAssetsApi();
  //     setAssets(
  //       resp?.data?.assets?.map((asset) => ({
  //         label: asset?.name,
  //         value: asset?._id,
  //         image: asset?.file,
  //         status: asset?.status,
  //       }))
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getAssets();
  // }, []);

  // const navigate = useNavigate();

  // useEffect(() => {
  //   setCount((prev) => ({ ...prev, conf: maintanaces?.length }));
  // }, [maintanaces]);

  // useEffect(() => {
  //   if (selectedDate) {
  //     const filtered = maintanaces.filter((item) =>
  //       dayjs(item.last_completion_date).isSame(selectedDate, "day")
  //     );
  //     setFilteredData(filtered);
  //   } else {
  //     // If no date is selected, show all data
  //     setFilteredData(maintanaces);
  //   }
  // }, [selectedDate, maintanaces]);

  // function formatDate(date) {
  //   return dayjs(date).format("MM/DD/YYYY"); // Adjust the format as needed
  // }

  function generateScheduleString(
    startDate,
    // startTime,
    recurrenceType,
    dayOfWeek,
    dayOfMonth,
    monthOption,
    month,
    every
  ) {
    console.log(dayOfWeek, "dwww");
    let dayOfWeekStr = dayOfWeek?.join(", ") || "";
    let scheduleString = "Occurs ";
    switch (recurrenceType) {
      case "daily":
        scheduleString += every > 1 ? `every ${every} days` : "every day";
        break;
      case "weekly":
        const weeklyString =
          every > 1
            ? `every ${every} weeks on ${dayOfWeekStr}`
            : `every ${dayOfWeekStr}`;
        scheduleString += weeklyString;
        break;
      case "monthly":
        scheduleString += "";
        if (dayOfMonth) {
          scheduleString +=
            every > 1
              ? `every ${every} months on day ${dayOfMonth}`
              : `	
              every month on day ${dayOfMonth}`;
        } else {
          scheduleString +=
            every > 1
              ? `every ${every} months on ${monthOption} ${dayOfWeekStr}`
              : `every month on ${monthOption} ${dayOfWeekStr}`;
        }
        break;
      case "yearly":
        scheduleString += "every year on ";
        if (dayOfMonth) {
          scheduleString += `day ${dayOfMonth} of ${month}`;
        } else {
          scheduleString += `${monthOption} ${dayOfWeekStr} of ${month}`;
        }
        break;
      default:
        return "Invalid recurrence type";
    }
    if (startDate) {
      scheduleString += ` at ${dayjs(convertUTCToLocalFormat(startDate)).format(
        "hh:mm A"
      )}`;
    }

    // const utcTime = new Date(startTime);
    // utcTime.setHours(utcTime.getHours()); // Adding 5 hours
    // utcTime.setMinutes(utcTime.getMinutes()); // Adding 30 minutes

    // const hours = utcTime.getHours();
    // const minutes = utcTime.getMinutes();

    // let formattedTime = `${hours > 12 ? hours - 12 : hours}:${String(
    //   minutes
    // ).padStart(2, "0")} ${hours >= 12 ? "PM" : "AM"}`;
    // scheduleString += ` at ${formattedTime}`;

    return startDate ? scheduleString : "";
  }

  const columns = [
    {
      title: <Typography className={"tableHeader"}>Scheduled at</Typography>,
      dataIndex: "Scheduled at",
      key: "Scheduled at",
      width: 250,
      fixed: "left",
      render: (val, record) => (
        <>
          {generateScheduleString(
            record["start_date"],
            // dayjs(record["start_time"], "HH:mm:ss"),
            record["repeat"],
            record["week_days"],
            record["day_of_month"],
            record["month_option"],
            record["month"],
            record["every"]
          ) || "-"}
        </>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>Code</Typography>,
      dataIndex: "code",
      key: "code",
      width: 140,
      fixed: "left",
    },
    {
      title: <Typography className={"tableHeader"}>Title</Typography>,
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: <Typography className={"tableHeader"}>Type</Typography>,
      dataIndex: "sub_type",
      key: "sub_type",
      width: 140,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // filters: [
      //   {
      //     label: "Mechanical",
      //     text: "Mechanical",
      //     value: "Mechanical",
      //   },
      //   {
      //     label: "Electrical",
      //     text: "Electrical",
      //     value: "Electrical",
      //   },
      //   {
      //     label: "Others",
      //     text: "Others",
      //     value: "Others",
      //   },
      // ],
      // onFilter: (value, record) => record?.sub_type?.startsWith(value),
      // filterSearch: true,
      render: (val) => (val ? val : "-"),
    },
    {
      title: (
        <Typography className={"tableHeader"}>
          Asset/ Production line
          {/* {type == INSPECTION ? "Asset" : "Asset/ Production line"} */}
        </Typography>
      ),
      dataIndex: "asset",
      key: "asset",
      width: 250,
      sorter: (a, b) =>
        (a?.asset?.name || a?.production_line?.name || "")?.localeCompare(
          b?.asset?.name || b?.production_line?.name || ""
        ),
      // render: (val, record) => (
      //   <>
      //     {type == INSPECTION
      //       ? record?.asset?.name || "-"
      //       : record?.asset
      //       ? record?.asset?.name || "-"
      //       : record?.production_line?.name || "-"}
      //   </>
      // ),
      render: (val, record) => (
        <>{record?.asset?.name || record?.production_line?.name || "-"}</>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>Location</Typography>,
      dataIndex: "site",
      key: "site",
      width: 200,
      render: (val, record) => (
        <>
          {type == INSPECTION
            ? record?.asset?.location?.name || "-"
            : record?.asset
            ? record?.asset?.location?.name || "-"
            : record?.production_line?.location?.name || "-"}
        </>
      ),
      // render: (val, record) => <>{record?.asset?.location?.name || "-"}</>,
    },
    {
      title: <Typography className={"tableHeader"}>Assigned to</Typography>,
      dataIndex: "assigned_users",
      key: "assigned_users",
      width: 200,
      render: (val, record) => {
        const list = [
          ...(record?.assigned_users || []),
          ...(record?.assigned_groups || []),
        ];

        return (
          <>
            {list?.length > 0 ? (
              <div
                className="flex-center"
                style={{
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                {list?.slice(0, 2)?.map((group) => (
                  <Typography>
                    {group?.first_name || group?.name}
                    {list?.length > 1 ? "," : ""}
                  </Typography>
                ))}
                {list?.length > 2 && (
                  <Popover
                    content={
                      <div
                        className="flex-center"
                        style={{
                          flexDirection: "column",
                          gap: "6px",
                          alignItems: "flex-start",
                        }}
                      >
                        {list?.map((group) => (
                          <Typography>
                            {group?.first_name || group?.name}
                          </Typography>
                        ))}
                      </div>
                    }
                  >
                    <Tag>+{list?.length - 2}</Tag>
                  </Popover>
                )}
              </div>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>SOP</Typography>,
      dataIndex: "sop",
      key: "sop",
      width: 140,
      render: (val, record) => (
        <>{record?.sop?._id ? record?.sop?.code : "-"}</>
      ),
    },
    // {
    //   title: <Typography className={"tableHeader"}>Estimated time</Typography>,
    //   dataIndex: "estimate_time",
    //   key: "estimate_time",
    //   width: "150px",
    // },
    {
      title: <Typography className={"tableHeader"}>Priority</Typography>,
      dataIndex: "priority",
      key: "priority",
      width: 140,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // filters: priorityOptions,
      // onFilter: (value, record) => record?.priority?.startsWith(value),
      // filterSearch: true,
      render: (val, record) => {
        const color =
          priorityOptions.filter((pri) => pri.value === val)[0]?.color ||
          "blue";
        return (
          <Tag
            style={{ minWidth: "80px", border: "0px" }}
            className={tagStyles.smTag}
            color={color}
          >
            {capitalizeFirstLetter(val) || "-"}
          </Tag>
        );
      },
    },
    {
      title: (
        <Typography className={"tableHeader"}>Last completion date</Typography>
      ),
      width: 150,
      dataIndex: "last_completion_date",
      key: "last_completion_date",
      render: (val, record) => (
        <>
          {val
            ? dayjs(convertUTCToLocalFormat(val)).format("DD MMM YYYY hh:mm A")
            : "-"}
        </>
      ),
    },
  ];

  const getMaintanaces = async () => {
    let params = { page: pageNumber };
    if (search) {
      params.name = search;
    }
    if (type) {
      params.type = type;
    }
    // if (selectedAsset) {
    //   params.asset = selectedAsset;
    // }
    if (dateFilter?.start_date) {
      params.start_date = dayjs(dateFilter?.start_date).format("YYYY-MM-DD");
    }
    if (dateFilter?.end_date) {
      params.end_date = dayjs(dateFilter?.end_date).format("YYYY-MM-DD");
    }
    filters?.asset && (params.asset = filters?.asset);
    filters?.production_line &&
      (params.production_line = filters?.production_line);
    filters?.types?.length > 0 && (params.sub_type = filters?.types);
    filters?.priorities?.length > 0 && (params.priority = filters?.priorities);
    // selectedProductionLine && (params.production_line = selectedProductionLine);
    // selectedPmTypes?.length > 0 && (params.sub_type = selectedPmTypes);
    // selectedPmPriority?.length > 0 && (params.priority = selectedPmPriority);
    // const queryString = new URLSearchParams(params);
    try {
      setLoading(true);
      const resp = await getMaintanancesApi(params);
      setMaintanances(resp?.data);
      // setTotalCount(resp?.data?.totalMaintenances);
    } catch (error) {
      // console.log(error);
      setMaintanances([]);
      // setTotalCount(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMaintanaces();
  }, [
    userDetails?.currFacility,
    // dateFilter,
    // search,
    // selectedAsset,
    type,
    pageNumber,
    refresh,
  ]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [
    search,
    dateFilter,
    filters,
    // selectedAsset,
    // selectedProductionLine,
    // selectedPmTypes,
    // selectedPmPriority,
  ]);

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  return (
    <>
      {/* <Col span={24}>
        <MaintenanceSegmented viewType={viewType} type={type} />
      </Col> */}
      <Col span={10}>
        <Space style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}>
          <Button
            style={{
              color: "#6941C6",
              // backgroundColor: "#fff",
              // borderColor: "#E9D7FE",
            }}
            className={btnStyles.smBtn}
            type="default"
            icon={
              <Image
                src={filterActiveIcon}
                width={18}
                height={18}
                alt="filter"
                preview={false}
              />
            }
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </Button>
        </Space>
        {showFilterModal && (
          <PmFilters
            type={type === INSPECTION ? "Inspection" : "PM"}
            viewType={"conf"}
            showFilterModal={showFilterModal}
            setShowFilterModal={setShowFilterModal}
            assets={assets}
            productionLines={productionLines}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </Col>
      <Col offset={4} span={10} style={{ textAlign: "right" }}>
        <RangePicker
          id="pm-conf-filter"
          format="DD-MMM-YYYY"
          defaultValue={[dateFilter.start_date, dateFilter.end_date]}
          onChange={(_, dateString) => {
            setDateFilter({
              start_date: dateString[0],
              end_date: dateString[1],
            });
          }}
        />
      </Col>
      {(filters?.asset ||
        filters?.production_line ||
        filters?.types?.length > 0 ||
        filters?.priorities?.length > 0) && (
        <Col
          span={24}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 6,
            flexWrap: "wrap",
          }}
        >
          {filters?.asset && displayFilterTag("Asset", "purple")}
          {filters?.production_line &&
            displayFilterTag("Production Line", "purple")}
          {filters?.types?.length > 0 &&
            displayFilterTag(
              `${type === INSPECTION ? "Inspection" : "PM"} Type`,
              "volcano",
              filters?.types?.length
            )}
          {filters?.priorities?.length > 0 &&
            displayFilterTag(
              "Priority",
              "magenta",
              filters?.priorities?.length
            )}
          <Typography
            style={{ color: "#7369F4", cursor: "pointer", fontSize: 12 }}
            onClick={() =>
              setFilters({
                asset: undefined,
                production_line: undefined,
                types: [],
                priorities: [],
              })
            }
          >
            Clear All
          </Typography>
        </Col>
      )}

      {/* <Col
        span={16}
        style={{ justifyContent: "flex-end", gap: "12px" }}
        className="flex-center"
      >
        <Select
          placeholder="Select asset"
          style={{
            textAlign: "left",
            width: "280px",
          }}
          allowClear
          showSearch={true}
          optionFilterProp="label"
          // className={inputStyles.smSelect}
          onChange={(e) => setSelectedAsset(e)}
          options={assets}
        />
        <Input.Search
          size="medium"
          onChange={debounce(handleSearch, 500)}
          placeholder={`Search by ${
            type == INSPECTION ? INSPECTION : "PM"
          } name or code`}
          style={{ width: "280px" }}
        />
        <RangePicker
          format="DD-MMM-YYYY"
          defaultValue={[dateFilter.start_date, dateFilter.end_date]}
          onChange={(_, dateString) => {
            setDateFilter({
              start_date: dateString[0],
              end_date: dateString[1],
            });
          }}
        />

        {createPM.includes(userDetails?.activeOrg?.role) && (
          <Button
            type="primary"
            id="create-pm"
            className={btnStyles.mdBtn}
            onClick={() =>
              navigate(
                `/${type == INSPECTION ? "inspections" : "maintenances"}/create`
              )
            }
          >
            + Create {type == INSPECTION ? INSPECTION : "PM"}
          </Button>
        )}
      </Col> */}
      {/* <PmFilters
        viewType={"conf"}
        selectedAsset={selectedAsset}
        setSelectedAsset={setSelectedAsset}
        selectedProductionLine={selectedProductionLine}
        setSelectedProductionLine={setSelectedProductionLine}
        selectedPmTypes={selectedPmTypes}
        setSelectedPmTypes={setSelectedPmTypes}
        selectedPmPriority={selectedPmPriority}
        setSelectedPmPriority={setSelectedPmPriority}
      /> */}

      <Col span={24}>
        <Table
          title={() => (
            <Space
              align="center"
              style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
            >
              <Input.Search
                size="large"
                onChange={debounce(handleSearch, 500)}
                placeholder={`Search by ${
                  type == INSPECTION ? INSPECTION : "PM"
                } name or code`}
                prefix={
                  <SearchOutlined style={{ fontSize: 16, color: "#667085" }} />
                }
                style={{ width: "300px" }}
                className="custom-input-search"
              />
              <Button
                type="primary"
                id="create-pm"
                className={btnStyles.mdBtn}
                onClick={() =>
                  navigate(
                    `/${
                      type == INSPECTION ? "inspections" : "maintenances"
                    }/create`
                  )
                }
              >
                + Create {type == INSPECTION ? INSPECTION : "PM"}
              </Button>
            </Space>
          )}
          key={`${type}_config`}
          onRow={(item) => {
            return {
              onClick: (e) => {
                !item?.children?.length > 0 &&
                  navigate(
                    `/${type == INSPECTION ? "inspections" : "maintenances"}/${
                      item?._id
                    }`
                  );
              },
            };
          }}
          scroll={{
            x: 1200,
            y: "55vh",
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={
                  <Typography>{`No ${
                    type == INSPECTION ? "Inspections" : "PM's"
                  } found`}</Typography>
                }
              />
            ),
          }}
          bordered
          rowClassName={"rowClassName"}
          columns={columns}
          dataSource={maintanaces?.maintenances || []}
          // onChange={onChange}
          pagination={false}
        />
      </Col>
      {maintanaces?.totalMaintenances > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={maintanaces?.totalMaintenances}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </>
  );
};

export default MaintenanceTable;
