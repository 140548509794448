import React from "react";
import { Space, Tag } from "antd";

const CustomLmsTag = ({ data }) => {
  const TagColors = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];

  return (
    <>
      <Space size={[0, 8]} wrap>
        {data?.map((item, index) => (
          <Tag key={item?._id} color={TagColors[index % TagColors.length]}>
            {item?.name || item}
          </Tag>
        ))}
      </Space>
    </>
  );
};

export default CustomLmsTag;
