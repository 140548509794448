import React, { useState } from "react";
import { Flex, Typography, Tabs, ConfigProvider, theme } from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";

import "./Locations.css";
import CustomLayout from "../Layout";
import TreeLocations from "./TreeLocatios";
import FacilityTable from "./FacilityTable";
const { useToken } = theme;

const Locations = () => {
  const { token } = useToken();
  const [type, setType] = useState("locations");
  const [refresh, setRefresh] = useState(false);

  const items = [
    {
      key: "locations",
      label: "Locations",
    },
    {
      key: "facilties",
      label: "Facilities",
    },
  ];

  return (
    <CustomLayout
      header={
        <Flex gap={24} align="center">
          <ConfigProvider
            theme={{
              components: {
                Tabs: {
                  horizontalMargin: "0px",
                  horizontalItemPadding: "13.5px",
                  itemActiveColor: "#7369F4",
                  itemColor: "#d0d5dd",
                },
              },
            }}
          >
            <Tabs
              defaultActiveKey="1"
              className="headerTabs"
              style={{ width: "auto" }}
              onChange={(key) => {
                setType(key);
              }}
            >
              {items.map((item) => (
                <Tabs.TabPane
                  key={item.key}
                  tab={
                    <Typography
                      // style={{
                      //   color:
                      //     type != item.key ? token.colorPrimary : "#7369F4",
                      // }}
                      className={typoStyles.h6}
                    >
                      {item?.label}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
          </ConfigProvider>
        </Flex>
      }
    >
      {type == "locations" ? (
        <TreeLocations setRefresh={setRefresh} refresh={refresh} />
      ) : (
        <FacilityTable />
      )}
    </CustomLayout>
  );
};

export default Locations;
