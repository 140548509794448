import {
  Button,
  Checkbox,
  Drawer,
  Empty,
  Flex,
  Form,
  Input,
  List,
  Popconfirm,
  Result,
  Select,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import typographyStyles from "../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";

import {
  createGroupApi,
  deleteMemberFromApi,
  editGroupApi,
  getUsersApi,
} from "../../../services/users.services";
import RandomAvatar from "../../Blocks/RandomAvatar";
import { debounce, userRoles } from "../../../helpers/utility";
import CustomSearch from "../../Blocks/CustomSearch";
import { getFacilitiesApi } from "../../../services/location";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";

const CreateOrEditGroup = ({ open, setOpen, setCurrGroup, currGroup }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const formRef = useRef(null);
  const isEdit = Object.keys(currGroup)?.length > 0;
  const [selectedUsers, setSelectedUsers] = useState({});
  const [searchedSMBUsers, setSearchedSMBUsers] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [currFacilty, setCurrFacilty] = useState(null);

  const onClose = () => {
    setOpen(false);
    setCurrGroup({});
    setSelectedUsers({});
    form.resetFields();
  };

  const createGroup = async (values) => {
    const members = Object.keys(selectedUsers);
    try {
      const resp = await createGroupApi({ ...values, members: members });
      if (resp) {
        message.success(resp?.data?.message);
        setOpen(false);
        setCurrGroup({});
        setSelectedUsers({});
        form.resetFields();
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    } finally {
    }
  };

  const editGroup = async (values) => {
    const members = Object.keys(selectedUsers);
    try {
      const resp = await editGroupApi(currGroup?._id, {
        ...values,
        members: members,
      });
      if (resp) {
        message.success(resp?.data?.message);
        setOpen(false);
        setCurrGroup({});
        setSelectedUsers({});
        form.resetFields();
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    } finally {
    }
  };

  useEffect(() => {
    let params = {
      selectedFacility: currFacilty,
    };

    const queryString = new URLSearchParams(params);
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getUsersApi(queryString);
        setUsers(res?.data);
      } catch (error) {
        console.error("Error while fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      currFacilty && fetchData();
    } else {
      setSelectedUsers({});
      setSearchedSMBUsers([]);
      setCurrFacilty(null);
    }
  }, [currFacilty]);

  const getFacilities = async () => {
    try {
      const data = await getFacilitiesApi(true);
      setFacilities(
        data?.data?.map((eachFac) => ({
          label: eachFac?.name,
          value: eachFac?._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFacilities();
  }, []);

  useEffect(() => {
    if (currGroup && currGroup.members && currGroup.members.length > 0) {
      form.setFieldsValue({
        name: currGroup?.name,
        facility: currGroup?.facility?._id,
      });
      const ids = currGroup.members.reduce((acc, each) => {
        acc[each._id] = true;
        return acc;
      }, {});
      setSelectedUsers(ids);
      setCurrFacilty(currGroup?.facility?._id);
    }
  }, [currGroup]);

  const deleteMember = async (item) => {
    const isExisting =
      currGroup?.members?.filter((each) => each?._id == item?._id)?.length > 0;
    if (isExisting) {
      try {
        const resp = await deleteMemberFromApi(currGroup?._id, item?._id);
        if (resp) {
          const { [item?._id]: _, ...rest } = selectedUsers;
          setSelectedUsers(rest);
          message.success(resp?.data?.message);
        }
      } catch (error) {
        console.log(error);
        message.error(error?.response?.data?.message);
      }
    } else {
      const { [item?._id]: _, ...rest } = selectedUsers;
      setSelectedUsers(rest);
    }
  };

  async function handleSMBUserSearch(value) {
    // setLoading(true);
    const queryString = new URLSearchParams({
      name: value,
      selectedFacility: currFacilty,
    });
    try {
      const res = await getUsersApi(queryString);
      setSearchedSMBUsers(res?.data || []);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  }

  const displayUserRole = (value) => {
    const role = userRoles.filter((role) => role?.value == value)?.[0];
    return (
      <Tag className={tagStyles.smRndTag} color={role?.color}>
        {role?.label}
      </Tag>
    );
  };

  return (
    <Drawer
      footer={
        <Flex justify="flex-end" gap={24}>
          <Button
            onClick={() => {
              setCurrGroup({});
              setSelectedUsers({});
              form.resetFields();
            }}
            className={btnStyles.mdBtn}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            className={btnStyles.mdBtn}
          >
            {isEdit ? "Save" : "Create"}
          </Button>
        </Flex>
      }
      title={isEdit ? "Edit Team" : "Create Team"}
      width={"40vw"}
      onClose={onClose}
      open={open}
    >
      <Form
        requiredMark={false}
        layout="vertical"
        onFinish={isEdit ? editGroup : createGroup}
        form={form}
        ref={formRef}
      >
        <Form.Item
          label="Team name"
          name="name"
          rules={[
            { required: true, message: "Please enter team name" },
            {
              whitespace: true,
              message: "Team name cannot be empty or contain only spaces",
            },
            {
              min: 4,
              message: "Team name should contain atleast 4 characters",
            },
          ]}
        >
          <Input
            style={{ width: "100%" }}
            placeholder="Enter team name"
            className={inputStyles.smInput}
          />
        </Form.Item>
        <Form.Item
          label="Facilities"
          name={"facility"}
          rules={[
            {
              required: true,
              message: "Please select facilty",
            },
          ]}
        >
          <Select
            value={currFacilty}
            onChange={(e) => {
              setSelectedUsers({});
              setCurrFacilty(e);
            }}
            className={inputStyles.smSelect}
            style={{ width: "100%" }}
            options={facilities}
            placeholder="Select facilty"
          />
        </Form.Item>

        {currFacilty && (
          <>
            <div style={{ textAlign: "right", marginBottom: "24px" }}>
              <CustomSearch
                placeholder={"Search users with email address"}
                onSearch={debounce(handleSMBUserSearch, 500)}
                options={[
                  ...searchedSMBUsers?.map((user) => ({
                    value: user?.email,
                    email: user?.email,
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    id: user?._id,
                  })),
                ]}
                width="50%"
                onSelect={(val, option) => {
                  setSelectedUsers((prevSelectedUsers) => {
                    const isUserPresentInList = users?.find(
                      (eachUser) => eachUser?._id == option?.id
                    );
                    if (!isUserPresentInList) {
                      setUsers((prev) => [
                        {
                          _id: option?.id,
                          email: option?.email,
                          first_name: option?.first_name,
                          last_name: option?.last_name,
                        },
                        ...prev,
                      ]);
                    }
                    const updatedSelectedUsers = {
                      [option?.id]: true,
                      ...prevSelectedUsers,
                    };
                    return updatedSelectedUsers;
                  });
                  setUsers((prev) => {
                    const updatedSmbUsers = [
                      {
                        _id: option?.id,
                        email: option?.email,
                        first_name: option?.first_name,
                        last_name: option?.last_name,
                      },
                      ...prev.filter((user) => user._id !== option?.id),
                    ];
                    return updatedSmbUsers;
                  });
                }}
              />
            </div>
            <List
              loading={loading}
              itemLayout="horizontal"
              dataSource={users}
              locale={{
                emptyText: (
                  <Result
                    icon={Empty.PRESENTED_IMAGE_SIMPLE}
                    title={<Typography>No users found</Typography>}
                  />
                ),
              }}
              renderItem={(item, index) => {
                return (
                  <div
                    key={item?._id}
                    style={{
                      display: "flex",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                    }}
                  >
                    <>
                      <Checkbox
                        style={{ marginRight: "12px" }}
                        checked={selectedUsers?.[item?._id] ?? false}
                        value={item?._id}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedUsers((prev) => ({
                              ...prev,
                              [item?._id]: true,
                            }));
                          } else {
                            const { [item?._id]: _, ...rest } = selectedUsers;
                            setSelectedUsers(rest);
                          }
                        }}
                      />
                      <List.Item
                        style={{ display: "flex", width: "100%" }}
                        extra={
                          <div className="flex-center">
                            {selectedUsers?.[item?._id] ? (
                              <Popconfirm
                                icon={null}
                                description="Are you sure to remove this user?"
                                onConfirm={(e) => {
                                  deleteMember(item);
                                }}
                              >
                                <Button danger>Delete</Button>
                              </Popconfirm>
                            ) : (
                              <Button
                                type="primary"
                                onClick={() =>
                                  setSelectedUsers((prev) => ({
                                    ...prev,
                                    [item?._id]: true,
                                  }))
                                }
                              >
                                Add User
                              </Button>
                            )}
                          </div>
                        }
                      >
                        <List.Item.Meta
                          className={"flex-center"}
                          title={
                            <>
                              <Typography
                                className={typographyStyles.body2}
                                style={{ wordBreak: "normal" }}
                              >
                                {item?.first_name || ""}
                              </Typography>
                              <Space style={{ gap: 0 }}>
                                {item?.department?.name && (
                                  <Tag
                                    className={tagStyles.smRndTag}
                                    color="magenta"
                                  >
                                    {item?.department?.name}
                                  </Tag>
                                )}
                                {item?.role && displayUserRole(item?.role)}
                              </Space>
                            </>
                          }
                        />
                      </List.Item>
                    </>
                  </div>
                );
              }}
            />
          </>
        )}
      </Form>
    </Drawer>
  );
};

export default CreateOrEditGroup;
