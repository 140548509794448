import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Tag,
  Typography,
  DatePicker,
  message,
  Flex,
  Pagination,
  Skeleton,
  Result,
  Empty,
} from "antd";
import React, { useEffect, useState } from "react";
import { getAssetAuditLogsApi } from "../../../services/assets.services";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertUTCToLocalFormat,
  convertUtcToIst,
  debounce,
  workOrderstatusList,
} from "../../../helpers/utility";
import dayjs from "dayjs";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import CustomLayout from "../../Layout";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import { PAGESIZE } from "../../../constants/defaultKeys";

const { RangePicker } = DatePicker;

const AuditLogsTable = ({ child = false, setAssetDetails }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [filteredLogs, setFilteredLogs] = useState([]);
  // const [search, setSearch] = useState("");
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "month"),
    end_date: dayjs().add(1, "month"),
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const getAuditLogs = async (download = false) => {
    let query = {};
    if (download) {
      query = {
        ...query,
        download: true,
      };
    } else {
      query = { ...query, page: pageNumber };
    }
    query = {
      ...query,
      start_date: dateFilter.start_date
        ? dayjs(dateFilter.start_date).format("YYYY-MM-DD")
        : undefined,
      end_date: dateFilter.end_date
        ? dayjs(dateFilter.end_date).format("YYYY-MM-DD")
        : undefined,
    };

    download ? setDownloadLoading(true) : setLoading(true);
    try {
      const response = await getAssetAuditLogsApi(query, download);

      if (download) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "audit_logs.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        setLogs(response?.data);
        // const fetchedLogs = response.data;
        // setLogs(child ? fetchedLogs.slice(0, 7) : fetchedLogs);
        // setFilteredLogs(child ? fetchedLogs.slice(0, 7) : fetchedLogs);
        // setAssetDetails &&
        //   setAssetDetails({
        //     name: fetchedLogs[0]?.asset?.name,
        //     code: fetchedLogs[0]?.asset?.code,
        //   });
      }
    } catch (error) {
      console.error("Error fetching audit logs:", error);
      if (download) {
        message.error("Error downloading audit logs");
      }
    } finally {
      download ? setDownloadLoading(false) : setLoading(false);
    }
  };

  useEffect(() => {
    getAuditLogs();
  }, [pageNumber, refresh]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [dateFilter]);

  // useEffect(() => {
  //   const filtered = logs?.filter((log) => {
  //     const workorderCode = log?.workorder?.code || "";
  //     const occurrenceCode = log?.occurrence?.code || "";
  //     const searchLower = search.toLowerCase();
  //     return (
  //       !search ||
  //       workorderCode.toLowerCase().includes(searchLower) ||
  //       occurrenceCode.toLowerCase().includes(searchLower)
  //     );
  //   });
  //   setFilteredLogs(filtered);
  // }, [search, logs]);

  const columns = [
    {
      title: <Typography className={"tableHeader"}>Asset</Typography>,
      dataIndex: "asset",
      key: "asset",
      width: 300,
      render: (val) => (val?.name ? val?.name : "-"),
    },
    {
      title: <Typography className="tableHeader">Date</Typography>,
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      render: (val, record) => {
        let currDate =
          record?.workorder?.start_date || record?.occurrence?.occurrence_date;
        return currDate
          ? dayjs(convertUTCToLocalFormat(currDate)).format("DD MMM YYYY")
          : "-";
      },
      // render: (text, record) => (
      //   <>
      //     {convertUtcToIst(
      //       record?.workorder?.start_date ||
      //         record?.occurrence?.occurrence_date,
      //       false
      //     )}
      //   </>
      // ),
    },
    {
      title: <Typography className="tableHeader">Time</Typography>,
      dataIndex: "time",
      key: "time",
      width: 150,
      render: (val, record) => {
        let currDate =
          record?.workorder?.start_date || record?.occurrence?.occurrence_date;
        return currDate
          ? dayjs(convertUTCToLocalFormat(currDate)).format("hh:mm A")
          : "-";
      },
      // render: (text, record) => (
      //   <>
      //     {dayjs(
      //       record?.workorder?.start_time || record?.occurrence?.start_time,
      //       "HH:mm:ss"
      //     ).format("hh:mm A")}
      //   </>
      // ),
    },
    {
      title: <Typography className="tableHeader">Task code</Typography>,
      dataIndex: "workorder",
      key: "workorder",
      width: 150,
      render: (_, record) => {
        let taskId = record?.workorder?._id || record?.occurrence?._id || "";
        let taskCode =
          record?.workorder?.code || record?.occurrence?.code || "";
        let taskType = taskCode?.includes("WO")
          ? "work-orders"
          : taskCode?.includes("INS")
          ? "inspections"
          : "maintenances";
        if (taskId) {
          return (
            <Typography
              style={{ color: "#1677ff", cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/${taskType}/${taskId}${
                    taskType === "work-orders" ? "" : "/job"
                  }`,
                  { state: { source: "/audit-logs" } }
                )
              }
            >
              {taskCode}
            </Typography>
          );
        } else {
          return "-";
        }
      },
      // render: (text, record) => {
      //   const { _id: workorderId, code: workorderCode } =
      //     record?.workorder || {};
      //   const { _id: occurrenceId, code: occurrenceCode } =
      //     record?.occurrence || {};
      //   if (workorderId) {
      //     return (
      //       <a href={`/work-orders/${workorderId}`}>{workorderCode || "-"}</a>
      //     );
      //   } else if (occurrenceId) {
      //     let jobType = occurrenceCode?.includes("INS")
      //       ? "inspections"
      //       : "maintenances";
      //     return (
      //       <a href={`/${jobType}/${occurrenceId}/job`}>
      //         {occurrenceCode || "-"}
      //       </a>
      //     );
      //   }
      //   return workorderCode || occurrenceCode || "-";
      // },
    },
    {
      title: <Typography className="tableHeader">Task name</Typography>,
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (text, record) => {
        const workorderName = record?.workorder?.name;
        const occurrenceName = record?.occurrence?.maintenance?.name;
        return <span>{workorderName || occurrenceName || "-"}</span>;
      },
      // display: child ? false : true,
    },
    {
      title: <Typography className="tableHeader">Status</Typography>,
      dataIndex: "status",
      key: "status",
      width: 160,
      render: (text, record) => {
        const status = record?.workorder?.status || record?.occurrence?.status;
        const value = workOrderstatusList.find(
          (statusItem) => statusItem.value === status
        );
        return value ? (
          <Tag
            className={tagStyles.smTag}
            style={{ fontWeight: "600", minWidth: "80px", textAlign: "left" }}
            color={value.color}
          >
            {value.label}
          </Tag>
        ) : (
          "-"
        );
      },
    },
  ];
  // .filter((column) => column.display !== false);

  // const handleSearch = debounce((e) => setSearch(e.target.value), 500);

  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography className={typoStyles.h6}>Audit Logs</Typography>
        </Flex>
      }
    >
      <Row gutter={[12, 12]}>
        {/* {!child && ( */}
        <Col
          offset={12}
          span={12}
          style={{ gap: "12px", justifyContent: "flex-end" }}
          className="flex-start"
        >
          {/* <Input.Search
            size="medium"
            onChange={handleSearch}
            placeholder="Search by WO or PM code"
            style={{ width: "280px" }}
          /> */}
          <RangePicker
            format="DD-MMM-YYYY"
            defaultValue={[dateFilter.start_date, dateFilter.end_date]}
            onChange={(_, dateString) =>
              setDateFilter({
                start_date: dateString[0],
                end_date: dateString[1],
              })
            }
          />
          <Button
            onClick={() => getAuditLogs(true)}
            type="primary"
            loading={downloadLoading}
          >
            Download Report
          </Button>
        </Col>
        {/* )} */}
        <Col span={24}>
          <Table
            className="tableContainer"
            loading={
              loading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
            // scroll={{ x: child && 500 }}
            columns={columns}
            dataSource={logs?.logs}
            pagination={false}
            scroll={{
              x: 1200,
              y: "60vh",
            }}
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No logs found</Typography>}
                />
              ),
            }}
          />
        </Col>
        {logs?.totalLogs > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={logs?.totalLogs}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
      </Row>
    </CustomLayout>
  );
};

export default AuditLogsTable;
