import {
  BookOutlined,
  EditOutlined,
  HeartOutlined,
  LeftOutlined,
  MoreOutlined,
  RightOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { FloatButton, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { deleteCond } from "../../../../helpers/utility";
import { editTyut } from "../../../../constants/defaultKeys";
import { UserContext } from "../../../../context/UserProvider";
import {
  bookmarkTyutByIdApi,
  getTyutsListApi,
  likeTyutByIdApi,
} from "../../../../services/tyuts.services";
import { useLocation, useNavigate } from "react-router-dom";

const FloatinButtons = ({
  tyutData,
  currentTyut,
  // tyutIds,
  setTyutData,
  setCurrentTyut,
  shouldFetchTyut,
  setIsModalOpen,
  setIsEditOpen,
}) => {
  const { userDetails } = useContext(UserContext);
  const [isTyutLiked, setIsTyutLiked] = useState(false);
  const [tyutIds, settyutIds] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  //   const canShareTyut = usePermission("can_share_tyut");
  const canShareTyut = true;

  async function likeTyut(id, data) {
    try {
      const res = await likeTyutByIdApi(id, data);
      if (res) {
        tyutData.like = !tyutData?.like;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getCoursesAndTyutsList() {
    try {
      const { data } = await getTyutsListApi();
      settyutIds(data?.tyuts);
    } catch (error) {
      console.error(error);
      message.error(error.response?.data?.message);
    }
  }

  async function bookmark(id, data) {
    try {
      const res = await bookmarkTyutByIdApi(id, data);
      if (res) {
        setTyutData((prev) => ({ ...prev, bookmark: data?.bookmark }));
        message.success(res?.data?.message);
      }
    } catch (error) {
      message.error(error.response?.data?.message);
    } finally {
      //   setLoading(false);
    }
  }

  const handleNext = () => {
    if (currentTyut < tyutIds.length) {
      setCurrentTyut(currentTyut + 1);
      navigate(`/trainings/tyut/${tyutIds[currentTyut]}`, undefined, {
        shallow: true,
      });
    }
  };

  const handlePrevious = () => {
    if (currentTyut > 0) {
      setCurrentTyut(currentTyut - 1);
      navigate(`/trainings/tyut/${tyutIds[currentTyut - 1]}`, undefined, {
        shallow: true,
      });
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "ArrowLeft" && shouldFetchTyut) handlePrevious();
      else if (e.key === "ArrowRight" && shouldFetchTyut) handleNext();
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentTyut, location]);

  useEffect(() => {
    setIsTyutLiked(tyutData?.like);
  }, [tyutData]);

  useEffect(() => {
    getCoursesAndTyutsList();
  }, []);

  return (
    <FloatButton.Group
      trigger={""}
      shape="circle"
      type="primary"
      style={{
        right: 28,
        bottom: "60px",
      }}
      icon={<MoreOutlined />}
    >
      {/* <FloatButton
        tooltip={isTyutLiked ? "Unlike tyut" : "Like tyut"}
        icon={
          <HeartOutlined
            className={"activeIconTyut"}
            style={
              isTyutLiked ? { border: "red", color: "red" } : { color: "black" }
            }
          />
        }
        // type="primary"
        onClick={() => {
          setIsTyutLiked(!isTyutLiked);
          likeTyut(tyutData?._id, {
            like: !isTyutLiked,
          });
        }}
      /> */}
      {tyutData && canShareTyut && (
        <FloatButton
          tooltip={"Share tyut"}
          icon={
            <ShareAltOutlined
              type="primary"
              onClick={() => setIsModalOpen(true)}
              size="large"
            />
          }
        />
      )}
      {/* <FloatButton
        tooltip={
          tyutData?.bookmark ? "Remove from Watch it later" : "Watch it later"
        }
        icon={
          <BookOutlined
            onClick={() =>
              bookmark(tyutData?._id, {
                bookmark: !tyutData?.bookmark,
              })
            }
          />
        }
      /> */}
      {/* {deleteCond(
        editTyut,
        tyutData?.creator?._id,
        userDetails?.activeOrg?.role,
        userDetails?._id
      ) && (
        <FloatButton
          tooltip="Edit"
          icon={<EditOutlined onClick={() => setIsEditOpen((prev) => !prev)} />}
        />
      )} */}
      <FloatButton
        tooltip={currentTyut === tyutIds?.length ? "There is no next" : "Next"}
        icon={
          <RightOutlined
            onClick={handleNext}
            disabled={currentTyut === tyutIds?.length}
          />
        }
      />
      <FloatButton
        tooltip={currentTyut === 0 ? "There is no previous " : "Previous"}
        icon={
          <LeftOutlined
            onClick={handlePrevious}
            disabled={tyutIds?.length === 0}
          />
        }
      />

      <FloatButton.BackTop tooltip="Scroll to top" />
    </FloatButton.Group>
  );
};

export default FloatinButtons;
