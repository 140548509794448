import { Card, Col, DatePicker, Row, Segmented, Space, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../Layout";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import AssetsTableView from "./AssetsTable";
import { getMWStatsApi } from "../../services/dashboard.services";
import {
  formatHours,
  maintanceStatuses,
  minutesToHoursAndMinutes,
  workOrderstatusList,
} from "../../helpers/utility";
import "./Dashboard.css";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  Cell,
  LabelList,
} from "recharts";
import dayjs from "dayjs";
import segmentedStyles from "../../assets/styles/Custom/Segmented.module.css";
import SparePartPieChart from "./SparePartPieChart";
import WaffleChart from "./WaffleChart";
// import { UserContext } from "../../context/UserProvider";
import EmployeeMetrics from "./EmployeeMetrics";
import { UserContext } from "../../context/UserProvider";
import BreakdownAnalysis from "./BreakdownAnalysis";
import MaintenanceMetrics from "./MaintenanceMetrics";

const { RangePicker } = DatePicker;
const Dashboard = () => {
  // const [stats, setStats] = useState({ maintenances: {}, workorders: {} });
  // const [dateFilter, setDateFilter] = useState({
  //   start_date: dayjs().subtract(1, "month"),
  //   end_date: dayjs().add(1, "month"),
  // });
  const [selectedTab, setSelectedTab] = useState("maintenance-metrics");

  const { userDetails } = useContext(UserContext);
  // const activeOrgId = userDetails?.activeOrg?.organization?._id;
  // const showDefaultTime =
  //   activeOrgId === "66c59162a4e681d334e25139" ? true : false;

  // const getStats = async () => {
  //   try {
  //     let params = {};
  //     if (dateFilter?.start_date) {
  //       params.start_date = dayjs(dateFilter?.start_date).format("YYYY-MM-DD");
  //     }
  //     if (dateFilter?.end_date) {
  //       params.end_date = dayjs(dateFilter?.end_date).format("YYYY-MM-DD");
  //     }
  //     const queryString = new URLSearchParams(params);
  //     const resp = await getMWStatsApi(queryString);
  //     setStats(resp?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   selectedTab === "analytics" && getStats();
  // }, [dateFilter, selectedTab]);

  // const data = maintanceStatuses?.map((eac, ibdex) => ({
  //   name: eac?.label,
  //   count: stats?.maintenances?.statusCounts?.[eac?.value] || 0,
  //   color: eac?.color,
  // }));

  // const inspectionData = maintanceStatuses?.map((eac, ibdex) => ({
  //   name: eac?.label,
  //   count: stats?.inspections?.statusCounts?.[eac?.value] || 0,
  //   color: eac?.color,
  // }));

  // const displayFormattedDuration = (
  //   value,
  //   color = "#7369F4",
  //   fontSizeLevel = "h5"
  // ) => {
  //   let durationObj = formatHours(Number(value) > 0 ? Number(value) : 0);
  //   return (
  //     <Typography
  //       className={typoStyles[fontSizeLevel]}
  //       style={{ color: color }}
  //     >
  //       {durationObj?.days ? durationObj?.days : ""}
  //       {durationObj?.days ? (
  //         <span className={typoStyles.body3}> days </span>
  //       ) : (
  //         ""
  //       )}
  //       {durationObj?.hours}
  //       <span className={typoStyles.body3}> hrs </span>
  //       {durationObj?.minutes}
  //       <span className={typoStyles.body3}> mins </span>
  //     </Typography>
  //   );
  // };

  // const displayStatsCard = (title, value, type, spanValue, subtitle = "") => {
  //   return (
  //     <Col span={spanValue} style={{ display: "flex" }}>
  //       <div
  //         className="flex-center"
  //         style={{
  //           width: "100%",
  //           padding: "12px",
  //           flexDirection: "column",
  //           gap: "8px",
  //           border: "2px solid #EAECF0",
  //           // borderColor:
  //           //   type === "warning"
  //           //     ? "rgba(255, 190, 11, 0.6)"
  //           //     : type === "danger"
  //           //     ? "rgba(255, 85, 0, 0.6)"
  //           //     : "#EAECF0",
  //           borderRadius: "12px",
  //         }}
  //       >
  //         <Typography
  //           className={typoStyles.body3}
  //           style={{ fontWeight: 500, textAlign: "center" }}
  //         >
  //           {title}
  //         </Typography>
  //         {subtitle !== "" && (
  //           <Typography
  //             className={typoStyles.body1}
  //             style={{ textAlign: "center" }}
  //           >
  //             {subtitle}
  //           </Typography>
  //         )}
  //         {type === "duration" ? (
  //           displayFormattedDuration(value)
  //         ) : (
  //           <Typography
  //             className={typoStyles.h5}
  //             style={{
  //               color: "#7369F4",
  //               marginTop: 10,
  //               // marginTop: type === "danger" ? 12 : 36,
  //             }}
  //           >
  //             {value}
  //           </Typography>
  //         )}
  //       </div>
  //     </Col>
  //   );
  // };

  // const displayTitleValue = ({
  //   title,
  //   value,
  //   type = "",
  //   dotColor = "",
  //   valueColor = "#3D5A80",
  //   valueFontSize = "subtext1",
  //   mb = 0,
  // }) => {
  //   return (
  //     <Space
  //       style={{
  //         width: "100%",
  //         justifyContent: "space-between",
  //         marginBottom: mb,
  //       }}
  //     >
  //       <Space style={{ gap: 12 }}>
  //         {dotColor && (
  //           <div
  //             style={{
  //               width: 16,
  //               height: 16,
  //               backgroundColor: dotColor,
  //               borderRadius: 4,
  //             }}
  //           ></div>
  //         )}
  //         <Typography className={typoStyles.body3} style={{ fontWeight: 500 }}>
  //           {title}
  //         </Typography>
  //       </Space>
  //       {type === "duration" ? (
  //         displayFormattedDuration(value, valueColor, valueFontSize)
  //       ) : (
  //         <Typography
  //           className={typoStyles[valueFontSize]}
  //           style={{ color: valueColor }}
  //         >
  //           {value}
  //         </Typography>
  //       )}
  //     </Space>
  //   );
  // };

  return (
    <CustomLayout
      header={<Typography className={typoStyles.h6}>Dashboard</Typography>}
    >
      <Row gutter={[24, 12]}>
        <Col span={24} className="flex-start">
          <Segmented
            id="analytics-asset-view"
            className={segmentedStyles.basicOne}
            options={[
              { value: "maintenance-metrics", label: "Maintenance Metrics" },
              { value: "breakdown-analysis", label: "Breakdown Analysis" },
              { value: "asset-health-data", label: "Asset Health Data" },
              ...(userDetails?.activeOrg?.role === "admin"
                ? [{ value: "employee-metrics", label: "Employee Metrics" }]
                : []),
            ]}
            onChange={(e) => setSelectedTab(e)}
            value={selectedTab}
          />
        </Col>
        {/* {selectedTab === "analytics" && (
          <Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <RangePicker
              format="DD-MMM-YYYY"
              defaultValue={[dateFilter.start_date, dateFilter.end_date]}
              onChange={(_, dateString) => {
                setDateFilter({
                  start_date: dateString[0],
                  end_date: dateString[1],
                });
              }}
            />
          </Col>
        )} */}
        {/* {selectedTab === "analytics" ? (
          <Col
            span={24}
            style={{ paddingBottom: 20, height: "81vh", overflowY: "auto" }}
          >
            <Row gutter={[24, 24]}>
              <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
                <Card
                  className="mainCards"
                  styles={{
                    header: {
                      border: "0px",
                    },
                  }}
                  title={"MROs"}
                  style={{ width: "100%" }}
                >
                  <Row gutter={[18, 0]}>
                    {displayStatsCard(
                      "Non Critical",
                      stats?.mros?.nonCriticalMROCount || 0,
                      "warning",
                      6
                    )}
                    <Col span={18}>
                      <Row gutter={[18, 12]}>
                        {displayStatsCard(
                          "Critical (Breakdown events)",
                          stats?.mros?.criticalMROCount || 0,
                          "warning",
                          24
                        )}
                        {displayStatsCard(
                          "Mechanical",
                          stats?.mros?.criticalMechanicalCount || 0,
                          "warning",
                          8
                        )}
                        {displayStatsCard(
                          "Electrical",
                          stats?.mros?.criticalElectricalCount || 0,
                          "warning",
                          8
                        )}
                        {displayStatsCard(
                          "Others",
                          stats?.mros?.criticalOthersCount || 0,
                          "warning",
                          8
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
                <Card
                  title="Breakdown time"
                  className="mainCards"
                  styles={{
                    header: {
                      border: "0px",
                    },
                  }}
                  style={{ width: "100%" }}
                >
                  <Row gutter={[12, 12]}>
                    <Col
                      span={14}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Space direction="vertical" style={{ width: "100%" }}>
                        {displayTitleValue({
                          title: "Total Breakdown time",
                          value: stats?.mros?.totalCriticalMROTime,
                          // value:
                          //   activeOrgId === "66c59162a4e681d334e25139"
                          //     ? 80.183
                          //     : stats?.mros?.totalCriticalMROTime,
                          type: "duration",
                          valueColor: "#7369F4",
                          valueFontSize: "h5",
                          mt: 8,
                        })}
                        {displayTitleValue({
                          title: "Others",
                          value: stats?.mros?.criticalOthersTime,
                          // value:
                          //   activeOrgId === "66c59162a4e681d334e25139"
                          //     ? 75.05
                          //     : stats?.mros?.criticalOthersTime,
                          type: "duration",
                          dotColor: "#DCDCDC",
                        })}
                        {displayTitleValue({
                          title: "Electrical",
                          value: stats?.mros?.criticalElectricalTime,
                          // value:
                          //   activeOrgId === "66c59162a4e681d334e25139"
                          //     ? 2.966
                          //     : stats?.mros?.criticalElectricalTime,
                          type: "duration",
                          dotColor: "#FFA384",
                        })}
                        {displayTitleValue({
                          title: "Mechanical",
                          value: stats?.mros?.criticalMechanicalTime,
                          // value:
                          //   activeOrgId === "66c59162a4e681d334e25139"
                          //     ? 2.166
                          //     : stats?.mros?.criticalMechanicalTime,
                          type: "duration",
                          dotColor: "#CB6767",
                        })}
                      </Space>
                    </Col>
                    <Col span={10}>
                      <WaffleChart
                        height={240}
                        total={stats?.mros?.totalCriticalMROTime || 0}
                        // total={
                        //   activeOrgId === "66c59162a4e681d334e25139"
                        //     ? 80.183
                        //     : stats?.mros?.totalCriticalMROTime || 0
                        // }
                        data={[
                          {
                            id: "Mechanical",
                            label: "Mechanical",
                            value: stats?.mros?.criticalMechanicalTime || 0,
                            // value:
                            //   activeOrgId === "66c59162a4e681d334e25139"
                            //     ? 2.166
                            //     : stats?.mros?.criticalMechanicalTime || 0,
                          },
                          {
                            id: "Electrical",
                            label: "Electrical",
                            value: stats?.mros?.criticalElectricalTime || 0,
                            // value:
                            //   activeOrgId === "66c59162a4e681d334e25139"
                            //     ? 2.966
                            //     : stats?.mros?.criticalElectricalTime || 0,
                          },
                          {
                            id: "Others",
                            label: "Others",
                            value: stats?.mros?.criticalOthersTime || 0,
                            // value:
                            //   activeOrgId === "66c59162a4e681d334e25139"
                            //     ? 75.05
                            //     : stats?.mros?.criticalOthersTime || 0,
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
                <Card
                  className="mainCards"
                  styles={{
                    header: {
                      border: "0px",
                    },
                  }}
                  title={"Work Orders"}
                  style={{ width: "100%" }}
                >
                  <Row gutter={[18, 12]}>
                    <Col span={12}>
                      <Row gutter={[12, 12]}>
                        {workOrderstatusList?.map((eachWO) => {
                          return (
                            <>
                              {eachWO.value !== "pending" && (
                                <Col span={12}>
                                  <div
                                    className="flex-center"
                                    style={{
                                      padding: "24px",
                                      flexDirection: "column",
                                      backgroundColor: eachWO?.color,
                                      color: "#fff",
                                      gap: "8px",
                                      height: "114px",
                                      border: "2px solid #EAECF0",
                                      borderRadius: "12px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 12,
                                        color: "#fff",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {" "}
                                      {eachWO.label}
                                    </span>
                                    <span
                                      className={typoStyles.h5}
                                      style={{ lineHeight: "38px" }}
                                    >
                                      {stats?.workorders?.statusCounts?.[
                                        eachWO?.value
                                      ] || 0}
                                    </span>
                                  </div>
                                </Col>
                              )}
                            </>
                          );
                        })}
                      </Row>
                    </Col>
                    <Col span={12}>
                      <div
                        style={{
                          border: "2px solid #EAECF0",
                          borderRadius: "12px",
                          padding: "12px",
                          flexDirection: "column",
                          gap: "8px",
                          height: "240px",
                          justifyContent: "space-around",
                        }}
                        className="flex-center"
                      >
                        <Typography
                          className={typoStyles.body3}
                          style={{ fontWeight: 500, textAlign: "center" }}
                        >
                          WO Completion Time (WOCT){" "}
                        </Typography>
                        {displayFormattedDuration(
                          stats?.workorders?.totalDuration
                        )}
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#3D5A80",
                            textAlign: "center",
                          }}
                        >
                          Total time spent completing workorders / Number of
                          workorders completed
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
                <Card
                  title="Spare Parts"
                  className="mainCards"
                  styles={{
                    header: {
                      border: "0px",
                    },
                  }}
                  style={{ width: "100%" }}
                >
                  <Row gutter={[12, 12]}>
                    <Col
                      // offset={2}
                      span={14}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Space direction="vertical" style={{ width: "100%" }}>
                        {displayTitleValue({
                          title: "Total Spare Parts Expenditure",
                          value: `₹ ${
                            stats?.inventories?.totalOverallCost || 0
                          }`,
                          valueColor: "#7369F4",
                          valueFontSize: "h5",
                          mt: 8,
                        })}
                        {displayTitleValue({
                          title: "Others",
                          value: `₹ ${
                            stats?.inventories?.totalOthersCost || 0
                          }`,
                          dotColor: "#8BC1F7",
                        })}
                        {displayTitleValue({
                          title: "Electrical",
                          value: `₹ ${
                            stats?.inventories?.totalElectricalCost || 0
                          }`,
                          dotColor: "#519DE9",
                        })}
                        {displayTitleValue({
                          title: "Mechanical",
                          value: `₹ ${
                            stats?.inventories?.totalMechanicalCost || 0
                          }`,
                          dotColor: "#06C",
                        })}
                      </Space>
                    </Col>
                    <Col
                      span={10}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <SparePartPieChart
                        total={stats?.inventories?.totalOverallCost || 0}
                        data={[
                          {
                            name: "Others",
                            value: stats?.inventories?.totalOverallCost
                              ? stats?.inventories?.totalOthersCost || 0
                              : 1,
                          },
                          {
                            name: "Electrical",
                            value: stats?.inventories?.totalOverallCost
                              ? stats?.inventories?.totalElectricalCost || 0
                              : 0,
                          },
                          {
                            name: "Mechanical",
                            value: stats?.inventories?.totalOverallCost
                              ? stats?.inventories?.totalMechanicalCost || 0
                              : 0,
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
                <Card
                  title="Preventive Maintenance"
                  className="mainCards"
                  styles={{
                    header: {
                      border: "0px",
                    },
                  }}
                  style={{ width: "100%" }}
                >
                  <Row gutter={[12, 12]}>
                    <Col span={14}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={600}
                          height={280}
                          data={data}
                          margin={{
                            top: 18,
                            right: 5,
                            left: 5,
                            bottom: 5,
                          }}
                        >
                          <XAxis
                            dataKey="name"
                            angle={-20}
                            dy={8}
                            tick={{ fontSize: 12 }}
                          />
                          <Bar
                            dataKey="count"
                            fill={"#108ee9"}
                            barSize={30}
                            radius={[6, 6, 0, 0]}
                          >
                            <LabelList dataKey="count" position="top" />
                            {data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry?.color} />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </Col>
                    <Col
                      span={10}
                      style={{
                        border: "2px solid #EAECF0",
                        borderRadius: "12px",
                        padding: "24px",
                        flexDirection: "column",
                        gap: "8px",
                        height: "240px",
                        justifyContent: "space-around",
                      }}
                      className="flex-center"
                    >
                      <Typography
                        className={typoStyles.body3}
                        style={{ fontWeight: 500, textAlign: "center" }}
                      >
                        Schedule Compliance{" "}
                      </Typography>
                      <Typography
                        className={typoStyles.h5}
                        style={{ color: "#7369F4" }}
                      >
                        {stats?.maintenances?.completedWithinTime || 0} /{" "}
                        {stats?.maintenances?.totalDocuments || 0}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#3D5A80",
                          textAlign: "center",
                        }}
                      >
                        PMs completed on time / Number of PMs completed
                      </Typography>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
                <Card
                  title="Inspections"
                  className="mainCards"
                  styles={{
                    header: {
                      border: "0px",
                    },
                  }}
                  style={{ width: "100%" }}
                >
                  <Row gutter={[12, 12]}>
                    <Col span={14}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={600}
                          height={280}
                          data={inspectionData}
                          margin={{
                            top: 18,
                            right: 5,
                            left: 5,
                            bottom: 5,
                          }}
                        >
                          <XAxis
                            dataKey="name"
                            angle={-20}
                            dy={8}
                            tick={{ fontSize: 12 }}
                          />
                          <Bar
                            dataKey="count"
                            fill={"#108ee9"}
                            barSize={30}
                            radius={[6, 6, 0, 0]}
                          >
                            <LabelList dataKey="count" position="top" />
                            {inspectionData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry?.color} />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </Col>
                    <Col
                      span={10}
                      style={{
                        border: "2px solid #EAECF0",
                        borderRadius: "12px",
                        padding: "24px",
                        flexDirection: "column",
                        gap: "8px",
                        height: "240px",
                        justifyContent: "space-around",
                      }}
                      className="flex-center"
                    >
                      <Typography
                        className={typoStyles.body3}
                        style={{ fontWeight: 500, textAlign: "center" }}
                      >
                        Schedule Compliance{" "}
                      </Typography>
                      <Typography
                        className={typoStyles.h5}
                        style={{ color: "#7369F4" }}
                      >
                        {stats?.maintenances?.completedWithinTime || 0} /{" "}
                        {stats?.maintenances?.totalDocuments || 0}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#3D5A80",
                          textAlign: "center",
                        }}
                      >
                        Inspections completed on time / Number of Inspections
                        completed
                      </Typography>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col> */}
        {selectedTab === "breakdown-analysis" ? (
          <BreakdownAnalysis />
        ) : selectedTab === "maintenance-metrics" ? (
          <MaintenanceMetrics />
        ) : selectedTab === "asset-health-data" ? (
          <AssetsTableView />
        ) : (
          <EmployeeMetrics />
        )}
      </Row>
    </CustomLayout>
  );
};

export default Dashboard;
