import { message } from "antd";
import { generateRandomString } from "../../../../helpers/utility";
import {
  INPSECTION_CHECKS,
  MAINTANANCE,
  POSTMAINTANCNE,
  SAFETY,
  TROUBLESHOOTING,
} from "../../../../constants/defaultKeys";

export const sectionOptionsList = {
  [MAINTANANCE]: {
    Done: {
      enable: [],
      helperText: "Done help text",
      images: [],
      color: "#87d068",
    },
    "Not Done": {
      enable: [],
      helperText: "Not done help text",
      images: [],
      color: "#f50",
    },
  },

  [POSTMAINTANCNE]: {
    Done: {
      enable: [],
      helperText: "Done help text",
      images: [],
      color: "#87d068",
    },
    "Not Done": {
      enable: [],
      helperText: "Not done help text",
      images: [],
      color: "#f50",
    },
  },
  [INPSECTION_CHECKS]: {
    Pass: {
      enable: [],
      helperText: "Pass help text",
      images: [],
      color: "#87d068",
    },
    Fail: {
      enable: [],
      helperText: "Fail help text",
      images: [],
      color: "#f50",
    },
    Warning: {
      enable: [],
      helperText: "Warning help text",
      images: [],
      color: "#fac515",
    },
  },
  [SAFETY]: {
    Yes: {
      enable: [],
      helperText: "yes help text",
      images: [],
      color: "#87d068",
    },
    No: {
      enable: [],
      helperText: "No help text",
      images: [],
      color: "#f50",
    },
  },
  [TROUBLESHOOTING]: {
    Yes: {
      enable: [],
      helperText: "yes help text",
      images: [],
      color: "#87d068",
    },
    No: {
      enable: [],
      helperText: "No help text",
      images: [],
      color: "#f50",
    },
  },
};

export const addSectionOrSectionQues = (
  sopContent,
  setSopContent,
  setEditing,
  sectionTitle,
  sampleQues,
  ref
) => {
  const existSafetySection = sopContent?.some(
    (eachSection) =>
      eachSection?.title?.toLowerCase() === sectionTitle?.toLowerCase()
  );
  const quesId = generateRandomString(12);
  if (existSafetySection) {
    // Create a copy of sopContent
    const updatedSopContent = [...sopContent];
    // Find the section titled sectionTitle
    const safetySection = updatedSopContent?.find(
      (eachSection) =>
        eachSection?.title?.toLowerCase() === sectionTitle?.toLowerCase()
    );
    if (!safetySection.procedures) {
      safetySection.procedures = [];
    }

    // Push the new procedure to the procedures array of the safety section
    safetySection?.procedures?.push({
      id: quesId,
      type: "section",
      parentId: safetySection?.id,
      question: sampleQues,
      things: sectionOptionsList[sectionTitle?.toLowerCase()],
      options: Object.keys(sectionOptionsList[sectionTitle?.toLowerCase()]) || [
        "Yes",
        "No",
      ],
      images: [],
      answers: [],
    });
    setEditing({ edit: false, data: { id: safetySection?.id } });

    // Set the state with the updated sopContent
    setSopContent(updatedSopContent);
  } else {
    const parentId = generateRandomString(12);
    setEditing({ edit: false, data: { id: parentId } });
    setSopContent([
      ...sopContent,
      {
        id: parentId,
        procedures: [
          {
            id: quesId,
            type: "section",
            parentId: parentId,
            question: sampleQues,
            things: sectionOptionsList[sectionTitle?.toLowerCase()],
            options: Object.keys(
              sectionOptionsList[sectionTitle?.toLowerCase()]
            ) || ["Yes", "No"],
            images: [],
            answers: [],
          },
        ],
        type: "section",
        title:
          sectionTitle?.length > 0
            ? sectionTitle.charAt(0).toUpperCase() +
              sectionTitle.slice(1).toLowerCase()
            : "",
        answers: [],
      },
    ]);
  }
  setTimeout(() => {
    const elem = document.getElementById(quesId);
    if (elem) {
      elem.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, 500);
};
function scrollQuestionnaireInToView(ref) {
  if (ref.current) {
    ref.current.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
    setIsUnfilled("red");
  }
}

export const getButtonsLsit = (
  sopContent,
  setSopContent,
  setEditing,
  ref,
  setModalOpen,
  setActiveTab
) => {
  return {
    "safety precautions and guidelines": {
      desc: "Safety",
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          addSectionOrSectionQues(
            sopContent,
            setSopContent,
            setEditing,
            "Safety precautions and guidelines",
            "Sample Safety",
            ref
          );
        }
      },
    },
    "inspection & checks": {
      desc: "Inspection",
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          addSectionOrSectionQues(
            sopContent,
            setSopContent,
            setEditing,
            "Inspection & checks",
            "Sample Inspection & Checks",
            ref
          );
        }
      },
    },
    "step-by-step maintenance": {
      desc: "Maintanance",
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          addSectionOrSectionQues(
            sopContent,
            setSopContent,
            setEditing,
            "Step-by-step maintenance",
            "Sample Step-by-Step Maintenance",
            ref
          );
        }
      },
    },
    "post-maintenance check and clean-up": {
      desc: "Post Maintanance",
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          addSectionOrSectionQues(
            sopContent,
            setSopContent,
            setEditing,
            "Post-maintenance check and clean-up",
            "Sample Post-Maintenance Check and Clean-up",
            ref
          );
        }
      },
    },
    troubleshooting: {
      desc: "Troubleshooting Guide",
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          addSectionOrSectionQues(
            sopContent,
            setSopContent,
            setEditing,
            "Troubleshooting",
            "Sample Troubleshooting",
            ref
          );
        }
      },
    },
    text: {
      desc: "Text",
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this text.");
        } else {
          const sopId = generateRandomString(12);
          setEditing({ edit: null, data: { id: sopId } });
          setSopContent([
            ...sopContent,
            {
              id: sopId,
              type: "text",
              text: "",
              edit: true,
            },
          ]);
        }
      },
    },
    question: {
      desc: "Question",
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          const sopId = generateRandomString(12);
          setEditing && setEditing({ edit: null, data: { id: sopId } });
          setSopContent([
            ...sopContent,
            {
              id: sopId,
              question: "",
              choices: ["Yes", "No"],
              type: "radio",
              edit: true,
            },
          ]);
        }
      },
    },
    checklist: {
      desc: "Checklist",
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          const sopId = generateRandomString(12);
          setEditing({ edit: null, data: { id: sopId } });
          setSopContent([
            ...sopContent,
            {
              id: sopId,
              question: "",
              choices: ["Yes"],
              type: "checkbox",
              edit: true,
            },
          ]);
        }
      },
    },
    dispatchEventocument: {
      desc: "Document",
      onClick: () => {
        setModalOpen && setModalOpen({ open: true, type: "document" });
        setActiveTab && setActiveTab("add_doc_link");
      },
    },
    video: {
      desc: "Video",
      onClick: () => {
        setModalOpen && setModalOpen({ open: true, type: "video" });
        setActiveTab && setActiveTab("add_yt_link");
      },
    },
  };
};
