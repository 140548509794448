import React from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Area,
  ComposedChart,
} from "recharts";
import "../Assets.css";
import { convertUTCToLocalFormat } from "../../../helpers/utility";

const DownTimeChart = ({ startDate, endDate, downData, assetDetails }) => {
  const startDateTime = new Date(startDate);
  startDateTime.setHours(0, 0, 0, 0); // Set time to 12:00 AM

  const endDateTime = new Date(endDate);
  endDateTime.setHours(24, 0, 0, 0); // Set time to end of the day

  // Calculate the difference in milliseconds
  const timeDiff = endDateTime - startDateTime;

  // Convert milliseconds to hours
  const totalHours = Math.floor(timeDiff / (1000 * 60 * 60));

  // Initialize downtimeData array for each hour of the total duration with 1 (no downtime)
  const downtimeData = Array.from({ length: totalHours }, (_, index) => ({
    hour: index % 24, // Wrap around after 24 hours
    Downtime: 1, // Default value is 1 (no downtime)
    duration: 0, // Initialize duration to 0
    start: null, // Initialize start time to null
    end: null, // Initialize end time to null
    reason: "", // Initialize reason to empty string
  }));

  // Add downtime 0 before asset creation time
  const creationTime = new Date(
    convertUTCToLocalFormat(assetDetails?.created_at)
  );
  for (let i = 0; i < downtimeData.length; i++) {
    const recordTime = new Date(startDateTime.getTime() + i * 60 * 60 * 1000);
    if (recordTime < creationTime) {
      downtimeData[i].Downtime = 0;
      downtimeData[i].start = "Before Asset Creation";
      downtimeData[i].end = creationTime.toLocaleString();
      downtimeData[i].reason = "Not applicable";
    }
  }

  // Loop through downtime records and update downtimeData accordingly
  downData.forEach((record) => {
    const startTime = new Date(record.start_time);

    const endTime = record.end_time
      ? new Date(record.end_time)
      : new Date(endDate);

    // Calculate start and end hours relative to startDate
    const startHour = Math.floor(
      (startTime.getTime() - startDateTime.getTime()) / (1000 * 60 * 60)
    );
    const endHour = Math.floor(
      (endTime.getTime() - startDateTime.getTime()) / (1000 * 60 * 60)
    );

    // Calculate downtime duration in minutes
    const durationMinutes = Math.round((endTime - startTime) / (1000 * 60));

    // Update downtimeData for each hour affected by the downtime
    for (let i = Math.max(startHour, 0); i <= endHour && i < totalHours; i++) {
      if (downtimeData[i]) {
        downtimeData[i].Downtime = 0;
        downtimeData[i].duration = durationMinutes;
        downtimeData[i].start = startTime.toLocaleString(); // Set start time
        downtimeData[i].end = endTime.toLocaleString(); // Set end time
        downtimeData[i].reason = record?.reason || ""; // Set reason
      }
    }
  });

  // Handle uptime after asset creation and before first downtime record
  let previousEndTime = creationTime;
  downData.forEach((record) => {
    const startTime = new Date(record.start_time);
    if (previousEndTime < startTime) {
      const startHour = Math.floor(
        (previousEndTime.getTime() - startDateTime.getTime()) / (1000 * 60 * 60)
      );
      const endHour = Math.floor(
        (startTime.getTime() - startDateTime.getTime()) / (1000 * 60 * 60)
      );

      for (let i = Math.max(startHour, 0); i < endHour && i < totalHours; i++) {
        if (downtimeData[i]) {
          downtimeData[i].Downtime = 1;
          downtimeData[i].start = previousEndTime.toLocaleString(); // Set start time
          downtimeData[i].end = startTime.toLocaleString(); // Set end time
          downtimeData[i].reason = "Uptime"; // Set reason
        }
      }
    }
    previousEndTime = new Date(record.end_time || endDate);
  });

  // Fill in the labels for all hours
  for (let i = 0; i < totalHours; i++) {
    downtimeData[i].label = `${i % 24}:00`; // Wrap around after 24 hours
  }

  return (
    <ResponsiveContainer width="100%" height={250}>
      <ComposedChart
        data={downtimeData}
        margin={{ top: 36, right: 30, left: -30, bottom: 10 }}
      >
        <XAxis dataKey="label" angle={-20} dy={10} tick={{ fontSize: 12 }} />
        <YAxis domain={[0, 1]} ticks={[0, 1]} />
        <Tooltip
          formatter={(value, name, props) => {
            if (props?.payload?.Downtime === 0) {
              return (
                <span>
                  <span>{props.payload.duration} mins</span>
                  <p>Start: {props.payload.start}</p>
                  <p>End: {props.payload.end}</p>
                  <p>Reason: {props.payload.reason}</p>
                </span>
              );
            } else {
              return "0 mins";
            }
          }}
        />
        <Area
          type="monotone"
          dataKey="Downtime"
          fill="#52c41a"
          stroke="#52c41a"
          fillOpacity={0.3}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default DownTimeChart;
