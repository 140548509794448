import {
  Button,
  Col,
  Empty,
  Input,
  Popover,
  Result,
  Row,
  Skeleton,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import { getAllAssetsWithLocsApi } from "../../services/assets.services";
import { useLocation, useNavigate } from "react-router-dom";
import { statusOptions } from "../../helpers/utility";
import "./Assets.css";
import { createAsset } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";
import { getItem, setItem } from "../../helpers/localStorage";
import AssetSegemented from "./AssetSegemented";

const AssetsListView = ({ setAssetsLength, setViewType, viewType }) => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [assets, setAssets] = useState([]);
  const [filteredData, setFileteredData] = useState([]);
  const { userDetails } = useContext(UserContext);
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { searchVal, pageType } = location.state || {};

  const columns = [
    {
      title: <Typography className={"tableHeader"}>Location</Typography>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <Typography className={"tableHeader"}>Code</Typography>,
      dataIndex: "code",
      key: "code",
      width: 140,
      render: (val) => (
        <>
          {val ? (
            <Typography>
              {val?.length > 50 ? `${val?.slice(0, 50)}...` : val}
            </Typography>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>Asset status</Typography>,
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (val) => {
        const color = statusOptions?.filter((each) => each?.value == val)?.[0]
          ?.color;
        return (
          <>
            {val ? (
              <Tag color={color} className={tagStyles.mdTag}>
                {val}
              </Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    // {
    //   title: <Typography className={"tableHeader"}>WOs created</Typography>,
    //   dataIndex: "workorderCount",
    //   key: "workorderCount",
    //   width: 140,
    // },
    // {
    //   title: <Typography className={"tableHeader"}>PMs created</Typography>,
    //   dataIndex: "maintenanceCount",
    //   key: "maintenanceCount",
    //   width: 140,
    // },
  ];

  function transformData(node) {
    const result = {
      key: node._id,
      name: node.name,
      // status: node.status || "-",
      code: node.code || null,
      children: [], // Initialize children array
    };

    if (node.children && node.children?.length > 0) {
      result.children = node.children.map((childNode) =>
        transformData(childNode)
      );
    }

    if (node.assets && node.assets?.length > 0) {
      const assetsToAdd = node.assets.map((asset) => ({
        key: asset._id,
        name: asset.name,
        status: asset.status || "-",
        code: asset.code || null,
        assetDescription: asset.description || null,
        dept: asset.charge_dept || null,
        asset: true,
        // Add other asset properties as needed
      }));

      // Add assets to the allAssetsState array
      setAssets((prev) => [...prev, ...assetsToAdd]);

      // Add assets as children to the result object
      result.children = result.children.concat(assetsToAdd);
    }

    return result;
  }

  const getLocations = async () => {
    const currKeys = getItem("keys") && JSON.parse(getItem("keys"));
    try {
      setLoading(true);
      const resp = await getAllAssetsWithLocsApi();
      setAssetsLength(resp?.data?.count);
      const transformedData = transformData(resp?.data?.locations?.[0]);

      setKeys(currKeys?.length > 0 ? currKeys : [""]);
      setTableData([transformedData]);
      setFileteredData([transformedData]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const filterTreeData = (searchText) => {
    const finalData = searchText
      ? assets?.filter((asset) => {
          return (
            asset?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
            asset?.code?.toLowerCase()?.includes(searchText?.toLowerCase())
          );
        })
      : tableData;
    setKeys(finalData?.map((each) => each?.key));
    setFileteredData(finalData);
  };

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    setItem("keys", JSON.stringify(keys));
  }, [keys]);

  useEffect(() => {
    if (pageType === "tree") {
      if (searchVal && searchVal !== "") {
        setSearchValue(searchVal);
        filterTreeData(searchVal);
      }
      if (searchVal || searchVal === "") {
        navigate(`/assets?type=tree`, { state: null });
      }
    }
  }, [location.state]);

  return (
    <Row gutter={[24, 18]}>
      <Col span={12} className="flex-start">
        <AssetSegemented navigate={navigate} viewType={viewType} />
      </Col>
      <Col
        span={12}
        style={{ textAlign: "right", justifyContent: "flex-end" }}
        className="flex-center"
      >
        <Input.Search
          size="medium"
          defaultValue={searchVal ? searchVal : ""}
          onChange={(e) => {
            setSearchValue(e.target.value);
            filterTreeData(e.target.value);
          }}
          placeholder="Search by asset name or code"
          style={{ width: "40%" }}
        />
        {/* {isDisabled ? (
            <Popover
              placement="left"
              content={
                <Typography>
                  You can't add more assets in the free trial
                </Typography>
              }
              trigger="hover"
            >
              <Button
                type="primary"
                id="create-asset"
                className={btnStyles.mdBtn}
                disabled={true}
                onClick={() => navigate("/assets/create")}
              >
                + Create Asset
              </Button>
            </Popover>
          ) : ( */}
        {createAsset?.includes(userDetails?.activeOrg?.role) && (
          <Button
            type="primary"
            id="create-asset"
            className={btnStyles.mdBtn}
            disabled={false}
            onClick={() => navigate("/assets/create")}
          >
            + Create Asset
          </Button>
        )}
        {/* )} */}
      </Col>
      <Col span={24}>
        {/* {keys?.length > 0 && ( */}
        <Table
          scroll={{
            x: 1200,
            y: "65vh",
          }}
          // loading={loading}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          rowKey={"key"}
          pagination={false}
          className="components-table-demo-nested"
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No assets found</Typography>}
              />
            ),
          }}
          expandable={{
            defaultExpandedRowKeys: keys,
            onExpand: (e, x) => {
              // if (x.key === filteredData.key) {
              //   // Don't allow collapsing the root node
              //   return;
              // } else
              if (e) {
                const newKeys = [...keys];
                const index = newKeys.indexOf(x.key);
                if (index !== -1) {
                  // newKeys.splice(index, 1); // Remove key if already open
                } else {
                  newKeys.push(x.key); // Add key if not open
                }
                setKeys(newKeys);
                // Navigate logic here
              } else {
                setKeys((prev) => prev.filter((each) => each !== x.key));
              }
            },
          }}
          onRow={(item) => {
            return {
              onClick: (e) => {
                item?.asset &&
                  navigate(`/assets/${item?.key}?type=tree`, {
                    state: { pageType: "tree", searchVal: searchValue },
                  });
              },
              style: { cursor: item?.asset ? "pointer" : "default" },
            };
          }}
          bordered
          columns={columns}
          dataSource={filteredData}
        />
        {/* )} */}
      </Col>
    </Row>
  );
};

export default AssetsListView;
