import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useContext } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { deleteDocs } from "../../../helpers/utility";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import CommonUpload from "../../Blocks/CommonUpload";
import { UserContext } from "../../../context/UserProvider";
import { handleDynamicValidations } from "./helper";

const Maintenance = ({ form, edit, documents, setDocuments }) => {
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const onUpload = async (resp) => {
    if (resp) {
      await form.setFieldValue(
        ["maintenance", "files"],
        resp?.path ? [...(documents?.maintenance || []), resp?.path] : []
      );
      await handleDynamicValidations(
        {
          maintenance: {
            files: resp?.path
              ? [...(documents?.maintenance || []), resp?.path]
              : [],
          },
        },
        form.getFieldsValue(),
        form
      );

      setDocuments({
        ...documents,
        maintenance: [...(documents?.maintenance || []), resp?.path],
      });
    }
  };

  const handleDisabledEndDate = (current) => {
    // Get the selected start date from the form values
    const startDate = form.getFieldValue()?.maintenance?.start_date;

    // Disable dates that are before the selected start date
    return current && current.isBefore(startDate, "day");
  };

  return (
    <Card
      title="Annual Maintenance Contract (AMC)"
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Form.Item
            label="Provider type"
            name={["maintenance", "provider_type"]}
          >
            <Select
              allowClear
              className={inputStyles.smSelect}
              style={{ width: "100%" }}
              placeholder="Select provider type"
              options={[
                { value: "internal", label: "Internal" },
                { value: "external", label: "External" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Contract start date"
            name={["maintenance", "start_date"]}
          >
            <DatePicker
              onChange={(e) => {
                form.setFieldsValue({ maintenance: { end_date: null } });
                handleDynamicValidations(
                  { maintenance: { end_date: null } },
                  form.getFieldsValue(),
                  form
                );
                // if (e) {
                //   form.setFields([
                //     {
                //       name: ["maintenance", "end_date"],
                //       errors: ["Contract end date is required"], // You can customize the error message here
                //     },
                //   ]);
                // }
              }}
              size="medium"
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Select start date"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Service provider name"
            name={["maintenance", "provider_name"]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter provider name"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Contract end date"
            name={["maintenance", "end_date"]}
          >
            <DatePicker
              disabledDate={handleDisabledEndDate}
              size="medium"
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Select end date"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Charge department"
            name={["maintenance", "charge_dept"]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter charge department"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["maintenance", "files"]}
            style={{ margin: "0px", height: "0px" }}
          >
            <div></div>
          </Form.Item>
          <Form.Item label=" " style={{ width: "100%" }}>
            <CommonUpload
              onDelete={async (e) => {
                deleteDocs(e?.name, storageType);
                const updatedMaintenanceFiles = documents?.maintenance?.filter(
                  (id) => id != e?.name
                );
                await form.setFieldValue(
                  ["maintenance", "files"],
                  updatedMaintenanceFiles
                );
                handleDynamicValidations(
                  { maintenance: { files: updatedMaintenanceFiles } },
                  form.getFieldsValue(),
                  form
                );
                setDocuments({
                  ...documents,
                  maintenance: updatedMaintenanceFiles,
                  // maintenance: documents?.maintenance?.filter(
                  //   (id) => id != e?.name
                  // ),
                });
                // deleteDocs(e?.name, storageType);
              }}
              onUpload={onUpload}
              disabled={!edit}
              dragger={false}
              documents={documents?.maintenance}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default Maintenance;
