import React, { useContext, useEffect, useState } from "react";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import CustomLayout from "../../Layout";
import {
  Form,
  Col,
  Row,
  Typography,
  Card,
  Flex,
  Button,
  message,
  Popconfirm,
  Tag,
  Space,
} from "antd";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";

import GeneralDetails from "./GeneralDetails";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import {
  createWorkOrderApi,
  deleteWorkOrderApi,
  getWorkorderByIDApi,
  updateWorkOrderApi,
} from "../../../services/work-orders.services";
import dayjs from "dayjs";
import Comments from "./Comments";
import WorkOrderStatus from "./WorkOrderStatus";
import {
  ONPREMISE,
  deleteWO,
  editWO,
  verifyPM,
} from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserProvider";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
  deleteCond,
  editCond,
  getEncodedImage,
  renameFile,
} from "../../../helpers/utility";
import SOPDetails from "./SOPDetails";
import { submitSOPApi } from "../../../services/sops.services";
import SparePartForm from "../../Blocks/SparePartForm";
import { getProductionLines } from "../../../services/assets.services";
import SparePartSection from "../../Blocks/SparePartSection";
import TaskInfo from "../../Blocks/TaskInfo";

const CreateWorkOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const params = useParams();
  const [woDocuments, setWoDocuments] = useState([]);
  const [edit, setEdit] = useState(false);
  const [workOrderID, setWorkOrderID] = useState(null);
  const [workOrderDetails, setWorkOrderDetails] = useState({});
  const { userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [sopData, setSopData] = useState({});
  const [answers, setAnswers] = useState({});
  const [productionLines, setProductionLines] = useState([]);
  const [plId, setPlId] = useState(null);
  // const [callSparePart, setCallSparePart] = useState(false);

  const { source, mro_id, description, scheduled_start_date, asset_id } =
    location.state || {};

  const formatDateTime = (date, format) =>
    date ? dayjs(date).format(format) : null;

  const onFinish = async (values) => {
    let selectedAsset = form.getFieldValue("asset");
    let selectedProductionLine = form.getFieldValue("production_line");
    if (!selectedAsset && !selectedProductionLine) {
      message.error("Please enter either asset or production line");
    } else {
      const storageType =
        userDetails?.activeOrg?.organization?.settings?.storage;
      const formatFiles = (docs) =>
        storageType == ONPREMISE
          ? docs
          : docs?.map((each) => (each ? renameFile(each) : ""));

      delete values?.asset_image;
      delete values?.asset_status;
      delete values?.comments;
      delete values?.assigned_list;

      let formatedData = {
        ...values,
        start_date: values?.start_date
          ? convertToUTCFormat(values.start_date)
          : "",
        // end_date: values?.end_date ? convertToUTCFormat(values.end_date) : "",

        // start_date: formatDateTime(values.start_date, "YYYY-MM-DD"),
        // end_date: formatDateTime(values.end_date, "YYYY-MM-DD"),
        // start_time: formatDateTime(values.start_date, "[T]HH:mm:ss.SSS[Z]"),
        // end_time: formatDateTime(values.end_date, "[T]HH:mm:ss.SSS[Z]"),
        files: values?.files?.length > 0 && formatFiles(values?.files),
      };

      if (Object.keys(formatedData).includes("verifiers")) {
        if (params?.id && formatedData?.verifiers?.length === 0) {
          formatedData = { ...formatedData, verifiers: [] };
        }
      }

      if (Object.keys(formatedData).includes("end_date")) {
        formatedData = {
          ...formatedData,
          end_date: values?.end_date ? convertToUTCFormat(values.end_date) : "",
        };
      }

      !params?.id && !formatedData?.end_date && delete formatedData?.end_date;
      !formatedData?.start_date && delete formatedData?.start_date;
      // !formatedData?.end_time && delete formatedData?.end_time;
      // !formatedData?.start_time && delete formatedData?.start_time;

      !formatedData?.files?.length > 0 && delete formatedData?.files;
      !params?.id &&
        formatedData?.verifiers?.length === 0 &&
        delete formatedData?.verifiers;

      if (!params?.id && mro_id) {
        formatedData = { ...formatedData, mro: mro_id };
      }

      console.log(formatedData, "resssss");

      try {
        const resp = params?.id
          ? await updateWorkOrderApi(params?.id, formatedData)
          : await createWorkOrderApi(formatedData);
        // if (values?.sop && resp) {
        //   await submitSOP(
        //     sopData?._id,
        //     resp?.data?.workorder?._id || resp?.data?._id || params?.id
        //   );
        // }
        if (params?.id) {
          message.success(resp?.data?.message);
          getWorkOrder();
          setEdit(false);
        } else {
          message.success(resp?.data?.message);
          setWorkOrderID(resp?.data?._id);
        }
      } catch (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!!"
        );
      }
    }
  };

  const submitSOP = async (sopId, id) => {
    try {
      const resp = await submitSOPApi(sopId, {
        workorder: id,
        responses: answers,
      });
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!!");
    }
  };

  const mapWorkOrderData = (data) => {
    console.log(data.start_date, data?.end_date);
    return {
      ...data,
      asset: data?.asset?._id,
      production_line: data?.production_line?._id,
      asset_image: data?.asset?.file,
      asset_status: data?.asset?.status,
      verifiers: data?.verifiers?.map((i) => i?._id),
      sop: data?.sop?._id,
      // end_date: data?.end_date
      //   ? dayjs(data.end_date, "YYYY-MM-DD HH:mm:ss")
      //   : null,
      // start_date: data?.start_date
      //   ? dayjs(data.start_date, "YYYY-MM-DD HH:mm:ss")
      //   : null,
      start_date: data?.start_date
        ? dayjs(convertUTCToLocalFormat(data?.start_date))
        : null,
      end_date: data?.end_date
        ? dayjs(convertUTCToLocalFormat(data?.end_date))
        : null,
      // start_time: data?.start_time ? dayjs(data?.start_time, "HH:mm:ss") : null,
      // end_time: data?.end_time ? dayjs(data?.end_time, "HH:mm:ss") : null,
      assigned_groups: data?.assigned_groups?.map((each) => each?._id),
      assigned_users: data?.assigned_users?.map((each) => each?._id),
      assigned_list: [
        ...(data?.assigned_groups?.map((each) => each?._id) || []),
        ...(data?.assigned_users?.map((each) => each?._id) || []),
      ],
    };
  };

  const getWorkOrder = async () => {
    try {
      const resp = await getWorkorderByIDApi(params?.id);
      const data = mapWorkOrderData(resp?.data?.workorder);
      setWoDocuments(resp?.data?.workorder?.files || []);
      delete data?.comments;
      setWorkOrderDetails(data);
      setPlId(data?.production_line);
      form.setFieldsValue(data);
      setSopData(resp?.data?.workorder?.sop || {});
      setAnswers(resp?.data?.sopSubmission?.responses || {});
    } catch (error) {
      console.log(error);
      // !callSparePart && navigate("/work-orders");
      navigate("/work-orders");
    }
  };

  const deleteWorkOrder = async () => {
    try {
      const resp = await deleteWorkOrderApi(params?.id);
      resp && navigate("/work-orders");
      message.success(resp?.data?.message);
    } catch (error) {
      console.log(error);
      message.error(error?.repsonse?.data?.message);
    }
  };

  const fetchProductionLines = async () => {
    try {
      const res = await getProductionLines();
      setProductionLines(
        res?.data?.assets?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    params?.id && setWorkOrderID(params?.id);
    params?.id ? getWorkOrder() : setEdit(true);
    !params?.id && form.setFieldValue("start_date", dayjs());
  }, [params]);

  useEffect(() => {
    fetchProductionLines();
    if (!params?.id && mro_id) {
      form.setFieldsValue({
        name: description ? description : "",
        start_date: scheduled_start_date
          ? dayjs(convertUTCToLocalFormat(scheduled_start_date))
          : null,
        asset: asset_id ? asset_id : undefined,
      });
    }
  }, [workOrderDetails]);

  const refreshCall = () => {
    getWorkOrder();
    setEdit(false);
  };

  return (
    <CustomLayout
      header={
        !params?.id ? (
          <Typography className={typoStyles.h6}>
            <LeftOutlined
              style={{ marginRight: "6px" }}
              onClick={() => navigate("/work-orders")}
            />
            {params?.id ? "Update Work Order" : "Create Work Order"}
          </Typography>
        ) : (
          <Flex justify="space-between">
            <Typography
              className={typoStyles.h6}
              style={{
                gap: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <LeftOutlined
                style={{ marginRight: "6px" }}
                onClick={() => {
                  if (source) {
                    navigate(source);
                  } else {
                    navigate("/work-orders");
                  }
                }}
                // onClick={() => navigate("/work-orders")}
              />
              {workOrderDetails?.code}
              {workOrderDetails?.mark_as_verified && (
                <Tag className={tagStyles.mdRndTag} color="green">
                  Verified
                </Tag>
              )}
            </Typography>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              {deleteCond(
                deleteWO,
                workOrderDetails?.creator?._id,
                userDetails?.activeOrg?.role,
                userDetails?._id
              ) && (
                <Popconfirm
                  icon={null}
                  description="Are you sure to delete this work order?"
                  onConfirm={(e) => {
                    deleteWorkOrder();
                  }}
                >
                  <Button className={btnStyles.mdBtn}>
                    <span className="hiddenBelowTab">Delete</span>
                    <DeleteOutlined style={{ margin: "0px" }} />
                  </Button>
                </Popconfirm>
              )}
              {!edit &&
                !workOrderDetails?.mark_as_verified &&
                editCond(
                  editWO,
                  workOrderDetails?.creator?._id,
                  userDetails?.activeOrg?.role,
                  userDetails?._id
                ) && (
                  <Button
                    className={btnStyles.mdBtn}
                    onClick={() => setEdit(true)}
                    type="primary"
                  >
                    <span className="hiddenBelowTab">Edit</span>

                    <EditOutlined style={{ margin: "0px" }} />
                  </Button>
                )}
            </div>
          </Flex>
        )
      }
      footer={
        <>
          {(!params?.id || (params?.id && edit)) && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (params?.id) {
                    setEdit(false);
                    getWorkOrder();
                  } else {
                    form.resetFields();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                onClick={form.submit}
                loading={loading}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <Form
        layout="vertical"
        requiredMark={false}
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} md={24} lg={24} xl={16}>
            <GeneralDetails
              edit={edit}
              form={form}
              workOrderDetails={workOrderDetails}
              woDocuments={woDocuments}
              setWoDocuments={setWoDocuments}
              setSopData={setSopData}
              productionLines={productionLines}
              setPlId={setPlId}
            />
          </Col>
          {params?.id && (
            <Col xs={24} md={24} lg={24} xl={8}>
              {/* <Card
                title="Status"
                styles={{
                  body: { padding: "12px" },
                  header: { backgroundColor: "#fff" },
                }}
              > */}
              <WorkOrderStatus
                // form={form}
                // onFinish={onFinish}
                workOrderDetails={workOrderDetails}
                refreshCall={refreshCall}
              />
              {/* </Card> */}
              <TaskInfo details={workOrderDetails} typeValue="WO" />
              {/* <Card
              styles={{ body: { padding: "16px" } }}
              style={{ marginTop: 12 }}
            >
              <Space style={{ width: "100%", gap: 18 }}>
                <Typography>WO Created By</Typography>
                {workOrderDetails?.creator?.first_name ||
                workOrderDetails?.creator?.last_name ? (
                  <Tag className={tagStyles.mdTag} color="purple">
                    {`${workOrderDetails?.creator?.first_name} ${workOrderDetails?.creator?.last_name}`}
                  </Tag>
                ) : (
                  "-"
                )}
              </Space>
              <Space style={{ width: "100%", gap: 24, marginTop: 12 }}>
                <Typography>WO Closed By</Typography>
                {workOrderDetails?.closed_by?.first_name ||
                workOrderDetails?.closed_by?.last_name ? (
                  <Tag className={tagStyles.mdTag} color="green">
                    {`${workOrderDetails?.closed_by?.first_name} ${workOrderDetails?.closed_by?.last_name}`}
                  </Tag>
                ) : (
                  "-"
                )}
              </Space>
              <Space style={{ width: "100%", gap: 20, marginTop: 12 }}>
                <Typography>WO Verified By</Typography>
                {workOrderDetails?.verified_by?.first_name ||
                workOrderDetails?.verified_by?.last_name ? (
                  <Tag className={tagStyles.mdTag} color="blue">
                    {`${workOrderDetails?.verified_by?.first_name} ${workOrderDetails?.verified_by?.last_name}`}
                  </Tag>
                ) : (
                  "-"
                )}
              </Space>
            </Card> */}
            </Col>
          )}
          <Col xs={24} md={24} lg={24} xl={16}>
            <SOPDetails
              edit={edit}
              workOrderDetails={workOrderDetails}
              sopData={sopData}
              setSopData={setSopData}
              answers={answers}
              setAnswers={setAnswers}
              form={form}
            />
          </Col>
          {/* {params?.id && ( */}
          {params?.id && (
            <Col xs={24} md={24} lg={24} xl={16}>
              <SparePartSection
                form={form}
                edit={edit}
                productionLines={productionLines}
                plId={plId}
                inventoryRequestData={workOrderDetails?.inventory_request}
                orderId={workOrderDetails?._id}
                type={"WO"}
                refreshCall={refreshCall}
                verifyStatus={
                  workOrderDetails?.mark_as_verified ||
                  userDetails?.activeOrg?.role === "operator"
                }
                taskStatus={workOrderDetails?.status}
              />
            </Col>
          )}
          {/* <Col xs={24} md={24} lg={24} xl={16}>
            <SparePartForm
              form={form}
              edit={edit}
              productionLines={productionLines}
              plId={plId}
              woId={params?.id ? workOrderDetails?._id : workOrderID}
              // woId={workOrderDetails?._id}
              type="WO"
              setCallSparePart={setCallSparePart}
              refreshCall={refreshCall}
              workOrderDetails={workOrderDetails}
            />
          </Col> */}
          {/* )} */}
          <Col xs={24} md={24} lg={24} xl={16}>
            <Comments
              form={form}
              workOrderID={workOrderID}
              setWorkOrderID={setWorkOrderID}
              edit={edit}
              verifyStatus={
                workOrderDetails?.mark_as_verified &&
                !(userDetails?.activeOrg?.role === "manager")
              }
            />
          </Col>
          {workOrderDetails?.status == "closed" &&
            !workOrderDetails?.mark_as_verified &&
            ((workOrderDetails?.verifiers?.length > 0 &&
              workOrderDetails?.verifiers?.includes(userDetails?._id)) ||
              (!workOrderDetails?.verifiers &&
                verifyPM.includes(userDetails?.activeOrg?.role))) && (
              <Col
                xs={24}
                md={24}
                lg={24}
                xl={16}
                style={{ textAlign: "center" }}
              >
                <Button
                  size="medium"
                  style={{
                    // color: workOrderDetails?.mark_as_verified && "green",
                    width: "40vw",
                    margin: "auto",
                  }}
                  type={"primary"}
                  // type={!workOrderDetails?.mark_as_verified && "primary"}
                  onClick={() =>
                    // !workOrderDetails?.mark_as_verified &&
                    onFinish({ mark_as_verified: true })
                  }
                >
                  Mark as Verified <CheckOutlined />
                  {/* {!workOrderDetails?.mark_as_verified && (
                    <>
                      Mark as Verified <CheckOutlined />
                    </>
                  )} */}
                </Button>
              </Col>
            )}
        </Row>
      </Form>
    </CustomLayout>
  );
};

export default CreateWorkOrder;
