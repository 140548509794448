import {
  Button,
  Flex,
  Form,
  Input,
  Select,
  Typography,
  message,
  Card,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../Layout";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import {
  getMetaDataApi,
  updateUserApi,
} from "../../../services/users.services";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";

import { userRoles } from "../../../helpers/utility";
import { getCookie, setCookie } from "../../../helpers/localStorage";
import { FACILITYID, ORGANISATION } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserProvider";

const Profile = () => {
  const [currUser, setCurrUser] = useState({});
  const [form] = Form.useForm();
  const [updating, setUpdating] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);

  useEffect(() => {
    setCurrUser(userDetails);
    form.setFieldsValue(userDetails);
  }, [userDetails]);

  const onFinish = async (values) => {
    if (!values.email && !values.mobile_number) {
      message.error("Please enter either email or phone number");
    } else {
      // delete values?.email;
      const updatedUser = { ...values, ...(values?.activeOrg || {}) };
      delete updatedUser?.activeOrg;
      try {
        const resp = await updateUserApi(currUser?._id, updatedUser);
        if (resp) {
          fetchUserData();
          message.success(resp?.data?.message);
          // message.success("User added successfully!");
        }
      } catch (error) {
        console.log(error);
        message.error(error?.response?.data?.message);
      } finally {
        setUpdating(false);
      }
    }
  };

  const fetchUserData = async () => {
    try {
      const orgID = getCookie(ORGANISATION);
      const id = getCookie(FACILITYID);
      const resp = await getMetaDataApi();
      if (resp) {
        setUserDetails((prev) => ({
          ...prev,
          ...resp?.data,
          activeOrg: orgID
            ? resp?.data?.organizations?.filter(
                (each) => each?.organization?._id == orgID
              )?.[0]
            : {},
        }));
        setCurrUser(resp?.data);
        form.setFieldsValue(resp?.data);
        !id &&
          setCookie(
            FACILITYID,
            "all",
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
    }
  };

  return (
    <CustomLayout
      header={<Typography className={typoStyles.h6}>My Profile</Typography>}
      footer={
        updating && (
          <Flex justify="flex-end" gap={12}>
            <Button
              className={btnStyles.mdBtn}
              onClick={() => {
                form.resetFields();
                setUpdating(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={btnStyles.mdBtn}
              type="primary"
              onClick={form.submit}
            >
              Update
            </Button>
          </Flex>
        )
      }
    >
      <Card
        title="Update profile"
        styles={{
          header: { backgroundColor: "#fff" },
        }}
        style={{ width: "40vw", margin: "auto" }}
      >
        <Form
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          form={form}
          onFieldsChange={(e) => setUpdating(true)}
          initialValues={currUser}
        >
          <Form.Item
            label="First name"
            name={"first_name"}
            rules={[{ required: true, message: "Please enter first name" }]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter first name"
            />
          </Form.Item>
          <Form.Item
            label="Last name"
            name={"last_name"}
            // rules={[{ required: true, message: "Please enter last name" }]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter last name"
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name={"email"}
            rules={[
              // { required: true, message: "Please enter email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input
              // disabled={Object.keys(currUser)?.length > 0}
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter email"
            />
          </Form.Item>
          <Form.Item
            label="Phone number"
            name={"mobile_number"}
            rules={[
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number",
              },
            ]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter phone number"
            />
          </Form.Item>
          <Form.Item label="Employee ID" name={["activeOrg", "employee_id"]}>
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter employee ID"
            />
          </Form.Item>
          <Form.Item label="Designation" name={["activeOrg", "designation"]}>
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter designation"
            />
          </Form.Item>
          <Form.Item label="Role" name={["activeOrg", "role"]}>
            <Select
              disabled={userDetails?.activeOrg?.role !== "admin"}
              className={inputStyles.smSelect}
              style={{ width: "100%" }}
              options={userRoles}
              // defaultValue={"admin"}
              placeholder="Select role"
            />
          </Form.Item>
        </Form>
      </Card>
    </CustomLayout>
  );
};

export default Profile;
