import { Col, Row, Typography } from "antd";
import React from "react";

import CustomLmsTag from "./CustomTag";
import AllElems from "../../../Sops/CreateSOP/AllElems";

import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";
import { TYUT } from "../../../../constants/defaultKeys";

function PreviewTyut({
  tyutContent,
  tyutMetaData,
  setEditing,
  setTyutContent,
  editing = { edit: false, data: null },
  viewType = "preview",
  showPreviewTitle = true,
  maxContentHeight = "60vh",
}) {
  return (
    <Row
      gutter={[12, 12]}
      style={{
        padding: "24px",
        minWidth: "100%",
        minHeight: "100px",
        background: "#efefef",
      }}
    >
      {showPreviewTitle && (
        <Col span={24}>
          <Typography
            style={{ color: "#9BA5B7", marginBlock: "0px" }}
            className={typographyStyles.body3}
          >
            Preview
          </Typography>
        </Col>
      )}
      <Col span={24}>
        <Typography.Paragraph
          className={typographyStyles.h6}
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: "more",
          }}
          style={{ marginBlock: "0px" }}
        >
          {tyutMetaData?.name}
        </Typography.Paragraph>
        <Typography.Paragraph
          style={{ color: "#9BA5B7", marginBlock: "0px" }}
          className={typographyStyles.body2}
          ellipsis={{ rows: 2, expandable: true, symbol: "read more" }}
        >
          {tyutMetaData?.description}
        </Typography.Paragraph>
      </Col>
      {tyutMetaData.tags?.length > 0 && (
        <Col span={24}>
          <CustomLmsTag data={tyutMetaData.tags} />
        </Col>
      )}
      <Col
        style={{
          maxHeight: maxContentHeight,
          overflowY: "auto",
          overflowX: "hidden",
          width: "100%",
          margin: "auto",
          padding: 0,
        }}
      >
        {tyutContent.map((eachElem) => (
          <>
            <AllElems
              contentType={TYUT}
              item={eachElem}
              viewType={viewType}
              editing={editing}
              state={tyutContent}
              setState={setTyutContent}
              setEditing={setEditing}
            />
          </>
        ))}
      </Col>
    </Row>
  );
}

export default PreviewTyut;
