import {
  Col,
  ConfigProvider,
  DatePicker,
  Empty,
  Pagination,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getAllAssetsApi } from "../../services/assets.services";
import dayjs from "dayjs";
import { getAllJobsApi } from "../../services/maintenance.services";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import AssetDateWiseView from "./AssetDateWiseView";
import styles from "./index.css";
import EventForm from "./EventForm";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import isLeapYear from "dayjs/plugin/isLeapYear";
import { generateScheduleStringForTable } from "../Maintenance/CreateMaintanance/helper";
import {
  MAINTANANCETEXT,
  PAGESIZE,
  colorMap,
} from "../../constants/defaultKeys";
import { convertUTCToLocalFormat } from "../../helpers/utility";

dayjs.extend(weekOfYear);
dayjs.extend(isLeapYear);
dayjs.extend(isoWeek);
dayjs.extend(isoWeeksInYear);

const { RangePicker } = DatePicker;

const AssetView = () => {
  const [assetsList, setAssetsList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [currEvent, setCurrEvent] = useState();
  const [refresh, setRefresh] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const tableRef = useRef();
  const getYearMonth = (date) => date.year() * 12 + date.month();

  const disabledDate = (current, { from, type }) => {
    if (from) {
      const minDate = from.add(-29, "days");
      const maxDate = from.add(29, "days");
      switch (type) {
        case "year":
          return (
            current.year() < minDate.year() || current.year() > maxDate.year()
          );
        case "month":
          return (
            getYearMonth(current) < getYearMonth(minDate) ||
            getYearMonth(current) > getYearMonth(maxDate)
          );
        default:
          return Math.abs(current.diff(from, "days")) >= 30;
      }
    }
    return false;
  };

  const getWeekDatesOfYear = (year) => {
    const yearEndDate = dayjs(`${year}-12-31`);
    const dayNum = dayjs(`${year}-01-01`).day();
    let startDate = dayjs(`${year}-01-01`).subtract(
      dayNum === 0 ? 6 : dayNum - 1,
      "day"
    );
    let week = 1;
    let weeks = [];
    let currDateWeek = 1;

    while (startDate <= yearEndDate) {
      const startOfWeek = startDate.format("DD MMM");
      const endOfWeek = startDate.add(6, "day").format("DD MMM");

      if (dayjs() >= startDate && dayjs() <= startDate.add(6, "day")) {
        currDateWeek = week;
      }

      weeks.push({ weekNumber: week, startOfWeek, endOfWeek });
      startDate = startDate.add(7, "day");
      week = week + 1;
    }

    return { weeks: weeks, currDateWeek: currDateWeek };
  };

  const fetchAssets = async (assetJobMap) => {
    let query = { page: pageNumber };
    try {
      const res = await getAllAssetsApi(query);
      let updated_assets = [];
      res?.data?.assets?.map((item) => {
        updated_assets.push({
          ...item,
          week_wise_data: assetJobMap?.[item?._id] ?? null,
        });
      });
      setAssetsList(updated_assets);
      setTotalCount(res?.data?.totalAssets);
    } catch (error) {
      // console.log(error);
      setAssetsList([]);
      setTotalCount(0);
    } finally {
      setLoading(false);
    }
  };

  const fetchPMS = async () => {
    let params = {
      type: MAINTANANCETEXT,
      start_date: dayjs().startOf("year").format("YYYY-MM-DD"),
      end_date: dayjs().endOf("year").format("YYYY-MM-DD"),
    };
    // const queryString = new URLSearchParams(params);
    setLoading(true);
    try {
      const resp = await getAllJobsApi(params);
      let assetJobMap = {};
      resp?.data?.occurrences?.map((item) => {
        let occurenceDate = convertUTCToLocalFormat(item?.occurrence_date);
        const dayNum = dayjs(occurenceDate).day();
        let weekVal = dayjs(occurenceDate)
          .subtract(dayNum === 0 ? 6 : dayNum - 1, "day")
          .format("DD MMM");
        let assetId = item?.maintenance?.asset?._id;

        if (assetId) {
          if (assetJobMap?.[assetId]) {
            if (assetJobMap?.[assetId]?.[weekVal]) {
              if (assetJobMap?.[assetId]?.[weekVal]?.[item?.code]) {
                assetJobMap[assetId][weekVal][item?.code] = [
                  ...assetJobMap[assetId][weekVal][item?.code],
                  item,
                ];
              } else {
                assetJobMap[assetId][weekVal] = {
                  ...assetJobMap[assetId][weekVal],
                  [`${item?.code}`]: [item],
                };
              }
            } else {
              assetJobMap[assetId] = {
                ...assetJobMap[assetId],
                [`${weekVal}`]: { [`${item?.code}`]: [item] },
              };
            }

            if (!assetJobMap?.[assetId]?.[item?.code]) {
              assetJobMap[assetId] = {
                ...assetJobMap[assetId],
                [`${item?.code}`]: item?.maintenance,
              };
              assetJobMap[assetId]["pm_list"] = [
                ...assetJobMap[assetId]["pm_list"],
                item?.code,
              ];
            }
          } else {
            assetJobMap = {
              ...assetJobMap,
              [`${assetId}`]: {
                [`${weekVal}`]: { [`${item?.code}`]: [item] },
                [`${item?.code}`]: item?.maintenance,
                pm_list: [item?.code],
              },
            };
          }
        }
      });
      fetchAssets(assetJobMap);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!(startDate && endDate)) {
      fetchPMS();
    }
  }, [startDate, endDate, pageNumber]); // currEvent

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const { weeks } = getWeekDatesOfYear(dayjs().year());
  const monthColumns = weeks?.map((w) => {
    return {
      title: (
        <Space direction="vertical" style={{ gap: 2, alignItems: "center" }}>
          <Typography
            className={typoStyles.body1}
            style={{ color: "#020202" }}
          >{`Week ${w?.weekNumber}`}</Typography>
          <Typography
            className={typoStyles.body1}
            style={{ color: "#020202" }}
          >{`(${w?.startOfWeek}-${w?.endOfWeek})`}</Typography>
        </Space>
      ),
      dataIndex: `Week ${w?.weekNumber}`,
      key: `Week ${w?.weekNumber}`,
      width: 165,
      render: (_, record) => {
        return (
          <Space direction="vertical" style={{ width: "100%" }}>
            {record?.week_wise_data?.pm_list?.map((item, index) => {
              let jobData = record?.week_wise_data?.[w?.startOfWeek]?.[item];
              console.log(jobData, "jobbbbbb");
              if (jobData) {
                let occurenceDate = convertUTCToLocalFormat(
                  jobData[0]?.occurrence_date
                );
                const currStartTime = dayjs(occurenceDate).format("hh:mm A");
                const currEndTime = dayjs(
                  convertUTCToLocalFormat(jobData[0]?.occurrence_end_date)
                ).format("hh:mm A");
                // const utcOffset = new Date(
                //   jobData[0]?.occurrence_date
                // ).getTimezoneOffset();
                // const currStartTime = dayjs(
                //   new Date(
                //     new Date(jobData[0]?.occurrence_date).getTime() +
                //       utcOffset * 60000
                //   )
                // ).format("HH:mm");
                // const currEndTime = dayjs(
                //   new Date(
                //     new Date(jobData[0]?.occurrence_end_date).getTime() +
                //       utcOffset * 60000
                //   )
                // ).format("HH:mm");

                return (
                  <Space
                    direction="vertical"
                    style={{
                      gap: 4,
                      cursor: "pointer",
                      width: "100%",
                      backgroundColor:
                        colorMap[index % colorMap.length].bgColor,
                    }}
                    className="assetPmCard"
                    onClick={() =>
                      setCurrEvent(
                        jobData?.map((j) => {
                          return {
                            ...j,
                            type: "pm",
                            occ: j,
                          };
                        })
                      )
                    }
                  >
                    <Typography
                      className={typoStyles.body1}
                      style={{
                        color: colorMap[index % colorMap.length].textColor,
                      }}
                    >
                      {jobData?.length === 1
                        ? weekdays[dayjs(occurenceDate).day()]
                        : `${
                            jobData[0]?.maintenance?.repeat === "daily"
                              ? "Daily"
                              : "Custom"
                          } (+${jobData?.length})`}
                    </Typography>
                    <Typography
                      className={typoStyles.body1}
                      style={{
                        color: colorMap[index % colorMap.length].textColor,
                      }}
                    >
                      {`${currStartTime}-${currEndTime}`}
                    </Typography>
                  </Space>
                );
              }
            })}
          </Space>
        );
      },
    };
  });
  const tableColumns = [
    {
      title: (
        <Typography className={typoStyles.body1} style={{ color: "#020202" }}>
          Asset
        </Typography>
      ),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 200,
    },
    {
      title: (
        <Typography className={typoStyles.body1} style={{ color: "#020202" }}>
          Scheduled At
        </Typography>
      ),
      dataIndex: "Scheduled at",
      key: "Scheduled at",
      fixed: "left",
      width: 250,
      render: (val, record) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            {record?.week_wise_data?.pm_list?.map((item, index) => {
              let data = record?.week_wise_data?.[item];
              return (
                <Space align="start" style={{ gap: 2 }}>
                  <Tag color={colorMap[index % colorMap.length].tagColor}>
                    {item}
                  </Tag>
                  {generateScheduleStringForTable(
                    data?.["start_date"],
                    dayjs(data?.["start_time"], "HH:mm:ss"),
                    data?.["repeat"],
                    data?.["week_days"],
                    data?.["day_of_month"],
                    data?.["month_option"],
                    data?.["month"],
                    data?.["every"]
                  ) || ""}
                </Space>
              );
            })}
          </Space>
        );
      },
    },
    ...monthColumns,
  ];

  useEffect(() => {
    // Scroll to specific column once data is loaded
    if (assetsList.length > 0 && tableRef.current) {
      const scrollContainer =
        tableRef?.current?.querySelector(".ant-table-body");
      const columnIndex = getWeekDatesOfYear(dayjs().year()).currDateWeek - 1; // Set index of the target column (e.g., address column)
      const columnWidth = tableColumns[columnIndex]?.width || 165; // Use column width or a default
      scrollContainer.scrollLeft = columnWidth * columnIndex;
      scrollContainer.scrollTop = 0;
    }
  }, [assetsList, tableColumns]);

  return (
    <Row gutter={[0, 24]}>
      <Col span={24} style={{ textAlign: "right" }}>
        <RangePicker
          format="DD-MMM-YYYY"
          onChange={(_, dateString) => {
            setStartDate(dateString[0]);
            setEndDate(dateString[1]);
          }}
          disabledDate={disabledDate}
        />
      </Col>
      {startDate && endDate ? (
        <AssetDateWiseView
          startDate={startDate}
          endDate={endDate}
          currEvent={currEvent}
          setCurrEvent={setCurrEvent}
          refresh={refresh}
        />
      ) : loading ? (
        <Skeleton active />
      ) : (
        <>
          <Col span={24}>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: "#f5f8ff",
                  },
                },
              }}
            >
              <div ref={tableRef}>
                <Table
                  // ref={tableRef}
                  pagination={false}
                  bordered
                  scroll={{
                    x: 1200,
                    y: "60vh",
                  }}
                  columns={tableColumns}
                  dataSource={assetsList}
                  locale={{
                    emptyText: (
                      <Result
                        icon={Empty.PRESENTED_IMAGE_SIMPLE}
                        title={<Typography>{"No data found"}</Typography>}
                      />
                    ),
                  }}
                />
              </div>
            </ConfigProvider>
          </Col>
          {totalCount > PAGESIZE && (
            <Col span={24} className="text-center">
              <Pagination
                current={pageNumber}
                pageSize={PAGESIZE}
                total={totalCount}
                onChange={(e) => setPageNumber(e)}
                showSizeChanger={false}
              />
            </Col>
          )}
        </>
      )}
      {currEvent && (
        <EventForm
          currEvent={currEvent}
          onClose={(status) => {
            setCurrEvent(null);
            if (status === "refresh") {
              if (startDate && endDate) {
                setRefresh((prev) => !prev);
              } else {
                fetchPMS();
              }
            }
          }}
          pageType="assetView"
        />
      )}
    </Row>
  );
};

export default AssetView;
