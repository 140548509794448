import {
  EyeOutlined,
  HeartOutlined,
  MoreOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Col,
  Dropdown,
  Row,
  Typography,
  Card,
  Image,
  Skeleton,
  Progress,
  Tag,
  message,
} from "antd";
import CustomCard from "../Blocks/CustomCard";
import { useNavigate } from "react-router-dom";
import RandomAvatar from "../Blocks/RandomAvatar";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import progressStyles from "../../assets/styles/Custom/Progress.module.css";
import "./Courses/Course.css";
import "./Tyuts/Tyuts.css";
import videothumbnail from "../../assets/images/tyuts/videothumbnail.svg";
import questionthumbnail from "../../assets/images/tyuts/questionthumbnail.svg";
import pdfthumbnail from "../../assets/images/tyuts/pdfthumbnail.svg";
import coursethumbnail from "../../assets/images/tyuts/coursethumbnail.jpg";
import VideoPlayer from "../Blocks/VideoPlayer";
import CustomCheckBox from "../Blocks/CustomCheckBox";
import CustomRadioButton from "../Blocks/CustomRadioButton";
import { verifyTyutAnswerApi } from "../../services/tyuts.services";
import { TYUT } from "../../constants/defaultKeys";
import { likeCourseApi } from "../../services/courses.services";
const { Meta } = Card;

export function CourseRender({ data, loading, setShareCourse }) {
  const navigate = useNavigate();
  const canShareTyut = true;

  return data?.map((item, index) => {
    return (
      <Col
        xl={{ span: 6 }}
        lg={{ span: 6 }}
        md={{ span: 12 }}
        sm={{ span: 12 }}
        xs={{ span: 24 }}
        key={index}
      >
        <CustomCard
          style={{
            border: "1px solid #EAECF0",
            padding: "12px",
          }}
          bodyStyle={{ padding: "8px 12px 0px 12px" }}
          cover={
            <Image
              style={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
              }}
              preview={false}
              alt="example"
              src={coursethumbnail}
              onClick={() => navigate(`/trainings/course/${item._id}`)}
            />
          }
        >
          <Skeleton loading={loading} avatar active>
            <Row gutter={[0, 12]}>
              <Col span={20}>
                <Typography.Paragraph
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "28px",
                    marginBottom: 8,
                  }}
                  ellipsis={{ rows: 1 }}
                >
                  {item?.name}
                </Typography.Paragraph>
              </Col>
              {item?.total_tyuts && (
                <Col span={4}>
                  <Tag color="blue" className={tagStyles.smRndTag}>
                    {item?.total_tyuts} Tyuts
                  </Tag>
                </Col>
              )}

              <Col span={22}>
                <Progress
                  className={progressStyles.basicProgress}
                  status="active"
                  percent={item?.percentage_completed?.toFixed()}
                />
              </Col>

              {/* <Col span={20}>
                <Meta
                  avatar={
                    <RandomAvatar
                      user={item?.creator}
                      indexId={item?.creator?._id}
                      name={item?.creator?.first_name}
                      url={item?.creator?.profile_image}
                      displayName={true}
                      size={24}
                    />
                  }
                />
              </Col> */}
              <Col
                span={2}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "12px",
                }}
              >
                {/* <HeartOutlined
                  className={"activeIcon"}
                  style={{ cursor: "default" }}
                />{" "}
                {item?.likes} */}
                {canShareTyut && (
                  <ShareAltOutlined
                    className={"activeIcon"}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setShareCourse({
                        share: true,
                        courseId: item?._id,
                        creator_id: item?.creator._id,
                      })
                    }
                  />
                )}
              </Col>
            </Row>
          </Skeleton>
        </CustomCard>
      </Col>
    );
  });
}
export function TyutRender({
  data,
  loading,
  setIsModalOpen,
  bookmark,
  setShareCourse,
}) {
  //   const canShareTyut = usePermission("can_share_tyut");
  const canShareTyut = true;
  const navigate = useNavigate();

  // async function handleCourseLike(e) {
  //   e.stopPropagation();
  //   try {
  //     const res = await likeCourseApi({
  //       courseId: courseData._id,
  //       payload: { liked: likedCourses?.[courseData._id] ? false : true },
  //     });
  //     handleLikeUpdate(courseData._id, type);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  return data?.map((item, index) => (
    <Col
      xl={{ span: 6 }}
      lg={{ span: 6 }}
      md={{ span: 12 }}
      sm={{ span: 12 }}
      xs={{ span: 24 }}
      key={index}
    >
      <CustomCard
        style={{
          border: "1px solid #EAECF0",
          padding: "12px",
          // minHeight: "330px",
        }}
        bodyStyle={{ padding: "8px 12px 0px 12px" }}
        cover={RenderThumbnail(
          item,
          () => {
            item?.type === "course"
              ? navigate(`/trainings/course/${item._id}`)
              : navigate(`/trainings/tyut/${item?._id}`);
          },
          { cursor: "pointer" }
        )}
      >
        <Row>
          <Col span={item?.type === "course" ? 20 : 24}>
            <Typography.Paragraph
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "28px",
                marginBottom: 8,
              }}
              ellipsis={{ rows: 1 }}
            >
              {item?.name}
            </Typography.Paragraph>
          </Col>
          {item?.type === "course" && (
            <>
              {item?.total_tyuts && (
                <Col span={4}>
                  <Tag color="blue" className={tagStyles.mdRndTag}>
                    {item?.total_tyuts} Tyuts
                  </Tag>
                </Col>
              )}
              <Col span={24}>
                <Progress
                  className={progressStyles.basicProgress}
                  status="active"
                  percent={item?.percentage_completed?.toFixed()}
                />
              </Col>
            </>
          )}
          <Col span={20}>
            {/**/}
            <Meta
              avatar={
                item?.tags?.length > 0 && (
                  <Col span={4}>
                    <Tag color="blue" className={tagStyles.smRndTag}>
                      {item?.tags?.[0]?.name?.slice(0, 10)}
                    </Tag>
                  </Col>
                )
              }
              // title={item?.creator?.first_name}
            />
            {/* <Meta
              avatar={
                <RandomAvatar
                  user={item?.creator}
                  indexId={item?.creator?._id}
                  name={item?.creator?.first_name}
                  url={item?.creator?.profile_image}
                  displayName={true}
                  size={28}
                />
              }
              // title={item?.creator?.first_name}
            /> */}
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              justifyContent: "end",
              gap: "12px",
            }}
          >
            {item?.type !== "course" && (
              <>
                <EyeOutlined
                  className={"activeIcon"}
                  style={{ cursor: "default" }}
                />{" "}
                {item?.views}
              </>
            )}
            <>
              <HeartOutlined
                className={"activeIcon"}
                style={{ cursor: "default" }}
                // onClick={handleCourseLike}
              />
              {item?.likes}
            </>

            {item?.type !== "course" ? (
              <Dropdown
                menu={{
                  items: [
                    canShareTyut && {
                      key: "1",
                      label: (
                        <Typography
                          onClick={() =>
                            setIsModalOpen({
                              isOpen: true,
                              tyut_id: item?._id,
                              creator_id: item?.creator._id,
                            })
                          }
                        >
                          Share
                        </Typography>
                      ),
                    },
                    {
                      key: "2",
                      label: (
                        <Typography
                          onClick={() =>
                            bookmark(item?._id, {
                              bookmarked: !item?.bookmarked,
                            })
                          }
                        >
                          {item?.bookmarked
                            ? "Remove from Watch it later"
                            : "Watch it later"}
                        </Typography>
                      ),
                    },
                  ],
                }}
              >
                <MoreOutlined style={{ cursor: "pointer" }} />
              </Dropdown>
            ) : (
              canShareTyut && (
                <ShareAltOutlined
                  onClick={() =>
                    setShareCourse({
                      share: true,
                      courseId: item?._id,
                      creator_id: item?.creator._id,
                    })
                  }
                />
              )
            )}
          </Col>
        </Row>
      </CustomCard>
    </Col>
  ));
}
export function RenderThumbnail(item, onClick, styles = {}) {
  return (
    <div className="thumbnailDiv">
      <Image
        onClick={onClick}
        alt="Thumbnail"
        preview={false}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "8px",
          position: "absolute", // Ensure the image fills the container
          top: 0,
          left: 0,
          ...styles,
        }}
        src={
          item?.type === "course"
            ? coursethumbnail
            : item?.has_video
            ? item?.thumbnail || videothumbnail
            : item?.has_document
            ? pdfthumbnail
            : questionthumbnail
        }
      />
    </div>
  );
}

export function RenderItems(
  storageType,
  data,
  ids,
  setAnsweredIds,
  answeredIds,
  tyutId,
  setViewAnsHint,
  isAnsSelected,
  viewAnsHint,
  selectedValue,
  setIsAnsSelected,
  isSelectedValue
) {
  const addId = (newId) => {
    if (ids.includes(newId) && !answeredIds.includes(newId)) {
      setAnsweredIds((prevIds) => [...new Set([...prevIds, newId])]);
    }
  };

  async function updateQuestion(value, data, formRef) {
    try {
      const res = await verifyTyutAnswerApi(tyutId, {
        question_id: data?.id,
        ...value,
      });
      if (res?.data?.answer?.toLocaleLowerCase().includes("wrong")) {
        Object.keys(isAnsSelected).includes(data?.id) &&
          setViewAnsHint((prev) => ({ ...prev, [data?.id]: true }));
        message.error(res?.data?.answer);
      } else {
        setViewAnsHint((prev) => ({ ...prev, [data?.id]: false }));
        message.success(res?.data?.answer);
      }
    } catch (error) {
      console.error(error);
      message.error(error.response?.data?.message);
    }
  }

  switch (data?.type) {
    case "video":
      return (
        <CustomCard>
          <VideoPlayer url={data?.src} height="500px" width="100%" />
        </CustomCard>
      );
    case "checkbox":
      return (
        <CustomCheckBox
          data={data}
          contentType={TYUT}
          title={data?.question}
          onFinish={(e) => {
            addId(data?.id);
            updateQuestion(e, data);
          }}
          onChange={(e) => {
            isSelectedValue({
              ...selectedValue,
              check: e,
            });
            // setViewAnsHint(false);
            setViewAnsHint((prev) => ({ ...prev, [data?.id]: false }));
            setIsAnsSelected({ ...isAnsSelected, [data?.id]: e?.length });
          }}
          isAnsSelected={isAnsSelected}
          viewAnsHint={viewAnsHint}
          selectedValue={selectedValue}
          storageType={storageType}
        />
      );
    case "radio":
      return (
        <CustomRadioButton
          data={data}
          contentType={TYUT}
          onFinish={(e) => {
            addId(data?.id);
            updateQuestion(e, data);
          }}
          title={data?.question}
          onChange={(e) => {
            isSelectedValue({
              ...selectedValue,
              radio: e.target.value,
            });
            setViewAnsHint((prev) => ({ ...prev, [data?.id]: false }));
            setIsAnsSelected({ ...isAnsSelected, [data?.id]: true });
          }}
          isAnsSelected={isAnsSelected}
          viewAnsHint={viewAnsHint}
          selectedValue={selectedValue}
          storageType={storageType}
        />
      );
    default:
      return null;
  }
}
