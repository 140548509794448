import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Cascader,
  Col,
  DatePicker,
  Empty,
  Image,
  Input,
  Pagination,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { getAllAssetsForListViewApi } from "../../services/assets.services";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserProvider";
import {
  debounce,
  formatHours,
  minutesToHoursAndMinutes,
} from "../../helpers/utility";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { PAGESIZE } from "../../constants/defaultKeys";
import dayjs from "dayjs";
import sortIcon from "../../assets/images/sortIcon.png";

const AssetsTableView = ({}) => {
  const [assets, setAssets] = useState([]);
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // const [dateFilter, setDateFilter] = useState({
  //   start_date: dayjs().subtract(1, "month"),
  //   end_date: dayjs().add(1, "month"),
  // });
  const [selectedSorter, setSelectedSorter] = useState(undefined);

  useEffect(() => {
    let params = {
      page: pageNumber,
    };
    if (searchValue) {
      params.name = searchValue;
    }
    // if (dateFilter?.start_date) {
    //   params.start_date = dayjs(dateFilter?.start_date).format("YYYY-MM-DD");
    // }
    // if (dateFilter?.end_date) {
    //   params.end_date = dayjs(dateFilter?.end_date).format("YYYY-MM-DD");
    // }
    if (selectedSorter) {
      params.sort_by = selectedSorter;
      params.sort_order = "desc";
    }

    // const queryString = new URLSearchParams(params);
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getAllAssetsForListViewApi(params);
        setAssets(res?.data);
      } catch (error) {
        console.error("Error while fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pageNumber, refresh]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [searchValue, selectedSorter]); // dateFilter

  const columns = [
    {
      title: <Typography className={"tableHeader"}>Asset</Typography>,
      dataIndex: "name",
      key: "name",
      width: 300,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: <Typography className={"tableHeader"}>Asset location</Typography>,
      dataIndex: "location",
      key: "location",
      width: 200,
      render: (val) => <>{val?.name}</>,
    },
    {
      title: <Typography className={"tableHeader"}>Asset downtime</Typography>,
      dataIndex: "totalDowntime",
      key: "totalDowntime",
      width: 200,
      // sorter: (a, b) => a?.totalDowntime - b?.totalDowntime,
      render: (val) => {
        let durationObj = formatHours(val ? val : 0);
        return (
          <Tag className={tagStyles.smRndTag} color="error">
            {durationObj?.days ? `${durationObj?.days} days ` : ""}
            {`${durationObj?.hours} hrs`}
            {` ${durationObj?.minutes} mins`}
          </Tag>
        );
        // const data = minutesToHoursAndMinutes(val * 60);
        // return (
        //   <Tag
        //     className={tagStyles.smRndTag}
        //     color="error"
        //   >{`${data.hours}hr ${data?.remainingMinutes}mins`}</Tag>
        // );
      },
    },
    {
      title: <Typography className={"tableHeader"}>MROs created</Typography>,
      dataIndex: "mroo",
      key: "mroo",
      // width: 140,
      children: [
        {
          title: <Typography className={"tableHeader"}>Mechanical</Typography>,
          dataIndex: "mroTypeCounts",
          key: "mroTypeCounts",
          width: 150,
          // sorter: (a, b) =>
          //   (a?.mroTypeCounts?.Mechanical || 0) -
          //   (b?.mroTypeCounts?.Mechanical || 0),
          render: (val) => (val?.Mechanical ? val?.Mechanical : 0),
        },
        {
          title: <Typography className={"tableHeader"}>Electrical</Typography>,
          dataIndex: "mroTypeCounts",
          key: "mroTypeCounts",
          width: 150,
          // sorter: (a, b) =>
          //   (a?.mroTypeCounts?.Electrical || 0) -
          //   (b?.mroTypeCounts?.Electrical || 0),
          render: (val) => (val?.Electrical ? val?.Electrical : 0),
        },
        {
          title: <Typography className={"tableHeader"}>Others</Typography>,
          dataIndex: "mroTypeCounts",
          key: "mroTypeCounts",
          width: 150,
          // sorter: (a, b) =>
          //   (a?.mroTypeCounts?.Others || 0) - (b?.mroTypeCounts?.Others || 0),
          render: (val) => (val?.Others ? val?.Others : 0),
        },
      ],
    },
    {
      title: <Typography className={"tableHeader"}>WOs created</Typography>,
      dataIndex: "workorderCount",
      key: "workorderCount",
      width: 140,
      // sorter: (a, b) => a?.workorderCount - b?.workorderCount,
    },
    {
      title: <Typography className={"tableHeader"}>PMs created</Typography>,
      dataIndex: "maintenanceCount",
      key: "maintenanceCount",
      width: 140,
      // sorter: (a, b) => a?.maintenanceCount - b?.maintenanceCount,
    },
    {
      title: (
        <Typography className={"tableHeader"}>
          Spare part expenditure
        </Typography>
      ),
      dataIndex: "inventoryExpenditure",
      key: "inventoryExpenditure",
      width: 160,
      // sorter: (a, b) => a?.inventoryExpenditure - b?.inventoryExpenditure,
    },
    {
      title: <Typography className={"tableHeader"}>SOPs</Typography>,
      dataIndex: "sopCodes",
      key: "sopCodes",
      width: 150,
      render: (val) => {
        return (
          <>
            {val?.length > 0 ? (
              <div
                className="flex-start"
                style={{ flexWrap: "wrap", gap: "2px" }}
              >
                {val?.map((eachSOP, index) => (
                  <a
                    href={`/procedures/${eachSOP?._id}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {" "}
                    {eachSOP?.code} {index + 1 != val?.length && ","}
                  </a>
                ))}
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: (
        <Typography className={"tableHeader"}>
          Mean Time To Repair (MTR)
        </Typography>
      ),
      dataIndex: "SOPss",
      width: 200,
      key: "SOPs",
    },
    {
      title: (
        <Typography className={"tableHeader"}>
          Mean Time Between Failure (MTBF)
        </Typography>
      ), // [total uptime / # of breakdowns]",
      dataIndex: "SOPss",
      width: 200,
      key: "SOPs",
    },
  ];
  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
  };

  const sortOptions = [
    {
      label: "MRO type",
      value: "mro_type",
      children: [
        {
          label: "Mechanical",
          value: "Mechanical",
        },
        {
          label: "Electrical",
          value: "Electrical",
        },
        {
          label: "Others",
          value: "Others",
        },
      ],
    },
    {
      label: "Spare part expenditure",
      value: "inventory",
    },
    {
      label: "WOs created",
      value: "workorder",
    },
    {
      label: "PMs created",
      value: "maintenance",
    },
    {
      label: "Asset downtime",
      value: "downtime",
    },
  ];

  return (
    // <Row gutter={[24, 18]}>
    <>
      <Col
        span={24}
        // offset={12}
        className="flex-center"
        style={{ justifyContent: "flex-end", gap: "12px" }}
      >
        <Space style={{ gap: 4 }}>
          {/* <Image src={sortIcon} width={21} height={21} preview={false} /> */}
          <Cascader
            style={{ width: 210, textAlign: "left" }}
            placeholder="Sort by"
            displayRender={(labels) => labels.join(" - ")}
            options={sortOptions}
            onChange={(value) => {
              setSelectedSorter(
                value?.length > 0 ? value[value?.length - 1] : undefined
              );
            }}
          />
        </Space>
        <Input.Search
          onChange={debounce(handleSearch, 500)}
          placeholder="Search by asset name or code"
          style={{ width: "280px" }}
        />
        {/* <DatePicker.RangePicker
          format="DD-MMM-YYYY"
          defaultValue={[dateFilter.start_date, dateFilter.end_date]}
          onChange={(_, dateString) => {
            setDateFilter({
              start_date: dateString[0],
              end_date: dateString[1],
            });
          }}
        /> */}
      </Col>

      <Col span={24}>
        <Table
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No assets found</Typography>}
              />
            ),
          }}
          pagination={false}
          scroll={{
            x: 1200,
            y: "60vh",
          }}
          onRow={(item) => {
            return {
              onClick: (e) => {
                navigate(`/assets/${item?._id}`, {
                  state: { source: "/assets?type=table" },
                });
              },
              style: { cursor: "pointer" }, //
            };
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          columns={columns}
          dataSource={assets?.assetsData}
        />
      </Col>
      {assets?.totalAssets > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={assets?.totalAssets}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </>
  );
};

export default AssetsTableView;
