import axiosInstance from "../config/axios/axios.config";

export const createLocationApi = (payload) => {
  return axiosInstance.post("/location", payload);
};
export const getLocationsApi = () => {
  return axiosInstance.get("/location");
};

export const getLocationByIdApi = () => {
  return axiosInstance.get(`/location`);
};

export const updateLocationApi = (id, payload) => {
  return axiosInstance.put(`/location/${id}`, payload);
};

export const deleteLocationApi = (id) => {
  return axiosInstance.delete(`/location/${id}`);
};

export const getFacilitiesApi = (query) => {
  return query
    ? axiosInstance.get(`/location/facilities?onlyFacilities=true`)
    : axiosInstance.get("/location/facilities");
};
export const getAccessibleLocationsApi = () => {
  return axiosInstance.get("/location/facility");
};
