import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../Layout";
import {
  Button,
  Cascader,
  Col,
  Empty,
  Flex,
  Image,
  Input,
  Pagination,
  Popconfirm,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  TreeSelect,
  Typography,
  message,
} from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AddEditInventory from "./AddEditInventory";
import { getAllAssetsWithLocsApi } from "../../services/assets.services";
import {
  deleteInventoryApi,
  getInventoryList,
} from "../../services/inventory.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import {
  PAGESIZE,
  createInventory,
  deleteInventory,
  editInventory,
} from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";
import { debounce, deleteCond, editCond } from "../../helpers/utility";
import sortIcon from "../../assets/images/sortIcon.png";

const Inventory = () => {
  const { userDetails } = useContext(UserContext);
  const [locations, setLocations] = useState({});
  const [inventories, setInventories] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [currInventory, setCurrInventory] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [typeVisible, setTypeVisible] = useState(false);
  const [selectedInvTypes, setSelectedInvTypes] = useState([]);
  const [selectedSorter, setSelectedSorter] = useState(undefined);

  const inventoryTypes = [
    {
      title: "Mechanical",
      value: "Mechanical",
    },
    {
      title: "Electrical",
      value: "Electrical",
    },
    {
      title: "Others",
      value: "Others",
    },
  ];

  function transformData(data) {
    function transformNode(node) {
      const transformedChildren = node.children.map(transformNode);
      return {
        value: node._id,
        title: node.name,
        disabled: node?.disabled,
        children: transformedChildren.length ? transformedChildren : undefined,
      };
    }
    let treeData = transformNode(data);
    return treeData;
  }

  const fetchLocations = async () => {
    try {
      const resp = await getAllAssetsWithLocsApi();
      let treeData = transformData(resp?.data?.locations?.[0]);

      setLocations(treeData);
    } catch (error) {
      setLocations({});
    }
  };

  const fetchInventoryList = async () => {
    let query = {
      page: pageNumber,
    };
    if (searchValue) {
      query = { ...query, name: searchValue };
    }
    selectedInvTypes?.length > 0 && (query.type = selectedInvTypes);
    if (selectedSorter) {
      query.sort_by = selectedSorter;
      query.sort_order = "desc";
    }

    setLoading(true);
    try {
      const res = await getInventoryList(query);
      setInventories(res?.data);
    } catch (error) {
      setInventories(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInventoryList();
  }, [pageNumber, refresh]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [selectedInvTypes, selectedSorter]);

  useEffect(() => {
    fetchLocations();
  }, []);

  const refreshPage = (source = "") => {
    if (source === "search" && pageNumber > 1) {
      setPageNumber(1);
    } else if (
      source === "delete" &&
      inventories?.inventories?.length === 1 &&
      pageNumber > 1
    ) {
      setPageNumber((prev) => prev - 1);
    } else {
      setRefresh((prev) => !prev);
    }
  };

  const deleteInventoryFunc = async (id) => {
    try {
      const resp = await deleteInventoryApi(id);
      if (resp) {
        message.success(resp?.data?.message);
        refreshPage("delete");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
    if (
      e?.target?.value?.trim()?.length === 0 ||
      e?.target?.value?.trim()?.length > 1
    ) {
      refreshPage("search");
    }
  };

  const handleTypeDropdownVisibleChange = (visible) => {
    setTypeVisible(visible);
    if (
      !visible &&
      (selectedTypes?.length !== selectedInvTypes?.length ||
        !selectedTypes?.every((i) => selectedInvTypes?.includes(i)))
    ) {
      setSelectedTypes([]);
      setSelectedInvTypes([]);
    }
  };

  const tableColumns = [
    {
      title: <Typography className={"tableHeader"}>Spare part code</Typography>,
      dataIndex: "code",
      key: "code",
      fixed: "left",
      width: 250,
      render: (val, record) => (
        <Space>
          {val}
          {(Number(record?.available_quantity) <= 0 ||
            Number(record?.available_quantity) <
              Number(record?.low_stock_threshold)) && (
            <Tag className={tagStyles.smTag} color="red">
              Low Stock
            </Tag>
          )}
        </Space>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>Location</Typography>,
      dataIndex: "location",
      key: "location",
      width: 250,
      render: (val) => val?.name || "-",
    },
    {
      title: <Typography className={"tableHeader"}>Spare part name</Typography>,
      dataIndex: "name",
      key: "name",
      width: 250,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: <Typography className={"tableHeader"}>Type</Typography>,
      dataIndex: "type",
      key: "type",
      width: 140,
      render: (val) => (val ? val : "-"),
    },
    {
      title: (
        <Typography className={"tableHeader"}>Quantity available</Typography>
      ),
      dataIndex: "available_quantity",
      key: "available_quantity",
      width: 180,
      // sorter: (a, b) => a?.available_quantity - b?.available_quantity,
    },
    {
      title: (
        <Typography className={"tableHeader"}>Low stock threshold</Typography>
      ),
      dataIndex: "low_stock_threshold",
      key: "low_stock_threshold",
      width: 180,
      sorter: (a, b) => a?.low_stock_threshold - b?.low_stock_threshold,
    },
    {
      title: <Typography className={"tableHeader"}>Cost per piece</Typography>,
      dataIndex: "cost",
      key: "cost",
      width: 180,
      // sorter: (a, b) => a?.cost - b?.cost,
    },
    {
      title: <Typography className={"tableHeader"}>Actions</Typography>,
      dataIndex: "action",
      key: "action",
      width: 140,
      render: (_, record) => {
        return (
          <Flex gap={12} align="center" justify="start">
            {deleteCond(
              deleteInventory,
              record?.creator,
              userDetails?.activeOrg?.role,
              userDetails?._id
            ) && (
              <Popconfirm
                icon={null}
                description="Are you sure to delete this inventory?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  deleteInventoryFunc(record?._id);
                }}
                onCancel={(e) => e.stopPropagation()}
              >
                <DeleteOutlined />
              </Popconfirm>
            )}
            {editCond(
              editInventory,
              record?.creator,
              userDetails?.activeOrg?.role,
              userDetails?._id
            ) && (
              <EditOutlined
                onClick={() => {
                  setCurrInventory(record);
                  setShowDrawer(true);
                }}
              />
            )}
          </Flex>
        );
      },
    },
  ];

  const sortOptions = [
    {
      label: "Quantity",
      value: "available_quantity",
    },
    {
      label: "Cost",
      value: "cost",
    },
  ];

  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography className={typoStyles.h6}>
            Inventory ({inventories?.totalInventories || 0})
          </Typography>
        </Flex>
      }
    >
      <>
        <Row gutter={[24, 18]}>
          <Col
            // offset={12}
            span={24}
            style={{ textAlign: "right", justifyContent: "flex-end" }}
            className="flex-center"
          >
            <Space style={{ gap: 4 }}>
              {/* <Image src={sortIcon} width={21} height={21} preview={false} /> */}
              <Cascader
                style={{ width: 210, textAlign: "left" }}
                placeholder="Sort by"
                displayRender={(labels) => labels.join(" - ")}
                options={sortOptions}
                onChange={(value) => {
                  setSelectedSorter(
                    value?.length > 0 ? value[value?.length - 1] : undefined
                  );
                }}
              />
            </Space>
            <TreeSelect
              className="filter-tree"
              style={{ width: 250, textAlign: "left" }}
              placeholder="Inventory Type"
              multiple={true}
              treeCheckable={true}
              showSearch={false}
              treeData={inventoryTypes}
              value={selectedTypes}
              maxTagCount={1}
              onChange={(e) => {
                setSelectedTypes(e);
              }}
              dropdownRender={(menu) => (
                <div onMouseDown={(e) => e.stopPropagation()}>
                  {menu}
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setSelectedInvTypes(selectedTypes);
                      setTypeVisible(false);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              )}
              popupClassName="filter-tree-dropdown"
              open={typeVisible}
              onDropdownVisibleChange={handleTypeDropdownVisibleChange}
            />
            <Input.Search
              size="medium"
              onChange={debounce(handleSearch, 500)}
              placeholder="Search by inventory name or code"
              style={{ width: 300 }}
            />
            {createInventory.includes(userDetails?.activeOrg?.role) && (
              <Button
                type="primary"
                className={btnStyles.mdBtn}
                onClick={() => {
                  setCurrInventory(null);
                  setShowDrawer(true);
                }}
              >
                + Add Inventory
              </Button>
            )}
          </Col>
          <Col span={24}>
            <Table
              className="tableContainer"
              scroll={{
                x: 1200,
                y: "60vh",
              }}
              locale={{
                emptyText: loading ? (
                  <Skeleton active />
                ) : (
                  <Result
                    icon={Empty.PRESENTED_IMAGE_SIMPLE}
                    title={<Typography>No inventory found</Typography>}
                  />
                ),
              }}
              pagination={false}
              columns={tableColumns}
              dataSource={inventories?.inventories || []}
              loading={
                loading
                  ? {
                      spinning: false,
                      indicator: null,
                    }
                  : false
              }
            />
          </Col>
          {inventories?.totalInventories > PAGESIZE && (
            <Col span={24} className="text-center">
              <Pagination
                current={pageNumber}
                pageSize={PAGESIZE}
                total={inventories?.totalInventories}
                onChange={(e) => setPageNumber(e)}
                showSizeChanger={false}
              />
            </Col>
          )}
        </Row>
        {showDrawer && (
          <AddEditInventory
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            locations={locations}
            currInventory={currInventory}
            refreshPage={refreshPage}
          />
        )}
      </>
    </CustomLayout>
  );
};

export default Inventory;
