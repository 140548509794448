import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, message, Upload, Space, Image } from "antd";
import { Mention, MentionsInput } from "react-mentions";
import CommentCss from "./CommentCss";
import { updateCommentApi } from "../../../../services/work-orders.services";
import { ONPREMISE } from "../../../../constants/defaultKeys";
import { UserContext } from "../../../../context/UserProvider";
import {
  deleteDocs,
  getAssetsPath,
  renameFile,
  uploadFunc,
} from "../../../../helpers/utility";
import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";

const EditComment = ({ users, refresh, editComment, setEditComment }) => {
  const { userDetails } = useContext(UserContext);
  const [comment, setComment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);

  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  useEffect(() => {
    setComment(editComment?.value);
    setImageFiles(editComment?.value?.files);
  }, [editComment]);

  const handleSubmit = async () => {
    setLoading(true);
    let data = {
      content: comment?.content,
      markup: comment?.markup,
      tagged_users: comment?.tagged_users?.map(
        (user) => user?._id || user?.id || user
      ),
      files:
        imageFiles?.length > 0
          ? imageFiles?.map((imgFile) =>
              storageType == ONPREMISE ? imgFile : renameFile(imgFile)
            )
          : [],
    };
    !comment?.tagged_users?.length > 0 && delete data?.tagged_users;

    try {
      const resp = await updateCommentApi(editComment?.value?._id, data);
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong!!");
    } finally {
      refresh();
      setLoading(false);
      setEditComment({ status: false });
    }
  };

  const handleCancel = () => {
    setEditComment({ status: false, value: null });
  };

  const handleChange = (event, _, newPlainTextValue, mentions) => {
    setComment({
      content: newPlainTextValue,
      markup: event?.target?.value,
      tagged_users: mentions?.length > 0 ? mentions : comment?.tagged_users,
    });
  };

  const handleUpload = (info) => {
    if (info.file.status !== "uploading") {
      uploadImage(info);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (info.file.status === "error") {
      message.error("Image upload failed.");
    }
  };

  const uploadImage = async (info, key) => {
    try {
      // setLoading(true);
      const resp = await uploadFunc(info, storageType);
      if (resp) {
        setImageFiles([...imageFiles, resp?.path || ""]);
      }
    } catch (error) {
    } finally {
      // setLoading(false);
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Modal
      footer={null}
      title="Edit Comment"
      onCancel={handleCancel}
      open={editComment?.status}
    >
      <Space style={{ width: "100%" }} direction="vertical">
        <MentionsInput
          placeholder="Use '@' for mention"
          value={comment?.markup}
          onChange={handleChange}
          style={CommentCss}
          className="custom-mentions-input-pl"
        >
          <Mention data={users} style={{ backgroundColor: "#cee4e5" }} />
        </MentionsInput>
        <Upload
          beforeUpload={(file) => {
            // console.log(file.size);
            return new Promise((resolve) => {
              new Compressor(file, {
                quality: 0.8, // Adjust quality between 0 and 1
                convertSize: 1000000,
                success(compressedFile) {
                  // console.log(compressedFile, "cf");
                  resolve(compressedFile); // Resolve with the compressed file
                },
                error(err) {
                  console.error("Compression failed:", err.message);
                },
              });
            });
          }}
          customRequest={dummyRequest}
          name="file"
          // action="/upload" // URL for handling the file upload request
          // listType="picture"
          showUploadList={false}
          onChange={handleUpload}
          accept="image/*"
          multiple={false}
        >
          <Button>
            Upload Images <UploadOutlined />
          </Button>
        </Upload>
        <Space style={{ width: "100%" }}>
          {imageFiles?.map(
            (imgSrc) =>
              imgSrc && (
                <div style={{ position: "relative" }}>
                  <Image
                    src={getAssetsPath(imgSrc, storageType)}
                    preview={true}
                    style={{ width: 80, height: 80 }}
                  />
                  <CloseCircleOutlined
                    style={{
                      fontSize: 16,
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                    onClick={() => {
                      deleteDocs(imgSrc, storageType);
                      setImageFiles(imageFiles?.filter((i) => i !== imgSrc));
                      // deleteDocs(imgSrc, storageType);
                    }}
                  />
                </div>
              )
          )}
        </Space>

        {comment?.content?.length > 0 && (
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            style={{ float: "right" }}
          >
            Update Comment
          </Button>
        )}
      </Space>
    </Modal>
  );
};

export default EditComment;
