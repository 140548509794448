import { ConfigProvider, Tabs, Typography } from "antd";
import React, { useContext } from "react";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import "./Organisation.css";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserProvider";

const OrgSettingsHeader = ({ count }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { userDetails } = useContext(UserContext);

  const tabItems = [
    {
      key: "groups",
      label:
        location.pathname.split("/")[2] == "groups"
          ? `Teams (${count})`
          : "Teams",
    },
    {
      key: "departments",
      label:
        location.pathname.split("/")[2] == "departments"
          ? `Departments (${count})`
          : "Departments",
      // label: "Departments",
    },
    ...(!userDetails?.activeOrg?.role !== "technician"
      ? [
          {
            key: "users",
            label:
              location.pathname.split("/")[2] == "users"
                ? `Users (${count})`
                : "Users",
          },
        ]
      : []),
    {
      key: "workflows",
      label:
        location.pathname.split("/")[2] == "workflows"
          ? `Workflows (${count})`
          : "Workflows",
      // label: "Workflows",
    },
    {
      key: "profile",
      label: "Profile",
    },
  ];

  const onChange = (key) => {
    navigate(`/org/${key}`);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 35,
      }}
    >
      <Typography className={typoStyles.h6} style={{ marginTop: 6 }}>
        Organization
      </Typography>
      <div style={{ height: 36 }}>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                colorBorderSecondary: "#d0d5dd",
              },
            },
          }}
        >
          <Tabs
            className="orgTabs"
            defaultActiveKey={location.pathname.split("/")[2]}
            onChange={onChange}
            moreIcon={null}
          >
            {tabItems?.map((item) => (
              <Tabs.TabPane key={item?.key} tab={item?.label} />
            ))}
          </Tabs>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default OrgSettingsHeader;
