import { Button, Form, Input, Typography, message } from "antd";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getMetaDataApi, signUpApi } from "../../../services/users.services";
import { setCookie } from "../../../helpers/localStorage";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../../constants/defaultKeys";

import { UserContext } from "../../../context/UserProvider";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";

const SignUpForm = ({ current }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setUserDetails } = useContext(UserContext);
  const onFinish = async (values) => {
    delete values?.confirm_password;
    try {
      setLoading(true);
      const resp = await signUpApi(values);
      if (resp) {
        setCookie(
          ACCESS_TOKEN,
          resp?.data?.access_token,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        setCookie(
          REFRESH_TOKEN,
          resp?.data?.refresh_token,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        await getOrgData();
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const getOrgData = async () => {
    try {
      const resp = await getMetaDataApi();
      setUserDetails(resp?.data);
      navigate(`/signup/${parseInt(current) + 1}`);
    } catch (error) {
      console.log(error);
    }
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Typography style={{ marginBottom: "12px" }} className={typoStyles.h5}>
          Sign Up
        </Typography>
        <Typography
          className={`${typoStyles.body3} grey-text`}
          style={{ marginBottom: "32px" }}
        >
          Welcome! Please enter your details.
        </Typography>
      </div>
      <Form layout="vertical" onFinish={onFinish} requiredMark={false}>
        <Form.Item
          label="First name"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Enter your first name",
            },
            {
              whitespace: true,
              message: "First name cannot be empty or contain only spaces",
            },
            {
              max: 50,
              message: "First name cannot exceed 50 characters",
            },
          ]}
        >
          <Input
            placeholder="Enter your first name"
            className={inputStyles.smInput}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Enter your last name",
            },
            {
              whitespace: true,
              message: "Last name cannot be empty or contain only spaces",
            },
            {
              max: 50,
              message: "Last name cannot exceed 50 characters",
            },
          ]}
        >
          <Input
            placeholder="Enter your last name"
            className={inputStyles.smInput}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            // { type: "email", message: "Please enter a valid email" },
            {
              required: true,
              message: "Enter your email",
            },
            {
              pattern: emailRegex,
              message: "Please enter a valid email",
            },
          ]}
        >
          <Input
            placeholder="Enter your email"
            className={inputStyles.smInput}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please input your password!" },
            {
              max: 500,
              message: "Password cannot exceed 500 characters",
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/,
              message:
                "Password must contain at least 1 letter, 1 number, and be at least 8 characters long.",
            },
            // {
            //   message:
            //     "Password must have at least 8 characters, including uppercase, lowercase, digit, and special character.",
            //   pattern: new RegExp(
            //     "^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
            //   ),
            // },
          ]}
        >
          <Input.Password
            placeholder="Enter your password"
            className={inputStyles.smInput}
            style={{ width: "100%", gap: "0px" }}
          />
        </Form.Item>

        <Form.Item
          label="Confirm your password"
          name="confirm_password"
          rules={[
            { required: true, message: "Please input your password!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The new passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password
            className={inputStyles.smInput}
            placeholder="Confirm your new password"
            style={{ width: "100%", gap: "0px" }}
          />
        </Form.Item>
        <div style={{ textAlign: "right" }}>
          <Button
            onClick={() => navigate("/login")}
            type="link"
            style={{ marginBottom: "24px" }}
          >
            <span style={{ color: "#2f3542", marginRight: "6px" }}>
              Already have an account?{" "}
            </span>{" "}
            Login
          </Button>
        </div>
        <Form.Item style={{ textAlign: "center" }}>
          <Button
            htmlType="submit"
            className={btnStyles.mdBtn}
            type="primary"
            style={{ width: "100%" }}
            loading={loading}
          >
            Sign Up
          </Button>
        </Form.Item>
        <Typography className="text-center" style={{ marginBottom: "12px" }}>
          By Creating an Account, you acknowledge and accept our
          <br />{" "}
          <a
            href="https://getbolt.in/privacy-policy"
            target="_blank"
            type="text"
            style={{ color: "#007aff", fontWeight: 500 }}
            // className="hyperlink"
          >
            Privacy Policy
          </a>
          .
        </Typography>
      </Form>
    </>
  );
};

export default SignUpForm;
