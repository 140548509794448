import { FilterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Select,
  Tag,
  TreeSelect,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getAllAssetsApi } from "../../services/assets.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import filterIcon from "../../assets/images/filterIcon.svg";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";

const MroFilters = ({
  // selectedAsset,
  // setSelectedAsset,
  // selectedSeverity,
  // setSelectedSeverity,
  // selectedMroTypes,
  // setSelectedMroTypes,
  // selectedStatus,
  // setSelectedStatus,
  showFilterModal,
  setShowFilterModal,
  assets,
  filters,
  setFilters,
}) => {
  // const [assets, setAssets] = useState([]);
  // const [selectedTypes, setSelectedTypes] = useState([]);
  // const [dropdownVisible, setDropdownVisible] = useState(false);

  const [form] = Form.useForm();
  const formRef = useRef();

  const severityList = [
    {
      label: "Critical",
      value: "critical",
    },
    {
      label: "Non Critical",
      value: "non-critical",
    },
  ];

  const mroTypes = [
    {
      title: "Mechanical",
      value: "Mechanical",
    },
    {
      title: "Electrical",
      value: "Electrical",
    },
    {
      title: "Others",
      value: "Others",
    },
  ];

  const mroStatuses = [
    {
      label: "Resolved",
      value: "resolved",
    },
    {
      label: "Not Resolved",
      value: "not-resolved",
    },
  ];

  // const fetchAssets = async () => {
  //   try {
  //     const resp = await getAllAssetsApi();
  //     setAssets(
  //       resp?.data?.assets?.map((asset) => ({
  //         label: (
  //           <div
  //             style={{
  //               gap: 8,
  //               width: "100%",
  //               display: "flex",
  //               alignItems: "center",
  //             }}
  //           >
  //             <Tag className={tagStyles.smTag} color="blue">
  //               {asset?.code}
  //             </Tag>
  //             <span
  //               style={{
  //                 whiteSpace: "nowrap",
  //                 overflow: "hidden",
  //                 textOverflow: "ellipsis",
  //               }}
  //             >
  //               {asset?.name}
  //             </span>
  //           </div>
  //         ),
  //         name: asset?.name,
  //         value: asset?._id,
  //       }))
  //     );
  //   } catch (error) {
  //     setAssets([]);
  //   }
  // };

  // useEffect(() => {
  //   fetchAssets();
  // }, []);

  // const handleDropdownVisibleChange = (visible) => {
  //   console.log(visible, "visible");
  //   setDropdownVisible(visible);
  //   if (
  //     !visible &&
  //     (selectedTypes?.length !== selectedMroTypes?.length ||
  //       !selectedTypes?.every((i) => selectedMroTypes?.includes(i)))
  //   ) {
  //     setSelectedTypes([]);
  //     setSelectedMroTypes([]);
  //   }
  // };

  useEffect(() => {
    if (showFilterModal) {
      form.setFieldsValue(filters);
    }
  }, [showFilterModal, filters]);

  const onFinish = (values) => {
    // console.log(values);
    setShowFilterModal(false);
    setFilters({
      asset: values?.asset,
      severity: values?.severity,
      types: values?.types,
      status: values?.status,
    });
  };

  return (
    <>
      <Modal
        title="Filter"
        width={"50%"}
        open={showFilterModal}
        onCancel={() => setShowFilterModal(false)}
        maskClosable={false}
        footer={
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            onClick={() => formRef?.current?.submit()}
          >
            Apply
          </Button>
        }
      >
        <Form layout="vertical" form={form} ref={formRef} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Asset" name={"asset"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Type asset name"
                  allowClear
                  showSearch={true}
                  filterOption={(inputValue, option) =>
                    option.name
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  options={assets}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="MRO Type" name={"types"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  // className={`filter-tree ${inputStyles.smSelect}`}
                  style={{ width: "100%" }}
                  placeholder="MRO Type"
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={mroTypes}
                  // value={selectedTypes}
                  // maxTagCount={1}
                  // maxTagPlaceholder={""}
                  // maxTagPlaceholder={
                  //   <div style={{ color: "rgba(0, 0, 0, 0.25)" }}>MRO Type</div>
                  // }
                  // onChange={(e) => {
                  //   setSelectedTypes(e);
                  // }}
                  // dropdownRender={(menu) => (
                  //   <div onMouseDown={(e) => e.stopPropagation()}>
                  //     {menu}
                  //     <Button
                  //       style={{ width: "100%" }}
                  //       type="primary"
                  //       onClick={() => {
                  //         setSelectedMroTypes(selectedTypes);
                  //         setDropdownVisible(false);
                  //       }}
                  //     >
                  //       Apply
                  //     </Button>
                  //   </div>
                  // )}
                  popupClassName="filter-tree-dropdown"
                  // open={dropdownVisible}
                  // onDropdownVisibleChange={handleDropdownVisibleChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="MRO Severity" name={"severity"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="MRO Severity"
                  allowClear
                  // value={selectedSeverity}
                  options={severityList}
                  // onChange={(e) => setSelectedSeverity(e)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="MRO Status" name={"status"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="MRO Status"
                  allowClear
                  // value={selectedStatus}
                  options={mroStatuses}
                  // onChange={(e) => setSelectedStatus(e)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* <Col
        span={4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 12,
        }}
      >
        <Image
          src={filterIcon}
          width={15}
          height={15}
          alt="filter"
          preview={false}
          style={{ marginBottom: 4 }}
        />
        <Typography style={{ marginLeft: 2 }}>Filters</Typography>
      </Col>
      <Col span={10}>
        <Select
          style={{ width: "100%" }}
          placeholder="Type asset name"
          allowClear
          showSearch={true}
          // optionFilterProp="label"
          filterOption={(inputValue, option) =>
            option.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          value={selectedAsset}
          options={assets}
          onChange={(e) => setSelectedAsset(e)}
        />
      </Col>
      <Col span={5}>
        <Select
          style={{ width: "100%" }}
          placeholder="MRO Severity"
          allowClear
          value={selectedSeverity}
          options={severityList}
          onChange={(e) => setSelectedSeverity(e)}
        />
      </Col>
      <Col span={5}>
        <TreeSelect
          className="filter-tree"
          // className={`filter-tree ${
          //   selectedTypes?.length ? "filter-tree-select" : ""
          // }`}
          style={{ width: "100%" }}
          placeholder="MRO Type"
          // allowClear
          multiple={true}
          treeCheckable={true}
          showSearch={false}
          treeData={mroTypes}
          value={selectedTypes}
          maxTagCount={1}
          // maxTagPlaceholder={""}
          // maxTagPlaceholder={
          //   <div style={{ color: "rgba(0, 0, 0, 0.25)" }}>MRO Type</div>
          // }
          onChange={(e) => {
            setSelectedTypes(e);
          }}
          dropdownRender={(menu) => (
            <div onMouseDown={(e) => e.stopPropagation()}>
              {menu}
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={() => {
                  setSelectedMroTypes(selectedTypes);
                  setDropdownVisible(false);
                }}
              >
                Apply
              </Button>
            </div>
          )}
          popupClassName="filter-tree-dropdown"
          open={dropdownVisible}
          onDropdownVisibleChange={handleDropdownVisibleChange}
        />
      </Col>
      <Col offset={4} span={5}>
        <Select
          style={{ width: "100%" }}
          placeholder="MRO Status"
          allowClear
          value={selectedStatus}
          options={mroStatuses}
          onChange={(e) => setSelectedStatus(e)}
        />
      </Col>
      {(selectedAsset ||
        selectedSeverity ||
        selectedMroTypes?.length ||
        selectedStatus) && (
        <Col
          span={3}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            style={{ color: "#7369F4", paddingLeft: 10, cursor: "pointer" }}
            onClick={() => {
              setSelectedAsset(undefined);
              setSelectedSeverity(undefined);
              setSelectedMroTypes([]);
              setSelectedTypes([]);
              setSelectedStatus(undefined);
            }}
          >
            CLEAR
          </Typography>
        </Col>
      )} */}
    </>
  );
};

export default MroFilters;
