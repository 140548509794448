import { Spin } from "antd";
import React from "react";

const Home = () => {
  return (
    <div style={{ height: "100vh", width: "100vw" }} className="flex-center">
      <Spin />
    </div>
  );
};

export default Home;
