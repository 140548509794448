import { Button, Checkbox, Form, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { Typography } from "antd";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import { createLocationApi } from "../../../services/location";
import { getCookie, setCookie } from "../../../helpers/localStorage";
import { ACCESS_TOKEN, ORGANISATION } from "../../../constants/defaultKeys";
import { redirectToDashboard } from "../../../helpers/domainUtility";
import { UserContext } from "../../../context/UserProvider";

const CreateLocation = ({}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { userDetails } = useContext(UserContext);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const resp = await createLocationApi({
        ...values,
        parentId: null,
        is_facility: true,
      });
      if (resp) {
        redirectToDashboard(
          userDetails?.activeOrg?.organization?.domain,
          userDetails?.activeOrg?.organization?.settings?.storage,
          navigate
        );
        setCookie(
          "isTourStarted",
          true,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        setCookie("tour", 0, `.${process.env.REACT_APP_PUBLIC_DOMAIN}`, "/");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!getCookie(ACCESS_TOKEN)) {
      navigate("/signup/0");
    } else if (!getCookie(ORGANISATION)) {
      navigate("/signup/1");
    }
  }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Typography style={{ marginBottom: "12px" }} className={typoStyles.h5}>
          Create Location
        </Typography>
        <Typography
          className={`${typoStyles.body3} grey-text`}
          style={{ marginBottom: "32px" }}
        >
          Please create your business geographical site
        </Typography>
      </div>
      <Form
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        // initialValues={edit && node}
        onFinishFailed={(e) => console.log("Location creation failed:", e)}
      >
        <Form.Item
          name={"name"}
          rules={[
            {
              whitespace: true,
              message: "Location name cannot be empty or contain only spaces",
            },
            { required: true, message: "Please enter location" },
            { max: 50, message: "Location name cannot excced 50 characters" },
            {
              min: 3,
              message: "Location name should contain atleast 3 characters",
            },
          ]}
        >
          <Input placeholder="Enter location name" size="medium" />
        </Form.Item>
        {/* <Form.Item valuePropName="checked" name={"is_facility"}>
          <Checkbox>Mark as facility</Checkbox>
        </Form.Item> */}
        <Form.Item style={{ textAlign: "center" }}>
          <Button loading={loading} type="primary" htmlType="submit">
            Create Location
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateLocation;
