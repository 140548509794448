import React, { useEffect, useState } from "react";
import { getGroupsApi } from "../../services/users.services";
import { getDepartments } from "../../services/department.services";
import { Button, Col, Row, TreeSelect, Typography } from "antd";
import { FilterOutlined } from "@ant-design/icons";

const UserFilters = ({
  selectedUserTeams,
  setSelectedUserTeams,
  selectedUserDept,
  setSelectedUserDept,
  selectedUserRoles,
  setSelectedUserRoles,
}) => {
  const [teams, setTeams] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [teamVisible, setTeamVisible] = useState(false);
  const [selectedDept, setSelectedDept] = useState([]);
  const [deptVisible, setDeptVisible] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roleVisible, setRoleVisible] = useState(false);

  const roleOptions = [
    {
      title: "Admin",
      value: "admin",
    },
    {
      title: "Manager",
      value: "manager",
    },
    {
      title: "Supervisor",
      value: "supervisor",
    },
    {
      title: "Technician",
      value: "technician",
    },
    {
      title: "Operator",
      value: "operator",
    },
  ];

  const fetchTeams = async () => {
    try {
      const resp = await getGroupsApi();
      setTeams(
        resp?.data?.groups?.map((g) => ({
          title: g?.name,
          value: g?._id,
        }))
      );
    } catch (error) {
      setTeams([]);
    }
  };

  const fetchDepartments = async () => {
    try {
      const res = await getDepartments();
      setDepartments(
        res?.data?.map((d) => ({
          title: d?.name,
          value: d?._id,
        }))
      );
    } catch (error) {
      setDepartments([]);
    }
  };

  useEffect(() => {
    fetchTeams();
    fetchDepartments();
  }, []);

  const handleTeamDropdownVisibleChange = (visible) => {
    setTeamVisible(visible);
    if (
      !visible &&
      (selectedTeams?.length !== selectedUserTeams?.length ||
        !selectedTeams?.every((i) => selectedUserTeams?.includes(i)))
    ) {
      setSelectedTeams([]);
      setSelectedUserTeams([]);
    }
  };

  const handleDeptDropdownVisibleChange = (visible) => {
    setDeptVisible(visible);
    if (
      !visible &&
      (selectedDept?.length !== selectedUserDept?.length ||
        !selectedDept?.every((i) => selectedUserDept?.includes(i)))
    ) {
      setSelectedDept([]);
      setSelectedUserDept([]);
    }
  };

  const handleRoleDropdownVisibleChange = (visible) => {
    setRoleVisible(visible);
    if (
      !visible &&
      (selectedRoles?.length !== selectedUserRoles?.length ||
        !selectedRoles?.every((i) => selectedUserRoles?.includes(i)))
    ) {
      setSelectedRoles([]);
      setSelectedUserRoles([]);
    }
  };

  return (
    <>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          {/* <Col
            span={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FilterOutlined style={{ fontSize: 18, color: "#7369F4" }} />
          </Col> */}
          <Col span={4}>
            <TreeSelect
              className="filter-tree"
              style={{ width: "100%" }}
              placeholder="Teams"
              multiple={true}
              treeCheckable={true}
              showSearch={false}
              treeData={teams}
              value={selectedTeams}
              maxTagCount={1}
              onChange={(e) => {
                setSelectedTeams(e);
              }}
              dropdownRender={(menu) => (
                <div onMouseDown={(e) => e.stopPropagation()}>
                  {menu}
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setSelectedUserTeams(selectedTeams);
                      setTeamVisible(false);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              )}
              popupClassName="filter-tree-dropdown"
              open={teamVisible}
              onDropdownVisibleChange={handleTeamDropdownVisibleChange}
            />
          </Col>
          <Col span={4}>
            <TreeSelect
              className="filter-tree"
              style={{ width: "100%" }}
              placeholder="Department"
              multiple={true}
              treeCheckable={true}
              showSearch={false}
              treeData={departments}
              value={selectedDept}
              maxTagCount={1}
              onChange={(e) => {
                setSelectedDept(e);
              }}
              dropdownRender={(menu) => (
                <div onMouseDown={(e) => e.stopPropagation()}>
                  {menu}
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setSelectedUserDept(selectedDept);
                      setDeptVisible(false);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              )}
              popupClassName="filter-tree-dropdown"
              open={deptVisible}
              onDropdownVisibleChange={handleDeptDropdownVisibleChange}
            />
          </Col>
          <Col span={4}>
            <TreeSelect
              className="filter-tree"
              style={{ width: "100%" }}
              placeholder="Role"
              multiple={true}
              treeCheckable={true}
              showSearch={false}
              treeData={roleOptions}
              value={selectedRoles}
              maxTagCount={1}
              onChange={(e) => {
                setSelectedRoles(e);
              }}
              dropdownRender={(menu) => (
                <div onMouseDown={(e) => e.stopPropagation()}>
                  {menu}
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setSelectedUserRoles(selectedRoles);
                      setRoleVisible(false);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              )}
              popupClassName="filter-tree-dropdown"
              open={roleVisible}
              onDropdownVisibleChange={handleRoleDropdownVisibleChange}
            />
          </Col>
          {(selectedUserTeams?.length > 0 ||
            selectedUserDept?.length > 0 ||
            selectedUserRoles?.length > 0) && (
            <Col
              span={3}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ color: "#7369F4", paddingLeft: 10, cursor: "pointer" }}
                onClick={() => {
                  setSelectedUserTeams([]);
                  setSelectedTeams([]);
                  setSelectedUserDept([]);
                  setSelectedDept([]);
                  setSelectedUserRoles([]);
                  setSelectedRoles([]);
                }}
              >
                CLEAR
              </Typography>
            </Col>
          )}
        </Row>
      </Col>
    </>
  );
};

export default UserFilters;
