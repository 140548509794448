import { Button, message } from "antd";
import React, { useState } from "react";
import AddElemModal from "./AddElem/AddElemModal";

import { generateRandomString } from "../../../helpers/utility";
import { addSectionOrSectionQues } from "./AddElem/addElemHelper";

import "../SOP.css";
import {
  INSPECTION,
  INPSECTION_CHECKS,
  MAINTANANCE,
  POSTMAINTANCNE,
  SAFETY,
  SOP,
  TYUT,
  TROUBLESHOOTING,
} from "../../../constants/defaultKeys";

function SOPElements(
  {
    contentType = SOP,
    sopType,
    sopContent,
    setSopContent,
    setEditing,
    setIsUnfilled,
    isUploadLimitReached,
  },
  ref
) {
  const [isModalOpen, setModalOpen] = useState({ open: false, type: "" });
  const [activeTab, setActiveTab] = useState("");

  function scrollQuestionnaireInToView() {
    if (ref.current) {
      ref.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      setIsUnfilled("red");
    }
  }

  const buttonsList = [
    {
      desc: "Safety",
      hidden: contentType == SOP ? false : true,
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          addSectionOrSectionQues(
            sopContent,
            setSopContent,
            setEditing,
            SAFETY,
            "Sample Safety",
            ref
          );
          scrollQuestionnaireInToView();
        }
      },
    },
    {
      desc: "Inspection",
      hidden: contentType == SOP && sopType == "Inspection" ? false : true,
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          addSectionOrSectionQues(
            sopContent,
            setSopContent,
            setEditing,
            INPSECTION_CHECKS,
            "Sample Inspection & Checks",
            ref
          );
        }
      },
    },
    {
      desc: "Maintanance",
      hidden:
        contentType == SOP && ["Maintenance", "Production"].includes(sopType)
          ? false
          : true,
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          addSectionOrSectionQues(
            sopContent,
            setSopContent,
            setEditing,
            MAINTANANCE,
            "Sample Step-by-Step Maintenance",
            ref
          );
        }
      },
    },
    {
      desc: "Troubleshooting",
      hidden: contentType == SOP && ["Repair"].includes(sopType) ? false : true,
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          addSectionOrSectionQues(
            sopContent,
            setSopContent,
            setEditing,
            TROUBLESHOOTING,
            "Sample Troubleshooting",
            ref
          );
        }
      },
    },
    {
      desc: "Post Maintanance",
      hidden:
        contentType == SOP && ["Maintenance"].includes(sopType) ? false : true,
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          addSectionOrSectionQues(
            sopContent,
            setSopContent,
            setEditing,
            POSTMAINTANCNE,
            "Sample Post-Maintenance Check and Clean-up",
            ref
          );
          scrollQuestionnaireInToView();
        }
      },
    },
    {
      desc: "Troubleshooting Guide",
      hidden: true,
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          addSectionOrSectionQues(
            sopContent,
            setSopContent,
            setEditing,
            TROUBLESHOOTING,
            "Troubleshooting Guide",
            ref
          );
          scrollQuestionnaireInToView();
        }
      },
    },
    {
      desc: "Text",
      hidden: [SOP, INSPECTION].includes(contentType) ? false : true,
      onClick: () => {
        scrollQuestionnaireInToView();
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this text.");
        } else {
          const sopId = generateRandomString(12);
          setEditing({ edit: null, data: { id: sopId } });
          setSopContent([
            ...sopContent,
            {
              id: sopId,
              type: "text",
              text: "",
              edit: true,
            },
          ]);
          setTimeout(() => {
            const elem = document.getElementById(sopId);
            if (elem) {
              elem.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
            }
          }, 500);
        }
      },
    },
    {
      desc: "Questionnaire",
      hidden: [SOP, INSPECTION].includes(contentType) ? true : false,
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          const sopId = generateRandomString(12);
          setEditing({ edit: null, data: { id: sopId } });
          setSopContent([
            ...sopContent,
            {
              id: sopId,
              question: "",
              choices: ["Yes", "No"],
              type: "radio",
              edit: true,
            },
          ]);
          setTimeout(() => {
            const elem = document.getElementById(sopId);
            if (elem) {
              elem.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
            }
          }, 500);
        }
      },
    },
    {
      desc: "Question",
      hidden: [SOP, INSPECTION].includes(contentType) ? false : true,
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          const sopId = generateRandomString(12);
          setEditing({ edit: null, data: { id: sopId } });
          setSopContent([
            ...sopContent,
            {
              id: sopId,
              question: "",
              choices: ["Yes", "No"],
              type: "radio",
              edit: true,
            },
          ]);
          setTimeout(() => {
            const elem = document.getElementById(sopId);
            if (elem) {
              elem.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
            }
          }, 500);
        }
      },
    },
    {
      desc: "Checklist",
      hidden: [SOP, INSPECTION].includes(contentType) ? false : true,
      onClick: () => {
        let isEditOn = sopContent?.filter((eachSOP) => eachSOP?.edit === true);
        if (isEditOn.length > 0) {
          scrollQuestionnaireInToView();
          message.error("Fill out this questionnaire.");
        } else {
          const sopId = generateRandomString(12);
          setEditing({ edit: null, data: { id: sopId } });
          setSopContent([
            ...sopContent,
            {
              id: sopId,
              question: "",
              choices: ["Yes"],
              type: "checkbox",
              edit: true,
            },
          ]);
          setTimeout(() => {
            const elem = document.getElementById(sopId);
            if (elem) {
              elem.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
            }
          }, 500);
        }
      },
    },
    {
      desc: "Document",
      hidden: [SOP, INSPECTION].includes(contentType) ? false : true,
      onClick: () => {
        setModalOpen({ open: true, type: "document" });
        setActiveTab("add_doc_link");
      },
    },
    {
      desc: "Video",
      onClick: () => {
        setModalOpen({ open: true, type: "video" });
        setActiveTab("add_yt_link");
      },
      disable:
        contentType == TYUT &&
        sopContent.filter((each) => each?.type == "video").length > 0,
    },
  ]?.filter((each) => !each?.hidden);

  return (
    <div
      className={
        ![SOP, INSPECTION].includes(contentType)
          ? "addTyutElemCard"
          : "addSOPElemCard"
      }
    >
      {buttonsList?.map((eachBtn, index) => (
        <Button
          size="small"
          className="addSOPElemBtn"
          style={{
            width: [SOP, INSPECTION].includes(contentType) && "100%",
            textAlign: "left",
          }}
          disabled={eachBtn?.disable}
          key={index}
          type="primary"
          ghost
          onClick={eachBtn?.onClick}
        >
          + <span className="sopBtnAdd">Add </span> {eachBtn.desc}
        </Button>
      ))}

      {isModalOpen.open && (
        <AddElemModal
          isUploadLimitReached={isUploadLimitReached}
          sopContent={sopContent}
          setSopContent={setSopContent}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
    </div>
  );
}

export default React.forwardRef(SOPElements);
