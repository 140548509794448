import React from "react";
import { sopLanguages } from "../../../constants/defaultKeys";
import { Button } from "antd";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";

const SOPLanguages = ({ languages, setLanguages }) => {
  return (
    <div className="sopLanguages">
      {sopLanguages.map((language, index) => {
        return (
          <Button
            type="primary"
            ghost
            className={`sopLanguageBtn ${
              languages?.includes(language?.value) ? "activeLngBtn" : ""
            }`}
            onClick={() => {
              if (languages.includes(language?.value)) {
                setLanguages(
                  languages.filter((lng) => lng !== language?.value)
                );
              } else {
                setLanguages([...languages, language?.value]);
              }
            }}
            classNames={btnStyles.smBtn}
            key={index}
          >
            {language?.label}
          </Button>
        );
      })}
    </div>
  );
};

export default SOPLanguages;
