import { Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomLayout from "../Layout";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";

import { useSearchParams } from "react-router-dom";
import AssetsListView from "./AssetsListView";
import AssetsTableView from "./AssetsTableView";

const Assets = () => {
  const [searchParams, _] = useSearchParams();
  const [assetsLength, setAssetsLength] = useState(0);
  const [viewType, setViewType] = useState("table");

  useEffect(() => {
    if (searchParams.get("type") == "tree") {
      setViewType("tree");
    } else {
      setViewType("table");
    }
  }, [searchParams]);

  return (
    <CustomLayout
      header={
        <Typography className={typoStyles.h6}>
          Assets ({assetsLength})
        </Typography>
      }
    >
      {viewType == "tree" ? (
        <AssetsListView
          setViewType={setViewType}
          viewType={viewType}
          setAssetsLength={setAssetsLength}
        />
      ) : (
        <AssetsTableView
          setAssetsLength={setAssetsLength}
          setViewType={setViewType}
          viewType={viewType}
        />
      )}
    </CustomLayout>
  );
};

export default Assets;
