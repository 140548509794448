import {
  Button,
  Col,
  Empty,
  Input,
  Pagination,
  Popover,
  Result,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./SOP.css";
import { UserContext } from "../../context/UserProvider";
import { getSOPsApi } from "../../services/sops.services";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";

import {
  createSOP,
  INSPECTION,
  PAGESIZE,
  SOP,
  SOPTYPES,
} from "../../constants/defaultKeys";
import {
  capitalizeFirstLetter,
  convertUtcToIst,
  debounce,
} from "../../helpers/utility";

const SOPSTable = () => {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sops, setSops] = useState({});
  const [viewType, setViewType] = useState("All");
  const searchParams = Object.fromEntries(
    new URLSearchParams(location?.search)
  );

  const params = useParams();

  const columns = [
    {
      title: <Typography className={"tableHeader"}>Code</Typography>,
      dataIndex: "code",
      key: "code",
      width: 150,
    },
    {
      title: <Typography className={"tableHeader"}>Title</Typography>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <Typography className={"tableHeader"}>Type</Typography>,
      dataIndex: "type",
      key: "type",
      width: 180,
      render: (val) => {
        const value = SOPTYPES.filter((item) => item.value == val)?.[0];
        return (
          <Tag
            style={{ fontSize: "14px" }}
            color={value?.color}
            bordered={false}
          >
            {value?.label}
          </Tag>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>Languages</Typography>,
      dataIndex: "language",
      key: "language",
      width: 250,
      render: (val, record) => (
        <>
          {record?.language?.length > 0 ? (
            <div
              className="flex-center"
              style={{
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "6px",
              }}
            >
              {record?.language?.slice(0, 2)?.map((group) => (
                <Tag className={tagStyles.mdTag}>
                  {capitalizeFirstLetter(group)}
                </Tag>
              ))}
              {record?.language?.length > 2 && (
                <Popover
                  content={
                    <div
                      className="flex-center"
                      style={{ flexDirection: "column", gap: "6px" }}
                    >
                      {record?.language?.map((group) => (
                        <Tag className={tagStyles.mdTag}>
                          {capitalizeFirstLetter(group)}
                        </Tag>
                      ))}
                    </div>
                  }
                >
                  <Tag>+{record?.language?.length - 2}</Tag>
                </Popover>
              )}
            </div>
          ) : (
            <>-</>
          )}
        </>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>Updated on</Typography>,
      dataIndex: "updated_at",
      key: "updated_at",
      width: 140,
      render: (val) => <>{convertUtcToIst(val)}</>,
    },
    {
      title: (
        <Typography className={"tableHeader"}>
          Estimated time (in hours)
        </Typography>
      ),
      dataIndex: "estimate_time",
      key: "estimate_time",
      width: 250,
      render: (val) => <>{val ?? "-"}</>,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  useEffect(() => {
    let params = {
      page: pageNumber,
    };
    if (searchValue) {
      params.name = searchValue;
    }
    if (viewType) {
      if (viewType != "All") {
        params.type = `${viewType}`;
      }
    }
    // const queryString = new URLSearchParams(params);
    const fetchData = async () => {
      // setLoading(true);
      try {
        const res = await getSOPsApi(params);
        setSops(res?.data);
      } catch (error) {
        console.error("Error while fetching data:", error);
      } finally {
        // setLoading(false);
      }
    };
    fetchData();
  }, [pageNumber, searchValue, viewType]);

  // useEffect(() => {
  //   if (searchParams?.type) {
  //     setViewType(searchParams?.type);
  //   }
  // }, [location]);

  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
  };

  return (
    <Row gutter={[24, 18]}>
      <Col
        span={12}
        offset={12}
        className="flex-center"
        style={{
          gap: "12px",
          justifyContent: "flex-end",
        }}
      >
        <Select
          options={[{ value: "All", label: "All" }, ...SOPTYPES]}
          value={viewType}
          onChange={(e) => setViewType(e)}
          style={{ width: "200px" }}
        />
        <Input.Search
          onChange={debounce(handleSearch, 500)}
          placeholder={`Search by SOP name or code`}
          style={{ width: "50%" }}
        />
        {createSOP.includes(userDetails?.activeOrg?.role) && (
          <Button
            id="create-sop"
            type="primary"
            className={btnStyles.mdBtn}
            onClick={() => navigate(`/procedures/create`)}
          >
            + Create Procedure
          </Button>
        )}
      </Col>
      <Col span={24}>
        <Table
          pagination={false}
          onRow={(item) => {
            return {
              onClick: (e) => {
                !item?.children?.length > 0 &&
                  navigate(`/procedures/${item?._id}`);
              },
            };
          }}
          locale={{
            emptyText: (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No {params?.type} SOPS found</Typography>}
              />
            ),
          }}
          scroll={{
            x: 1200,
            y: "60vh",
          }}
          rowClassName={"rowClassName"}
          bordered
          columns={columns}
          dataSource={sops?.sops}
          onChange={onChange}
        />
        {sops?.totalSops > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={sops?.totalSops}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default SOPSTable;
