import React, { useState } from "react";
import { Col, Row } from "antd";
import ReactPlayer from "react-player";
// const ReactPlayer = dynamic(() => import("react-player"), {
//   ssr: false,
// });

function VideoPlayer({
  url,
  width = "960px",
  height = "auto",
  setLoading = null,
  style = {},
}) {
  // const [isPlaying, setIsPlaying] = useState(false);
  return (
    <div
      style={{ ...style }}
      // onClick={() => {
      //   setIsPlaying(!isPlaying);
      // }}
    >
      <ReactPlayer
        // config={}
        onReady={() => {
          setLoading?.(false);
        }}
        onError={(e) => {
          setLoading?.(false);
        }}
        // light="/images/LMS/test.png"
        url={url}
        controls={true}
        height={height}
        width={width}
        // playing={isPlaying}
        // onPlay={() => setIsPlaying(true)}
        // onPause={() => setIsPlaying(false)}
        // playIcon={
        //   <Image
        //     src="/images/LMS/icons/play.png"
        //     height={200}
        //     width={200}
        //     alt="Play Button"
        //   />
        // }
        // onPlay={(e) => console.log(e, " setIsPlaying(true)")}
        // onPause={() => setIsPlaying(false)}
        // playing={isPlaying}
        // style={{ width: "300px", height: "auto" }}
      />
    </div>
  );
}

export default VideoPlayer;
