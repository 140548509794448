import { Card, Col, DatePicker, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  displayFormattedDuration,
  displayStatsCard,
  displayTitleValue,
} from "./helpers";
import dayjs from "dayjs";
import { getMWStatsApi } from "../../services/dashboard.services";
import WaffleChart from "./WaffleChart";
import { maintanceStatuses, workOrderstatusList } from "../../helpers/utility";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import SparePartPieChart from "./SparePartPieChart";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
} from "recharts";

const { RangePicker } = DatePicker;

const MaintenanceMetrics = () => {
  const [stats, setStats] = useState({ maintenances: {}, workorders: {} });
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "month"),
    end_date: dayjs().add(1, "month"),
  });

  const getStats = async () => {
    try {
      let params = {};
      if (dateFilter?.start_date) {
        params.start_date = dayjs(dateFilter?.start_date).format("YYYY-MM-DD");
      }
      if (dateFilter?.end_date) {
        params.end_date = dayjs(dateFilter?.end_date).format("YYYY-MM-DD");
      }
      const queryString = new URLSearchParams(params);
      const resp = await getMWStatsApi(queryString);
      setStats(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStats();
  }, [dateFilter]);

  const data = maintanceStatuses?.map((eac, ibdex) => ({
    name: eac?.label,
    count: stats?.maintenances?.statusCounts?.[eac?.value] || 0,
    color: eac?.color,
  }));

  const inspectionData = maintanceStatuses?.map((eac, ibdex) => ({
    name: eac?.label,
    count: stats?.inspections?.statusCounts?.[eac?.value] || 0,
    color: eac?.color,
  }));

  return (
    <>
      <Col
        span={24}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <RangePicker
          format="DD-MMM-YYYY"
          defaultValue={[dateFilter.start_date, dateFilter.end_date]}
          onChange={(_, dateString) => {
            setDateFilter({
              start_date: dateString[0],
              end_date: dateString[1],
            });
          }}
        />
      </Col>
      <Col
        span={24}
        style={{ paddingBottom: 20, height: "81vh", overflowY: "auto" }}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
            <Card
              className="mainCards"
              styles={{
                header: {
                  border: "0px",
                },
              }}
              title={"Work Orders"}
              style={{ width: "100%" }}
            >
              <Row gutter={[18, 12]}>
                <Col span={12}>
                  <Row gutter={[12, 12]}>
                    {workOrderstatusList?.map((eachWO) => {
                      return (
                        <>
                          {eachWO.value !== "pending" && (
                            <Col span={12}>
                              <div
                                className="flex-center"
                                style={{
                                  padding: "24px",
                                  flexDirection: "column",
                                  backgroundColor: eachWO?.color,
                                  color: "#fff",
                                  gap: "8px",
                                  height: "114px",
                                  border: "2px solid #EAECF0",
                                  borderRadius: "12px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 12,
                                    color: "#fff",
                                    fontWeight: 600,
                                  }}
                                >
                                  {" "}
                                  {eachWO.label}
                                </span>
                                <span
                                  className={typoStyles.h5}
                                  style={{ lineHeight: "38px" }}
                                >
                                  {stats?.workorders?.statusCounts?.[
                                    eachWO?.value
                                  ] || 0}
                                </span>
                              </div>
                            </Col>
                          )}
                        </>
                      );
                    })}
                  </Row>
                </Col>
                <Col span={12}>
                  <div
                    style={{
                      border: "2px solid #EAECF0",
                      borderRadius: "12px",
                      padding: "12px",
                      flexDirection: "column",
                      gap: "8px",
                      height: "240px",
                      justifyContent: "space-around",
                    }}
                    className="flex-center"
                  >
                    <Typography
                      className={typoStyles.body3}
                      style={{ fontWeight: 500, textAlign: "center" }}
                    >
                      WO Completion Time (WOCT){" "}
                    </Typography>
                    {displayFormattedDuration(stats?.workorders?.totalDuration)}
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#3D5A80",
                        textAlign: "center",
                      }}
                    >
                      Total time spent completing workorders / Number of
                      workorders completed
                    </Typography>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
            <Card
              title="Spare Parts"
              className="mainCards"
              styles={{
                header: {
                  border: "0px",
                },
              }}
              style={{ width: "100%" }}
            >
              <Row gutter={[12, 12]}>
                <Col
                  span={14}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {displayTitleValue({
                      title: "Total Spare Parts Expenditure",
                      value: `₹ ${stats?.inventories?.totalOverallCost || 0}`,
                      valueColor: "#7369F4",
                      valueFontSize: "h5",
                      mt: 8,
                    })}
                    {displayTitleValue({
                      title: "Others",
                      value: `₹ ${stats?.inventories?.totalOthersCost || 0}`,
                      dotColor: "#8BC1F7",
                    })}
                    {displayTitleValue({
                      title: "Electrical",
                      value: `₹ ${
                        stats?.inventories?.totalElectricalCost || 0
                      }`,
                      dotColor: "#519DE9",
                    })}
                    {displayTitleValue({
                      title: "Mechanical",
                      value: `₹ ${
                        stats?.inventories?.totalMechanicalCost || 0
                      }`,
                      dotColor: "#06C",
                    })}
                  </Space>
                </Col>
                <Col
                  span={10}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <SparePartPieChart
                    total={stats?.inventories?.totalOverallCost || 0}
                    data={[
                      {
                        name: "Others",
                        value: stats?.inventories?.totalOverallCost
                          ? stats?.inventories?.totalOthersCost || 0
                          : 1,
                      },
                      {
                        name: "Electrical",
                        value: stats?.inventories?.totalOverallCost
                          ? stats?.inventories?.totalElectricalCost || 0
                          : 0,
                      },
                      {
                        name: "Mechanical",
                        value: stats?.inventories?.totalOverallCost
                          ? stats?.inventories?.totalMechanicalCost || 0
                          : 0,
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
            <Card
              title="Preventive Maintenance"
              className="mainCards"
              styles={{
                header: {
                  border: "0px",
                },
              }}
              style={{ width: "100%" }}
            >
              <Row gutter={[12, 12]}>
                <Col span={14}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={600}
                      height={280}
                      data={data}
                      margin={{
                        top: 18,
                        right: 5,
                        left: 5,
                        bottom: 5,
                      }}
                    >
                      <XAxis
                        dataKey="name"
                        angle={-20}
                        dy={8}
                        tick={{ fontSize: 12 }}
                      />
                      <Bar
                        dataKey="count"
                        fill={"#108ee9"}
                        barSize={30}
                        radius={[6, 6, 0, 0]}
                      >
                        <LabelList dataKey="count" position="top" />
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry?.color} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Col>
                <Col
                  span={10}
                  style={{
                    border: "2px solid #EAECF0",
                    borderRadius: "12px",
                    padding: "24px",
                    flexDirection: "column",
                    gap: "8px",
                    height: "240px",
                    justifyContent: "space-around",
                  }}
                  className="flex-center"
                >
                  <Typography
                    className={typoStyles.body3}
                    style={{ fontWeight: 500, textAlign: "center" }}
                  >
                    Schedule Compliance{" "}
                  </Typography>
                  <Typography
                    className={typoStyles.h5}
                    style={{ color: "#7369F4" }}
                  >
                    {stats?.maintenances?.completedWithinTime || 0} /{" "}
                    {stats?.maintenances?.totalDocuments || 0}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#3D5A80",
                      textAlign: "center",
                    }}
                  >
                    PMs completed on time / Number of PMs completed
                  </Typography>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
            <Card
              title="Inspections"
              className="mainCards"
              styles={{
                header: {
                  border: "0px",
                },
              }}
              style={{ width: "100%" }}
            >
              <Row gutter={[12, 12]}>
                <Col span={14}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={600}
                      height={280}
                      data={inspectionData}
                      margin={{
                        top: 18,
                        right: 5,
                        left: 5,
                        bottom: 5,
                      }}
                    >
                      <XAxis
                        dataKey="name"
                        angle={-20}
                        dy={8}
                        tick={{ fontSize: 12 }}
                      />
                      <Bar
                        dataKey="count"
                        fill={"#108ee9"}
                        barSize={30}
                        radius={[6, 6, 0, 0]}
                      >
                        <LabelList dataKey="count" position="top" />
                        {inspectionData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry?.color} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Col>
                <Col
                  span={10}
                  style={{
                    border: "2px solid #EAECF0",
                    borderRadius: "12px",
                    padding: "24px",
                    flexDirection: "column",
                    gap: "8px",
                    height: "240px",
                    justifyContent: "space-around",
                  }}
                  className="flex-center"
                >
                  <Typography
                    className={typoStyles.body3}
                    style={{ fontWeight: 500, textAlign: "center" }}
                  >
                    Schedule Compliance{" "}
                  </Typography>
                  <Typography
                    className={typoStyles.h5}
                    style={{ color: "#7369F4" }}
                  >
                    {stats?.maintenances?.completedWithinTime || 0} /{" "}
                    {stats?.maintenances?.totalDocuments || 0}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#3D5A80",
                      textAlign: "center",
                    }}
                  >
                    Inspections completed on time / Number of Inspections
                    completed
                  </Typography>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default MaintenanceMetrics;
