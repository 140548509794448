// contexts/NotificationsContext.js
import { ACCESS_TOKEN, ORGANISATION } from "../constants/defaultKeys";
import { createContext, useContext, useEffect, useState } from "react";
import { getCookie } from "../helpers/localStorage";

const NotificationsContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationsContext);
};

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    if (getCookie(ACCESS_TOKEN)) {
      const eventSource = new EventSource(
        `${process.env.REACT_APP_BASE_URL}events/?token=Bearer ${getCookie(
          ACCESS_TOKEN
        )}&orgid=${getCookie(ORGANISATION)}`
      );
      eventSource.onmessage = (event) => {
        const newNotification = JSON.parse(event.data);
        setNotifications(newNotification);
      };
      eventSource.onerror = (error) => {
        console.error("SSE error:", error);
      };

      return () => {
        eventSource.close();
      };
    }
  }, []);

  const addNotification = (notifications) => {
    notifications?.length > 0 &&
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...notifications,
      ]);
  };

  const contextValue = {
    notifications,
    setNotifications, // Include setNotifications in the context
  };

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  );
};
