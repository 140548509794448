import { Avatar, Flex, Tag, Typography } from "antd";
import { Fragment, useState } from "react";
import RandomAvatar from "../Blocks/RandomAvatar";
import { workOrderstatusList } from "../../helpers/utility";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import "./index.css";

export default function EventCard({ currEvent }) {
  const { code, name, maintenance } = currEvent;

  const users = [
    ...currEvent?.occ?.assigned_users,
    ...currEvent?.occ?.assigned_groups,
  ];
  const status = workOrderstatusList?.filter(
    (status) => status?.value == currEvent?.status
  )?.[0];

  return (
    <div height="100%" style={{ width: "100%" }} className="calenderEvent">
      <Flex justify="flex-start" style={{ gap: 12, flexWrap: "wrap" }}>
        <Typography
          style={{
            color: currEvent?.type == "wo" ? "#fff" : "#fff",
            fontSize: 12,
            lineHeight: "16px",
          }}
        >
          {code}
          <span className="eventName">
            {": "}
            {name || maintenance?.name}
          </span>
        </Typography>

        <Tag
          className={tagStyles.smTag}
          style={{
            fontWeight: "600",
            fontSize: 10,
            padding: "1px 4px",
            borderRadius: "12px",
            height: "16px",
          }}
          color={status?.color}
        >
          {status?.label}{" "}
        </Tag>
        {/* {users?.length > 0 && (
          <Avatar.Group maxCount={4}>
            {users?.map((item) => (
              <Fragment key={item?._id}>
                <RandomAvatar
                  indexId={item?._id}
                  name={item?.first_name}
                  size={20}
                  fontSize={10}
                />
              </Fragment>
            ))}
          </Avatar.Group>
        )} */}
      </Flex>
    </div>
  );
}
