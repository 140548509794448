import { Col, Row, Typography, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getMetaDataApi,
  loginApi,
  sendOtpApi,
  validateOtpApi,
} from "../../../services/users.services";
import { setCookie } from "../../../helpers/localStorage";
import {
  ACCESS_TOKEN,
  ACTIVEDOMAIN,
  ORGANISATION,
  REFRESH_TOKEN,
  USER,
} from "../../../constants/defaultKeys";
import OTPScreen from "./OTPScreen";
import MobileScreen from "./MobileScreen";
import EmailLogin from "./EmailLogin";
import ForgotPasword from "./ForgotPassword";
import CheckEmail from "./CheckEmail";
import loginBG from "../../../assets/images/cmms-logos/loginBG.svg";
import ChooseOrgModal from "../../Organisations/ChooseOrgModal";
import { UserContext } from "../../../context/UserProvider";
import {
  getSubdomain,
  redirectToDashboard,
} from "../../../helpers/domainUtility";
import { getDomainDetailsApi } from "../../../services/organisation.services";
import darkLogo from "../../../assets/images/cmms-logos/CMMSLogo.svg";

const Login = ({ pageType }) => {
  const navigate = useNavigate();
  const [loginType, setLoginType] = useState(pageType || "email");
  const [loginDetails, setLoginDetails] = useState({
    mobile: "",
    token: "",
    email: "",
  });
  const [openChooseModal, setOpenChooseModal] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [orgDetails, setOrgDetails] = useState({ logo: darkLogo, name: "" });

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const resp = await loginApi(values);
      if (resp) {
        setCookie(
          ACCESS_TOKEN,
          resp?.data?.access_token,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        setCookie(
          REFRESH_TOKEN,
          resp?.data?.refresh_token,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );

        await getOrgData();
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const getOrgData = async () => {
    try {
      const resp = await getMetaDataApi();
      if (orgDetails?.name) {
        const activeOrg =
          resp?.data?.organizations?.filter(
            (eachOrg) => eachOrg?.organization?._id === orgDetails?.id
          )?.[0] || {};
        if (Object?.keys(activeOrg).length > 0) {
          setCookie(
            ORGANISATION,
            activeOrg?.organization?._id,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.organization?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setUserDetails({ ...resp?.data, activeOrg: activeOrg });
          redirectToDashboard(
            activeOrg?.organization?.domain,
            activeOrg?.organization?.settings?.storage,
            navigate
          );
        } else {
          // message.error("You are not authorized to access this domain");
          setUserDetails(resp?.data);
          setOpenChooseModal(true);
        }
      } else {
        if (resp?.data?.organizations?.length === 1) {
          const activeOrg = resp?.data?.organizations?.[0];
          setCookie(
            ORGANISATION,
            activeOrg?.organization?._id,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.organization?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setUserDetails({ ...resp?.data, activeOrg: activeOrg });
          redirectToDashboard(
            activeOrg?.organization?.domain,
            activeOrg?.organization?.settings?.storage,
            navigate
          );
        } else if (resp?.data?.organizations?.length === 0) {
          navigate("/signup/1");
          setUserDetails(resp?.data);
        } else {
          setUserDetails(resp?.data);
          setOpenChooseModal(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendOtp = async (values) => {
    try {
      const resp = await sendOtpApi(values);
      if (resp) {
        setLoginType("otp");
        setLoginDetails({
          mobile: values?.mobile_number,
          token: resp?.data?.token,
          email: "",
        });
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  const validateOtp = async (values) => {
    try {
      const resp = await validateOtpApi({
        ...values,
        token: loginDetails?.token,
      });
      if (resp) {
        setCookie(
          ACCESS_TOKEN,
          resp?.data?.access_token,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        setCookie(
          REFRESH_TOKEN,
          resp?.data?.refresh_token,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        if (resp?.data?.is_first_login) {
          navigate("/set-password");
        } else {
          await getOrgData();
        }
        // await getOrgData();
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  const getDomainDetails = async (domain) => {
    try {
      const resp = await getDomainDetailsApi(domain);
      console.log(resp);
      setOrgDetails({
        logo: resp?.data?.org?.profile_pic,
        name: resp?.data?.org?.name,
        id: resp?.data?.org?._id,
      });
    } catch (error) {
      console.log(error);
      window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/login`;
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_STORAGE === "CLOUD") {
      const subdomain = getSubdomain(window.location.hostname);
      subdomain &&
        subdomain != process.env.REACT_APP_PUBLIC_DOMAIN &&
        getDomainDetails(subdomain);
    }
  }, []);

  return (
    <Row
      style={{
        height: "100vh",
        alignItems: "center",
        alignSelf: "center",
        backgroundImage: `url(${loginBG})`,
        backgroundSize: "auto 40vw",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center -20vh",
        mixBlendMode: "soft-light",
      }}
    >
      <ChooseOrgModal openModal={openChooseModal} closable={false} />
      <Col
        xs={{ span: 16, offset: 4 }}
        md={{ span: 10, offset: 6 }}
        lg={{ span: 8, offset: 8 }}
        xl={{ span: 6, offset: 9 }}
        // style={{ backgroundColor: "red" }}
      >
        {loginType === "otp" ? (
          <OTPScreen
            loginDetails={loginDetails}
            validateOtp={validateOtp}
            setLoginDetails={setLoginDetails}
            setLoginType={setLoginType}
            orgDetails={orgDetails}
          />
        ) : loginType === "mobile" ? (
          <MobileScreen
            sendOtp={sendOtp}
            setLoginType={setLoginType}
            orgDetails={orgDetails}
          />
        ) : loginType === "forgot" ? (
          <ForgotPasword
            sendOtp={sendOtp}
            setLoginType={setLoginType}
            loginDetails={loginDetails}
            setLoginDetails={setLoginDetails}
            orgDetails={orgDetails}
          />
        ) : loginType === "check" ? (
          <CheckEmail
            loginDetails={loginDetails}
            setLoginDetails={setLoginDetails}
            setLoginType={setLoginType}
            orgDetails={orgDetails}
          />
        ) : (
          <EmailLogin
            onFinish={onFinish}
            setLoginType={setLoginType}
            loading={loading}
            orgDetails={orgDetails}
          />
        )}
      </Col>
      {/* <Col span={24} style={{ alignSelf: "end" }}>
        <Typography
          className={textStyles.body3}
          style={{
            textAlign: "center",
            fontWeight: 500,
            color: "#98A2B3",
            marginBottom: "12px",
          }}
        >
          © All Rights Reserved. BeyondScale Technologies Pvt Ltd.
        </Typography>
      </Col> */}
    </Row>
  );
};

export default Login;
