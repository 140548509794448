import React, { useEffect, useState } from "react";
import { getAssetDowntimeByIdApi } from "../../../services/assets.services";
import { useParams } from "react-router-dom";
import { Card, Col, DatePicker, Flex, Row, Tag, Typography } from "antd";
import dayjs from "dayjs";
import DownTimeChart from "./DownTimeChart";
import { convertUTCToLocalFormat } from "../../../helpers/utility";
const { RangePicker } = DatePicker;

const AssetDownTime = ({ setAssetDownTimeID, refresh, assetDetails }) => {
  const params = useParams();

  const [downtime, setDownTime] = useState({ hours: 0, min: 0 });
  const [downData, setDownData] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "day"));
  const [endDate, setEndDate] = useState(dayjs().endOf("day"));

  const getAssetDowntime = async () => {
    try {
      const resp = await getAssetDowntimeByIdApi(params?.id, {
        startTime: dayjs(startDate).format("YYYY-MM-DD"),
        endTime: dayjs(endDate).format("YYYY-MM-DD"),
      });
      setAssetDownTimeID(resp?.data?.updateId);

      const modifiedData = [
        ...resp?.data?.data?.map((each) => {
          const startTime = dayjs(convertUTCToLocalFormat(each?.start_time));
          const endTime = each?.end_time
            ? dayjs(convertUTCToLocalFormat(each?.end_time))
            : dayjs().endOf("day");
          // const startTimeUTC = dayjs(each?.start_time)
          //   .subtract(5, "hours")
          //   .subtract(30, "minutes")
          //   .toISOString();
          // const endTimeUTC = each?.end_time
          //   ? dayjs(each?.end_time)
          //       .subtract(5, "hours")
          //       .subtract(30, "minutes")
          //       .toISOString()
          //   : dayjs().endOf("day").toISOString();

          return {
            start_time: startTime,
            end_time: endTime,
          };
        }),
      ];
      setDownData(modifiedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAssetDowntime();
  }, [startDate, endDate, refresh]);

  useEffect(() => {
    let totalHours = 0;
    let totalMinutes = 0;
    downData.forEach((record) => {
      const startTime = new Date(record.start_time);
      const endTime = new Date(record.end_time);

      if (record.start_time) {
        const durationMinutes = Math.round((endTime - startTime) / (1000 * 60));
        totalHours += Math.floor(durationMinutes / 60);
        totalMinutes += durationMinutes % 60;
      }
    });

    if (totalMinutes >= 60) {
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes %= 60;
    }

    setDownTime({ hours: totalHours, min: totalMinutes });
  }, [downData]);

  return (
    <Card
      style={{ height: "100%" }}
      title={
        <Flex justify="space-between" align="center">
          <span>Asset Downtime Details</span>
          <RangePicker
            format="DD-MMM-YYYY"
            disabled={false}
            style={{ width: "55%" }}
            defaultValue={[startDate, endDate]}
            onChange={(dates) => {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }}
          />
        </Flex>
      }
    >
      <Row gutter={[12, 24]}>
        <Col
          span={24}
          style={{ textAlign: "left", paddingInline: "16px", height: "100%" }}
        >
          <Typography>
            Downtime{" "}
            <Tag color="blue" style={{ marginLeft: "12px" }}>
              {downtime?.hours} hrs {downtime.min} mins
            </Tag>{" "}
          </Typography>
        </Col>
        <Col
          span={24}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <DownTimeChart
            assetDetails={assetDetails}
            setDownTime={setDownTime}
            downData={downData}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default AssetDownTime;
