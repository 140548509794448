import { createContext, useState, useEffect } from "react";
import { Spin } from "antd";
import { getMetaDataApi } from "../services/users.services";
import {
  ACCESS_TOKEN,
  FACILITYID,
  ORGANISATION,
} from "../constants/defaultKeys";
import { getCookie, setCookie } from "../helpers/localStorage";
import { useLocation } from "react-router-dom";
import { getFacilitiesApi } from "../services/location";

export const UserContext = createContext({});

function UserProvider({ children }) {
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const getFacilities = async (userData = {}) => {
    const id = getCookie(FACILITYID);
    const orgId = getCookie(ORGANISATION);
    const activeOrg = orgId
      ? userData?.organizations?.filter(
          (each) => each?.organization?._id == orgId
        )
      : {};

    try {
      const data = await getFacilitiesApi(true);
      if (!id) {
        if (data && data?.data?.length > 1) {
          setCookie(
            FACILITYID,
            "all",
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
        } else if (data && data?.data?.length === 1) {
          setCookie(
            FACILITYID,
            data?.data?.[0]?._id,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
        }
      }

      setUserDetails({
        ...userData,
        activeOrg: activeOrg[0],
        facilities: [
          ...(data && data?.data?.length > 1
            ? [{ label: "All facilities", value: "all" }]
            : []),
          ...(data?.data?.map((eachFac) => ({
            label: eachFac?.name,
            value: eachFac?._id,
          })) || []),
        ],
        currFacilty: id
          ? id
          : data?.data?.length === 1
          ? data?.data?.[0]?._id
          : "all",
      });
    } catch (error) {
      console.log(error);
      setUserDetails({
        ...userData,
        activeOrg: activeOrg,
        facilities: [],
        currFacilty: "all",
      });
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const accessToken = getCookie(ACCESS_TOKEN);
        const isLogoutPath = location.pathname === "/logout";
        const orgID = getCookie(ORGANISATION);

        if (accessToken && !isLogoutPath) {
          const response = await getMetaDataApi();
          if (response && orgID) {
            getFacilities(response.data);
          } else {
            setUserDetails({ ...response.data, activeOrg: {} });
          }
        } else {
          // setLoading(false);
          // setUserDetails({});
          // clear();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 500); // Set loading to false after a delay of 0.5 second
      }
    };

    fetchUserData();

    // Clean-up function not needed here since it's a one-time fetch
  }, [location]);

  if (loading) {
    return (
      <div className="flex-center" style={{ height: "100vh" }}>
        <Spin />
      </div>
    );
  }

  return (
    <UserContext.Provider value={{ userDetails, setUserDetails }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
