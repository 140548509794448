import { Modal, Tabs, Input, Button, Form, Popover } from "antd";
import React, { useState, useEffect, useContext } from "react";
import LinkElem, { vimeoRegex, youtubeRegex } from "./LinkElem";
import MediaElem from "./MediaElem";
import { v4 as uuidv4 } from "uuid";
import btnStyles from "../../../../assets/styles/Custom/Button.module.css";
import "../../SOP.css";
import inputStyles from "../../../../assets/styles/Custom/Input.module.css";
import { InfoCircleFilled } from "@ant-design/icons";
import { UserContext } from "../../../../context/UserProvider";
import { getAssetsPath, getEncodedImage } from "../../../../helpers/utility";

function getIdFromVimeoURL(url) {
  return /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)[3];
}

function AddElemModal({
  isModalOpen,
  setModalOpen,
  activeTab,
  setActiveTab,
  sopContent,
  isUploadLimitReached,
  setSopContent,
  data = {},
}) {
  const [form] = Form.useForm();
  const [url, setURL] = useState(data.src || null);
  const [signedUrl, setSignedUrl] = useState(null);
  const [uploading, setUploading] = useState({ loading: false, type: "" });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (signedUrl === undefined) {
      form.resetFields(["src"]);
    } else if (typeof signedUrl?.file === "string") {
      form.setFieldsValue({ src: signedUrl?.file });
      form.setFieldValue("src", signedUrl?.file);
    }
  }, [signedUrl, form]);

  useEffect(() => {
    setURL(null);
  }, [activeTab, form]);

  useEffect(() => {
    setLoading(true);
  }, [url]);
  const videoTabItems = [
    {
      key: "add_yt_link",
      label: `Youtube`,
    },

    {
      key: "add_vimeo_link",
      label: `Vimeo`,
    },
    {
      key: `add_${isModalOpen.type}`,
      label: isUploadLimitReached ? (
        <>
          <span>Upload {isModalOpen.type} </span>
          <Popover placement="top" title={"Reached max file upload limit"}>
            <InfoCircleFilled />
          </Popover>
        </>
      ) : (
        `Upload ${isModalOpen.type}`
      ),
      disabled: isUploadLimitReached,
    },
  ];

  function getYouTubeVideoId(url) {
    const regex =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  let mediaType = isModalOpen?.type;

  return (
    <Modal
      className={"addMediaElemModal"}
      maskClosable={false}
      style={{ minHeight: "500px", minWidth: "50%" }}
      centered
      title={null}
      open={isModalOpen.open}
      onCancel={() => {
        if (data.id) {
          let updatedsopContent = sopContent.map((eachSOP) => {
            if (eachSOP.id === data.id) {
              delete eachSOP.edit;
            }
            return eachSOP;
          });
          setSopContent([...updatedsopContent]);
        }
        setModalOpen({ open: false });
      }}
      footer={false}
    >
      <Tabs
        className={"addMediaElemTabs"}
        activeKey={activeTab}
        items={videoTabItems}
        onChange={(key) => {
          setActiveTab(key);
          form.resetFields();
        }}
      />

      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        style={{ margin: "24px 36px" }}
        onFinish={(values) => {
          values = { ...values, src: values?.src || signedUrl?.file };
          if (data.id) {
            setSopContent((prevSopContent) =>
              prevSopContent.map((eachSOP) => {
                return eachSOP.id === data.parentId
                  ? {
                      ...eachSOP,
                      procedures: eachSOP.procedures.map((eachQ) => {
                        return eachQ.id === data.id
                          ? {
                              ...eachQ,
                              src: values?.src,
                              previewURL: signedUrl?.previewURL || false,
                              thumbnail: youtubeRegex?.test(values.src)
                                ? `https://img.youtube.com/vi/${getYouTubeVideoId(
                                    values.src
                                  )}/hqdefault.jpg`
                                : vimeoRegex?.test(values.src)
                                ? `https://vumbnail.com/${getIdFromVimeoURL(
                                    values.src
                                  )}.jpg`
                                : undefined,
                            }
                          : eachQ;
                      }),
                    }
                  : eachSOP;
              })
            );
          }
          setModalOpen({ open: false });
        }}
        initialValues={{ title: data?.title, src: data?.src }}
      >
        {activeTab == `add_${isModalOpen.type}` ? (
          // Media = any upload file
          <MediaElem
            data={[data]}
            mediaType={isModalOpen.type}
            sopContent={sopContent}
            setSopContent={setSopContent}
            setModalOpen={setModalOpen}
            setSignedUrl={setSignedUrl}
            uploading={uploading}
            signedUrl={signedUrl}
            setUploading={setUploading}
          />
        ) : (
          // anything which has to with links
          <LinkElem
            tab={activeTab}
            setLoading={setLoading}
            form={form}
            setURL={setURL}
            url={url}
            loading={loading}
          />
        )}
        <div
          style={{
            minWidth: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Popover
            placement="left"
            content={
              uploading.loading || !signedUrl?.file ? "Upload to continue" : ""
            }
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={uploading.loading}
              className={btnStyles.mdBtn}
            >
              Done
            </Button>
          </Popover>
        </div>
      </Form>
    </Modal>
  );
}

export default AddElemModal;
