import React, { useEffect, useState } from "react";
import CustomLayout from "../Layout";
import {
  Button,
  Cascader,
  Col,
  DatePicker,
  Empty,
  Image,
  Input,
  Pagination,
  Result,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import { getMroList } from "../../services/work-orders.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import {
  convertUTCToLocalFormat,
  convertUtcToIst,
  debounce,
  displayFilterTag,
  formatHours,
  workOrderstatusList,
} from "../../helpers/utility";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  FilterFilled,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import { PAGESIZE } from "../../constants/defaultKeys";
import MroFilters from "./MroFilters";
// import sortIcon from "../../assets/images/sortIcon.png";
import sortDescIcon from "../../assets/images/sortDescIcon.svg";
import sortIcon from "../../assets/images/sortIcon1.svg";
import filterActiveIcon from "../../assets/images/filterActiveIcon.svg";
import { getAllAssetsApi } from "../../services/assets.services";

const { RangePicker } = DatePicker;

const MRO = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  // const [selectedAsset, setSelectedAsset] = useState(undefined);
  // const [selectedSeverity, setSelectedSeverity] = useState(undefined);
  // const [selectedMroTypes, setSelectedMroTypes] = useState([]);
  // const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [search, setSearch] = useState("");
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "month"),
    end_date: dayjs().add(1, "month"),
  });
  const [refresh, setRefresh] = useState(false);
  const [selectedSorter, setSelectedSorter] = useState(undefined);
  const [filters, setFilters] = useState({
    asset: undefined,
    severity: undefined,
    types: [],
    status: undefined,
  });
  const [assets, setAssets] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const fetchAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          value: asset?._id,
        }))
      );
    } catch (error) {
      setAssets([]);
    }
  };

  const fetchMroList = async () => {
    let query = { page: pageNumber };
    search && (query.name = search);
    if (dateFilter?.start_date) {
      query.start_date = dayjs(dateFilter?.start_date).format("YYYY-MM-DD");
    }
    if (dateFilter?.end_date) {
      query.end_date = dayjs(dateFilter?.end_date).format("YYYY-MM-DD");
    }
    filters?.asset && (query.asset = filters?.asset);
    filters?.severity && (query.severity = filters?.severity);
    filters?.status && (query.status = filters?.status);
    filters?.types?.length > 0 && (query.type = filters?.types);
    if (selectedSorter) {
      query.sort_by = selectedSorter;
      query.sort_order = "desc";
    }

    setLoading(true);
    try {
      const res = await getMroList(query);
      setData(res?.data);
    } catch (error) {
      // console.log(error);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  useEffect(() => {
    fetchMroList();
  }, [
    // search,
    // dateFilter,
    pageNumber,
    refresh,
  ]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [
    search,
    dateFilter,
    filters,
    // selectedAsset,
    // selectedSeverity,
    // selectedMroTypes,
    // selectedStatus,
    selectedSorter,
  ]);

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  // const displayFilterTag = (title, tagColor, count = 1) => {
  //   return (
  //     <Tag
  //       color={tagColor}
  //       className={tagStyles.mdRndTag}
  //       style={{ borderRadius: 8, border: 0, fontSize: 12 }}
  //     >
  //       {`${title}${count > 1 ? ` (${count})` : ""}`}
  //     </Tag>
  //   );
  // };

  const tableColumns = [
    {
      title: <Typography className={"tableHeader"}>MRO code</Typography>,
      dataIndex: "code",
      key: "code",
      width: 120,
      fixed: "left",
      render: (val, record) =>
        val ? (
          <Typography
            style={{ color: "#1677ff", cursor: "pointer" }}
            onClick={() => navigate(`/mro/${record?._id}`)}
          >
            {val}
          </Typography>
        ) : (
          "-"
        ),
    },
    {
      title: <Typography className={"tableHeader"}>WO code</Typography>,
      dataIndex: "workorders",
      key: "workorders",
      width: 120,
      render: (values) =>
        values?.[0]?.code ? (
          <Typography
            style={{ color: "#1677ff", cursor: "pointer" }}
            onClick={() =>
              navigate(`/work-orders/${values?.[0]?._id}`, {
                state: { source: `/mro` },
              })
            }
          >
            {values?.[0]?.code}
          </Typography>
        ) : (
          "-"
        ),
    },
    {
      title: (
        <Typography className={"tableHeader"}>
          MRO breakdown severity
        </Typography>
      ),
      dataIndex: "severity",
      key: "severity",
      width: 140,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // filters: [
      //   {
      //     label: "Critical",
      //     text: "Critical",
      //     value: "critical",
      //   },
      //   {
      //     label: "Non Critical",
      //     text: "Non Critical",
      //     value: "non-critical",
      //   },
      // ],
      // onFilter: (value, record) => record?.severity?.startsWith(value),
      // filterSearch: true,
      render: (val) =>
        val ? (
          <Tag
            className={tagStyles.mdRndDotTag}
            style={{ borderRadius: 16, marginTop: 10 }}
            color={val === "critical" ? "error" : "warning"}
          >
            {val === "critical" ? "Critical" : "Non Critical"}
          </Tag>
        ) : (
          "-"
        ),
    },
    {
      title: (
        <Typography className={"tableHeader"}>MRO breakdown time</Typography>
      ),
      dataIndex: "mro_duration",
      key: "mro_duration",
      width: 200,
      render: (val, record) => {
        if (record?.severity) {
          if (record?.severity === "critical") {
            if (record?.workorders?.[0]?.status === "closed") {
              let durationObj = formatHours(val ? val : 0);
              return (
                <Tag className={tagStyles.smRndTag} color="error">
                  {durationObj?.days ? `${durationObj?.days} days ` : ""}
                  {`${durationObj?.hours} hrs`}
                  {` ${durationObj?.minutes} mins`}
                </Tag>
              );
            } else {
              return <Typography>MRO is not yet resolved</Typography>;
            }
          } else {
            return <Typography>Not a breakdown</Typography>;
          }
        } else {
          return "-";
        }
      },
    },
    {
      title: (
        <Typography className={"tableHeader"}>WO completion time</Typography>
      ),
      dataIndex: "workorders",
      key: "workorders",
      width: 200,
      render: (values) => {
        if (values?.[0]?.started_at && values?.[0]?.ended_at) {
          let start = dayjs(convertUTCToLocalFormat(values?.[0]?.started_at));
          let end = dayjs(convertUTCToLocalFormat(values?.[0]?.ended_at));
          let durationInHours = end.diff(start, "hour", true);
          let durationObj = formatHours(durationInHours);

          return (
            <Tag className={tagStyles.smRndTag} color="blue">
              {durationObj?.days ? `${durationObj?.days} days ` : ""}
              {`${durationObj?.hours} hrs`}
              {` ${durationObj?.minutes} mins`}
            </Tag>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: <Typography className={"tableHeader"}>Type</Typography>,
      dataIndex: "type",
      key: "type",
      width: 140,
      // filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      // filters: [
      //   {
      //     label: "Mechanical",
      //     text: "Mechanical",
      //     value: "Mechanical",
      //   },
      //   {
      //     label: "Electrical",
      //     text: "Electrical",
      //     value: "Electrical",
      //   },
      //   {
      //     label: "Others",
      //     text: "Others",
      //     value: "Others",
      //   },
      // ],
      // onFilter: (value, record) => record?.type?.startsWith(value),
      // filterSearch: true,
      render: (val) => val ?? "-",
    },
    {
      title: <Typography className={"tableHeader"}>Asset</Typography>,
      dataIndex: "asset",
      key: "asset",
      width: 250,
      sorter: (a, b) => a?.asset?.name?.localeCompare(b?.asset?.name),
      render: (val) => val?.name ?? "-",
    },
    {
      title: <Typography className={"tableHeader"}>MRO description</Typography>,
      dataIndex: "description",
      key: "description",
      width: 250,
      render: (val) => (val ? val : "-"),
    },
    {
      title: <Typography className={"tableHeader"}>MRO audio</Typography>,
      dataIndex: "file",
      key: "file",
      width: 280,
      render: (val) =>
        val ? (
          <audio controls style={{ width: "100%" }}>
            <source src={val} type="audio/mpeg" />
          </audio>
        ) : (
          "-"
        ),
    },
    {
      title: <Typography className={"tableHeader"}>MRO created by</Typography>,
      dataIndex: "creator",
      key: "creator",
      width: 150,
      render: (values) =>
        values?.first_name || values?.last_name
          ? `${values?.first_name || ""} ${values?.last_name || ""}`
          : "-",
    },
    {
      title: <Typography className={"tableHeader"}>MRO created at</Typography>,
      dataIndex: "created_at",
      key: "created_at",
      width: 160,
      render: (val, record) => (
        <>
          {val
            ? dayjs(convertUTCToLocalFormat(val)).format("DD MMM hh:mm A")
            : "-"}
        </>
      ),
    },
    {
      title: (
        <Typography className={"tableHeader"}>
          MRO expected to start at
        </Typography>
      ),
      dataIndex: "start_date",
      key: "start_date",
      width: 160,
      render: (val, record) => (
        <>
          {val
            ? dayjs(convertUTCToLocalFormat(val)).format("DD MMM hh:mm A")
            : "-"}
        </>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>WO started at</Typography>,
      dataIndex: "workorders",
      key: "workorders",
      width: 160,
      render: (values, record) => (
        <>
          {values?.[0]?.started_at
            ? dayjs(convertUTCToLocalFormat(values?.[0]?.started_at)).format(
                "DD MMM hh:mm A"
              )
            : "-"}
        </>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>WO closed at</Typography>,
      dataIndex: "workorders",
      key: "workorders",
      width: 160,
      render: (values, record) => (
        <>
          {values?.[0]?.ended_at
            ? dayjs(convertUTCToLocalFormat(values?.[0]?.ended_at)).format(
                "DD MMM hh:mm A"
              )
            : "-"}
        </>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>MRO resolved at</Typography>,
      dataIndex: "ended_at",
      key: "ended_at",
      width: 160,
      render: (val, record) => (
        <>
          {val
            ? dayjs(convertUTCToLocalFormat(val)).format("DD MMM hh:mm A")
            : "-"}
        </>
      ),
    },
    // {
    //   title: <Typography className={"tableHeader"}>MRO Start Date</Typography>,
    //   dataIndex: "start_date",
    //   key: "start_date",
    //   width: 140,
    //   render: (val, record) => <>{val ? convertUtcToIst(val, false) : "-"}</>,
    // },
    {
      title: <Typography className={"tableHeader"}>WO status</Typography>,
      dataIndex: "workorders",
      key: "workorders",
      width: 140,
      sorter: (a, b) =>
        a?.workorders?.[0]?.status?.localeCompare(b?.workorders?.[0]?.status),
      render: (values) => {
        const value = workOrderstatusList?.filter(
          (status) => status?.value == values?.[0]?.status
        )?.[0];
        return (
          <>
            {value?.label ? (
              <Tag
                className={tagStyles.smTag}
                color={value?.color}
                style={{
                  minWidth: "80px",
                  textAlign: "left",
                }}
              >
                {value?.label}
              </Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: <Typography className={"tableHeader"}>WO closed by</Typography>,
      dataIndex: "workorders",
      key: "workorders",
      width: 140,
      render: (values) =>
        values?.[0]?.closed_by?.first_name || values?.[0]?.closed_by?.last_name
          ? `${values?.[0]?.closed_by?.first_name || ""} ${
              values?.[0]?.closed_by?.last_name || ""
            }`
          : "-",
    },
    {
      title: <Typography className={"tableHeader"}>WO verification</Typography>,
      dataIndex: "workorders",
      key: "workorders",
      width: 140,
      sorter: (a, b) =>
        a?.workorders?.[0]?.mark_as_verified -
        b?.workorders?.[0]?.mark_as_verified,
      render: (values) => (
        <Tag
          className={tagStyles.smTag}
          color={values?.[0]?.mark_as_verified ? "success" : "error"}
          style={{ minWidth: "90px", border: "0px" }}
        >
          {values?.[0]?.mark_as_verified ? "Verified" : "Not Verified"}
        </Tag>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>WO verified by</Typography>,
      dataIndex: "workorders",
      key: "workorders",
      width: 140,
      render: (values) =>
        values?.[0]?.verified_by?.first_name ||
        values?.[0]?.verified_by?.last_name
          ? `${values?.[0]?.verified_by?.first_name || ""} ${
              values?.[0]?.verified_by?.last_name || ""
            }`
          : "-",
    },
    {
      title: <Typography className={"tableHeader"}>MRO status</Typography>,
      dataIndex: "status",
      key: "status",
      width: 140,
      render: (val) => (
        <Tag
          className={tagStyles.smTag}
          color={val === "resolved" ? "#87d068" : "#f50"}
          style={{
            minWidth: "95px",
            textAlign: "left",
          }}
        >
          {val === "resolved" ? "Resolved" : "Not Resolved"}
        </Tag>
      ),
    },
    {
      title: (
        <Typography className={"tableHeader"}>MRO verification</Typography>
      ),
      dataIndex: "mark_as_verified",
      key: "mark_as_verified",
      width: 140,
      sorter: (a, b) => a?.mark_as_verified - b?.mark_as_verified,
      render: (val) => (
        <Tag
          className={tagStyles.smTag}
          color={val ? "success" : "error"}
          style={{ minWidth: "90px", border: "0px" }}
        >
          {val ? "Verified" : "Not Verified"}
        </Tag>
      ),
    },
    {
      title: <Typography className={"tableHeader"}>MRO verified by</Typography>,
      dataIndex: "verification_steps",
      key: "verification_steps",
      width: 140,
      render: (values, record) =>
        record?.mark_as_verified &&
        (values?.[0]?.verifier?.first_name || values?.[0]?.verifier?.last_name)
          ? `${values?.[0]?.verifier?.first_name || ""} ${
              values?.[0]?.verifier?.last_name || ""
            }`
          : "-",
    },
  ];

  const sortOptions = [
    {
      label: "MRO breakdown time",
      value: "mro_duration",
    },
  ];

  return (
    <CustomLayout
      header={
        <Typography className={typoStyles.h6}>
          MROs - Maintenance, Repair & Operations ({data?.totalMros || 0})
        </Typography>
      }
    >
      <Row gutter={[0, 16]}>
        <Col span={10}>
          <Space
            style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}
          >
            <Button
              style={{
                color: "#6941C6",
                // backgroundColor: "#fff",
                // borderColor: "#E9D7FE",
              }}
              className={btnStyles.smBtn}
              type="default"
              icon={
                <Image
                  src={filterActiveIcon}
                  width={18}
                  height={18}
                  alt="filter"
                  preview={false}
                />
              }
              onClick={() => setShowFilterModal(true)}
            >
              Filter
            </Button>
            <div style={{ position: "relative" }}>
              <Select
                style={{
                  width: 220,
                }}
                className="custom-select"
                placeholder="Sort by"
                allowClear
                options={sortOptions}
                onChange={(e) => setSelectedSorter(e)}
              />
              <div
                style={{
                  position: "absolute",
                  left: 8,
                  top: 6,
                }}
              >
                <Image
                  src={sortIcon}
                  width={18}
                  height={18}
                  alt="sorter"
                  preview={false}
                />
              </div>
            </div>
          </Space>
          {showFilterModal && (
            <MroFilters
              showFilterModal={showFilterModal}
              setShowFilterModal={setShowFilterModal}
              assets={assets}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </Col>
        <Col offset={4} span={10} style={{ textAlign: "right" }}>
          <RangePicker
            id="mro-filter"
            format="DD-MMM-YYYY"
            defaultValue={[dateFilter.start_date, dateFilter.end_date]}
            onChange={(_, dateString) => {
              setDateFilter({
                start_date: dateString[0],
                end_date: dateString[1],
              });
            }}
          />
        </Col>
        {(filters?.asset ||
          filters?.types?.length > 0 ||
          filters?.severity ||
          filters?.status) && (
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 6,
              flexWrap: "wrap",
            }}
          >
            {filters?.asset && displayFilterTag("Asset", "purple")}
            {filters?.types?.length > 0 &&
              displayFilterTag("MRO Type", "volcano", filters?.types?.length)}
            {filters?.severity && displayFilterTag("MRO Severity", "magenta")}
            {filters?.status && displayFilterTag("MRO Status", "blue")}
            <Typography
              style={{ color: "#7369F4", cursor: "pointer", fontSize: 12 }}
              onClick={() =>
                setFilters({
                  asset: undefined,
                  severity: undefined,
                  types: [],
                  status: undefined,
                })
              }
            >
              Clear All
            </Typography>
          </Col>
        )}
        {/* <Col span={14}>
          <Row gutter={[8, 12]}>
            <MroFilters
              selectedAsset={selectedAsset}
              setSelectedAsset={setSelectedAsset}
              selectedSeverity={selectedSeverity}
              setSelectedSeverity={setSelectedSeverity}
              selectedMroTypes={selectedMroTypes}
              setSelectedMroTypes={setSelectedMroTypes}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
            />
            <Col span={24}>
              <Row gutter={[8, 8]}>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: 6,
                  }}
                >
                  <Image
                    src={sortDescIcon}
                    width={17}
                    height={17}
                    alt="sorter"
                    preview={false}
                    // style={{ marginBottom: 4 }}
                  />
                  Sorters
                </Col>
                <Col span={6}>
                  <Select
                    style={{
                      width: "100%",
                      position: "relative",
                    }}
                    className="custom-select"
                    placeholder="Sort by"
                    allowClear
                    options={sortOptions}
                    onChange={(e) => setSelectedSorter(e)}
                  />
                  <div
                    style={{
                      position: "absolute",
                      left: 12,
                      top: 6,
                    }}
                  >
                    <Image
                      src={sortIcon}
                      width={18}
                      height={18}
                      alt="sorter"
                      preview={false}
                    />
                  </div>
                  <Cascader
                    // style={{ width: 210, textAlign: "left" }}
                    placeholder="Sort by"
                    displayRender={(labels) => labels.join(" - ")}
                    options={sortOptions}
                    onChange={(value) => {
                      setSelectedSorter(
                        value?.length > 0 ? value[value?.length - 1] : undefined
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          offset={2}
          span={8}
          style={
            {
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "flex-end",
              // gap: 24,
              // border: "1px solid",
            }
          }
        >
          <Row gutter={[0, 8]}>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 24,
              }}
            >
              <DatePicker.RangePicker
                id="mro-filter"
                format="DD-MMM-YYYY"
                defaultValue={[dateFilter.start_date, dateFilter.end_date]}
                onChange={(_, dateString) => {
                  setDateFilter({
                    start_date: dateString[0],
                    end_date: dateString[1],
                  });
                }}
              />
              <Button
                id="create-mro"
                type="primary"
                className={btnStyles.mdBtn}
                onClick={() => navigate("/mro/create")}
              >
                + Create MRO
              </Button>
            </Col>
            <Col span={24} style={{ textAlign: "right" }}>
              <Input.Search
                size="large"
                onChange={debounce(handleSearch, 500)}
                placeholder="Search by MRO code"
                prefix={
                  <SearchOutlined style={{ fontSize: 16, color: "#667085" }} />
                }
                style={{ width: "280px" }}
                className="custom-input-search"
              />
            </Col>
          </Row>
        </Col> */}
        <Col span={24}>
          <Table
            title={() => (
              <Space
                align="center"
                style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
              >
                <Input.Search
                  size="large"
                  onChange={debounce(handleSearch, 500)}
                  placeholder="Search by MRO code"
                  prefix={
                    <SearchOutlined
                      style={{ fontSize: 16, color: "#667085" }}
                    />
                  }
                  style={{ width: "300px" }}
                  className="custom-input-search"
                />
                <Button
                  id="create-mro"
                  type="primary"
                  className={btnStyles.mdBtn}
                  onClick={() => navigate("/mro/create")}
                >
                  + Create MRO
                </Button>
              </Space>
            )}
            scroll={{
              x: 1200,
              y: "55vh",
            }}
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No MROs found</Typography>}
                />
              ),
            }}
            loading={
              loading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
            bordered
            columns={tableColumns}
            dataSource={data?.mros || []}
            pagination={false}
          />
        </Col>
        {data?.totalMros > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={data?.totalMros}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
      </Row>
    </CustomLayout>
  );
};

export default MRO;
