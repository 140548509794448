import React from "react";
import "../SOP.css";

const TypingIndicator = () => (
  <div className="typing-indicator">
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default TypingIndicator;
