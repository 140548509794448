import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Modal,
  Space,
  Typography,
  Radio,
  DatePicker,
  Alert,
  message,
  Card,
} from "antd";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
  workOrderstatusList,
} from "../../../helpers/utility";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import dayjs from "dayjs";
import { updateWorkOrderApi } from "../../../services/work-orders.services";

const WorkOrderStatus = ({
  // onFinish,
  workOrderDetails,
  // form,
  refreshCall,
}) => {
  const [woStatus, setWoStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const params = useParams();
  const { userDetails } = useContext(UserContext);

  // useEffect(() => {
  //   workOrderDetails?.status && setWoStatus(workOrderDetails?.status);
  // }, [workOrderDetails]);

  useEffect(() => {
    if (woStatus && woStatus !== workOrderDetails?.status && !showModal) {
      updateWoStatus();
    }
  }, [woStatus]);

  const updateWoStatus = async (dateVal = null) => {
    let payload = { status: woStatus };
    if (woStatus === "in-progress" && dateVal) {
      payload = { ...payload, started_at: convertToUTCFormat(dateVal) };
    } else if (woStatus === "closed") {
      payload = { ...payload, ended_at: convertToUTCFormat(dateVal) };
    }

    try {
      const resp = await updateWorkOrderApi(params?.id, payload);
      if (resp?.data) {
        message.success(resp?.data?.message);
        refreshCall();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!!");
    }
  };

  const handleDisabledDate = (current) => {
    if (woStatus === "closed" && workOrderDetails?.started_at) {
      const startDate = dayjs(
        convertUTCToLocalFormat(workOrderDetails?.started_at)
      );
      return current && current.isBefore(startDate, "day");
    } else {
      return false;
    }
  };

  const handleDisabledTime = (current) => {
    if (woStatus === "closed" && workOrderDetails?.started_at) {
      const startDate = dayjs(
        convertUTCToLocalFormat(workOrderDetails?.started_at)
      );
      if (!startDate) {
        return {};
      }
      const startHours = startDate.hour();
      const startMinutes = startDate.minute();

      if (current && current.isSame(startDate, "day")) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).splice(0, startHours),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === startHours) {
              return Array.from({ length: 60 }, (_, i) => i).splice(
                0,
                startMinutes
              );
            }
            return [];
          },
        };
      }
      return {};
    } else {
      return {};
    }
  };

  return (
    <Card
      title="Status"
      styles={{
        body: { padding: "12px" },
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row
        gutter={[6, 16]}
        style={{
          width: "100%",
          justifyItems: "center",
        }}
      >
        {workOrderstatusList
          ?.filter((each) => each?.value !== "pending")
          // ?.filter((each) => {
          //   if (
          //     workOrderDetails?.status !== "pending" &&
          //     each?.value === "pending"
          //   ) {
          //     return false;
          //   } else if (
          //     workOrderDetails?.status === "pending" &&
          //     each?.value === "open"
          //   ) {
          //     return false;
          //   } else {
          //     return true;
          //   }
          // })
          ?.map((status, index) => {
            return (
              <Col span={6} key={index}>
                {/* <Form.Item
                  name={"status"}
                  style={{ height: "0px", margin: "0px" }}
                ></Form.Item> */}
                <Button
                  ghost={
                    workOrderDetails?.status == status?.value ? true : false
                  }
                  type={
                    workOrderDetails?.status == status?.value
                      ? "primary"
                      : "dashed"
                  }
                  // className={woStatus == status?.value ? "statusActive" : "status"}
                  // disabled={
                  //   workOrderDetails?.mark_as_verified ||
                  //   userDetails?.activeOrg?.role === "operator"
                  // }
                  // onClick={() => {
                  //   setWoStatus(status?.value);
                  //   params?.id
                  //     ? onFinish({ status: status?.value })
                  //     : form.setFieldsValue({ status: status?.value });
                  // }}
                  style={{
                    height: "84px",
                    width: "100%",
                    padding: "2px",
                    whiteSpace: "wrap",
                    // fontWeight: 600,
                    backgroundColor:
                      workOrderDetails?.status == status?.value &&
                      status?.color,
                    color: workOrderDetails?.status == status?.value && "#fff",
                    border: workOrderDetails?.status == status?.value && "0px",
                    pointerEvents: "none",
                  }}
                  // icon={status?.icon}
                >
                  {status?.icon}
                  <br />
                  {status.label}
                </Button>
              </Col>
            );
          })}
        {workOrderDetails?.status === "open" && (
          <Col span={24}>
            <Button
              className={btnStyles.mdBtn}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                setWoStatus("in-progress");
                setShowModal(true);
              }}
              disabled={userDetails?.activeOrg?.role === "operator"}
            >
              Start Work Order
            </Button>
          </Col>
        )}
        {workOrderDetails?.status === "in-progress" && (
          <Col span={12}>
            <Button
              className={btnStyles.mdBtn}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                setWoStatus("on-hold");
              }}
              disabled={userDetails?.activeOrg?.role === "operator"}
            >
              On Hold
            </Button>
          </Col>
        )}
        {workOrderDetails?.status === "on-hold" && (
          <Col span={12}>
            <Button
              className={btnStyles.mdBtn}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                setWoStatus("in-progress");
              }}
              disabled={userDetails?.activeOrg?.role === "operator"}
            >
              Resume
            </Button>
          </Col>
        )}
        {["in-progress", "on-hold"].includes(workOrderDetails?.status) && (
          <Col span={12}>
            <Button
              className={btnStyles.mdBtn}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                setWoStatus("closed");
                setShowModal(true);
              }}
              disabled={userDetails?.activeOrg?.role === "operator"}
            >
              Closed
            </Button>
          </Col>
        )}
      </Row>
      {showModal && (
        <Modal
          title=""
          open={showModal}
          onCancel={() => {
            setShowModal(false);
            setSelectedOption(undefined);
          }}
          footer={null}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            {(!workOrderDetails?.inventory_request?.requested ||
              workOrderDetails?.inventory_request?.requested?.length === 0) &&
              woStatus === "closed" && (
                <>
                  <Typography>
                    Did you use any spare part for this WO ?
                  </Typography>
                  <Radio.Group
                    style={{ marginBottom: 15 }}
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e?.target?.value)}
                  >
                    <Radio value={"yes"}>Yes</Radio>
                    <Radio value={"no"}>No</Radio>
                  </Radio.Group>
                  {selectedOption === "yes" && (
                    <Alert
                      // style={{ marginTop: 15 }}
                      type="info"
                      message={
                        "Please add the used spare part details before closing WO"
                      }
                    />
                  )}
                </>
              )}
            {(selectedOption === "no" ||
              (workOrderDetails?.inventory_request?.requested &&
                workOrderDetails?.inventory_request?.requested?.length > 0) ||
              woStatus !== "closed") && (
              <>
                <Typography>{`WO ${
                  woStatus === "closed" ? "closed" : "started"
                } at`}</Typography>
                <DatePicker
                  // disabled={!edit}
                  format="YYYY-MM-DD HH:mm"
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  placeholder="Select date"
                  size="medium"
                  allowClear={false}
                  showTime={{
                    defaultValue: dayjs("00:00", "HH:mm"),
                  }}
                  style={{
                    width: "70%",
                  }}
                  disabledDate={handleDisabledDate}
                  disabledTime={handleDisabledTime}
                />
                <Button
                  type="primary"
                  className={btnStyles.smBtn}
                  style={{ float: "right", marginTop: 10 }}
                  onClick={() => {
                    setShowModal(false);
                    setSelectedOption(undefined);
                    updateWoStatus(selectedDate);
                  }}
                >
                  Submit
                </Button>
              </>
            )}
          </Space>
        </Modal>
      )}
    </Card>
  );
};

export default WorkOrderStatus;
