import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Typography,
  Checkbox,
  Tag,
  Popconfirm,
  Card,
} from "antd";
import Tree from "react-d3-tree";
import "./Locations.css";
import { useCenteredTree } from "./locationHelpers";
import {
  createLocationApi,
  deleteLocationApi,
  getLocationByIdApi,
  updateLocationApi,
} from "../../services/location";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import markup from "../../assets/images/Locations/marker.png";
import {
  createLOcation,
  deleteLOcation,
  editLOcation,
} from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";

const Location = ({ id, setRefresh }) => {
  const [form] = Form.useForm();
  const containerStyles = {
    width: "100%",
    height: "80vh",
    overFlowY: "auto",
  };
  const [translate, containerRef] = useCenteredTree();
  const [tree, setTree] = useState({});
  const [node, setNode] = useState();
  const [edit, setEdit] = useState(false);
  const { userDetails } = useContext(UserContext);
  const [showMsg, setShowMsg] = useState(false);

  const linkStyle = {
    stroke: "red",
    strokeWidth: 20,
  };

  const handleNodeClick = (datum) => {
    form.resetFields();
    setNode({ ...datum, is_facility: false });
  };

  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => {
    return (
      <g>
        <circle
          r="10"
          onClick={(e) => {
            toggleNode(e);
          }}
        />
        <text
          fill="black"
          strokeWidth="0.5"
          x="20"
          style={{ fontSize: "16px", lineHeight: "24px" }}
        >
          {nodeDatum.name}
        </text>
        <text
          onClick={() => handleNodeClick(nodeDatum)}
          fill="black"
          strokeWidth="0.5"
          dy="20"
          x="20"
          style={{ fontSize: "16px" }}
        >
          +
        </text>
        <text
          fill="black"
          strokeWidth="0.5"
          dy="20"
          x="40"
          onClick={() => deleteLocation(nodeDatum?._id)}
        >
          -
        </text>
        {nodeDatum.attributes?.department && (
          <text fill="black" x="20" dy="40" strokeWidth="1">
            Department: {nodeDatum.attributes?.department}
          </text>
        )}
      </g>
    );
  };

  const getLocation = async () => {
    try {
      const resp = await getLocationByIdApi();
      setTree(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocation();
  }, [id]);

  const onFinish = async (values) => {
    try {
      const resp = edit
        ? await updateLocationApi(node?._id, {
            parentId: node?.parent,
            ...values,
            is_facility: values?.is_facility ? values?.is_facility : false,
          })
        : await createLocationApi({
            ...values,
            parentId: node?._id,
            is_facility: values?.is_facility ? values?.is_facility : false,
          });
      getLocation();
    } catch (error) {
      console.log(error);
    } finally {
      setNode(undefined);
      form.resetFields();
      setEdit(false);
      setRefresh((prev) => !prev);
    }
  };

  const deleteLocation = async (id) => {
    try {
      const resp = await deleteLocationApi(id);
      getLocation();
      setNode(undefined);
    } catch (error) {
      console.log(error);
    } finally {
      setRefresh((prev) => !prev);
    }
  };

  const editLocation = async (values) => {
    const data = {
      ...values,
      isNew: false,
      nodeId: node?._id,
      is_facility: values?.is_facility ? values?.is_facility : false,
    };
    try {
      const resp = await updateLocationApi(node?.parent, data);
      getLocation();
    } catch (error) {
    } finally {
      setNode(undefined);
      setEdit(false);
      form.resetFields();
    }
  };

  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps,
  }) => (
    <g width={500} style={{ marginRight: "100px" }}>
      <circle
        r={15}
        stroke="#c5c0ff"
        fill="#7369F4"
        onClick={(e) => {
          toggleNode(e);
        }}
      ></circle>

      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject {...foreignObjectProps}>
        {nodeDatum?.is_facility && (
          <>
            <Tag color="purple">Facility</Tag>
            <br />
          </>
        )}

        <Space size={12} className="locationText">
          <Typography
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#2f3542",
              lineHeight: "24px",
              wordBreak: "break-word",
              maxWidth: "150px",
              // letterSpacing: "0.025rem",
            }}
          >
            {nodeDatum.name}
          </Typography>
          {(editLOcation?.includes(userDetails?.activeOrg?.role) ||
            deleteLOcation?.includes(userDetails?.activeOrg?.role)) && (
            <Space class="locationActions" style={{ gap: "12px" }}>
              {editLOcation?.includes(userDetails?.activeOrg?.role) && (
                <EditFilled
                  onClick={() => {
                    setEdit(true);
                    setNode(nodeDatum);
                    setShowMsg(false);
                    form.setFieldsValue(nodeDatum);
                  }}
                />
              )}
              {deleteLOcation?.includes(userDetails?.activeOrg?.role) && (
                <Popconfirm
                  icon={null}
                  // description="Are you sure to delete this location?"
                  description={
                    nodeDatum?.is_facility
                      ? "Users/ Assets are assigned to this facility. Please move them to another facility before proceeding with the deletion."
                      : "Are you sure to delete this location?"
                  }
                  onConfirm={(e) => {
                    deleteLocation(nodeDatum?._id);
                  }}
                >
                  <DeleteOutlined />
                </Popconfirm>
              )}
            </Space>
          )}
        </Space>

        <br />

        {createLOcation.includes(userDetails?.activeOrg?.role) && (
          <Typography
            onClick={() => handleNodeClick(nodeDatum)}
            style={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              color: "#00359E",
              marginLeft: "20px",
              textDecoration: "underline",
            }}
          >
            + sub-location
          </Typography>
        )}
      </foreignObject>
    </g>
  );

  const foreignObjectProps = { width: 200, height: 250, x: 25, y: -15 };

  const getModalTitle = () => {
    if (node?.parent) {
      return edit ? "Edit sublocation" : "Add sublocation";
    } else {
      return edit ? "Edit location" : "Add location";
    }
  };

  return (
    <Card>
      <div style={containerStyles} ref={containerRef}>
        <Tree
          // draggable={false}
          data={tree}
          translate={translate}
          renderCustomNodeElement={(rd3tProps) =>
            renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
          }
          // renderCustomNodeElement={renderRectSvgNode}
          orientation="vertical"
          styles={{ links: linkStyle }}
          transitionDuration={500}
          separation={{
            siblings: 2, // Adjust the horizontal separation between sibling nodes
            nonSiblings: 2, // Adjust the horizontal separation between non-sibling nodes
          }}
        />

        <Modal
          title={getModalTitle()}
          open={Boolean(node)}
          onCancel={() => {
            setEdit(false);
            setNode(undefined);
            form.resetFields();
          }}
          footer={null}
        >
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={node && onFinish}
            // initialValues={edit && node}
            form={form}
            onFinishFailed={(e) => console.log("Location creation failed:", e)}
          >
            <Form.Item
              name={"name"}
              label="Location name"
              style={{ marginBottom: "0px" }}
              rules={[
                {
                  whitespace: true,
                  message: "Asset name cannot be empty or contain only spaces",
                },
                { required: true, message: "Please enter location" },
                {
                  max: 50,
                  message: "Location name cannot excced 50 characters",
                },
                {
                  min: 3,
                  message: "Location name should contain atleast 3 characters",
                },
              ]}
            >
              <Input size="medium" />
            </Form.Item>
            <Form.Item valuePropName="checked" name={"is_facility"}>
              <Checkbox
                onChange={(e) => {
                  if (node?.is_facility) {
                    setShowMsg(!e?.target?.checked ? true : false);
                  }
                }}
              >
                Mark as facility
              </Checkbox>
            </Form.Item>
            {showMsg && (
              <Typography>
                There are Users & Assets under this facility. It is advised to
                move them to another facility before deselecting here. Do you
                still want to deselect the facility here ?
              </Typography>
            )}
            {showMsg ? (
              <Space
                style={{
                  marginTop: 5,
                  gap: 12,
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{ color: "green", borderColor: "green" }}
                  onClick={() => {
                    setEdit(false);
                    setNode(undefined);
                    form.resetFields();
                  }}
                >
                  No
                </Button>
                <Button type="primary" danger htmlType="submit">
                  Yes
                </Button>
              </Space>
            ) : (
              <Form.Item style={{ textAlign: "right", marginBottom: "0px" }}>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            )}
          </Form>
        </Modal>
      </div>
    </Card>
  );
};

export default Location;
