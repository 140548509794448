import {
  Checkbox,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  TimePicker,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import {
  monthOptions,
  repeatOptions,
  weekDays,
  weekDaysOptions,
} from "../../../helpers/utility";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { INSPECTION } from "../../../constants/defaultKeys";

const Recurrence = ({
  edit,
  typeofRec,
  setTypeOfRec,
  form,
  maintanaceDetails,
  occurString,
  setOccurString,
  type1,
}) => {
  const [type, setType] = useState("daily");
  const params = useParams();
  const [multiple, setMultiple] = useState(true);

  const handleTypeChange = (e) => {
    setType(e);
  };

  const handleDisabledEndDate = (current) => {
    // Get the selected start date from the form values
    const startDate = form.getFieldValue("start_date");

    // Disable dates that are before the selected start date
    return current && current.isBefore(startDate, "day");
  };

  useEffect(() => {
    setType(maintanaceDetails?.repeat);
    maintanaceDetails?.every &&
      setMultiple(!(Number(maintanaceDetails?.every) > 1));
    maintanaceDetails?.day_of_month ? setTypeOfRec(1) : setTypeOfRec(2);
  }, [maintanaceDetails]);

  return (
    <>
      <Flex
        style={{
          width: "100%",
        }}
        gap={12}
      >
        <Form.Item
          style={{
            width: "33.33%",
          }}
          rules={[
            { required: true, message: "Please select start date & time" },
          ]}
          label="Start date & time"
          name={"start_date"}
        >
          <DatePicker
            disabled={!edit}
            format="YYYY-MM-DD HH:mm"
            onChange={() => form.setFieldsValue({ end_date: null })}
            placeholder="Select start date & time"
            size="medium"
            showTime={{
              defaultValue: dayjs("00:00", "HH:mm"),
            }}
            // defaultValue={dayjs().startOf("day")}
            allowClear={false}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        {/* <Form.Item
          rules={[{ required: true, message: "Please select start time" }]}
          style={{
            width: "33.33%",
          }}
          label={"Start time"}
          name={"start_time"}
        >
          <TimePicker
            format="HH:mm"
            style={{ width: "100%" }}
            placeholder="Select start time"
            disabled={!edit}
            size="medium"
            className={inputStyles.smInput}
          />
        </Form.Item> */}
        <Form.Item
          rules={[
            { required: true, message: "Please enter duration in hours" },
          ]}
          style={{
            width: "33.33%",
          }}
          label={"Duration (hours)"}
          name={"duration"}
        >
          <InputNumber
            min={0.25}
            max={1000}
            step={0.25}
            type="number"
            disabled={!edit}
            style={{ minWidth: "200px" }}
            placeholder="Enter duration in hours"
          />
        </Form.Item>
      </Flex>

      <Flex gap={12} style={{ width: "66%" }} align="center">
        <>
          <Flex
            gap={12}
            style={{ width: "50%" }}
            align="center"
            justify="right"
          >
            {type !== "yearly" && (
              <>
                <Checkbox
                  checked={!multiple}
                  disabled={!edit}
                  onChange={(e) => {
                    setMultiple(!e?.target?.checked);
                    form.setFieldsValue({ every: !e?.target?.checked ? 1 : 2 });
                  }}
                />
              </>
            )}
            <Typography style={{ fontSize: "12px" }}>
              Repeat for every
            </Typography>

            {type !== "yearly" && (
              <Form.Item
                name={"every"}
                style={{ marginBottom: "0px" }}
                // rules={[{ required: true, message: "Please select" }]}
              >
                <InputNumber
                  className={inputStyles.smNumberInput}
                  disabled={!edit || type === "yearly" || multiple}
                  onChange={(e) => {
                    !e
                      ? form.setFields([
                          {
                            name: "every",
                            errors: [`Enter valid value`],
                          },
                        ])
                      : form.setFields([
                          {
                            name: "every",
                            errors: [],
                          },
                        ]);
                  }}
                  placeholder=""
                  defaultValue={1}
                  max={99}
                  min={1}
                />
              </Form.Item>
            )}
          </Flex>
          <Form.Item
            name={"repeat"}
            style={{ marginBottom: "0px", width: "50%" }}
            rules={[{ required: true, message: "Please select mode" }]}
          >
            <Select
              disabled={!edit}
              placeholder="Select"
              style={{
                width: "100%",
              }}
              value={type}
              className={inputStyles.smSelect}
              onChange={handleTypeChange}
              options={repeatOptions.map((option) => ({
                ...option,
                label:
                  !multiple && option?.value !== "yearly"
                    ? `${option.label}s`
                    : option.label, // Plural label if multiple is false
              }))}
            />
          </Form.Item>
        </>
      </Flex>
      {type === "weekly" && (
        <Form.Item
          rules={[{ required: true, message: "Please select weekday" }]}
          name={"week_days"}
          style={{ marginBlock: "24px" }}
        >
          <Select
            allowClear={false}
            mode="multiple"
            disabled={!edit}
            placeholder="Select day"
            style={{
              width: "66%",
            }}
            className={inputStyles.smSelect}
            options={weekDays}
          />
        </Form.Item>
      )}

      {(type == "monthly" || type == "yearly") && (
        <Radio.Group
          disabled={!edit}
          onChange={(e) => {
            form.resetFields([
              "day_of_month",
              "month",
              "month_option",
              "week_days",
            ]);
            setTypeOfRec(e?.target?.value);
          }}
          style={{ marginTop: "24px" }}
          value={typeofRec}
        >
          <Space direction="vertical">
            <Radio value={1}>
              <Flex
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                gap={12}
                align="center"
                justify="space-evenly"
              >
                <Typography style={{ width: "62px" }}>On </Typography>
                <Flex gap={12} align="center">
                  {type === "yearly" && (
                    <Form.Item name={"month"} style={{ marginBottom: "0px" }}>
                      <Select
                        disabled={!edit || typeofRec == 2}
                        placeholder="Select month"
                        style={{
                          width: "200px",
                        }}
                        className={inputStyles.smSelect}
                        options={monthOptions}
                      />
                    </Form.Item>
                  )}
                  {(type === "monthly" || type == "yearly") && (
                    <Form.Item
                      name={"day_of_month"}
                      style={{ marginBottom: "0px" }}
                    >
                      <Select
                        disabled={!edit || typeofRec == 2}
                        placeholder="Day"
                        style={{
                          width: "200px",
                        }}
                        className={inputStyles.smSelect}
                        options={Array.from(
                          { length: 31 },
                          (_, index) => index + 1
                        )?.map((each) => ({ value: each, label: each }))}
                      />
                    </Form.Item>
                  )}
                </Flex>
              </Flex>
            </Radio>
            <Radio value={2}>
              <Flex
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                gap={12}
                align="center"
                justify="space-evenly"
              >
                <Typography style={{ width: "62px" }}>On the</Typography>
                <Flex gap={12} align="center">
                  {(type == "yearly" || type == "monthly") && (
                    <Form.Item
                      name={"month_option"}
                      style={{ marginBottom: "0px" }}
                    >
                      <Select
                        disabled={!edit || typeofRec == 1}
                        placeholder="Select"
                        style={{
                          width: "200px",
                        }}
                        onChange={(e) => console.log(e)}
                        className={inputStyles.smSelect}
                        options={weekDaysOptions}
                      />
                    </Form.Item>
                  )}
                  {(type == "yearly" || type == "monthly") && (
                    <Form.Item
                      name={"week_days"}
                      rules={[
                        {
                          required: typeofRec === 2 ? true : false,
                          message: "Please select weekday",
                        },
                      ]}
                      style={{ marginBottom: "0px" }}
                    >
                      <Select
                        allowClear={false}
                        mode="multiple"
                        disabled={!edit || typeofRec == 1}
                        placeholder="Select day"
                        style={{
                          width: "200px",
                        }}
                        className={inputStyles.smSelect}
                        options={weekDays}
                      />
                    </Form.Item>
                  )}
                  {type === "yearly" && (
                    <Form.Item name={"month"} style={{ marginBottom: "0px" }}>
                      <Select
                        disabled={!edit || typeofRec == 1}
                        placeholder="Select month"
                        style={{
                          width: "200px",
                        }}
                        className={inputStyles.smSelect}
                        options={monthOptions}
                      />
                    </Form.Item>
                  )}
                </Flex>
              </Flex>
            </Radio>
          </Space>
        </Radio.Group>
      )}
      <Flex
        style={{
          width: "66%",
          marginTop: "24px",
        }}
        gap={12}
      >
        <Form.Item
          style={{
            width: "50%",
          }}
          label={`Till which date you want this ${
            type1 === INSPECTION ? "Inspection" : "PM"
          } to be executed?`}
          name={"end_date"}
          rules={[{ required: true, message: "Please select end date" }]}
        >
          <DatePicker
            disabledDate={handleDisabledEndDate}
            disabled={!edit}
            format="YYYY-MM-DD"
            placeholder="Enter end date"
            size="medium"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        {/* <Form.Item
          rules={[{ required: true, message: "Please select end time" }]}
          style={{
            width: "50%",
          }}
          label={"End time"}
          name={"end_time"}
        >
          <TimePicker
            format="HH:mm"
            style={{ width: "100%" }}
            placeholder="Select end time"
            disabled={!edit}
            size="medium"
            className={inputStyles.smInput}
          />
        </Form.Item> */}
      </Flex>
      {occurString && (
        <Typography style={{ color: "#108ee9" }}>{occurString}</Typography>
      )}
    </>
  );
};

export default Recurrence;
