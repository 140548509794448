import { Button, Col, Divider, Form, Image, Input, Typography } from "antd";
import React from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import darkLogo from "../../../assets/images/cmms-logos/CMMSLogo.svg";
import { useNavigate } from "react-router-dom";

const EmailLogin = ({ onFinish, setLoginType, loading, orgDetails }) => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Image
          src={orgDetails.logo || darkLogo}
          style={{ height: "100px", width: "auto" }}
          preview={false}
        />
        <Typography style={{ marginBlock: "12px" }} className={typoStyles.h6}>
          {orgDetails.name ? `Login to ${orgDetails.name}` : "Login"}
        </Typography>
        <Typography
          className={`${typoStyles.body3} grey-text`}
          style={{ marginBottom: "32px" }}
        >
          Welcome back! Please enter your details.
        </Typography>
      </div>
      <Form layout="vertical" onFinish={onFinish} requiredMark={false}>
        <Form.Item
          label="Email or Mobile"
          name="emailOrMobile"
          rules={[
            {
              validator: (_, value, callback) => {
                if (value && value !== "") {
                  if (
                    /^[0-9]{10}$/.test(value) ||
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                      value
                    )
                  ) {
                    callback();
                  } else {
                    callback("Please enter a valid email or mobile number");
                  }
                } else {
                  callback("Please enter your email or mobile number");
                }
              },
            },
          ]}
          // rules={[
          //   { type: "email", message: "Please enter a valid email" },
          //   {
          //     required: true,
          //     message: "Enter your email",
          //   },
          // ]}
        >
          <Input
            placeholder="Enter your email"
            className={inputStyles.smInput}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please input your password!" },
            {
              max: 500,
              message: "Password cannot exceed 500 characters",
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/,
              message:
                "Password must contain at least 1 letter, 1 number, and be at least 8 characters long.",
            },
            // {
            //   message:
            //     "Password must have at least 8 characters, including uppercase, lowercase, digit, and special character.",
            //   pattern: new RegExp(
            //     "^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
            //   ),
            // },
          ]}
        >
          <Input.Password
            placeholder="Enter your password"
            className={inputStyles.smInput}
            style={{ width: "100%", gap: "0px" }}
          />
        </Form.Item>
        <div style={{ textAlign: "right" }}>
          <Button
            onClick={() => setLoginType("forgot")}
            type="link"
            style={{ marginBottom: "24px", paddingInline: "0px" }}
          >
            Forgot password?
          </Button>
        </div>
        <Form.Item style={{ textAlign: "center" }}>
          <Button
            htmlType="submit"
            className={btnStyles.mdBtn}
            type="primary"
            loading={loading}
            style={{ width: "100%" }}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
      <div className="text-center">
        <Divider>Or</Divider>
        <Button
          onClick={() => {
            setLoginType("mobile");
            navigate("/mobile");
          }}
          type="link"
          style={{
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Login with mobile
        </Button>
        {window.location.hostname == process.env.REACT_APP_PUBLIC_DOMAIN && (
          <>
            <br />
            <Button
              onClick={() => navigate("/signup/0")}
              type="link"
              style={{ marginBottom: "24px", paddingInline: "0px" }}
            >
              <span style={{ color: "#2f3542", marginRight: "6px" }}>
                Don't have an account?{" "}
              </span>
              Sign Up
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default EmailLogin;
