import React, { Fragment, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "./UserProvider";
import { extractDomainFromUrl } from "../helpers/utility";
import { getCookie } from "../helpers/localStorage";
import { ACTIVEDOMAIN } from "../constants/defaultKeys";

function AuthProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails = {} } = useContext(UserContext);

  useEffect(() => {
    console.log("innnnn");

    const currentDomain = window.location.hostname;
    const mainDomainUrl = process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL;
    const mainDomain = extractDomainFromUrl(mainDomainUrl);
    const path = window.location.pathname;

    const isUnProtectedRoute =
      path.startsWith("/signup") ||
      path == "/login" ||
      path == "/mobile" ||
      path.startsWith("/join") ||
      path === "/" ||
      path === "/logout";

    if (
      !currentDomain.includes(process.env.REACT_APP_PUBLIC_DOMAIN) &&
      process.env.REACT_APP_PUBLIC_ENV !== "STAGING"
    ) {
      // If the current domain doesn't match the public domain and it's not a staging environment, navigate to the home page
      window.location.href = `${mainDomainUrl}/login`;
    } else if (
      (currentDomain === mainDomain && isUnProtectedRoute) ||
      [
        "/404",
        "/500",
        "/domain",
        "/login",
        "/mobile",
        "logout",
        "/delete-account",
      ].includes(path) ||
      /^\/otp\/[a-zA-Z0-9_-]+$/.test(path) ||
      /^\/join\/[a-zA-Z0-9_-]+$/.test(path) ||
      /^\/assets\/[a-zA-Z0-9_-]+$/.test(path)
    ) {
      // If it's the main domain and the path is an unprotected route or one of the specified paths, do nothing
      if (path === "/") {
        window.location.href = `${mainDomainUrl}/login`;
      } else {
        return;
      }
    } else if (currentDomain !== mainDomain && isUnProtectedRoute) {
      // If it's not the main domain and it's an unprotected route, navigate to the dashboard home page
      if (path === "/" && Object.keys(userDetails).length === 0) {
        window.location.href = `${mainDomainUrl}/login`;
      }
      navigate("/dashboard");
    } else if (currentDomain == mainDomain && !isUnProtectedRoute) {
      const activeDomain = getCookie(ACTIVEDOMAIN);
      if (
        userDetails?.activeOrg?.organization?.settings?.storage === "on-premise"
      ) {
        return;
      } else if (activeDomain) {
        window.location.href = `https://${activeDomain}.${process.env.REACT_APP_PUBLIC_DOMAIN}/dashboard`;
      } else {
        window.location.href = `${mainDomainUrl}/login`;
      }
    } else {
      const activeDomain = getCookie(ACTIVEDOMAIN);
      if (
        process.env.REACT_APP_PUBLIC_ENV !== "STAGING" &&
        activeDomain &&
        !isUnProtectedRoute &&
        currentDomain?.toLowerCase() !==
          `${getCookie(ACTIVEDOMAIN)?.toLowerCase()}.${
            process.env.REACT_APP_PUBLIC_DOMAIN
          }`
      ) {
        window.location.href = `${mainDomainUrl}/login`;
      }
    }
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default AuthProvider;
