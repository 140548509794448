import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  LeftOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Flex,
  Image,
  Modal,
  Popover,
  Row,
  Typography,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router";

import CourseMetaData from "./CourseMetaData";
import EachTyut from "./EachTyut";
import CustomLayout from "../../../Layout";
import { UserContext } from "../../../../context/UserProvider";
import SOPContent from "../../../Sops/CreateSOP/SOPContent";
import SOPElements from "../../../Sops/CreateSOP/SOPElements";
import SideMenu from "../../../Layout/SideMenu";
import AddTyuts from "./AddTyuts";
import DND from "../../../Blocks/DND";
import notyuts from "../../../../assets/images/tyuts/notyuts.svg";
import buttonStyles from "../../../../assets/styles/Custom/Button.module.css";
import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";
import "../Course.css";

import {
  getAlTyutsApi,
  updateTyutAPi,
} from "../../../../services/tyuts.services";
import PreviewTyut from "../../Tyuts/CreateTyut/PreviewTyut";
import ShareTyut from "../../Tyuts/CreateTyut/ShareTyut";
import {
  createCourseApi,
  getCourseByIDApi,
  getCoursePermissionByIDApi,
  updateCourseApi,
} from "../../../../services/courses.services";

// editCourseId = null, renderEdit = false
function CreateCourse({}) {
  const params = useParams();
  let editCourseId = params?.id;
  let renderEdit = editCourseId ? true : false;
  const navigate = useNavigate();
  const tyutFormRef = useRef(null);
  const userData = useContext(UserContext);
  const courseMetaDataFormRef = useRef(null);
  const activeQuestionnaireRef = useRef(null);
  const [canOrgView, setOrgView] = useState(false);
  const [isEditTyut, setEditTyut] = useState(false);
  const [selectedTyuts, setSelectedTyut] = useState([]);
  const [searchedTyuts, setSearchedTyuts] = useState({
    allTyuts: [],
    myTyuts: [],
  });
  const [tyutMetaData, setTyutMetaData] = useState({});
  const [isAddTyutOpen, setAddTyutOpen] = useState(false);
  const [publishCourse, setPublishCourse] = useState(false);
  const [activeTyut, setActiveTyut] = useState(selectedTyuts[0]);
  const [editing, setEditing] = useState({ edit: false, data: null });
  const [sharedWith, setSharedWith] = useState({ groups: [], users: [] });
  const [tyutContent, setTyutContent] = useState(activeTyut?.content || []);

  useEffect(() => {
    async function getUserCreatedTyuts() {
      try {
        const allTyutsData = await getAlTyutsApi();
        setSearchedTyuts({
          allTyuts: allTyutsData.data || [],
          myTyuts:
            allTyutsData?.data?.filter(
              (each) => each?.creator?._id == userData?.userDetails?._id
            ) || [],
        });
      } catch (err) {
        console.error(err);
      }
    }
    if (
      searchedTyuts.myTyuts.length == 0 ||
      searchedTyuts.allTyuts.length == 0
    ) {
      getUserCreatedTyuts();
    }
  }, []);

  useEffect(() => {
    if (publishCourse && renderEdit) {
      updateCourse();
    }
  }, [publishCourse]);

  // useEffect(() => {
  //   if (activeTyut?._id) {
  //     updateTyut({
  //       tyutId: activeTyut._id,
  //       payload: {
  //         ...activeTyut,
  //         ...tyutMetaData,
  //         content: tyutContent,
  //         permissions: undefined,
  //       },
  //     });
  //   }
  // }, [tyutMetaData]);

  // async function updateTyut({ tyutId, payload }) {
  //   try {
  //     const res = await updateTyutAPi({ tyutId, payload });
  //     message.success(res?.data?.message);
  //     const updatedTyuts = selectedTyuts.filter(
  //       (tyuts) => tyuts._id !== tyutId
  //     );
  //     setActiveTyut(payload);
  //     setSelectedTyut([...updatedTyuts, payload]);
  //     setEditTyut(false);
  //   } catch (error) {
  //     message.error(error?.response?.data?.message);
  //     console.log(error);
  //   }
  // }

  useEffect(() => {
    setTyutContent(activeTyut?.content || []);
  }, [activeTyut]);

  async function onPublishCourse(permissions, anyOneCanView) {
    let courseMetaData = courseMetaDataFormRef.current.getFieldsValue();
    let createCourseData = {
      ...courseMetaData,
      permissions,
      tyuts: selectedTyuts?.map((eachTyut) => eachTyut._id),
    };
    if (anyOneCanView) {
      createCourseData.permissions.push({
        level: "public",
        entity: String(userData?.userDetails?._id),
      });
    } else createCourseData.permissions = permissions;
    try {
      const res = await createCourseApi(createCourseData);
      message.success(res?.data?.message);
      navigate("/trainings?type=course");
      // router.push(`/lms/course/${res?.data?._id || "dashboard"}`);
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  }

  useLayoutEffect(() => {
    if (renderEdit) {
      getCourseByIDApi(editCourseId)
        .then((res) => {
          // if (res.data.can_edit) {
          if (true) {
            setSelectedTyut(res.data.tyuts);
            courseMetaDataFormRef.current.setFieldsValue({
              name: res.data.name,
              description: res.data.description,
            });
            getCoursePermissions(editCourseId);
          }
          // else router.back();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }, [renderEdit, editCourseId]);

  async function updateCourse() {
    const courseId = editCourseId;
    try {
      await updateCourseData(courseId);
      message.success("Course updated successfully");

      navigate(`/trainings/course/${courseId}`);
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setPublishCourse(false);
    }
  }

  async function updateCourseData(courseId) {
    let courseMetaData = courseMetaDataFormRef.current.getFieldsValue();
    let createCourseData = {
      ...courseMetaData,
      tyuts: selectedTyuts?.map((eachTyut) => eachTyut?._id),
    };
    const res = await updateCourseApi(courseId, createCourseData);
  }

  async function getCoursePermissions(courseId) {
    try {
      const res = await getCoursePermissionByIDApi(courseId);
      let users = [];
      let groups = [];
      let anyOneCanView = false;
      (res.data || []).map((eachPermission) =>
        eachPermission.level == "user"
          ? users.push({ ...eachPermission, id: eachPermission._id })
          : eachPermission.level == "public"
          ? (anyOneCanView = true)
          : groups.push({ ...eachPermission, id: eachPermission._id })
      );
      setSharedWith({ users, groups });
      setOrgView(anyOneCanView);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <CustomLayout
      sideContent={<SideMenu />}
      header={
        <Flex justify="space-between">
          <Typography className={typographyStyles.h6}>
            <LeftOutlined
              style={{ marginRight: "6px" }}
              onClick={() =>
                navigate(
                  params?.id
                    ? `/trainings/course/${params.id}`
                    : "/trainings?type=course"
                )
              }
            />
            {editCourseId ? "Edit" : "Create"} Course
          </Typography>
        </Flex>
      }
    >
      <Row>
        <Popover
          placement="left"
          content={
            <>
              {selectedTyuts.length < 2
                ? "Please add at least two tyuts to proceed"
                : "Proceed to publish"}
            </>
          }
        >
          <Button
            style={{
              position: "fixed",
              right: "2.5rem",
              top: "5rem",
              zIndex: 100,
            }}
            className={buttonStyles.mdBtn}
            type="primary"
            disabled={selectedTyuts.length <= 1 || isEditTyut ? true : false}
            onClick={() => {
              const isFormFilled = Object.values(
                courseMetaDataFormRef.current?.getFieldsValue() || {}
              ).every((eachVal) => {
                if (!eachVal) {
                  courseMetaDataFormRef.current?.submit();
                  return false;
                }
                return true;
              });
              if (isFormFilled) {
                setPublishCourse(true);
              }
            }}
          >
            Publish <ShareAltOutlined />
          </Button>
        </Popover>
        <Col span={20}>
          <Drawer
            width={"75vw"}
            open={isAddTyutOpen}
            onClose={() => {
              setAddTyutOpen(false);
              if (selectedTyuts.length == 0) setActiveTyut(null);
            }}
            title="Add Tyuts"
            footer={
              <Col
                span={24}
                style={{
                  textAlign: "right",
                  minHeight: "45px",
                }}
                onClick={() => setAddTyutOpen(false)}
              >
                <Button
                  className={buttonStyles.mdBtn}
                  type="primary"
                  // style={{ width: "80px" }}
                >
                  Add {selectedTyuts.length > 0 && `(${selectedTyuts.length})`}
                </Button>
              </Col>
            }
          >
            <AddTyuts
              selectedTyuts={selectedTyuts}
              setSearchedTyuts={setSearchedTyuts}
              activeTyut={activeTyut}
              setActiveTyut={setActiveTyut}
              searchedTyuts={searchedTyuts}
              setSelectedTyuts={setSelectedTyut}
            />
          </Drawer>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col span={10} offset={0} style={{ padding: "24px" }}>
          <CourseMetaData formRef={courseMetaDataFormRef} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <Typography
              className={typographyStyles.subtext2}
              style={{
                visibility: selectedTyuts.length == 0 ? "hidden" : "initial",
              }}
            >
              {selectedTyuts.length}{" "}
              {selectedTyuts.length > 1 ? "tyuts" : "tyut"} added
            </Typography>
            <Button
              type="primary"
              style={{ marginBottom: "12px" }}
              onClick={() => setAddTyutOpen(true)}
              className={buttonStyles.mdBtn}
            >
              {selectedTyuts.length == 0 ? (
                "Add Tyuts"
              ) : (
                <>
                  Add more <PlusOutlined />
                </>
              )}
            </Button>
          </div>
          <div
            style={{
              minHeight: "calc(100vh - 400px)",
              maxHeight: "calc(100vh - 450px)",
              overflow: "auto",
              padding: "5px",
            }}
          >
            <DND
              setState={setSelectedTyut}
              RenderComponent={EachTyut}
              id="_id"
              showDragNdDropDelete={false}
              allowDelete={true}
              state={selectedTyuts}
              selectable={false}
              setActiveTyut={setActiveTyut}
              activeTyut={activeTyut}
              setSelectedTyut={setSelectedTyut}
              moveDragIconTopBy="10px"
              moveDragIconLeftBy="2px"
            />
          </div>
        </Col>

        {activeTyut ? (
          <>
            <Col
              span={13}
              offset={0}
              style={{
                position: "relative",
                padding: "0px",
                overflow: "auto",
                minHeight: "calc(100vh - 150px)",
              }}
            >
              <div
                style={{
                  minHeight: activeTyut.can_edit
                    ? "calc(100vh - 150px) "
                    : "calc(100vh - 200px) ",
                  maxHeight: "calc(100vh - 200px)",
                  overflowY: "auto",
                  overflowX: "hidden",
                  position: "relative",
                }}
              >
                <PreviewTyut
                  maxContentHeight={"100%"}
                  showPreviewTitle={false}
                  editing={editing}
                  setEditing={setEditing}
                  setTyutContent={setTyutContent}
                  tyutContent={tyutContent}
                  tyutMetaData={activeTyut}
                  viewType={isEditTyut ? "edit" : "preview"}
                />
              </div>

              {/* {!activeTyut.can_edit && ( */}
              {/* <div
                className={"floatingActionButtons"}
                style={{ bottom: "0px" }}
              >
                <Button
                  className={buttonStyles.mdBtn}
                  type="primary"
                  style={{
                    width: "100px",
                  }}
                  disabled={
                    true
                    // userData.userDetails?._id !== activeTyut?.creator?._id
                  }
                  onClick={() => {
                    setEditTyut((prev) => !prev);
                  }}
                >
                  Edit tyut
                </Button>
              </div> */}
              {/* )} */}
            </Col>
          </>
        ) : (
          <Col
            span={13}
            offset={1}
            style={{
              width: "100%",
              background: "#FAF9FB",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingBlock: "25px",
            }}
          >
            <Image
              preview={false}
              src={notyuts}
              alt="No tyuts"
              style={{ width: "100%", height: "25vh" }}
            />
            <Typography className={typographyStyles.h6}>
              {selectedTyuts.length > 0 && !activeTyut
                ? "Select a tyut to preview/edit"
                : "Add tyuts to preview"}
            </Typography>
            <Typography className={typographyStyles.body2}>
              Add your tyuts combined to create a course
            </Typography>
          </Col>
        )}
      </Row>

      {isEditTyut && (
        <Modal
          maskClosable={false}
          style={{
            minWidth: "55vw",
            position: "relative",
            top: "10px",
          }}
          open={isEditTyut}
          onCancel={() => {
            setEditTyut(false);
            setActiveTyut({ ...activeTyut });
          }}
          footer={
            <div>
              <Button
                className={typographyStyles.mdBtn}
                type={"default"}
                disabled={userData.userDetails._id === activeTyut?.creator?._id}
                onClick={() => {
                  setEditTyut(false);
                  setActiveTyut({ ...activeTyut });
                }}
              >
                Cancel
              </Button>
              <Button
                className={typographyStyles.mdBtn}
                type="primary"
                // style={{
                //   width: "100px",
                // }}
                disabled={editing.edit || editing.edit == null}
                onClick={() => {
                  tyutFormRef.current?.submit();
                  tyutFormRef.current = null;
                }}
              >
                Update
              </Button>
            </div>
          }
        >
          <div
            style={{
              maxWidth: "40vw",
              margin: "0 auto",
            }}
          >
            <SOPContent
              ref={activeQuestionnaireRef}
              sopMetaData={activeTyut}
              setsopMetaData={setTyutMetaData}
              sopFormRef={tyutFormRef}
              sopContent={setTyutContent}
              editing={editing}
              setEditing={setEditing}
            />
            <SOPElements
              ref={activeQuestionnaireRef}
              editing={editing}
              setEditing={setEditing}
              sopContent={tyutContent}
              setSopContent={setTyutContent}
            />
          </div>
        </Modal>
      )}

      {publishCourse && !renderEdit && (
        <Modal
          open={publishCourse}
          onCancel={() => setPublishCourse(false)}
          footer={false}
          style={{ minHeight: "50vh" }}
        >
          <ShareTyut
            canOrgView={canOrgView}
            sharedWith={sharedWith}
            shareType="Course"
            setPreview={setPublishCourse}
            placeholder="Share course"
            tyutMetaData={{}}
            editBtn={true}
            publishHandler={(permissions, anyOneCanView) =>
              onPublishCourse(permissions, anyOneCanView)
            }
          />
        </Modal>
      )}
    </CustomLayout>
  );
}

export default CreateCourse;
