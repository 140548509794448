import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  MinusCircleOutlined,
  MinusOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import {
  createInventoryRequest,
  getInventoryList,
  updateInventoryRequest,
} from "../../services/inventory.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { useNavigate, useParams } from "react-router-dom";

const SparePartForm = ({
  form,
  inventoryList,
  plId,
  selectedPlAssets,
  cancelFunc,
  editDetails,
  orderId,
  type,
  refreshCall,
  // form,
  // edit,
  // productionLines,
  // plId,
  // woId,
  // type,
  // setCallSparePart,
  // refreshCall,
  // workOrderDetails,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  // const [currQuantity, setCurrQuantity] = useState(0);
  // const [inventoryList, setInventoryList] = useState([]);
  // const [selectedPlAssets, setSelectedPlAssets] = useState([]);
  // const [showForm, setShowForm] = useState(false);
  const [editList, setEditList] = useState([]);
  const [showButtons, setShowButtons] = useState({});
  // const [showEdit, setShowEdit] = useState(false);
  const [form1] = Form.useForm();

  useEffect(() => {
    if (editDetails) {
      // setCurrQuantity(Number(editDetails?.requested_quantity || 0));
      form1.setFieldsValue({
        spare_asset: editDetails?.asset?._id || undefined,
        spare_part: editDetails?.inventory?._id,
        quantity: editDetails?.requested_quantity || 0,
        cost: editDetails?.inventory?.cost || 0,
        total_cost:
          Number(editDetails?.requested_quantity || 0) *
          Number(editDetails?.inventory?.cost || 0),
      });
    }
  }, [editDetails]);

  const getSparePartOptions = (data) => {
    if (data?.length) {
      return data?.map((item) => ({
        ...item,
        label: (
          <Space style={{ gap: 8 }}>
            <Typography>{item?.name}</Typography>
            <Tag
              className={tagStyles.smRndTag}
              // color="blue"
            >{`Cost: ${item?.cost}`}</Tag>
          </Space>
        ),
        value: item?._id,
      }));
    } else {
      return [];
    }
  };

  // const fetchInventoryList = async () => {
  //   try {
  //     const res = await getInventoryList();
  //     setInventoryList(
  //       res?.data?.map((item) => ({
  //         ...item,
  //         label: (
  //           <Space style={{ gap: 8 }}>
  //             <Typography>{item?.name}</Typography>
  //             <Tag
  //               className={tagStyles.smRndTag}
  //               // color="blue"
  //             >{`Cost: ${item?.cost}`}</Tag>
  //           </Space>
  //         ),
  //         value: item?._id,
  //       }))
  //     );
  //   } catch (error) {
  //     setInventoryList([]);
  //   }
  // };

  // useEffect(() => {
  //   fetchInventoryList();
  //   if (workOrderDetails && Object.keys(workOrderDetails).length) {
  //     // let spare_parts_list = [];
  //     // workOrderDetails?.inventory_request?.requested?.map((item) => {
  //     //   spare_parts_list.push({
  //     //     cost: item?.inventory?.cost || 0,
  //     //       spare_asset: item?.asset?.id,
  //     //       spare_part: item?.inventory?._id,
  //     //       quantity: item?.requested_quantity,
  //     //       total_cost:
  //     //         Number(item?.inventory?.cost || 0) *
  //     //         Number(item?.requested_quantity || 0),
  //     //   });

  //     // });
  //     // Ensure form is ready before setting values
  //     // setTimeout(() => {
  //     setEditList(
  //       Array(workOrderDetails?.inventory_request?.requested?.length).fill(
  //         false
  //       )
  //     );
  //     form1.setFieldsValue({
  //       spare_parts: workOrderDetails?.inventory_request?.requested?.map(
  //         (item) => ({
  //           entry_id: item?._id,
  //           cost: item?.inventory?.cost || 0,
  //           spare_asset: item?.asset?.id,
  //           spare_part: item?.inventory?._id,
  //           quantity: item?.requested_quantity,
  //           total_cost:
  //             Number(item?.inventory?.cost || 0) *
  //             Number(item?.requested_quantity || 0),
  //         })
  //       ),
  //     });
  //     // }, 0);
  //   }
  // }, [workOrderDetails]);

  // useEffect(() => {
  //   if (plId) {
  //     let plObject = productionLines?.filter((i) => i?.value === plId)?.[0];
  //     setSelectedPlAssets(
  //       plObject?.assets?.map((p) => ({
  //         label: p?.asset?.name,
  //         value: p?.asset?._id,
  //       }))
  //     );
  //   } else {
  //     setSelectedPlAssets([]);
  //   }
  // }, [plId]);

  // useEffect(() => {
  //   if (woId && !params?.id) {
  //     form1.submit();
  //   }
  // }, [woId]);

  const onFinish = async (values) => {
    console.log("sp-form-values", values);
    let payload = {};
    if (!editDetails) {
      if (type === "WO") {
        payload = { ...payload, workorder: orderId };
      } else if (type === "PM") {
        payload = { ...payload, occurrence: orderId };
      }
      payload = {
        ...payload,
        asset: plId ? values?.spare_asset : form.getFieldValue("asset"),
        inventory_id: values?.spare_part,
        // requested_quantity: Number(values?.quantity),
      };
    }
    payload = { ...payload, requested_quantity: Number(values?.quantity) };
    // let payload = {
    //   workorder: woId,
    //   asset: plId ? plId : form.getFieldValue("asset"),
    //   inventory_id: values?.spare_parts?.[0]?.spare_part,
    //   requested_quantity: Number(values?.spare_parts?.[0]?.quantity || 0),
    //   // requested: [
    //   //   {
    //   //     inventory: values?.spare_parts?.[0]?.spare_part,
    //   //     requested_quantity: Number(values?.spare_parts?.[0]?.quantity || 0),
    //   //   },
    //   // ],
    // };
    let ids = {
      inventoryRequestId: editDetails?.inventory_request_id,
      assetId: editDetails?.asset?._id,
      inventoryId: editDetails?.inventory?._id,
    };

    try {
      const resp = editDetails
        ? await updateInventoryRequest(ids, payload)
        : await createInventoryRequest([payload]);
      resp &&
        message.success(
          editDetails
            ? resp?.data?.message
            : "Inventory request created successfully"
        );
      resp && cancelFunc();
      resp && params?.id && refreshCall();
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    } finally {
      // !params?.id && type == "WO" && navigate("/work-orders");
    }
  };

  // const handleQuantityChange = (stepValue) => {
  //   let currCost = form1.getFieldValue("cost") || 0;
  //   let currVal = currQuantity;
  //   setCurrQuantity(currVal + stepValue);
  //   form1.setFieldsValue({
  //     quantity: currVal + stepValue,
  //     total_cost: Number(currVal + stepValue) * Number(currCost),
  //   });
  // };

  const handleQuantityChange = (value) => {
    let currCost = form1.getFieldValue("cost") || 0;
    form1.setFieldsValue({
      quantity: value,
      total_cost: (Number(value) || 0) * Number(currCost),
    });
  };

  const onSparePartSelect = (value) => {
    let sparePartObj = inventoryList?.filter((s) => s?._id === value)[0];
    let currQuantity = form1.getFieldValue("quantity") || 0;
    form1.setFieldsValue({
      cost: sparePartObj?.cost || 0,
      total_cost: Number(sparePartObj?.cost || 0) * Number(currQuantity),
    });
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      form={form1}
      onFinish={onFinish}
      style={{ width: "100%" }}
    >
      <Row
        gutter={[16, 0]}
        style={{ width: "100%", marginTop: editDetails ? 0 : 16 }}
      >
        {plId && (
          <Col span={7}>
            <Form.Item
              name="spare_asset"
              rules={[
                {
                  required: true,
                  message: "Please select asset",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Select
                disabled={editDetails}
                placeholder="Select asset"
                style={{ width: "100%" }}
                className={inputStyles.smSelect}
                showSearch={true}
                optionFilterProp="label"
                // onChange={() => {}}
                options={selectedPlAssets}
              />
            </Form.Item>
          </Col>
        )}
        <Col span={7}>
          <Form.Item
            name="cost"
            style={{ height: "0px", margin: "0px" }}
          ></Form.Item>
          <Form.Item
            name="spare_part"
            rules={[
              {
                required: true,
                message: "Please select spare part",
              },
            ]}
            style={{ width: "100%" }}
          >
            <Select
              disabled={editDetails}
              placeholder="Select spare part"
              style={{ width: "100%" }}
              className={inputStyles.smSelect}
              showSearch={true}
              filterOption={(inputValue, option) =>
                option.name.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              onChange={(value) => onSparePartSelect(value)}
              options={getSparePartOptions(inventoryList)}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Space style={{ gap: 0, alignItems: "baseline" }}>
            {/* <Button
              type="primary"
              disabled={!currQuantity}
              icon={<MinusOutlined />}
              onClick={() => handleQuantityChange(-1)}
              style={{
                marginBottom: 16,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            /> */}
            <Form.Item
              name="quantity"
              style={{ width: "100%" }}
              // style={{ width: "60px" }}
              rules={[
                {
                  validator: (_, value, callback) => {
                    if (value && value !== "") {
                      if (/^[0-9]+$/.test(value)) {
                        if (Number(value) > 0) {
                          callback();
                        } else {
                          callback(
                            "Please enter a valid quantity greater than 0"
                          );
                        }
                      } else {
                        callback("Please enter a valid number");
                      }
                    } else {
                      callback("Please enter a quantity");
                    }
                  },
                },
              ]}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter quantity",
              //   },
              // ]}
            >
              <Input
                style={{
                  width: "100%",
                  // borderRadius: 0,
                  // pointerEvents: "none",
                }}
                className={inputStyles.smInput}
                placeholder="Enter quantity"
                onChange={(e) => handleQuantityChange(e?.target?.value)}
              />
            </Form.Item>
            {/* <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleQuantityChange(1)}
              style={{
                marginBottom: 16,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            /> */}
          </Space>
        </Col>
        <Col span={3}>
          <Form.Item name="total_cost" style={{ width: "100%" }}>
            <Input
              style={{ width: "100%" }}
              className={inputStyles.smInput}
              placeholder="Total cost"
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Space style={{ width: "100%", gap: 16, marginTop: 6 }}>
            <CheckOutlined
              style={{ fontSize: 20, color: "green", cursor: "pointer" }}
              onClick={() => form1.submit()}
            />
            <CloseOutlined
              style={{ fontSize: 20, color: "red", cursor: "pointer" }}
              onClick={() => cancelFunc()}
            />
          </Space>
        </Col>
      </Row>
    </Form>
  );

  // return (
  //   <Card
  //     title="Spare Parts Required"
  //     styles={{
  //       header: { backgroundColor: "#fff" },
  //     }}
  //   >
  //     <Form
  //       layout="vertical"
  //       requiredMark={false}
  //       form={form1}
  //       onFinish={onFinish}
  //     >
  //       <Form.List name="spare_parts">
  //         {(fields, { add, remove }) => (
  //           <>
  //             {fields.map(({ key, name, ...restField }, index) => {
  //               return (
  //                 <>
  //                   <Form.Item
  //                     name={[name, "entry_id"]}
  //                     style={{ height: "0px", margin: "0px" }}
  //                   ></Form.Item>
  //                   <Form.Item
  //                     name={[name, "cost"]}
  //                     style={{ height: "0px", margin: "0px" }}
  //                   ></Form.Item>
  //                   <Row
  //                     gutter={[16, 0]}
  //                     style={{
  //                       borderTop: index !== 0 ? "1px dashed grey" : 0,
  //                       paddingTop: index !== 0 ? 12 : 0,
  //                     }}
  //                   >
  //                     {plId && (
  //                       <>
  //                         <Col span={8}>
  //                           <Form.Item
  //                             {...restField}
  //                             label="Adding spare part to this asset"
  //                             name={[name, "spare_asset"]}
  //                             style={{ width: "100%" }}
  //                             // rules={[
  //                             //   {
  //                             //     required: true,
  //                             //     message: "Please select asset for spare part",
  //                             //   },
  //                             // ]}
  //                           >
  //                             <Select
  //                               disabled={!edit}
  //                               placeholder="Select asset for spare part"
  //                               style={{ width: "100%" }}
  //                               className={inputStyles.smSelect}
  //                               showSearch={true}
  //                               optionFilterProp="label"
  //                               // onChange={() => {}}
  //                               options={selectedPlAssets}
  //                             />
  //                           </Form.Item>
  //                         </Col>
  //                         <Col span={14}></Col>
  //                       </>
  //                     )}
  //                     <Col span={8}>
  //                       <Form.Item
  //                         {...restField}
  //                         label="Spare part"
  //                         name={[name, "spare_part"]}
  //                         rules={[
  //                           {
  //                             required: true,
  //                             message: "Please select spare part",
  //                           },
  //                         ]}
  //                         style={{ width: "100%" }}
  //                       >
  //                         <Select
  //                           disabled={!edit}
  //                           placeholder="Select spare part"
  //                           style={{ width: "100%" }}
  //                           className={inputStyles.smSelect}
  //                           showSearch={true}
  //                           filterOption={(inputValue, option) =>
  //                             option.name
  //                               .toUpperCase()
  //                               .indexOf(inputValue.toUpperCase()) !== -1
  //                           }
  //                           onChange={(value) =>
  //                             onSparePartSelect(value, index)
  //                           }
  //                           options={inventoryList}
  //                         />
  //                       </Form.Item>
  //                     </Col>
  //                     <Col span={3}>
  //                       <Form.Item
  //                         {...restField}
  //                         label="Quantity"
  //                         name={[name, "quantity"]}
  //                         style={{ width: "100%" }}
  //                         rules={[
  //                           {
  //                             required: true,
  //                             message: "Please enter quantity",
  //                           },
  //                         ]}
  //                       >
  //                         <Input
  //                           style={{ width: "100%" }}
  //                           className={inputStyles.smInput}
  //                           placeholder="Enter quantity"
  //                           disabled={!edit}
  //                           onChange={(e) =>
  //                             handleQuantityChange(e?.target?.value, index)
  //                           }
  //                         />
  //                       </Form.Item>
  //                     </Col>
  //                     <Col span={3}>
  //                       <Form.Item
  //                         {...restField}
  //                         label="Total cost"
  //                         name={[name, "total_cost"]}
  //                         style={{ width: "100%" }}
  //                       >
  //                         <Input
  //                           style={{ width: "100%" }}
  //                           className={inputStyles.smInput}
  //                           placeholder="Total cost"
  //                           disabled={true}
  //                         />
  //                       </Form.Item>
  //                     </Col>
  //                     {form1.getFieldValue([
  //                       "spare_parts",
  //                       name,
  //                       "entry_id",
  //                     ]) && (
  //                       <Col span={1}>
  //                         <EditOutlined
  //                           style={{
  //                             fontSize: "20px",
  //                             marginTop: 26,
  //                           }}
  //                         />
  //                       </Col>
  //                     )}
  //                     {form1.getFieldValue([name, "entry_id"]) && (
  //                       <Col span={2}>
  //                         <DeleteOutlined
  //                           style={{
  //                             fontSize: "24px",
  //                             marginTop: 24,
  //                           }}
  //                           // onClick={(e) => {
  //                           //   remove(name);
  //                           // }}
  //                         />
  //                       </Col>
  //                     )}
  //                     {showButtons[name] && (
  //                       // !form1.getFieldValue(
  //                       //   "spare_parts",
  //                       //   name,
  //                       //   "entry_id"
  //                       // ) &&
  //                       <Col span={24}>
  //                         <Space style={{ gap: 12 }}>
  //                           <Button
  //                             onClick={() => {
  //                               remove(name);
  //                               setShowForm(false);
  //                               setShowButtons((prev) => ({
  //                                 ...prev,
  //                                 [name]: false,
  //                               }));
  //                               !params?.id && setCallSparePart(false);
  //                             }}
  //                           >
  //                             Cancel
  //                           </Button>
  //                           {params?.id && (
  //                             <Button
  //                               type="primary"
  //                               onClick={() => form1.submit()}
  //                             >
  //                               Done
  //                             </Button>
  //                           )}
  //                         </Space>
  //                       </Col>
  //                     )}
  //                   </Row>
  //                 </>
  //               );
  //             })}
  //             {!showForm && (
  //               <Form.Item>
  //                 <Button
  //                   type="primary"
  //                   onClick={() => {
  //                     add();
  //                     setShowForm(true);
  //                     const currentFields = form1.getFieldValue("spare_parts");
  //                     setShowButtons((prev) => ({
  //                       ...prev,
  //                       [currentFields.length]: true,
  //                     }));
  //                     !params?.id && setCallSparePart(true);
  //                   }}
  //                   icon={<PlusOutlined />}
  //                   disabled={!edit}
  //                 >
  //                   Add spare part
  //                 </Button>
  //               </Form.Item>
  //             )}
  //           </>
  //         )}
  //       </Form.List>
  //     </Form>
  //   </Card>
  // );
};

export default SparePartForm;
