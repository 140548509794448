import React, { useContext, useEffect, useState } from "react";
import { Avatar, Dropdown, Typography, theme } from "antd";
import {
  ArrowRightOutlined,
  LockOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./Layout.css";
import { useNavigate } from "react-router-dom";
import { getAvatarBackgroundColor } from "../../helpers/utility";
import { UserContext } from "../../context/UserProvider";

const UserSettings = () => {
  const { userDetails } = useContext(UserContext);
  const navigate = useNavigate();

  //   const numericUserId = Number(userDetails?.user_id);
  //   const colorIndex = numericUserId % 24;
  const selectedColor = getAvatarBackgroundColor(userDetails?.first_name);

  const items = [
    ...[
      {
        key: "1",
        title: "Manage profile",
        href: "/profile",
        icon: <UserOutlined />,
      },
      // {
      //   key: "2",
      //   title: "Organisation settings",
      //   href: "/org/profile",
      //   icon: <SettingOutlined />,
      // },
      {
        key: "3",
        title: "Reset password",
        href: "/password",
        icon: <LockOutlined />,
      },
    ].map((item) => {
      return {
        key: item.key,
        label: (
          <div className="flex-start" onClick={() => navigate(item?.href)}>
            {item?.icon}
            {item.title}
          </div>
        ),
      };
    }),

    {
      key: "5",
      label: (
        <div
          className="flex-start"
          onClick={() => {
            navigate("/logout");
          }}
        >
          <ArrowRightOutlined />
          <Typography style={{}}> Log out</Typography>
        </div>
      ),
    },
  ];

  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
        dropdownRender={(menu) => (
          <div className={"profileMenu"}>
            <div
              className="text-left"
              style={{
                padding: "16px",
                gap: "16px",
                borderBottom: "1px solid #EAECF0",
              }}
            >
              <Typography style={{ fontWeight: 600, textAlign: "left" }}>
                {userDetails?.first_name} {userDetails?.last_name}
                <br />
                <span style={{ color: "#475467", fontWeight: 400 }}>
                  {userDetails?.email}
                </span>
              </Typography>
            </div>

            {React.cloneElement(menu, {
              className: "settingsdropDown",
              style: {
                boxShadow: "none",
                padding: "6px",
              },
            })}
          </div>
        )}
        className={"profileDropDown"}
        placement="bottomRight"
      >
        <Avatar
          style={{
            cursor: "pointer",
            // backgroundColor: selectedColor,
            backgroundColor: userDetails?.profile_pic ? "" : selectedColor,
          }}
          size={36}
        >
          <span style={{ fontSize: "18px" }}>
            {" "}
            {userDetails?.first_name?.slice(0, 1).toUpperCase()}
            {/* {userDetails?.last_name?.slice(0, 1)?.toUpperCase()} */}
          </span>
        </Avatar>
      </Dropdown>
    </>
  );
};

export default UserSettings;
