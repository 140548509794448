import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Text,
  ResponsiveContainer,
  Legend,
} from "recharts";

const COLORS = ["#8BC1F7", "#519DE9", "#06C"];

const SparePartPieChart = ({ data, total }) => {
  function renderTooltipWithLabel({ active, payload, label }) {
    if (active && payload && payload.length) {
      if (payload[0].name !== "extra") {
        return (
          <div
            style={{
              fontSize: "14px",
              width: "120px",
              background: "#fff",
              color: "#000",
              padding: "4px 10px",
              border: "1px solid #eaecf0",
            }}
          >
            {payload[0].name}: <strong>{`₹ ${payload[0].value}`}</strong>
          </div>
        );
      } else {
        return null;
      }
    }
    return null;
  }

  return (
    <PieChart width={240} height={240}>
      <Pie
        data={data}
        cx={"50%"}
        cy={"50%"}
        legendType="none"
        stroke="none"
        isAnimationActive={false}
        labelLine={false}
        innerRadius={50}
        outerRadius={100}
        fill="#8884d8"
        dataKey="value"
      >
        {data?.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              index === 0 && Number(total) === 0 ? "#f2f2f2" : COLORS[index]
            }
          />
        ))}
      </Pie>
      {Number(total) > 0 && <Tooltip content={renderTooltipWithLabel} />}
    </PieChart>
  );
};
export default SparePartPieChart;
