import axios from "axios";
import { JSEncrypt } from "nodejs-jsencrypt";
import CryptoJS from "crypto-js";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";

import {
  ACCESS_TOKEN,
  FACILITYID,
  ORGANISATION,
  REFRESH_TOKEN,
} from "../../constants/defaultKeys";
import {
  PUBLIC_KEY,
  PRIVATE_KEY,
  getTokenIfNotExpired,
} from "../../helpers/utility";
import { clear, getCookie, setCookie } from "../../helpers/localStorage";
const secretKey = uuidv4();
const axiosConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use((config) => {
  const token = getCookie(ACCESS_TOKEN);
  const facilityId = getCookie(FACILITYID);
  const orgId = getCookie(ORGANISATION);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (orgId) {
    config.headers.orgId = orgId;
  }
  if (facilityId) {
    config.headers.facilityId = facilityId == "all" ? "" : facilityId;
  }
  // if (config.data) {
  //   const encrypt = new JSEncrypt();
  //   encrypt.setPublicKey(PUBLIC_KEY);

  //   // Check if data has already been encrypted
  //   const hasEncryptedHeader = Object.keys(config.headers).some(
  //     (key) => key.toLowerCase() === "x-encrypted-data"
  //   );

  //   if (!hasEncryptedHeader) {
  //     const data = CryptoJS.AES.encrypt(
  //       JSON.stringify(config.data),
  //       secretKey
  //     ).toString();
  //     config.headers["X-Encrypted-Data"] = "true";
  //     config.data = { data, key: encrypt.encrypt(JSON.stringify(secretKey)) };
  //   }
  // }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // const decrypt = new JSEncrypt();
    // decrypt.setPrivateKey(PRIVATE_KEY);

    // const data = JSON.parse(
    //   CryptoJS.AES.decrypt(
    //     response?.data?.data,
    //     decrypt.decrypt(response?.data?.key)
    //   ).toString(CryptoJS.enc.Utf8)
    // );
    // response.data = data;
    return response;
  },
  async (error) => {
    // const decrypt = new JSEncrypt();
    // decrypt.setPrivateKey(PRIVATE_KEY);

    // const data = JSON.parse(
    //   CryptoJS.AES.decrypt(
    //     error?.response?.data?.data,
    //     decrypt.decrypt(error?.response?.data?.key)
    //   ).toString(CryptoJS.enc.Utf8)
    // );
    // error.response.data = data;

    const refreshTokenApi = "/auth/refresh/";
    const originalRequest = error?.config;
    const errorMessage = error?.response?.data?.code;
    const errorStatusCode = error?.response?.status;
    const tokenInvalid = "token_expired";
    const invalidCred = "invalid_credentials";
    const accountNotFound = "user_not_found";
    const unauthorised = "unauthorized";

    // Prevent infinite loops
    if (errorStatusCode === 401 && originalRequest.url === refreshTokenApi) {
      clear();
      window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/logout`;
      return Promise.reject(error);
    }

    //Invalid credentials or user not exist
    if (
      (errorMessage === invalidCred ||
        errorMessage === accountNotFound ||
        errorMessage === unauthorised) &&
      errorStatusCode === 401
    ) {
      clear();
      window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/logout`;
    }

    // if (errorStatusCode === 404) {
    //   window.location.href = "/page-not-found";
    // }

    // if (errorStatusCode === 403) {
    //   window.location.href = "/not-authorized";
    // }

    if (
      error.response?.data?.code === tokenInvalid &&
      errorStatusCode === 401
    ) {
      //triggers when user session is expired
      const refreshToken = getCookie(REFRESH_TOKEN);
      if (refreshToken) {
        const regex = new RegExp(
          "^[A-Za-z0-9-_=]+.[A-Za-z0-9-_=]+.?[A-Za-z0-9-_.+/=]*$"
        );

        if (regex.test(refreshToken)) {
          const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

          // exp date in token is expressed in seconds, while now() returns milliseconds:
          const now = Math.ceil(Date.now() / 1000);

          //triggers if refresh token is not expired
          if (tokenParts.exp > now) {
            return axiosInstance
              .post(refreshTokenApi, { refresh_token: refreshToken })
              .then((response) => {
                setCookie(
                  ACCESS_TOKEN,
                  response.data.access_token,
                  `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
                  "/"
                );
                setCookie(
                  REFRESH_TOKEN,
                  response.data.refresh_token,
                  `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
                  "/"
                );

                return axiosInstance(originalRequest);
              })
              .catch((error) => {
                message.error(error.response.data.message);
              });
          } else {
            clear();
            window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/logout`;
            message.error(
              "Your session has been expired, please login again",
              8
            );
          }
        } else {
          clear();
          window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/logout`;
        }
      } else {
        clear();
        window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/logout`;
        message.error("Your session has been expired, please login again", 8);
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
