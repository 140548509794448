import { Row, Col, Checkbox, Button, Typography, Image } from "antd";
import React, { useContext } from "react";
import Questionnaire from "../AddElem/Questionnaire/Questionnaire";
import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../../assets/styles/Custom/Button.module.css";
import "../../SOP.css";
import { EditOutlined } from "@ant-design/icons";
import TyutsQuestion from "../AddElem/Questionnaire/TyutsQuestion";
import { TYUT } from "../../../../constants/defaultKeys";
import { getAssetsPath } from "../../../../helpers/utility";
import { UserContext } from "../../../../context/UserProvider";

function ViewMCQ({
  mcq,
  disabled,
  sopContent,
  setSopContent,
  editing,
  setEditing,
  setIsUnfilled,
  viewType = "edit",
  contentType,
}) {
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const CheckboxGroup = Checkbox.Group;
  return (
    <>
      {editing.data?.id !== mcq.id ? (
        <Row
          className={"viewSOPElemCard"}
          key={mcq.id}
          style={
            viewType === "edit"
              ? {
                  borderRadius: "16px",
                  boxShadow: " 0px 1px 4px 0px rgba(0, 0, 0, 0.16)",
                }
              : {}
          }
        >
          <Col span={24} className={"viewQuestionnaireContent"}>
            <Typography.Paragraph
              ellipsis={{ rows: 2, expandable: true, symbol: "read more" }}
            >
              {mcq.question}
            </Typography.Paragraph>
            <CheckboxGroup value={mcq.answers}>
              <Row>
                {mcq.choices.map((eachChoice) => {
                  return (
                    <>
                      <Col span={mcq?.optionType == "images" ? 12 : 24}>
                        <Checkbox key={eachChoice} value={eachChoice}>
                          {mcq?.optionType == "images" ? (
                            <Image
                              src={getAssetsPath(eachChoice, storageType)}
                              alt="Uploaded"
                              style={{
                                width: "100px",
                                marginBlock: "12px",
                                height: "100px",
                              }}
                            />
                          ) : (
                            <Typography.Paragraph
                              className={typographyStyles.body2}
                              style={{ marginBottom: "5px" }}
                              ellipsis={{
                                expandable: true,
                                rows: 2,
                                symbol: "read more",
                              }}
                            >
                              {eachChoice}
                            </Typography.Paragraph>
                          )}
                        </Checkbox>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </CheckboxGroup>
          </Col>
          {viewType === "edit" && (
            <Col span={24} className={"viewQuestionnaireEditBtn"}>
              <Button
                className={btnStyles.smBtn}
                type="primary"
                disabled={editing.edit}
                onClick={(e) => setEditing({ edit: true, data: mcq })}
              >
                <span>Edit</span>
                <EditOutlined />
                {/* <Image
                  src={"/images/LMS/images/edit-icon.svg"}
                  alt="edit"
                  height={18}
                  width={18}
                /> */}
              </Button>
            </Col>
          )}
        </Row>
      ) : (
        <>
          {contentType == TYUT ? (
            <TyutsQuestion
              contentType={contentType}
              sopContent={sopContent}
              setSopContent={setSopContent}
              questionnaire={mcq}
              editing={editing}
              setEditing={setEditing}
              setIsUnfilled={setIsUnfilled}
              optionType={mcq.optionType}
            />
          ) : (
            <Questionnaire
              contentType={contentType}
              sopContent={sopContent}
              setSopContent={setSopContent}
              questionnaire={mcq}
              editing={editing}
              setEditing={setEditing}
              setIsUnfilled={setIsUnfilled}
            />
          )}
        </>
      )}
    </>
  );
}

export default ViewMCQ;
