import React, { useEffect, useState } from "react";
import {
  Empty,
  Result,
  Table,
  Tag,
  Typography,
  Col,
  Segmented,
  Skeleton,
} from "antd";
import { getFacilitiesApi } from "../../services/location";

const FacilityTable = () => {
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: <Typography className={"tableHeader"}>Facility</Typography>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <Typography className={"tableHeader"}>No of users</Typography>,
      dataIndex: "membersCount",
      key: "membersCount",
    },
    {
      title: <Typography className={"tableHeader"}>No of assets</Typography>,
      dataIndex: "assetsCount",
      key: "assetsCount",
    },
  ];

  const getFacilities = async () => {
    setLoading(true);
    try {
      const data = await getFacilitiesApi();
      setFacilities(
        data?.data?.map((each) => {
          delete each?.children;
          return each;
        })
      );
    } catch (error) {
      // console.log(error);
      setFacilities([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFacilities();
  }, []);

  return (
    <Table
      // locale={{
      //   emptyText: (
      //     <Result
      //       icon={Empty.PRESENTED_IMAGE_SIMPLE}
      //       title={<Typography>No facilities found</Typography>}
      //     />
      //   ),
      // }}
      // rowClassName={"rowClassName"}
      bordered
      columns={columns}
      dataSource={facilities}
      pagination={false}
      loading={
        loading
          ? {
              spinning: false,
              indicator: null,
            }
          : false
      }
      scroll={{
        x: 1200,
        y: "70vh",
      }}
      locale={{
        emptyText: loading ? (
          <Skeleton active />
        ) : (
          <Result
            icon={Empty.PRESENTED_IMAGE_SIMPLE}
            title={<Typography>No facilities found</Typography>}
          />
        ),
      }}
    />
  );
};

export default FacilityTable;
