import {
  Button,
  Checkbox,
  Col,
  Popover,
  Radio,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import React, { useState, useRef, useEffect } from "react";
import { SaveOutlined } from "@ant-design/icons";
import inputStyles from "../../../../../assets/styles/Custom/Input.module.css";
import "../../../SOP.css";
import MCQ from "./MCQ";
import SAQ from "./SAQ";
import btnStyles from "../../../../../assets/styles/Custom/Button.module.css";

function TyutsQuestion(
  {
    sopContent,
    setSopContent,
    editing,
    setEditing,
    // questionnaire = {},
    type = "mcq",
    isUnfilled,
    setIsUnfilled,
    optionType = "text",
  },
  ref
) {
  const [questionnaireType, setQuestionnaireType] = useState(type || "mcq");
  const [optionsType, setOptionsType] = useState(optionType);

  const defaultChoices = editing.data?.choices
    ? editing.data?.choices?.reduce((obj, item) => {
        obj[item] = item;
        return obj;
      }, {})
    : optionsType == "images"
    ? { option1: "", option2: "", option3: "", option4: "" }
    : {
        option1: "Option 1",
        option2: "Option 2",
        option3: "Option 3",
        option4: "Option 4",
      };
  const [questionnaire, setQuestionnaire] = useState({
    question: editing.data?.question || "",
    ansDesc: editing.data?.ansDesc || "",
  });
  const [choices, setChoices] = useState(defaultChoices);
  const [isAnsSelected, setAnsSelected] = useState(
    editing.data?.answers?.length > 0
  );
  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current !== null && optionsType !== "images") {
      let answersData = formRef.current?.getFieldsValue().answers;
      answersData = Array.isArray(answersData) ? answersData : [answersData];
      answersData = answersData?.map((answer) => answer?.split("<__>")[0]);
      const allChoices = Object.values(choices);
      let allChoicesList;
      if (allChoices.length > 0) {
        allChoicesList = allChoices.reduce((obj, item) => {
          let refactoredItem = item.split("<__>")[0];
          obj[refactoredItem] = refactoredItem;
          return obj;
        }, {});
        allChoicesList = Object.keys(allChoicesList);
      }
      if (questionnaireType == "mcq") {
        let val = Object.values(choices).some((eachChoice) =>
          answersData.includes(eachChoice.split("<__>")[0])
        );
        setAnsSelected(val);
      } else {
        const saqChoices = Object.values(choices).map(
          (eachChoice) => eachChoice.split("<__>")[0]
        );
        setAnsSelected(saqChoices.includes(answersData[0]?.split("<__>")[0]));
      }

      if (allChoicesList?.length != allChoices?.length) {
        setAnsSelected({ duplicates: true });
      }
    }
  }, [choices, formRef.current?.getFieldsValue().answers]);

  return (
    <Row ref={ref}>
      <Col span={24}>
        <Row className={"viewTyutElemCard"}>
          <Col
            span={24}
            className={"viewQuestionnaireType"}
            style={{ gap: "12px" }}
          >
            <Select
              className={inputStyles.smSelect}
              value={optionsType}
              style={{ minWidth: "120px" }}
              onChange={(val) => {
                setOptionsType(val);
                setAnsSelected(false);
                if (val === "images") {
                  setChoices({
                    option1: "",
                    option2: "",
                    option3: "",
                    option4: "",
                  });
                } else {
                  setChoices({
                    option1: "Option 1",
                    option2: "Option 2",
                    option3: "Option 3",
                    option4: "Option 4",
                  });
                }
              }}
              options={[
                { value: "text", label: "Text" },
                { value: "images", label: "Images" },
              ]}
            />
            <Select
              className={inputStyles.smSelect}
              value={questionnaireType}
              style={{ minWidth: "120px" }}
              onChange={(val) => {
                setQuestionnaireType(val);
                setAnsSelected(false);
              }}
              options={[
                { value: "mcq", label: "Checkbox" },
                { value: "saq", label: "Radio" },
              ]}
            />
          </Col>
          <Col span={24} className={"viewQuestionnaireContent"}>
            {questionnaireType == "mcq" ? (
              <MCQ
                mcq={editing.data || {}}
                choices={choices}
                isAnsSelected={isAnsSelected}
                questionnaire={questionnaire}
                setQuestionnaire={setQuestionnaire}
                setAnsSelected={setAnsSelected}
                setChoices={setChoices}
                formRef={formRef}
                sopContent={sopContent}
                setSopContent={setSopContent}
                optionsType={optionsType}
              />
            ) : (
              <SAQ
                saq={editing.data || {}}
                questionnaire={questionnaire}
                setQuestionnaire={setQuestionnaire}
                choices={choices}
                isAnsSelected={isAnsSelected}
                setAnsSelected={setAnsSelected}
                setChoices={setChoices}
                formRef={formRef}
                sopContent={sopContent}
                setSopContent={setSopContent}
                optionsType={optionsType}
              />
            )}
          </Col>
          <Col span={24} className={"viewQuestionnaireEditBtn"}>
            <div
              key={"1"}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: `${editing.edit ? "0" : "24px"}`,
                gap: "15px",
              }}
            >
              {editing.edit && (
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => {
                    const updatedTyutElems = sopContent.map((eachTyut) => {
                      if (eachTyut.id === editing.data.id) delete eachTyut.edit;
                      return eachTyut;
                    });
                    setSopContent([...updatedTyutElems]);
                    setEditing({ edit: false, data: null });
                  }}
                >
                  Cancel
                </Button>
              )}
              <Popover
                placement="left"
                content={
                  <>
                    {isAnsSelected.duplicates ? (
                      <Typography.Text>
                        Options should be unique
                      </Typography.Text>
                    ) : !isAnsSelected ? (
                      <Typography.Text>
                        {questionnaireType == "mcq" ? (
                          <Checkbox checked disabled />
                        ) : (
                          <Radio checked disabled />
                        )}{" "}
                        Choose an answer
                      </Typography.Text>
                    ) : formRef.current?.getFieldsValue().question?.trim()
                        ?.length == 0 ||
                      !formRef.current?.getFieldsValue().question?.trim() ? (
                      <Typography.Text>Add question title</Typography.Text>
                    ) : (
                      <div>Proceed to submit</div>
                    )}
                  </>
                }
                trigger="hover"
              >
                <Button
                  className={btnStyles.mdBtn}
                  type="primary"
                  disabled={
                    !isAnsSelected ||
                    isAnsSelected?.duplicates ||
                    formRef.current?.getFieldsValue().question == 0
                  }
                  onClick={() => {
                    if (formRef.current === null) {
                      message.error(
                        "Please complete the questionnaire to submit"
                      );
                    } else {
                      formRef.current?.submit();
                      const isFormFilled = Object.values(
                        formRef.current?.getFieldsValue() || {}
                      ).every((eachVal) => {
                        if (!eachVal) {
                          setEditing({ edit: null, data: null });
                          return false;
                        }
                        return true;
                      });
                      if (isFormFilled) {
                        setEditing({ edit: false, data: null });
                      }
                    }
                    setIsUnfilled("#406aff");
                  }}
                >
                  Save
                  <SaveOutlined />
                </Button>
              </Popover>
            </div>
          </Col>
        </Row>
        <div className={"activeCardTag"} style={{ background: isUnfilled }} />
      </Col>
    </Row>
  );
}

export default React.forwardRef(TyutsQuestion);
