import {
  Col,
  ConfigProvider,
  Empty,
  Pagination,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getAllAssetsApi,
  getProductionLines,
} from "../../services/assets.services";
import dayjs from "dayjs";
import { getAllJobsApi } from "../../services/maintenance.services";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { generateScheduleStringForTable } from "../Maintenance/CreateMaintanance/helper";
import {
  MAINTANANCETEXT,
  PAGESIZE,
  colorMap,
} from "../../constants/defaultKeys";
import { convertUTCToLocalFormat } from "../../helpers/utility";

const ProdLineDateWiseView = ({
  startDate,
  endDate,
  currEvent,
  setCurrEvent,
  refresh,
}) => {
  const [productionLines, setProductionLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const getRangeDates = (startDate, endDate) => {
    let dates = [];
    let currentDate = dayjs(startDate);

    // Iterate until the current date is after the end date
    while (currentDate.isBefore(dayjs(endDate).add(1, "day"))) {
      dates.push(currentDate.format("MMM DD"));
      currentDate = currentDate.add(1, "day");
    }

    return dates;
  };

  const fetchProductionLines = async (plJobMap) => {
    let query = { page: pageNumber };
    try {
      const res = await getProductionLines(query);
      let updated_pls = [];
      res?.data?.assets?.map((item) => {
        updated_pls.push({
          ...item,
          date_wise_data: plJobMap?.[item?._id] ?? null,
        });
      });
      setProductionLines(updated_pls);
      setTotalCount(res?.data?.productionUnitCount);
    } catch (error) {
      // console.log(error);
      setProductionLines([]);
      setTotalCount(0);
    } finally {
      setLoading(false);
    }
  };

  const fetchPMS = async () => {
    let params = {
      type: MAINTANANCETEXT,
      start_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD"),
    };
    // const queryString = new URLSearchParams(params);
    setLoading(true);
    try {
      const resp = await getAllJobsApi(params);
      let plJobMap = {};
      resp?.data?.occurrences?.map((item) => {
        let occurenceDate = convertUTCToLocalFormat(item?.occurrence_date);
        let dateVal = dayjs(occurenceDate).format("MMM DD");
        let plId = item?.maintenance?.production_line?._id;

        if (plId) {
          if (plJobMap?.[plId]) {
            if (plJobMap?.[plId]?.[dateVal]) {
              if (!plJobMap?.[plId]?.[dateVal]?.[item?.code]) {
                plJobMap[plId][dateVal] = {
                  ...plJobMap[plId][dateVal],
                  [`${item?.code}`]: item,
                };
              }
            } else {
              plJobMap[plId] = {
                ...plJobMap[plId],
                [`${dateVal}`]: { [`${item?.code}`]: item },
              };
            }

            if (!plJobMap?.[plId]?.[item?.code]) {
              plJobMap[plId] = {
                ...plJobMap[plId],
                [`${item?.code}`]: item?.maintenance,
              };
              plJobMap[plId]["pm_list"] = [
                ...plJobMap[plId]["pm_list"],
                item?.code,
              ];
            }
          } else {
            plJobMap = {
              ...plJobMap,
              [`${plId}`]: {
                [`${dateVal}`]: { [`${item?.code}`]: item },
                [`${item?.code}`]: item?.maintenance,
                pm_list: [item?.code],
              },
            };
          }
        }
      });
      fetchProductionLines(plJobMap);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchPMS();
    }
  }, [refresh, pageNumber]); // currEvent

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dates = getRangeDates(startDate, endDate);
  const dateColumns = dates?.map((d) => {
    return {
      title: (
        <Typography className={typoStyles.body1} style={{ color: "#020202" }}>
          {d}
        </Typography>
      ),
      dataIndex: d,
      key: d,
      width: 165,
      render: (_, record) => {
        return (
          <Space direction="vertical" style={{ width: "100%" }}>
            {record?.date_wise_data?.pm_list?.map((item, index) => {
              let jobData = record?.date_wise_data?.[d]?.[item];
              if (jobData) {
                let occurenceDate = convertUTCToLocalFormat(
                  jobData?.occurrence_date
                );
                const currStartTime = dayjs(occurenceDate).format("hh:mm A");
                const currEndTime = dayjs(
                  convertUTCToLocalFormat(jobData?.occurrence_end_date)
                ).format("hh:mm A");
                // const utcOffset = new Date(
                //   jobData?.occurrence_date
                // ).getTimezoneOffset();
                // const currStartTime = dayjs(
                //   new Date(
                //     new Date(jobData?.occurrence_date).getTime() +
                //       utcOffset * 60000
                //   )
                // ).format("HH:mm");
                // const currEndTime = dayjs(
                //   new Date(
                //     new Date(jobData?.occurrence_end_date).getTime() +
                //       utcOffset * 60000
                //   )
                // ).format("HH:mm");

                return (
                  <Space
                    direction="vertical"
                    style={{
                      gap: 4,
                      cursor: "pointer",
                      width: "100%",
                      backgroundColor:
                        colorMap[index % colorMap.length].bgColor,
                    }}
                    className="assetPmCard"
                    onClick={() =>
                      setCurrEvent([{ ...jobData, type: "pm", occ: jobData }])
                    }
                  >
                    <Typography
                      className={typoStyles.body1}
                      style={{
                        color: colorMap[index % colorMap.length].textColor,
                      }}
                    >
                      {weekdays[dayjs(occurenceDate).day()]}
                    </Typography>
                    <Typography
                      className={typoStyles.body1}
                      style={{
                        color: colorMap[index % colorMap.length].textColor,
                      }}
                    >
                      {`${currStartTime}-${currEndTime}`}
                    </Typography>
                  </Space>
                );
              }
            })}
          </Space>
        );
      },
    };
  });
  const tableColumns = [
    {
      title: (
        <Typography className={typoStyles.body1} style={{ color: "#020202" }}>
          Production Line
        </Typography>
      ),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 200,
    },
    {
      title: (
        <Typography className={typoStyles.body1} style={{ color: "#020202" }}>
          Scheduled At
        </Typography>
      ),
      dataIndex: "Scheduled at",
      key: "Scheduled at",
      fixed: "left",
      width: 250,
      render: (val, record) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            {record?.date_wise_data?.pm_list?.map((item, index) => {
              let data = record?.date_wise_data?.[item];
              return (
                <Space align="start" style={{ gap: 2 }}>
                  <Tag color={colorMap[index % colorMap.length].tagColor}>
                    {item}
                  </Tag>
                  {generateScheduleStringForTable(
                    data?.["start_date"],
                    dayjs(data?.["start_time"], "HH:mm:ss"),
                    data?.["repeat"],
                    data?.["week_days"],
                    data?.["day_of_month"],
                    data?.["month_option"],
                    data?.["month"],
                    data?.["every"]
                  ) || ""}
                </Space>
              );
            })}
          </Space>
        );
      },
    },
    ...dateColumns,
  ];

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <Col span={24}>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: "#f5f8ff",
                  },
                },
              }}
            >
              <Table
                pagination={false}
                bordered
                scroll={{
                  x: 1200,
                  y: "60vh",
                }}
                columns={tableColumns}
                dataSource={productionLines}
                locale={{
                  emptyText: (
                    <Result
                      icon={Empty.PRESENTED_IMAGE_SIMPLE}
                      title={<Typography>{"No data found"}</Typography>}
                    />
                  ),
                }}
              />
            </ConfigProvider>
          </Col>
          {totalCount > PAGESIZE && (
            <Col span={24} className="text-center">
              <Pagination
                current={pageNumber}
                pageSize={PAGESIZE}
                total={totalCount}
                onChange={(e) => setPageNumber(e)}
                showSizeChanger={false}
              />
            </Col>
          )}
        </>
      )}
    </>
  );
};

export default ProdLineDateWiseView;
