import axiosInstance from "../config/axios/axios.config";

export const getInventoryList = (query) => {
  return axiosInstance.get(`/inventory/all`, { params: query });
};

export const getInventoryDetails = (id) => {
  return axiosInstance.get(`/inventory/${id}`);
};

export const addInventory = (payload) => {
  return axiosInstance.post(`/inventory`, payload);
};

export const updateInventory = (id, payload) => {
  return axiosInstance.put(`/inventory/${id}`, payload);
};

export const deleteInventoryApi = (id) => {
  return axiosInstance.delete(`/inventory/${id}`);
};

export const createInventoryRequest = (payload) => {
  return axiosInstance.post(`/inventory/requests`, payload);
};

export const updateInventoryRequest = (ids, payload) => {
  return axiosInstance.patch(
    `/inventory/requests/${ids.inventoryRequestId}/${ids.assetId}/${ids.inventoryId}`,
    payload
  );
};
