import React from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import { Card, Col, Form, Input, Row } from "antd";

const SensorData = () => {
  return (
    <Card
      title="Asset Sensor Threshold Details"
      style={{ height: "100%" }}
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Form.Item
            label="Temperature (max. threshold in °C)"
            name={["sensor_config", "temp"]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter maximum temperature threshold in °C"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Vibrations (max. threshold in m/s²)"
            name={["sensor_config", "vibrations"]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter maximum vibrations threshold in m/s²"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Pressure (max. threshold in Pa)"
            name={["sensor_config", "pressure"]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter maximum pressure threshold in Pa"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Cycles (max. threshold)"
            name={["sensor_config", "cycles"]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter maximum cycles threshold"
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default SensorData;
