import { Button, Col, Form, Input, Row, message } from "antd";
import React, { useState, useRef } from "react";
import { SaveOutlined } from "@ant-design/icons";
import btnStyles from "../../../../../assets/styles/Custom/Button.module.css";
import "../../../SOP.css";
import inputStyles from "../../../../../assets/styles/Custom/Input.module.css";
import { generateRandomString } from "../../../../../helpers/utility";

function TextElem(
  { sopContent, setSopContent, editing, setEditing, isUnfilled, setIsUnfilled },
  ref
) {
  const [questionnaire, setQuestionnaire] = useState({
    text: editing?.data?.text || "",
  });
  const [form] = Form.useForm();
  const text = editing?.data || {};

  const formRef = useRef(null);

  return (
    <Row ref={ref} id={text.id}>
      <Col span={24}>
        <Row
          className={"viewSOPElemCard"}
          style={{
            borderRadius: "16px",
            boxShadow: " 0px 1px 4px 0px rgba(0, 0, 0, 0.16)",
          }}
        >
          <Col span={24} className={"viewQuestionnaireContent"}>
            <Form
              requiredMark={false}
              style={{ width: "100%" }}
              ref={formRef}
              form={form}
              name="enter-text"
              layout="vertical"
              onFinish={(val) => {
                // if (val.answers !== null) {
                if (text.id) {
                  sopContent.map((eachTyut) => {
                    if (eachTyut.id === text.id) {
                      eachTyut.text = val.text;
                      eachTyut.type = "text";
                    }
                  });
                  setSopContent([...sopContent]);
                } else {
                  let newTyuts = sopContent.filter(
                    (eachTyut) => eachTyut.edit != true
                  );
                  setSopContent([
                    ...newTyuts,
                    {
                      id: generateRandomString(12),
                      text: val.text,
                      type: "text",
                    },
                  ]);
                }
                //   setAnsSelected(true);
                // } else {
                //   setAnsSelected(false);
                // }
              }}
              initialValues={{
                text: questionnaire?.text || null,
              }}
            >
              <Form.Item
                name="text"
                rules={[
                  {
                    required: true,
                    message: "Please add text",
                  },
                ]}
              >
                <Input.TextArea
                  className={inputStyles.smInput}
                  style={{ minWidth: "100%" }}
                  placeholder="Enter your text here"
                  value={questionnaire.text}
                  onChange={(e) => setQuestionnaire({ text: e.target.value })}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={24} className={"viewQuestionnaireEditBtn"}>
            <div
              key={"1"}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: `${editing.edit ? "0" : "24px"}`,
                gap: "15px",
              }}
            >
              {editing.edit && (
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => {
                    const updatedTyutElems = sopContent.map((eachTyut) => {
                      if (eachTyut.id === editing.data.id) delete eachTyut.edit;
                      return eachTyut;
                    });
                    setSopContent([...updatedTyutElems]);
                    setEditing({ edit: false, data: null });
                  }}
                >
                  Cancel
                </Button>
              )}

              <Button
                className={btnStyles.mdBtn}
                type="primary"
                disabled={formRef.current?.getFieldsValue().text == 0}
                onClick={() => {
                  if (formRef.current === null) {
                    message.error("Please enter the text to submit");
                  } else {
                    formRef.current?.submit();
                    const isFormFilled = Object.values(
                      formRef.current?.getFieldsValue() || {}
                    ).every((eachVal) => {
                      if (!eachVal) {
                        setEditing({ edit: null, data: null });
                        return false;
                      }
                      return true;
                    });
                    if (isFormFilled) {
                      setEditing({ edit: false, data: null });
                    }
                  }
                  setIsUnfilled("#406aff");
                }}
              >
                Save
                <SaveOutlined />
              </Button>
            </div>
          </Col>
        </Row>
        <div className={"activeCardTag"} style={{ background: isUnfilled }} />
      </Col>
    </Row>
  );
}

export default React.forwardRef(TextElem);
