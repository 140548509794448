import {
  Card,
  Col,
  DatePicker,
  Empty,
  Flex,
  Form,
  Image,
  Input,
  Row,
  Select,
  Tag,
  TimePicker,
  Typography,
  TreeSelect,
  Breadcrumb,
  ConfigProvider,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  getAllAssetsApi,
  getAssetLocationByIDApi,
} from "../../../services/assets.services";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import {
  getAssetsPath,
  priorityOptions,
  statusOptions,
} from "../../../helpers/utility";
import { getUsersandGroupsApi } from "../../../services/users.services";
import WODocuments from "./WODocumnts";
import { UserContext } from "../../../context/UserProvider";
import { ONPREMISE } from "../../../constants/defaultKeys";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const GeneralDetails = ({
  form,
  workOrderDetails,
  edit,
  woDocuments,
  setWoDocuments,
  setSopData,
  productionLines,
  setPlId,
}) => {
  const params = useParams();
  const [assets, setAssets] = useState([]);
  const [image, setImage] = useState({ name: "", status: "" });
  const [supervisors, setSuperVisors] = useState([]);
  const [technicians, setTechinicans] = useState([]);
  const [location, setLocation] = useState([]);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const workTypeOptions = [
    {
      label: "Electrical",
      value: "Electrical",
    },
    {
      label: "Mechanical",
      value: "Mechanical",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  const currentStaus = statusOptions.filter(
    (status) => status?.value == workOrderDetails?.asset?.status
  )?.[0];

  const handleAssetChange = async (value, e) => {
    getLocation(value);
    if (e?.image) {
      const [, afterCmms] = e?.image?.split("cmms/");
      const encodedImage = encodeURIComponent(afterCmms);
      setImage({
        name: e?.image,
        // name: storageType == ONPREMISE ? encodedImage : e?.image,
        status: e?.status,
      });
    } else {
      setImage({ name: "", status: "" });
    }
    setPlId(null);
    // form.setFieldsValue({
    //   assigned_users: [],
    //   assigned_groups: [],
    //   assigned_list: [],
    // });
    form.setFieldValue("production_line", undefined);
  };

  const handleProductionLineChange = (value) => {
    let plObject = productionLines?.filter((i) => i?.value === value)?.[0];
    setLocation([{ title: plObject?.location?.name }]);
    // getTecniciansandGroups(plObject?.location?._id);
    setImage({ name: "", status: "" });
    setPlId(value);
    // form.setFieldsValue({
    //   assigned_users: [],
    //   assigned_groups: [],
    //   assigned_list: [],
    // });
    form.setFieldValue("asset", undefined);
  };

  const getLocation = async (id) => {
    try {
      const resp = await getAssetLocationByIDApi(id);
      if (resp?.data) {
        setLocation(resp?.data?.map((each) => ({ title: each?.name })));
      }
      // if (resp?.data && resp?.data?.length) {
      //   getTecniciansandGroups(resp?.data?.[resp?.data?.length - 1]?.id);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const getAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          // label: asset?.name,
          value: asset?._id,
          image: asset?.file,
          status: asset?.status,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getTecniciansandGroups = async (location_id) => {
    try {
      const params = {};
      params.role = "technician";
      // params.facility_id = location_id;

      // const queryString = new URLSearchParams(params);
      const resp = await getUsersandGroupsApi(params);
      let assignedUsers = [],
        assignedGroups = [];

      const users = resp?.data?.users?.map((user) => {
        workOrderDetails?.assigned_users?.includes(user?._id) &&
          assignedUsers.push(user?._id);
        return {
          value: user._id,
          title: `${user?.first_name} ${user?.last_name || ""}`,
          group: false,
          id: user?._id,
        };
      });

      const groups = resp?.data?.groups?.map((group) => {
        workOrderDetails?.assigned_groups?.includes(group?._id) &&
          assignedGroups.push(group?._id);
        return {
          value: group._id,
          title: group.name,
          group: true,
          children: group.members.map((member) => ({
            value: `${member._id}-${group?._id}`,
            title: `${member?.first_name} ${member?.last_name || ""}`,
            group: false,
            id: member?._id,
          })),
        };
      });

      setTechinicans([...users, ...groups]);
      form.setFieldsValue({
        assigned_users: assignedUsers,
        assigned_groups: assignedGroups,
        assigned_list: [...assignedGroups, ...assignedUsers],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getUserByRole = async (role, searchValue) => {
    try {
      const params = {};
      if (searchValue) params.name = searchValue;
      if (role || role?.length) params.role = role;

      // const queryString = new URLSearchParams(params);
      const resp = await getUsersandGroupsApi(params);

      let assignedSupervisor = [];
      const users = resp?.data?.users?.map(({ first_name, last_name, _id }) => {
        if (workOrderDetails?.verifiers?.includes(_id)) {
          assignedSupervisor.push(_id);
        }
        return {
          value: _id,
          label: `${first_name} ${last_name || ""}`,
          group: false,
        };
      });

      // const groups = resp?.data?.groups?.map(({ name, _id }) => ({
      //   value: _id,
      //   label: name,
      //   group: true,
      // }));
      console.log("supp", users);

      setSuperVisors(users);
      form.setFieldValue("verifiers", assignedSupervisor);

      // const userArray = role === "technician" ? users : groups;
      // if (role === "technician") {
      //   // setTechinicans([
      //   //   {
      //   //     label: <span>Users</span>,
      //   //     title: "Users",
      //   //     options: users,
      //   //   },
      //   //   {
      //   //     label: <span>Groups</span>,
      //   //     title: "Groups",
      //   //     options: groups,
      //   //   },
      //   // ]);
      // } else {
      //   console.log("userrrrrrr", users);
      //   setSuperVisors(users);
      //   console.log(assignedSupervisor);
      //   form.setFieldValue("supervisor", assignedSupervisor);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const id = workOrderDetails?.asset;
    id && getLocation(id);
    // let encodedImage = "";
    // if (workOrderDetails?.asset_image) {
    //   const [, afterCmms] = workOrderDetails?.asset_image?.split("cmms/");
    //   encodedImage = encodeURIComponent(afterCmms);
    // }

    setImage({
      name:
        form.getFieldValue("asset_image") ||
        (storageType == ONPREMISE
          ? workOrderDetails?.asset_image || ""
          : // ? encodedImage
            workOrderDetails?.asset_image),
      status:
        form.getFieldValue("asset_status") || workOrderDetails?.asset?.status,
    });
  }, [form, workOrderDetails]);

  useEffect(() => {
    if (
      !params?.id ||
      (params?.id && workOrderDetails && Object.keys(workOrderDetails).length)
    ) {
      getAssets();
      // getUserByRole("technician");
      getTecniciansandGroups();
      getUserByRole(["supervisor", "manager"]);
    }
  }, [workOrderDetails]);

  // useEffect(() => {
  //   if (
  //     // !params?.id ||
  //     params?.id &&
  //     workOrderDetails &&
  //     Object.keys(workOrderDetails).length
  //   ) {
  //     getTecniciansandGroups(workOrderDetails?.asset?.location?._id);
  //   }
  // }, [workOrderDetails]);

  const handleDisabledEndDate = (current) => {
    // Get the selected start date from the form values
    const startDate = form.getFieldValue("start_date");

    // Disable dates that are before the selected start date
    return current && current.isBefore(startDate, "day");
  };

  const handleDisabledEndTime = (current) => {
    const startDate = form.getFieldValue("start_date");
    if (!startDate) {
      return {};
    }
    const startHours = startDate.hour();
    const startMinutes = startDate.minute();

    if (current && current.isSame(startDate, "day")) {
      return {
        disabledHours: () =>
          Array.from({ length: 24 }, (_, i) => i).splice(0, startHours),
        disabledMinutes: (selectedHour) => {
          if (selectedHour === startHours) {
            return Array.from({ length: 60 }, (_, i) => i).splice(
              0,
              startMinutes
            );
          }
          return [];
        },
      };
    }
    return {};
  };

  return (
    <Card
      title="Work Order Details"
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      {/* {edit ? ( */}
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name={"asset_status"}
            style={{ height: "0px", margin: "0px" }}
          ></Form.Item>

          <Form.Item
            style={{ height: "0px", margin: "0px" }}
            name={"asset_image"}
          ></Form.Item>
          <Form.Item
            label="Title"
            name={"name"}
            rules={[
              {
                whitespace: true,
                message:
                  "Workorder title cannot be empty or contain only spaces",
              },
              { required: true, message: "Please enter workorder title" },
            ]}
          >
            <Input
              style={{ width: "100%" }}
              placeholder="Enter title here"
              disabled={!edit}
              className={inputStyles.smInput}
            />
          </Form.Item>
          {/* <Flex
            style={{
              width: "100%",
            }}
            gap={12}
          > */}
          <Flex
            style={{
              width: "100%",
              flexDirection: "column",
              marginBottom: "12px",
            }}
          >
            <Form.Item
              style={{
                width: "100%",
                marginBlock: "0px 6px",
              }}
              label={"Asset"}
              name={"asset"}
              // rules={[{ required: true, message: "Please select asset" }]}
            >
              <Select
                disabled={params?.id ? true : false}
                placeholder="Select asset"
                style={{
                  width: "100%",
                }}
                className={inputStyles.smSelect}
                showSearch={true}
                // optionFilterProp="label"
                filterOption={(inputValue, option) =>
                  option.name
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={handleAssetChange}
                options={assets}
              />
            </Form.Item>
          </Flex>
          <Typography style={{ textAlign: "center" }}>or</Typography>

          <Form.Item
            style={{
              width: "100%",
              marginBlock: "0px 6px",
            }}
            label={"Production line"}
            name={"production_line"}
            // rules={[{ required: true, message: "Please select asset" }]}
          >
            <Select
              disabled={params?.id ? true : false}
              placeholder="Select production line"
              style={{
                width: "100%",
              }}
              className={inputStyles.smSelect}
              onChange={handleProductionLineChange}
              options={productionLines}
            />
          </Form.Item>
          {/* </Flex> */}
          {location?.length > 0 && (
            <Flex>
              <Form.Item label="Location">
                <div
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #d0d5dd",
                    padding: "4px 12px",
                  }}
                >
                  <ConfigProvider
                    theme={{
                      components: {
                        Breadcrumb: {
                          itemColor: "#2f3542",
                          lastItemColor: "#2f3542",
                        },
                      },
                    }}
                  >
                    <Breadcrumb separator=">" items={location} />
                  </ConfigProvider>
                </div>
              </Form.Item>
            </Flex>
          )}
          <Flex style={{ width: "100%" }} gap={12}>
            <Form.Item
              style={{
                width: "100%",
              }}
              label={"Work order type"}
              name={"type"}
            >
              <Select
                disabled={!edit}
                placeholder="Select work order type"
                style={{
                  width: "100%",
                }}
                className={inputStyles.smSelect}
                onChange={() => {}}
                options={workTypeOptions}
              />
            </Form.Item>
            <Form.Item
              style={{
                width: "100%",
              }}
              label={"Priority"}
              name={"priority"}
            >
              <Select
                disabled={!edit}
                placeholder="Select priority"
                defaultValue={"moderate"}
                style={{
                  width: "100%",
                }}
                className={inputStyles.smSelect}
                onChange={() => {}}
                options={priorityOptions}
              />
            </Form.Item>
          </Flex>
          <Form.Item
            style={{
              height: "0px",
              margin: "0px",
            }}
            name={"assigned_users"}
          ></Form.Item>
          <Form.Item
            style={{
              height: "0px",
              margin: "0px",
            }}
            name={"assigned_groups"}
          ></Form.Item>
          <Flex
            style={{
              width: "100%",
            }}
            gap={12}
          >
            <Form.Item
              style={{
                width: "100%",
              }}
              label={"Assign technician"}
              name={"assigned_list"}
            >
              <TreeSelect
                allowClear
                multiple={true}
                treeCheckable={true}
                className={inputStyles.smSelect}
                showSearch
                placeholder={"Assign technician"}
                treeData={technicians}
                style={{ width: "100%" }}
                showCheckedStrategy="SHOW_PARENT"
                filterTreeNode={(input, treeNode) => {
                  const inputValue = input.toUpperCase();
                  const title = treeNode.title.toUpperCase();
                  const match = title.indexOf(inputValue) !== -1;

                  return match;
                }}
                onSelect={(e, values) => {
                  const id = values.value.split("-")[1];
                  if (values?.group) {
                    if (values?.children?.length === 0) {
                      message.info(
                        `There are no technicians present in "${values?.title}" group.`
                      );
                    }
                    form.setFieldsValue({
                      assigned_groups: [
                        ...(form.getFieldValue("assigned_groups") || []),
                        e,
                      ],
                    });
                  } else {
                    form.setFieldsValue({
                      assigned_users: [
                        ...(form.getFieldValue("assigned_users") || []),
                        id || e,
                      ],
                    });
                  }
                  // values?.group
                  //   ? form.setFieldsValue({
                  //       assigned_groups: [
                  //         ...(form.getFieldValue("assigned_groups") || []),
                  //         e,
                  //       ],
                  //     })
                  //   : form.setFieldsValue({
                  //       assigned_users: [
                  //         ...(form.getFieldValue("assigned_users") || []),
                  //         id || e,
                  //       ],
                  //     });
                }}
                disabled={!edit}
                onDeselect={(e, values) => {
                  const id = values.value.split("-")[1];
                  const updatedAssignedGroups = form
                    .getFieldValue("assigned_groups")
                    ?.filter((val) => val !== e);
                  const updatedAssignedUsers = form
                    .getFieldValue("assigned_users")
                    ?.filter((val) => val !== (id || e));

                  if (values?.group) {
                    form.setFieldsValue({
                      assigned_groups: updatedAssignedGroups,
                    });
                  } else {
                    form.setFieldsValue({
                      assigned_users: updatedAssignedUsers,
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              style={{
                width: "100%",
              }}
              label="Verifiers"
              name={"verifiers"}
            >
              <Select
                className={inputStyles.smSelect}
                allowClear
                placeholder={"Select verifiers"}
                mode="multiple"
                options={supervisors}
                style={{ width: "100%" }}
                filterOption={(inputValue, option) =>
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={(e) => console.log(e)}
                // onSearch={(value) => {
                //   if (value) {
                //     getUserByRole("supervisor", value);
                //   }
                // }}
                disabled={!edit}
              />
            </Form.Item>
          </Flex>
          <Flex
            style={{
              width: "100%",
            }}
            gap={12}
          >
            <Form.Item
              style={{
                width: "100%",
              }}
              label="Scheduled to start at"
              // label="Start date & time"
              name={"start_date"}
              rules={[
                { required: true, message: "Please select start date & time" },
              ]}
            >
              <DatePicker
                disabled={!edit}
                format="YYYY-MM-DD HH:mm"
                onChange={() => form.setFieldsValue({ end_date: null })}
                placeholder="Select start date & time"
                size="medium"
                showTime={{
                  defaultValue: dayjs("00:00", "HH:mm"),
                }}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            <Form.Item
              style={{
                width: "100%",
              }}
              // rules={[{ required: true, message: "Please select end date & time" }]}
              name={"end_date"}
              label="Scheduled to close at"
              // label="End date & time"
            >
              <DatePicker
                disabledDate={handleDisabledEndDate}
                disabledTime={handleDisabledEndTime}
                disabled={!edit}
                format="YYYY-MM-DD HH:mm"
                placeholder="Select end date & time"
                size="medium"
                showTime={{
                  defaultValue: dayjs("00:00", "HH:mm"),
                }}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Flex>
          {/* <Flex
            style={{
              width: "100%",
            }}
            gap={12}
          >
            <Form.Item
              rules={[{ required: true, message: "Please select start time" }]}
              style={{
                width: "100%",
              }}
              label={"Start time"}
              name={"start_time"}
            >
              <TimePicker
                format="HH:mm"
                style={{ width: "100%" }}
                placeholder="Select start time"
                disabled={!edit}
                size="medium"
                className={inputStyles.smInput}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Please select end time" }]}
              style={{
                width: "100%",
              }}
              label={"End time"}
              name={"end_time"}
            >
              <TimePicker
                format="HH:mm"
                style={{ width: "100%" }}
                placeholder="Select end time"
                disabled={!edit}
                size="medium"
                className={inputStyles.smInput}
              />
            </Form.Item>
          </Flex> */}
          <Flex
            style={{
              width: "100%",
            }}
            gap={12}
          >
            <div style={{ width: "50%", marginTop: "16px" }}>
              <WODocuments
                form={form}
                woDocuments={woDocuments}
                setWoDocuments={setWoDocuments}
                edit={edit}
              />
            </div>
          </Flex>
        </Col>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
            }}
          >
            {image?.status && (
              <Typography>
                Asset status:{" "}
                <Tag className={tagStyles?.smTag}> {image.status}</Tag>
              </Typography>
            )}
            {image?.name ? (
              <Image
                // preview={false}
                src={getAssetsPath(image.name, storageType)}
                alt="Asset"
                style={{
                  width: "100%",
                  height: "400px",
                  borderRadius: "12px",
                }}
              />
            ) : (
              <Empty
                description="No asset images"
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
        </Col>
      </Row>
      {/* ) : (
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Typography style={{ fontWeight: 300, color: "#000" }}>
                  Title
                </Typography>
                <Typography>{workOrderDetails?.code}</Typography>
              </Col>
              <Col span={12}>
                <Typography style={{ fontWeight: 300, color: "#000" }}>
                  Asset
                </Typography>
                <Typography>{workOrderDetails?.asset?.file || "-"}</Typography>
              </Col>
              <Col span={12}>
                <Typography style={{ fontWeight: 300, color: "#000" }}>
                  Priority
                </Typography>
                <Typography>{workOrderDetails?.priority}</Typography>
              </Col>
              <Col span={12}>
                <Typography style={{ fontWeight: 300, color: "#000" }}>
                  Techinician
                </Typography>
                <Typography>{workOrderDetails?.assign_to}</Typography>
              </Col>
              <Col span={12}>
                <Typography style={{ fontWeight: 300, color: "#000" }}>
                  Supervisor
                </Typography>
                <Typography>{workOrderDetails?.supervisor}</Typography>
              </Col>
              <Col span={12}>
                <Typography style={{ fontWeight: 300, color: "#000" }}>
                  Due Start Date
                </Typography>
                <Typography>{workOrderDetails?.start_date}</Typography>
              </Col>
              <Col span={12}>
                <Typography style={{ fontWeight: 300, color: "#000" }}>
                  Due End Date
                </Typography>
                <Typography>{workOrderDetails?.end_date}</Typography>
              </Col>
            </Row>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
            }}
          >
            {workOrderDetails?.asset?.file ? (
              <Image
                // preview={false}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                // src={`${process.env.REACT_APP_BASE_URL_DOMAIN}/images/${image}`}
                alt="Asset"
                style={{
                  width: "100%",
                  height: "220px",
                  borderRadius: "12px",
                }}
              />
            ) : (
              <Empty
                description="No asset images"
                style={{ width: "100%", height: "100%" }}
              />
            )}
            {workOrderDetails?.asset?.status && (
              <Typography>
                Asset status:{" "}
                <Tag color={currentStaus?.color} className={tagStyles?.smTag}>
                  {currentStaus?.label}
                </Tag>
              </Typography>
            )}
          </Col>
        </Row>
      )} */}
    </Card>
  );
};

export default GeneralDetails;
