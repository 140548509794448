import { Col } from "antd";
import React, { useState } from "react";
import AddElemModal from "./AddElem/AddElemModal";
import Questionnaire from "./AddElem/Questionnaire/Questionnaire";
import ViewMCQ from "./viewSop/ViewMCQ";
import ViewSAQ from "./viewSop/ViewSAQ";
import ViewVideo from "./viewSop/ViewVideo";
import ViewDoc from "./viewSop/ViewDoc";
import "../SOP.css";
import ViewText from "./viewSop/ViewText";
import TextElem from "./AddElem/Questionnaire/TextElem";
import SectionComp from "./AddElem/SectionComp";
import TyutsQuestion from "./AddElem/Questionnaire/TyutsQuestion";
import { SOP, TYUT } from "../../../constants/defaultKeys";

function AllElems(
  {
    item,
    contentType = SOP,
    state: sopContent,
    setState: setSopContent,
    editing,
    setEditing,
    viewType,
    isDragging,
    isUnfilled,
    setIsUnfilled,
    isUploadLimitReached,
  },
  ref
) {
  const [isModalOpen, setModalOpen] = useState({ open: false, type: "" });
  const [activeTab, setActiveTab] = useState("");

  return (
    <Col
      key={item?.id}
      span={24}
      className={"allTyutElems"}
      style={{ marginTop: "24px" }}
    >
      {(item.type === "video" || item.type === "document") && item?.edit ? (
        <>
          <AddElemModal
            isUploadLimitReached={isUploadLimitReached}
            data={item}
            sopContent={sopContent}
            setSopContent={setSopContent}
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </>
      ) : item.type === "section" ? (
        <SectionComp
          sopContent={sopContent}
          setSopContent={setSopContent}
          ref={ref}
          isUnfilled={isUnfilled}
          setIsUnfilled={setIsUnfilled}
          editing={editing}
          setEditing={setEditing}
          data={item}
        />
      ) : (item.type === "checkbox" || item.type === "radio") &&
        contentType == TYUT &&
        item?.edit ? (
        <TyutsQuestion
          ref={ref}
          contentType={contentType}
          isUnfilled={isUnfilled}
          setIsUnfilled={setIsUnfilled}
          sopContent={sopContent}
          setSopContent={setSopContent}
          editing={editing}
          setEditing={setEditing}
          type={item.type === "checkbox" ? "mcq" : "saq"}
          optionType={item.optionType || "text"}
        />
      ) : (item.type === "checkbox" || item.type === "radio") &&
        contentType == SOP &&
        item?.edit ? (
        <Questionnaire
          ref={ref}
          contentType={contentType}
          isUnfilled={isUnfilled}
          setIsUnfilled={setIsUnfilled}
          sopContent={sopContent}
          setSopContent={setSopContent}
          editing={editing}
          setEditing={setEditing}
          type={item.type === "checkbox" ? "mcq" : "saq"}
        />
      ) : item.type === "text" && item?.edit ? (
        <TextElem
          ref={ref}
          isUnfilled={isUnfilled}
          setIsUnfilled={setIsUnfilled}
          sopContent={sopContent}
          setSopContent={setSopContent}
          editing={editing}
          setEditing={setEditing}
          type={"text"}
        />
      ) : item.type === "checkbox" ? (
        <ViewMCQ
          contentType={contentType}
          mcq={item}
          disabled={isDragging}
          sopContent={sopContent}
          setSopContent={setSopContent}
          editing={editing}
          setEditing={setEditing}
          setIsUnfilled={setIsUnfilled}
          viewType={viewType}
        />
      ) : item.type === "radio" ? (
        <ViewSAQ
          contentType={contentType}
          saq={item}
          sopContent={sopContent}
          setSopContent={setSopContent}
          editing={editing}
          setEditing={setEditing}
          viewType={viewType}
          setIsUnfilled={setIsUnfilled}
        />
      ) : item.type == "video" ? (
        <ViewVideo
          contentType={contentType}
          videoElem={item}
          sopContent={sopContent}
          setSopContent={setSopContent}
          setModalOpen={setModalOpen}
          setActiveTab={setActiveTab}
          viewType={viewType}
          setIsUnfilled={setIsUnfilled}
        />
      ) : item.type == "document" ? (
        <ViewDoc
          docElem={item}
          sopContent={sopContent}
          setSopContent={setSopContent}
          setModalOpen={setModalOpen}
          setActiveTab={setActiveTab}
          viewType={viewType}
        />
      ) : item.type == "text" ? (
        <ViewText
          textElem={item}
          sopContent={sopContent}
          setSopContent={setSopContent}
          editing={editing}
          setEditing={setEditing}
          viewType={viewType}
          setIsUnfilled={setIsUnfilled}
        />
      ) : (
        <strong>{item?.title}</strong>
      )}
    </Col>
  );
}

export default React.forwardRef(AllElems);
